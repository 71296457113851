import React from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { StructureCoverImageIconXS } from '../../utils/icons/StructureIcon';
import i18n from './../../i18n';

const Activities: React.FC = () => {
    const navigate = useNavigate();
    const activities: any = useLoaderData();

    return (
        <div className="flex flex-col w-full p-8 m-auto">
            {/* create new activity */}
            <div className='flex flex-col'>
                <div className='flex flex-col gap-4  py-8'>
                    <p className="text-4xl font-bold text-center">{i18n.t('task_management')}</p>
                    <p className="text-lg font-semibold text-center">{i18n.t('task_management_info_message')}</p>
                </div>
                <button
                    className="bg-light_black text-white font-bold py-2 px-4 rounded-full"
                    onClick={() => navigate('/backoffice/activities/create')}
                >
                    {i18n.t('create_new_activity')}
                </button>
            </div>

            {/* list activities */}
            <div className='flex flex-col'>
                {activities?.response?.length > 0 && <p className="text-4xl font-semibold mt-20 mb-8 text-center ">{i18n.t('activity_list')}</p>}

                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-20'>
                    {activities.response.map((activity: any, index: number) => (
                        <div key={index} className='flex flex-col gap-4 cursor-pointer'>
                            <ActivitiesBox activity={activity} />
                        </div>
                    ))
                    }
                </div>
            </div>
        </div >

    )
}

interface IProps {
    activity: any
}

const ActivitiesBox: React.FC<IProps> = ({ activity }) => {

    const navigate = useNavigate();

    const onPressHandler = () => {
        navigate(`/backoffice/activities/manage/${activity.id}`)
    }

    return (
        <div className='flex flex-col border border-light_black' onClick={onPressHandler}>
            {!!activity.image
                ? <img
                    className='w-full object-cover aspect-video'
                    src={activity.image}
                    alt='img_activity'
                />
                : <div className='w-full h-1/2 object-cover rounded-b flex justify-center items-center' style={{ backgroundColor: '#F1F1F1' }}>
                    <StructureCoverImageIconXS />
                </div>
            }

            <div className='flex flex-col justify-center gap-2 items-center text-center h-1/2'>
                <div className='flex flex-col'>
                    <p className='font-semibold text-2xl mb-2 mt-10 line-clamp-1'>{activity.title}</p>
                    <p className='text-md line-clamp-1'>{activity.city}, {activity.address}</p>
                </div>
            </div>
        </div>
    )
}


export default Activities