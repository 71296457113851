import { useEffect, useState } from 'react';
import { Link, Navigate, Outlet, useNavigate, useLoaderData, useLocation, useParams, useNavigation } from 'react-router-dom';
import useCookie from '../../hooks/useCookie';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import useIsOpen from '../../hooks/useIsOpen';
import { HttpMethod, apiService } from '../../api/apiService';
import { ChatIcon, ChatIconActive } from '../../utils/icons/ChatIcon';
import { HomeIcon, HomeIconActive } from '../../utils/icons/HomeIcon';
import { BookingIcon, BookingIconActive } from '../../utils/icons/BookingIcon';
import { AdminPanelIcon } from '../../utils/icons/AdminPanelIcon';
import { UserSetting } from '../../utils/icons/UserSetting';
import { USER_ROLES } from '../../utils/enums/user_roles.enum';
import { replaceUser } from '../../redux/auth/authSlice';
import moment from 'moment';
import { UserNoBGIcon } from '../../utils/icons/UserNoBGIcon';
import { BurgerMenuIcon } from '../../utils/icons/BurgerMenuIcon';
import useScreenSize from '../../hooks/useScreenSize';
import { StructureIcon } from '../../utils/icons/StructureIcon';
import { ConnivioLogo } from '../../utils/icons/ConnivioLogo';
import { ItineraryIcon, ItineraryIconActive } from '../../utils/icons/ItineraryIcon';
import useSocket from '../../hooks/useSocket';
import { replaceUnreadMessages } from '../../redux/chat/chatSlice';
import { IMessage } from '../../utils/interfaces/db_models/message.interface';
import checkPageStatus from '../../utils/notification';
import Modal from '../../components/Modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import i18n from './../../i18n';
import LanguagesMenu from '../../components/LanguagesMenu';

export const AppLayout = () => {
    const response = useLoaderData() as IUser;
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.auth) as { user: IUser };
    const { unreadMessages } = useAppSelector(state => state.chat) as { unreadMessages: { chatRoomId: string, unreadMessageCount: number }[] };
    const { isOpen, setIsOpen, ref } = useIsOpen(false);
    const { isOpen: burgerOpen, setIsOpen: setBurgerOpen, ref: burgerRef } = useIsOpen(false);
    const { isOpen: bookingLinkOpen, setIsOpen: setBookingLinkOpen } = useIsOpen(false);
    const { cookieData, setCookie, removeCookie } = useCookie({ accessToken: 'accessToken', refreshToken: 'refreshToken', structure: 'structure', admin: 'admin' });
    const navigate = useNavigate();
    const navigation = useNavigation();
    const location = useLocation();
    const screenSize = useScreenSize();
    const socket = useSocket();

    const bookingToken = localStorage.getItem('bookingToken');

    const totalUnreadMessages = unreadMessages.reduce((total, message) => total + message.unreadMessageCount, 0);

    useEffect(() => {
        const element = document.querySelector('.iubenda-tp-btn.iubenda-cs-preferences-link');
        if (element)
            document.body.removeChild(element)
    }, []);

    useEffect(() => {
        if (response) {
            dispatch(replaceUser(response));
        } else {
            removeCookie('refreshToken');
            removeCookie('accessToken');
            dispatch(replaceUser(undefined));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, response]);

    useEffect(() => {
        if (socket && !!user) {
            socket.emit('join private room', user.id);
            listUnreadMessage();
        }
    }, [socket, user]);

    useEffect(() => {
        if (!!socket) {
            socket.on('new message notification', (body: { chatRoomId: string, message: IMessage }) => {
                checkPageStatus(body.message, navigate);
                listUnreadMessage();
            });

            socket.on('initial messages', () => {
                listUnreadMessage();
            });

            return () => {
                socket.disconnect();
            };
        }
    }, [socket]);

    useEffect(() => {
        if (bookingToken) {
            setBookingLinkOpen(true);
        } else {
            setBookingLinkOpen(false);
        }
    }, [bookingToken]);

    if (!bookingToken) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('bookingToken');

        if (token) {
            localStorage.setItem('bookingToken', token);
        }
    }

    const logout = async () => {
        try {
            const response = await apiService(HttpMethod.POST, '/users/logout', undefined, undefined, { isRefreshToken: true });

            if (response.modifiedCount) {
                removeCookie('accessToken');
                removeCookie('refreshToken');
                navigate('/', { replace: true });
            }

            return response;
        } catch (error) {
            throw error
        }
    };

    const listUnreadMessage = async () => {
        try {
            const response = await apiService(HttpMethod.GET, '/messages/list/unread/' + user.id);
            dispatch(replaceUnreadMessages(response));
        } catch (error) {
            throw error
        }
    };

    if (!cookieData.refreshToken) {
        return <Navigate to="/" />
    } else if (!response?.isProfileCompleted) {
        return <Navigate to={'/utilities/profile/complete/' + response.id} />
    } else if (cookieData.structure && (response.role !== USER_ROLES.USER)) {
        return <Navigate to={"/backoffice/" + moment().format('YYYY')} />
    } else if (cookieData.admin && response.role === USER_ROLES.ADMIN) {
        return <Navigate to="/admin" />
    }

    return <div className="flex flex-col h-full w-full text-light_black">
        <div className='w-full h-16 p-2 px-6 flex justify-between bg-white shadow items-center z-20 relative'>
            {(screenSize === 'xs' || screenSize === 'sm') &&
                <div onClick={() => setBurgerOpen(!burgerOpen)} className='cursor-pointer' >
                    <BurgerMenuIcon />
                </div>
            }

            {burgerOpen && (
                <div ref={burgerRef} className="absolute top-full right-0 p-4 px-6 bg-white rounded-b shadow flex flex-col gap-8 w-full font-semibold text-xl md:text-sm">

                    <Link to="/app" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname === '/app' && 'text-orange'}`}>
                        {location.pathname === '/app' ? <HomeIconActive /> : <HomeIcon />}
                        <p>{i18n.t('home')}</p>
                    </Link>

                    <Link to="bookings" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/app/bookings') && 'text-orange'}`}>
                        {location.pathname.startsWith('/app/bookings') ? <BookingIconActive /> : <BookingIcon />}
                        <p>{i18n.t('bookings')}</p>
                    </Link>

                    <Link to="chatrooms" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/app/chatrooms') && 'text-orange'}`}>
                        <div className='relative'>
                            {location.pathname.startsWith('/app/chatrooms') ? <ChatIconActive /> : <ChatIcon />}
                            {!!totalUnreadMessages && <div className={`h-3 w-3 bg-orange aspect-square rounded-full absolute top-0 right-0 ${location.pathname.startsWith('/app/chatrooms') ? 'border border-white' : ''}`} />}
                        </div>
                        <p>{i18n.t('chat')}</p>
                    </Link>

                    <Link to="/app/itinerary/chatbot" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/app/itinerary') && 'text-orange'}`}>
                        {location.pathname.startsWith('/app/itinerary') ? <ItineraryIconActive /> : <ItineraryIcon />}
                        <p>{i18n.t('itinerary')}</p>
                    </Link>
                </div>
            )}

            <Link to="/app">
                <ConnivioLogo />
            </Link>

            <div className='flex justify-end items-center gap-6 md:w-full'>
                {(screenSize !== 'xs' && screenSize !== 'sm') &&
                    <>
                        <Link to="/app">
                            {location.pathname === '/app' ? <HomeIconActive /> : <HomeIcon />}
                        </Link>

                        <Link to="bookings">
                            {location.pathname.startsWith('/app/bookings') ? <BookingIconActive /> : <BookingIcon />}
                        </Link>

                        <Link to="chatrooms" className='relative'>
                            {location.pathname.startsWith('/app/chatrooms') ? <ChatIconActive /> : <ChatIcon />}
                            {!!totalUnreadMessages && <div className={`h-3 w-3 bg-orange aspect-square rounded-full absolute top-0 right-0 ${location.pathname.startsWith('/app/chatrooms') ? 'border border-white' : ''}`} />}
                        </Link>

                        <Link to="/app/itinerary/chatbot">
                            {location.pathname.startsWith('/app/itinerary') ? <ItineraryIconActive /> : <ItineraryIcon />}
                        </Link>
                    </>
                }

                {!!user &&
                    <div ref={ref}>
                        <div onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' >
                            <UserNoBGIcon />
                        </div>

                        {isOpen && (
                            <div className="absolute h-fit overflow-auto top-full right-0 p-4 px-6 bg-white rounded-b shadow flex flex-col gap-8 md:gap-6 w-full md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 text-xl md:text-sm">

                                <div className='flex items-center justify-between gap-6 text-orange'>
                                    <div className='flex items-center gap-4 cursor-pointer'>
                                        <img alt={'profile_' + user.name} src={user.profile_image} style={{ height: 30, width: 30 }} className='rounded-full object-cover' />
                                        <p className='font-semibold line-clamp-1'>{i18n.t('user_profile')}</p>
                                    </div>
                                    <Link to={"user/" + user.id} onClick={() => setIsOpen(false)} className='hover:scale-110'>
                                        <UserSetting />
                                    </Link>
                                </div>

                                {user?.structureUsers?.map((structureUser, index) => {
                                    if (!structureUser.structure.isDisabled) {
                                        return <div key={index} onClick={() => setCookie('structure', structureUser.structure_id.toString(), { isPayload: false })} className='flex items-center justify-between gap-6 cursor-pointer'>
                                            <div className='flex items-center gap-4'>
                                                <img alt={'logo_' + structureUser.structure.name} src={structureUser.structure.logo_image} style={{ height: 30, width: 30 }} className='rounded-full object-cover' />
                                                <p className='font-semibold line-clamp-1'>{structureUser.structure.name}</p>
                                            </div>
                                        </div>
                                    } else {
                                        return null
                                    }
                                })}

                                {(user.role === USER_ROLES.BUSINESS || user.role === USER_ROLES.ADMIN) &&
                                    <Link to={'coliving/create'} onClick={() => setIsOpen(false)} title={!user.canCreateStructure ? i18n.t('can_create_structure_info') : undefined} className='flex items-center gap-4 cursor-pointer' >
                                        <div className={!user.canCreateStructure ? 'opacity-50 cursor-default' : undefined}><StructureIcon /></div>
                                        <div className='flex flex-col'>
                                            <p className={`font-semibold text-sm line-clamp-1  ${!user.canCreateStructure && 'opacity-50 cursor-default'}`}>{i18n.t('add_accommodation')}</p>
                                            {!user.canCreateStructure && <p className='font-semibold text-xs line-clamp-1 text-orange '>{i18n.t('not_enabled')}</p>}
                                        </div>
                                    </Link>
                                }

                                {user?.role === USER_ROLES.ADMIN &&
                                    <div className='flex items-center gap-4 cursor-pointer' onClick={() => setCookie('admin', user.id, { isPayload: false })}>
                                        <AdminPanelIcon />
                                        <p className='font-semibold line-clamp-1'>{i18n.t('administration')}</p>
                                    </div>
                                }

                                <LanguagesMenu />

                                <div className='border-b border-gray-300' />

                                {/* <div className='flex items-center gap-4'>
                                <PrivacyIcon />
                                <p className='font-semibold'>Sicurezza e privacy</p>
                            </div>

                            <div className='flex items-center gap-4'>
                                <SupportIcon />
                                <p className='font-semibold'>Assistenza e supporto</p>
                            </div>

                            <div className='border-b border-gray-300' /> */}

                                <button onClick={logout} className='bg-light_black text-white text-xl font-semibold rounded-full w-fit px-4 py-1 flex self-center my-2'>
                                    {i18n.t('logout')}
                                </button>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>

        <div className={`h-full w-full overflow-y-auto overflow-x-hidden ${(isOpen || burgerOpen) && "blur md:blur-none"}`}>
            <Outlet />

            <Modal isOpen={(bookingLinkOpen && !location.pathname.startsWith('/app/booking/invite'))} className='flex flex-col gap-8 font-medium text-base p-8 md:w-1/2' wFull={false} >
                <p className='text-2xl md:text-3xl font-bold text-center'>{i18n.t('you_have_been_invited_to_join_a_accommodation')}</p>
                <p className='text-sm md:text-lg text-center' dangerouslySetInnerHTML={{__html:i18n.t('invited_admin_info', {escapeValue:false})}}></p>

                {navigation.state === 'idle'
                    ? <Link to={'booking/invite/' + bookingToken} className="border border-light_black w-fit text-xl font-semibold rounded-full justify-center px-4 py-1 flex self-end">{i18n.t('view')}</Link>
                    : <LoadingIndicator label={i18n.t('loading')} />
                }
            </Modal>
        </div >
    </div >
};