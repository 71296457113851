import { Form, Link, useLoaderData, useNavigate } from 'react-router-dom'
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { useAppSelector } from '../../redux/store';
import moment from 'moment';
import { ArrowLeft2 } from '../../utils/icons/ArrowIcon';
import i18n from './../../i18n';

const UserProfile = () => {
    const userFetched = useLoaderData() as IUser;
    const { user } = useAppSelector(store => store.auth) as { user: IUser };
    const navigate = useNavigate();

    const currentBooking = userFetched.bookings.find(booking => {
        return moment(booking.fromDate).isSameOrBefore(moment()) && moment(booking.toDate).isSameOrAfter(moment());
    });

    return (
        <div className='flex flex-col p-8 gap-8 select-none w-full'>
            <div>
                <div className='flex w-full items-center justify-between'>
                    <div className='cursor-pointer' onClick={() => navigate(-1)}>
                        <ArrowLeft2 />
                    </div>

                    {user?.id !== userFetched?.id
                        ? <Form method='put'>
                            <input name="memberId" value={userFetched?.id} readOnly hidden />
                            <button type='submit' className='px-4 py-1 rounded-full bg-light_black text-white font-medium'>{i18n.t('send_message')}</button>
                        </Form>
                        : <Link to='manage' className='px-4 py-1 rounded-full bg-light_black h-fit text-white font-medium'>{i18n.t('edit')}</Link>
                    }

                </div>
                <div className='flex flex-col mt-10 md:flex-row w-full gap-4 md:gap-10'>
                    <div className='md:w-1/4 flex items-center flex-col md:flex-raw justify-center'>
                        <div className='h-44 w-44 rounded-full overflow-hidden' style={{ boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)' }}>
                            <img
                                className='w-full h-full object-cover'
                                src={userFetched.profile_image}
                                alt='profile-img'
                            />
                        </div>
                    </div>

                    <div className='w-full md:w-3/4 flex flex-col gap-2 truncate'>
                        <div className='flex justify-between flex-col w-full'>
                            <p className='text-2xl md:text-4xl font-bold'>
                                {userFetched.name}
                            </p>

                            <p className='text-sm md:text-lg font-semibold whitespace-pre-line'>
                                {userFetched.profession.name} - {userFetched.city} {userFetched.province}
                            </p>

                            {userFetched.phone && (user?.id === userFetched?.id) &&
                                <p className='font-medium text-sm text-gray-400 truncate'>
                                    <span className='text-base text-light_black'>{i18n.t('phone_number')}: </span>
                                    {userFetched.phone}
                                </p>
                            }

                            {userFetched.email && (user?.id === userFetched?.id) &&

                                <p className='font-medium text-sm text-gray-400 truncate'>
                                    <span className='text-base text-light_black'>{i18n.t('email')}: </span>
                                    {userFetched.email}
                                </p>
                            }

                        </div>

                        <div className='flex flex-col gap-1'>
                            <p className='text-lg font-bold'>{user?.id !== userFetched?.id ? i18n.t('affinity') : i18n.t('interests')}</p>
                            <div className='flex gap-2 flex-wrap'>
                                {!!userFetched.tagCategories.length && !!user
                                    ? userFetched.tagCategories.map((tag, index) => {
                                        const isTagCommon = user.id === userFetched.id ? true : user.tagCategories.find(userTag => userTag.id === tag.id);
                                        const style = "px-4 p-1 rounded-full border border-light_black font-medium";
                                        const commonTagStyle = "px-4 p-1 rounded-full bg-yellow-600 font-medium";
                                        return <p key={index} title={isTagCommon && i18n.t('tag_in_common')} className={isTagCommon ? commonTagStyle : style}>{tag.name}</p>
                                    })
                                    : <p className='text-sm text-gray-400'>{i18n.t('no_active_tags')}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full border-b border-light_black' />

            <div className='flex max-md:flex-col-reverse md:flex-raw w-full gap-4 md:gap-10'>
                <div className='w-full md:w-1/4 h-full justify-between flex flex-col gap-6'>
                    {!!currentBooking &&
                        <div className='flex flex-col gap-4'>
                            <p className='text-xl lg:text-3xl font-bold'>{i18n.t('current_coliving')}</p>

                            <div>
                                <p className='text-lg font-medium'>{currentBooking.structure.name}</p>
                                <p className='text-sm'>{currentBooking.structure.city}</p>
                            </div>

                            <div className='flex flex-col gap-1'>
                                {moment().isSameOrAfter(moment(currentBooking.fromDate)) && moment().isBefore(moment(currentBooking.toDate)) && (
                                    <div className="relative h-2 border border-light_black rounded overflow-hidden">
                                        <div className="absolute left-0 top-0 h-full bg-light_black rounded transition-transform"
                                            style={{ width: `${(moment().diff(moment(currentBooking.fromDate), 'days') / moment(currentBooking.toDate).diff(moment(currentBooking.fromDate), 'days')) * 100}%`, transform: 'scaleX(1.2)' }}
                                        />
                                    </div>
                                )}
                                <div className='flex justify-between'>
                                    <div>
                                        <p className='text-base lg:text-sm'>{i18n.t('days_of_stay')}</p>
                                        <p className='font-bold text-lg lg:text-base'>{moment(currentBooking.toDate).diff(moment(currentBooking.fromDate), 'days')} {i18n.t('days')}</p>
                                    </div>
                                    <div>
                                        <p className='text-base lg:text-sm text-right'>{i18n.t('remaining')}</p>
                                        <p className='font-bold text-lg lg:text-base'>{moment(currentBooking.toDate).diff(moment(), 'days')} {i18n.t('days')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {userFetched?.biography &&
                    <div className='w-full md:w-3/4 flex flex-col gap-2 h-full'>
                        <p className='text-xl lg:text-3xl font-bold'>{i18n.t('about_me')}</p>
                        <p className='font-medium text-base lg:text-sm whitespace-pre-line'>{userFetched.biography}</p>
                    </div>
                }
            </div>
        </div >
    )
}

export default UserProfile