export const ColivingIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.625 10.875H23.375V9.125H21.625V10.875ZM21.625 15.875H23.375V14.125H21.625V15.875ZM21.625 20.875H23.375V19.125H21.625V20.875ZM19.625 24.625V23.75H26.25V6.25H14V9.28125L13.125 8.65625V5.375H27.125V24.625H19.625ZM2.875 24.625V14.5625L10 9.5L17.125 14.5625V24.625H11.6875V19.1875H8.3125V24.625H2.875ZM3.75 23.75H7.4375V18.3125H12.5625V23.75H16.25V15L10 10.625L3.75 15V23.75Z" fill="black" />
        </svg>
    )
}

export const ColivingIconActive = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.625 10.875H23.375V9.125H21.625V10.875ZM21.625 15.875H23.375V14.125H21.625V15.875ZM21.625 20.875H23.375V19.125H21.625V20.875ZM2.875 24.625V14.5625L10 9.5L17.125 14.5625V24.625H12.25V18.625H7.75V24.625H2.875ZM19.625 24.625V13.3125L13.125 8.65625V5.375H27.125V24.625H19.625Z" fill="#D9981D" />
        </svg>
    )
}