import React, { ReactNode } from 'react';

interface ModalProps {
    isOpen: boolean;
    refs?: React.LegacyRef<HTMLDivElement>;
    children: ReactNode;
    className?: string;
    wFull?: boolean;
    heightFit?: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, refs, children, className, wFull = true, heightFit = false }) => {

    return (
        <>
            {isOpen && (
                <div className="absolute inset-0 flex items-center justify-center px-8 py-10 z-50">
                    <div className="fixed inset-0 bg-light_black opacity-50" />
                    <div ref={refs} className={`bg-white p-4 rounded-lg z-50 ${!heightFit && 'max-h-96'} h-fit overflow-auto container ${wFull && 'w-full'} select-none ${className}`}>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
