// socketHook.ts
import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { retrieveConfig } from '../config/config.environment';

const SOCKET_SERVER_URL = retrieveConfig().socketEndpoint;

const useSocket = (): Socket | null => {
    const [isSocketInitialized, setIsSocketInitialized] = useState(false);
    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        if (!SOCKET_SERVER_URL) {
            return;
        }

        const socket = io(SOCKET_SERVER_URL);
        socketRef.current = socket;

        socket.on('connect', () => {
            setIsSocketInitialized(true);
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, []);

    return isSocketInitialized ? socketRef.current : null;
};

export default useSocket;
