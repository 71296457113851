import { Form, useNavigate, useNavigation } from 'react-router-dom';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { ArrowLeft, ArrowLeft2, ArrowRight } from '../../utils/icons/ArrowIcon';
import { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import useIsOpen from '../../hooks/useIsOpen';
import { listItems } from '../../api/actions/genericActions';
import { ListInput } from '../../utils/interfaces/listInput.interface';
import { IProfessionCategory, ListProfessionCategoryInput } from '../../utils/interfaces/db_models/professionCategory.interface';
import { IProfession } from '../../utils/interfaces/db_models/profession.interface';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ITagCategory, ListTagCategoryInput } from '../../utils/interfaces/db_models/tagCategory.interface';
import CropImage from '../../components/CropImage';
import { useAppSelector } from '../../redux/store';
import { useFormik } from 'formik';
import i18n from './../../i18n';

const UserProfileManage = () => {
    const user = useAppSelector(store => store.auth.user) as IUser;
    const navigate = useNavigate();
    const navigation = useNavigation();
    const { isOpen, setIsOpen, ref } = useIsOpen(false);
    const { isOpen: tagOpen, setIsOpen: setTagOpen } = useIsOpen(false);

    const [professionsCategory, setProfessionsCategory] = useState<IProfessionCategory[]>([]);
    const [userTagCategoriesId, setUserTagCategoriesId] = useState<string[]>(user?.tagCategories?.map(tag => { return tag.id }) || []);
    const [selectedAffinitiesId, setSelectedAffinitiesId] = useState<string[]>(user?.affinities?.map(affinity => { return affinity.id }) || []);
    const [tagCategories, setTagCategories] = useState<ITagCategory[]>([]);
    const [categoryIndex, setCategoryIndex] = useState(0);
    const [professionIndex, setProfessionIndex] = useState(0);
    const [affinitiesIndex, setAffinitiesIndex] = useState(0);
    const [selectedProfession, setSelectedProfession] = useState<IProfession | undefined>();
    const [selectedImage, setSelectedImage] = useState<string | null>();
    const [isTab, setIsTab] = useState(true);

    useEffect(() => {
        if (isOpen) {
            getProfession();
        }
    }, [isOpen]);

    useEffect(() => {
        if (tagOpen) {
            getTagCategory();
        }
    }, [tagOpen]);

    const formik = useFormik({
        initialValues: {
            company_name_business: { label: i18n.t('business_name'), value: user?.company_name_business || "" },
            vat_business: { label: i18n.t('vat_number'), value: user?.vat_business || "" },
            unique_code_business: { label: i18n.t('unique_code'), value: user?.unique_code_business || "" },
            city_business: { label: i18n.t('city'), value: user?.city_business || "" },
            cap_business: { label: i18n.t('zip_code'), value: user?.cap_business || "" },
            province_business: { label: i18n.t('province'), value: user?.province_business || "" },
            address_business: { label: i18n.t('address'), value: user?.address_business || "" },
        },
        enableReinitialize: true,
        onSubmit: () => { }
    });

    const getProfession = async () => {
        try {
            const { response } = await listItems<ListInput<ListProfessionCategoryInput>, IProfessionCategory, ListProfessionCategoryInput>(
                '/profession-categories/get-all',
                { pageIndex: 0, pageSize: 100 },
                undefined,
                undefined,
                { professions: true }
            );

            if (!selectedProfession) {
                setProfessionsCategory(response.data);

                const index1 = response.data.findIndex(cat => cat.id === user.profession.category_id);
                const index2 = response.data[index1].professions.findIndex(prof => prof.id === user.profession_id);

                setCategoryIndex(index1);
                setProfessionIndex(index2);
                setSelectedProfession(response.data[index1]?.professions[index2]);
            }
        } catch (error) {
            throw error;
        }
    };

    const getTagCategory = async () => {
        try {
            const { response } = await listItems<ListInput<ListTagCategoryInput>, ITagCategory, ListTagCategoryInput>(
                '/tag-categories/get-all',
                { pageIndex: 0, pageSize: 100 },
            );

            setTagCategories(response.data);
        } catch (error) {
            throw error;
        }
    };

    const handleImageChange = (newImageSrc: string | null) => {
        setSelectedImage(newImageSrc);
    };

    return (!!user
        ? <Form method='PUT' encType="multipart/form-data" className='flex flex-col gap-8 p-8 select-none flex-1'>
            <div className='flex w-full items-center justify-between'>
                <button type='button' className='cursor-pointer' disabled={navigation.state !== 'idle'} onClick={() => navigate(-1)}>
                    <ArrowLeft2 />
                </button>
                <button type='submit' disabled={navigation.state !== 'idle'} className='px-4 py-1 rounded-full bg-light_black h-fit text-white font-medium'>
                    {i18n.t('save')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                </button>
            </div>

            <div className='flex w-full gap-4 md:gap-6 flex-col md:flex-row'>
                <div className='md:w-2/6 flex items-center flex-col md:flex-raw justify-center'>
                    <CropImage onImageChange={handleImageChange} name='image' rounded={true} maxWidth={512} maxHeight={512}>
                        <label htmlFor="image" className='cursor-pointer' >
                            <img
                                className='object-cover h-full md:h-44 md:w-44 aspect-square rounded-full'
                                style={{ boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)' }}
                                src={selectedImage || user.profile_image}
                                alt='img_coliving'
                            />
                        </label>
                    </CropImage>
                </div>

                <div className='w-full md:w-4/6 flex flex-col gap-4 truncate'>
                    <div className='flex justify-between'>
                        <div className='flex flex-col gap-2 w-full'>
                            <input name='name' placeholder={i18n.t('enter_a_name')} defaultValue={user.name} className='text-2xl md:text-4xl py-1 font-bold border-b border-gray-500 focus:outline-none w-full' />

                            <div className='text-sm md:text-lg font-semibold flex flex-wrap gap-1'>
                                <p className='cursor-pointer text-orange' onClick={() => setIsOpen(true)}>{user.profession.name}</p>
                                <p>-</p>
                                <p>{user.city} {user.province.toUpperCase()}</p>
                            </div>

                            <div className='flex font-medium truncate w-full'>
                                <p className='pl-0 p-1'>{i18n.t('phone_number')}: </p>
                                <input name='phone' placeholder={i18n.t('enter_a_phone_number')} className='text-sm w-full p-1 border-b border-gray-500 focus:outline-none' defaultValue={user.phone} />
                            </div>

                            <div className='flex font-medium items-baseline truncate justify-between w-full'>
                                <div className='flex flex-col md:flex-row items-baseline gap-2'>
                                    <p>{i18n.t('email')}: </p>
                                    <p className='text-sm'>{user.email}</p>
                                </div>
                                <div onClick={() => navigate('change/' + user.email)} className='text-sm font-bold text-orange cursor-pointer'>{i18n.t('edit_email')}</div>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col gap-1 w-full'>
                        <div className='flex justify-between items-center w-full'>
                            <p className='text-lg font-bold'>{i18n.t('interests')}</p>
                            <button
                                type='button'
                                className='text-sm font-bold text-orange cursor-pointer'
                                onClick={() => {
                                    setUserTagCategoriesId(user?.tagCategories?.map(tag => { return tag.id }) || []);
                                    setTagOpen(!tagOpen);
                                }}
                            >
                                {!tagOpen ? i18n.t('edit_interests') : i18n.t('close')}
                            </button>
                        </div>
                        <div className='flex gap-2 flex-wrap w-full'>
                            {!tagOpen
                                ? !!user.tagCategories
                                    ? user.tagCategories.map((tag, index) => {
                                        return <p key={index} className='px-4 p-1 rounded-full border border-light_black font-medium w-fit'>{tag.name}</p>
                                    })
                                    : <p className='text-sm text-gray_shady'>{i18n.t('no_active_tags')}</p>
                                : !!tagCategories?.length
                                    ? tagCategories.map((tag, index) => {
                                        const exist = userTagCategoriesId.find(id => id === tag.id);
                                        if (exist)
                                            return <div key={index} className='px-4 p-1 rounded-full border border-orange bg-orange font-medium w-fit cursor-pointer'
                                                onClick={() => setUserTagCategoriesId(prev => {
                                                    return !exist ? [...prev, tag.id] : prev.filter(aff => aff !== tag.id);
                                                })}
                                            >{tag.name}</div>

                                        return <div key={index} className='px-4 p-1 rounded-full border border-light_black  font-medium w-fit cursor-pointer'
                                            onClick={() => setUserTagCategoriesId(prev => {
                                                return !exist ? [...prev, tag.id] : prev.filter(aff => aff !== tag.id);
                                            })}
                                        >{tag.name}</div>
                                    })
                                    : <LoadingIndicator label={i18n.t('loading')} wFull={false} />
                            }

                            {/* INPUT FORM FOR TAG CATEGORY ARRAY */}
                            {!!userTagCategoriesId.length && <select multiple hidden={true} className='hidden' name='tagCategories[]' value={userTagCategoriesId} onChange={() => false}>
                                {userTagCategoriesId.map(tag =>
                                    <option hidden={true} key={tag} value={tag} />
                                )}
                            </select>}

                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full border-b border-light_black' />

            <div className='flex max-md:flex-col-reverse md:flex-raw w-full gap-4 md:gap-6'>
                {!!user.isBusiness &&
                    <div className='w-full md:w-2/6 h-full justify-between flex flex-col gap-2'>
                        <div>
                            <p className='text-xl lg:text-3xl font-bold'>{i18n.t('business_info')}</p>
                            <p className='text-xs font-medium'>{i18n.t('information_about_your_reality')}</p>
                        </div>

                        <div className='grid grid-cols-2 gap-x-4 gap-y-1 font-medium truncate w-full'>
                            {Object.entries(formik.values).map(([key, value]) => {
                                const errorValue = formik.errors[key as keyof typeof formik.values];

                                return <div key={key} className={`flex flex-col py-1 ${(key === 'company_name_business') && 'col-span-2'}`}>
                                    <label className='text-sm font-medium'>{value.label}</label>
                                    <input
                                        name={key}
                                        maxLength={key === 'cap_business' ? 5 : key === 'province_business' ? 2 : undefined}
                                        onChange={(e) => formik.setFieldValue(key, { ...value, value: key === 'province_business' ? e.target.value.toUpperCase() : e.target.value })}
                                        value={value.value as string}
                                        className='text-sm w-full border-b border-gray-500 focus:outline-none'
                                    />

                                    {!!errorValue && isOpen
                                        ? <div className='text-red-500 font-medium text-sm'>
                                            {errorValue.value}
                                        </div>
                                        : null}
                                </div>
                            })}
                        </div>
                    </div>}

                <div className={`w-full ${!!user.isBusiness ? 'md:w-4/6' : 'md:w-full'} flex flex-col gap-2 h-full`}>
                    <p className='text-xl lg:text-3xl font-bold'>{i18n.t('about_me')}</p>
                    <div className='w-full border rounded border-gray-500 px-1'>
                        <textarea rows={8} maxLength={1000} name='biography' placeholder={i18n.t('enter_a_description')} className='font-medium text-base lg:text-sm w-full p-1 -mb-1.5 focus:outline-none resize-none' defaultValue={user?.biography} />
                    </div>
                    <div className='flex flex-col w-full items-end'>
                        <p className='font-semibold text-sm opacity-60'>{i18n.t('max_1000_characters')}</p>
                    </div>
                </div>
            </div>

            <Modal refs={ref} isOpen={isOpen} wFull={false} className='w-full lg:w-1/2 h-full max-h-full'>
                <div className='flex flex-col overflow-auto gap-6 h-full items-center'>

                    <div className='flex w-full'>
                        <button type='button' className={`w-full text-xl font-medium px-4 py-1 opacity-95 ${isTab ? 'bg-orange border-orange' : 'bg-white_shady'}`} onClick={() => setIsTab(true)}>{i18n.t('profession')}</button>
                        <button type='button' className={`w-full text-xl font-medium px-4 py-1 opacity-95 ${!isTab ? 'bg-orange border-orange' : 'bg-white_shady'}`} onClick={() => setIsTab(false)}>{i18n.t('affinity')}</button>
                    </div>

                    {!!professionsCategory.length
                        ? <div className='flex flex-col gap-4 w-full items-center h-full justify-center'>
                            <div className='flex flex-col w-full lg:w-2/3 gap-4 self-center'>
                                <p className="text-2xl lg:text-3xl font-semibold text-center">{i18n.t('category')} {(isTab ? categoryIndex + 1 : affinitiesIndex + 1).toString().padStart(2, '0')}/{professionsCategory.length}</p>
                                <div className='flex items-center justify-between w-full gap-12'>
                                    <button type='button' disabled={isTab ? categoryIndex === 0 : affinitiesIndex === 0} className='disabled:opacity-50' onClick={() => {
                                        if (isTab) {
                                            setCategoryIndex(categoryIndex - 1);
                                            setProfessionIndex(0);
                                        } else {
                                            setAffinitiesIndex(affinitiesIndex - 1);
                                        }
                                    }}>
                                        <ArrowLeft />
                                    </button>
                                    <p className='font-semibold text-base lg:text-xl text-center'>{professionsCategory[isTab ? categoryIndex : affinitiesIndex].name}</p>
                                    <button type='button' disabled={isTab ? categoryIndex === professionsCategory.length - 1 : affinitiesIndex === professionsCategory.length - 1} className='disabled:opacity-50' onClick={() => {
                                        if (isTab) {
                                            setCategoryIndex(categoryIndex + 1);
                                            setProfessionIndex(0);
                                        } else {
                                            setAffinitiesIndex(affinitiesIndex + 1);
                                        }
                                    }}>
                                        <ArrowRight />
                                    </button>
                                </div>
                            </div>

                            {isTab
                                ? <div className='flex flex-col w-full lg:w-2/3 gap-4'>
                                    <p className="text-2xl lg:text-3xl font-semibold text-center">{i18n.t('profession')}</p>
                                    <div className='flex items-center justify-between w-full gap-12'>
                                        <button type='button' disabled={professionIndex === 0} className='disabled:opacity-50' onClick={() => {
                                            setProfessionIndex(professionIndex - 1);
                                            setSelectedProfession(professionsCategory[categoryIndex]?.professions[professionIndex - 1]);
                                        }}>
                                            <ArrowLeft />
                                        </button>
                                        <p className='font-semibold text-base lg:text-xl text-center'>{professionsCategory[categoryIndex].professions[professionIndex].name}</p>
                                        <button type='button' disabled={professionIndex === professionsCategory[categoryIndex].professions.length - 1} className='disabled:opacity-50' onClick={() => {
                                            setProfessionIndex(professionIndex + 1);
                                            setSelectedProfession(professionsCategory[categoryIndex]?.professions[professionIndex + 1]);
                                        }}>
                                            <ArrowRight />
                                        </button>
                                    </div>
                                </div>
                                : <div className='flex flex-col h-full gap-4'>
                                    <p className="text-2xl lg:text-3xl font-semibold text-center">{i18n.t('professions')} ({i18n.t('select2')} {selectedAffinitiesId.length})</p>
                                    <div className='flex gap-4 flex-wrap lg:justify-center w-full border-t border-light_black py-6'>
                                        {professionsCategory[affinitiesIndex].professions.map((profession, index) => {
                                            return <button
                                                key={index}
                                                type='button'
                                                className={`text-xl font-semibold rounded-full px-4 py-1 ${selectedAffinitiesId.find(aff => aff === profession.id) ? 'bg-orange' : 'border border-light_black'}`}
                                                onClick={() => setSelectedAffinitiesId(prevAffinities => {
                                                    const index = prevAffinities.indexOf(profession.id);
                                                    return index === -1 ? [...prevAffinities, profession.id] : prevAffinities.filter(aff => aff !== profession.id);
                                                })}
                                            >
                                                {profession.name}
                                            </button>
                                        })}
                                    </div>
                                </div>}
                        </div>
                        : <LoadingIndicator label={i18n.t('loading_professions')} additionalStyle='py-10 h-full' />
                    }

                    {/* INPUT FORM FOR AFFINITIES ARRAY */}
                    {!!selectedAffinitiesId.length && <select hidden multiple={true} name='affinities[]' value={selectedAffinitiesId} onChange={() => false}>
                        {selectedAffinitiesId.map(affinity => <option key={affinity} value={affinity} />)}
                    </select>}

                    {/* INPUT FORM FOR USER PROFESSION */}
                    {!!selectedProfession && <input type='hidden' name='profession_id' value={selectedProfession?.id} />}

                    <div className='w-full flex justify-center'>
                        <button type='submit' disabled={navigation.state !== 'idle'} className='px-4 py-1 rounded-full bg-light_black w-fit text-white font-medium'>
                            {i18n.t('save')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                        </button>
                    </div>
                </div>
            </Modal>

        </Form >
        : <LoadingIndicator icon additionalStyle='h-full' wFull />
    )
}

export default UserProfileManage