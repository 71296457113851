import { ITALIAN_REGIONS } from "../../enums/italian_regions.enum";
import { REQUEST_STATUS } from "../../enums/request_status.enum";
import { IBooking, ListBookingInput } from "./bookings.interface";
import { IChatRoom, ListChatRoomInput } from "./chatRoom.interface";
import { IModel } from "./index.interface";
import { IItinerary, ListItineraryInput } from "./itinerary.interface";
import { IStructureUser, ListStructureUserInput } from "./structureUser.interface";
import { ISuggestion, ListSuggestionInput } from "./suggestion.interface";

export interface IStructure extends IModel {
    id: string;
    name: string;
    email: string;
    phone_number: string;
    city: string;
    province: string;
    address: string;
    region: ITALIAN_REGIONS;
    latitude?: number;
    longitude?: number;
    description?: string;
    plain_services?: string;
    logo_image?: string;
    cover_image?: string;
    request_status: REQUEST_STATUS;
    isProfileCompleted: boolean;

    structureUsers: IStructureUser[];
    bookings: IBooking[];
    itinerarys: IItinerary[];
    suggestions: ISuggestion[];
    chatRooms: IChatRoom[];

    isDisabled: boolean;
}

export interface ListStructureInput {
    structureUser?: boolean | { include: ListStructureUserInput };
    bookings?: boolean | { include: ListBookingInput };
    itinerary?: boolean | { include: ListItineraryInput };
    suggestion?: boolean | { include: ListSuggestionInput };
    chatRoom?: boolean | { include: ListChatRoomInput };
};

export const fieldTypesStructure: Record<string, string> = {
    id: 'text',
    name: 'text',
    email: 'email',
    phone_number: 'text',
    address: 'text',
    city: 'text',
    province: 'text',
    vat: 'text',
    company_name: 'text',
    unique_code: 'text',
    latitude: 'number',
    longitude: 'number',
    request_status: 'text',
    created_at: 'text',
    updated_at: 'text',
    deleted: 'checkbox',
    version: 'number',
};