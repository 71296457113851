import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useActionData, useNavigate, useNavigation } from "react-router-dom";
import { getErrorMessage } from "../../utils/manageError";
import { PasswordHidden, PasswordVisible } from "../../utils/icons/PasswordVisibility";
import i18n from './../../i18n';

const RegisterUser = () => {
  const navigation = useNavigation();
  const navigate = useNavigate();
  const response = useActionData() as any;

  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    passwordConfirm: false
  });

  const [showErrors, setShowErrors] = useState(false);

  const userForm = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      city: '',
      province: '',
      password: '',
      passwordConfirm: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(i18n.t('required_field')),
      surname: Yup.string().required(i18n.t('required_field')),
      email: Yup.string().email(i18n.t('invalid_email_address')).required(i18n.t('required_field')),
      phone: Yup.string().required(i18n.t('required_field')),
      city: Yup.string().required(i18n.t('required_field')),
      province: Yup.string().required(i18n.t('required_field')),
      password: Yup.string().required(i18n.t('required_field')).matches(/^(?=.*[A-Z])(?=.*\d).{8,}$/, i18n.t('policy_password_info')),
      passwordConfirm: Yup.string().oneOf([Yup.ref('password')], i18n.t('the_passwords_must_match')).required(i18n.t('required_field')),
    }),
    onSubmit: (values) => { },
  });

  const handleConfirm = () => {
    if (Object.entries(userForm.errors).length > 0) {
      setShowErrors(true);
      return;
    }
  };

  return (
    <div className="h-full flex flex-col items-center justify-center gap-12 p-8 md:p-20">
      {/* form title */}
      <div className="flex flex-col md:items-center gap-4">
        <p className="text-6xl font-bold">{i18n.t('your_profile')}</p>
        <p className="text-2xl font-normal">{i18n.t('your_profile_message')}</p>
      </div>

      {/* formik form that renders the form based on the current step */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-6 w-full">
        <div>
          <p className="font-semibold text-lg">{i18n.t('name')}</p>
          <input
            placeholder={i18n.t('enter_your_name')}
            type="text"
            name="name"
            onChange={userForm.handleChange}
            value={userForm.values.name}
            className="border-b border-light_black py-2 w-full focus:outline-none"
          />
          <p className="text-red-500 text-sm mt-1">{showErrors && userForm.errors.name}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">{i18n.t('surname')}</p>
          <input
            placeholder={i18n.t('enter_your_surname')}
            type="text"
            name="surname"
            onChange={userForm.handleChange}
            value={userForm.values.surname}
            className="border-b border-light_black py-2 w-full focus:outline-none"
          />
          <p className="text-red-500 text-sm mt-1">{showErrors && userForm.errors.surname}</p>

        </div>
        <div>
          <p className="font-semibold text-lg">{i18n.t('email')}</p>
          <input
            placeholder={i18n.t('enter_your_email')}
            type="text"
            name="email"
            onChange={userForm.handleChange}
            value={userForm.values.email}
            className="border-b border-light_black py-2 w-full focus:outline-none"
          />
          <p className="text-red-500 text-sm mt-1">{showErrors && userForm.errors.email}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">{i18n.t('phone_number')}</p>
          <input
            placeholder={i18n.t('enter_your_phone_number')}
            type="text"
            name="phone"
            onChange={userForm.handleChange}
            value={userForm.values.phone}
            className="border-b border-light_black py-2 w-full focus:outline-none"
          />
          <p className="text-red-500 text-sm mt-1">{showErrors && userForm.errors.phone}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">{i18n.t('city')}</p>
          <input
            placeholder={i18n.t('enter_your_city')}
            type="text"
            name="city"
            onChange={userForm.handleChange}
            value={userForm.values.city}
            className="border-b border-light_black py-2 w-full focus:outline-none"
          />
          <p className="text-red-500 text-sm mt-1">{showErrors && userForm.errors.city}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">{i18n.t('province')}</p>
          <input
            placeholder={i18n.t('enter_your_province')}
            type="text"
            name="province"
            maxLength={2}
            onChange={userForm.handleChange}
            value={userForm.values.province}
            className="uppercase placeholder:normal-case border-b border-light_black py-2 w-full focus:outline-none"
          />
          <p className="text-red-500 text-sm mt-1">{showErrors && userForm.errors.province}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">{i18n.t('password')}</p>
          <div className='flex justify-between items-center border-b border-light_black'>
            <input
              placeholder={i18n.t('create_a_password')}
              type={passwordVisibility.password ? 'text' : 'password'}
              name="password"
              onChange={userForm.handleChange}
              value={userForm.values.password}
              className="py-2 w-full focus:outline-none"
            />
            <div onClick={() => setPasswordVisibility(
              { ...passwordVisibility, password: !passwordVisibility.password }
            )}>
              {passwordVisibility.password ? <PasswordVisible /> : <PasswordHidden />}
            </div>
          </div>
          <p className="text-red-500 text-sm mt-1">{(showErrors && !userForm.errors.password?.startsWith(i18n.t('the_password_must'))) && userForm.errors.password}</p>
        </div>
        <div>
          <p className="font-semibold text-lg">{i18n.t('confirm_password')}</p>
          <div className='flex justify-between items-center border-b border-light_black'>
            <input
              placeholder={i18n.t('confirm_password')}
              type={passwordVisibility.passwordConfirm ? 'text' : 'password'}
              name="passwordConfirm"
              onChange={userForm.handleChange}
              value={userForm.values.passwordConfirm}
              className="py-2 w-full focus:outline-none"
            />
            <div onClick={() => setPasswordVisibility(
              { ...passwordVisibility, passwordConfirm: !passwordVisibility.passwordConfirm }
            )}>
              {passwordVisibility.passwordConfirm ? <PasswordVisible /> : <PasswordHidden />}
            </div>
          </div>
          <p className="text-red-500 text-sm mt-1">{showErrors && userForm.errors.passwordConfirm}</p>
        </div>
      </div>

      <div className='flex flex-col gap-2 w-full -my-8'>
        <p className={`text-gray_shady text-left w-full text-xs ${(showErrors && userForm.errors.password?.startsWith(i18n.t('the_password_must'))) && 'text-red-500'}`}>{i18n.t('policy_password_info')}</p>
        <p className="text-red-500 text-sm text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
      </div>
      {/* navigation buttons */}
      <div className="flex gap-4 justify-end w-full">
        <button
          type="button"
          className="border border-light_black rounded-full font-medium px-4 py-1"
          disabled={navigation.state !== "idle"}
          onClick={() => navigate(-1)}
        >
          {i18n.t('cancel')}
        </button>
        <button
          className="border border-light_black text-white bg-light_black rounded-full font-medium px-4 py-1"
          type={Object.entries(userForm.errors).length > 0 ? 'button' : 'submit'}
          disabled={navigation.state !== "idle" || !userForm.dirty}
          onClick={handleConfirm}
        >
          {i18n.t('confirm')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
        </button>
      </div>
    </div>
  );
};

export default RegisterUser;
