import i18n from './../../i18n';

export enum REQUEST_STATUS {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
}

export const requestStatusDbMapping = {
    [REQUEST_STATUS.PENDING]: 'pending',
    [REQUEST_STATUS.SUCCESS]: 'active',
};

export const mapRequestStatus = (value: string) => {
    if (Object.keys(requestStatusDbMapping).includes(value)) {
        return requestStatusDbMapping[value as keyof typeof requestStatusDbMapping];
    }
    return value;
};

export const requestStatusOptions: { label: string; value: string }[] = Object.keys(REQUEST_STATUS).map(key => ({
    label: mapRequestStatus(String(key)),
    value: key,
}));

export const getRequestStatusColor = (status: REQUEST_STATUS): string => {
    switch (status) {
        case REQUEST_STATUS.PENDING:
            return 'bg-gray_shady';
        case REQUEST_STATUS.SUCCESS:
            return 'bg-green-600';
        default:
            return 'bg-light_black';
    }
};