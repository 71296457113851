import { useLoaderData, useNavigate } from "react-router-dom";
import { IUser, ListUserInput } from "../../utils/interfaces/db_models/user.interface";
import { Column, ColumnFiltersState, PaginationState, SortingState, createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../components/DataTable";
import { useState } from "react";
import SelectForm from "../../components/SelectForm";
import { listItems } from "../../api/actions/genericActions";
import { mapUserRoles, userRolesOptions } from "../../utils/enums/user_roles.enum";
import { ListInput } from "../../utils/interfaces/listInput.interface";
import useScreenSize from "../../hooks/useScreenSize";
import i18n from './../../i18n';

const columnHelper = createColumnHelper<IUser>();

const columns = [
    columnHelper.accessor('name', {
        id: 'name',
        header: i18n.t('name'),
        cell: info => info.getValue(),
    }),
    columnHelper.accessor('email', {
        header: i18n.t('email'),
        cell: info => info.renderValue(),
    }),
    columnHelper.accessor('phone', {
        header: i18n.t('phone_number'),
        cell: info => info.renderValue(),
        enableColumnFilter: false
    }),
    columnHelper.accessor('city', {
        header: i18n.t('city'),
        cell: info => info.renderValue(),
        enableColumnFilter: false
    }),
    columnHelper.accessor('role', {
        header: i18n.t('role'),
        cell: info => mapUserRoles(String(info.renderValue())),
        meta: { filterComponent: (props: { column: Column<IUser> }) => <SelectForm value={props.column.getFilterValue()} setValue={props.column.setFilterValue} options={userRolesOptions} /> },
        enableColumnFilter: false
    }),
    columnHelper.accessor('isVerified', {
        header: i18n.t('verified'),
        cell: info => <div className='flex w-full h-full items-center justify-center'>
            <div className={`aspect-square rounded-full h-4 w-4 ${!!info.renderValue() ? "bg-green-600" : "bg-red-600"}`} />
        </div>,
        meta: { filterComponent: (props: { column: Column<IUser> }) => <SelectForm value={props.column.getFilterValue()} setValue={props.column.setFilterValue} options={[{ label: i18n.t('verified'), value: true }, { label: i18n.t('not_verified'), value: false }]} /> },
        enableColumnFilter: false
    })
]

const Users = () => {
    const { response } = useLoaderData() as { response: { data: IUser[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number } };
    const navigate = useNavigate()
    const screenSize = useScreenSize();

    const [users, setUsers] = useState<{ data: IUser[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number }>(response);
    const [isLoading, setIsLoading] = useState(false);

    const handleLoadUsers = async (pagination: PaginationState, sorting: SortingState, columnFilters: ColumnFiltersState) => {
        setIsLoading(true);
        const { response } = await listItems<ListInput<ListUserInput>, IUser, ListUserInput>('/users/get-all', pagination, sorting, columnFilters);
        setUsers(response);
        setIsLoading(false);
    };

    const handleRowClick = (data: IUser) => {
        navigate('manage/' + data.id);
    }

    return (
        <div className='flex flex-col gap-6 px-10 sm:px-14 py-6 w-full h-full select-none text-light_black'>
            <div className='flex justify-between'>
                <p className='font-bold text-3xl'>{i18n.t('users')}</p>
                <p className='font-bold text-3xl'>{i18n.t('totals')} {users?.totalItems}</p>
            </div>

            <div className="border-t shadow -mx-20" />

            <DataTable<IUser>
                columns={
                    (screenSize === 'xs' || screenSize === 'sm')
                        ? [columns[0], columns[5]]
                        : (screenSize === 'md')
                            ? [columns[0], columns[1], columns[2], columns[5]]
                            : columns
                }
                data={users?.data}
                pageIndexs={users.pageIndex}
                pageSizes={users.pageSize}
                pageCount={users.totalPages}
                totalItems={users.totalItems}
                handleNext={handleLoadUsers}
                isLoading={isLoading}
                noDataMessage={i18n.t('no_user_found')}
                onRowClick={handleRowClick}
            />
        </div>
    )
}

export default Users