import moment from "moment";
import { Link } from "react-router-dom";
import { IBooking } from "../utils/interfaces/db_models/bookings.interface";
import React from "react";
import i18n from './../i18n';
import { BOOKING_STATUS, bookingStatusDbMapping, getBookingstatusColor, mapBookingStatus } from "../utils/enums/booking_status.enum";

interface IProps {
    booking: IBooking;
    redirectUrl?: string;
}

const BookingCard: React.FC<IProps> = ({ booking, redirectUrl = 'bookings/' }) => {

    const totalDays = moment(booking?.toDate).diff(moment(booking?.fromDate), 'days');
    const daysPassed = moment().startOf('days').diff(moment(booking?.fromDate), 'days');
    const progressPercentage = (daysPassed / totalDays) * 100;

    return (
        <Link to={redirectUrl + booking.id} className="flex w-full items-center gap-4">
            <img
                className={`object-cover aspect-square rounded-full h-12 w-12 md:h-28 md:w-28 ${booking.status === BOOKING_STATUS.COMPLETED && 'grayscale'}`}
                src={booking.structure.logo_image}
                alt=''
            />

            <div className="flex flex-col gap-2 w-full h-full justify-between">
                <div className="flex w-full justify-between items-center whitespace-nowrap">
                    <p className="font-bold text-2xl">{booking.structure.name}</p>
                    <div className={`border md:flex hidden ${booking.status === BOOKING_STATUS.COMPLETED ? 'bg-light_black text-white' : 'bg-yellow-600'} px-4 py-0.5 text-xl font-semibold rounded-full flex items-center`}>
                        <p>{i18n.t(bookingStatusDbMapping[booking.status])}</p>
                    </div>
                    <div title={i18n.t(mapBookingStatus(booking.status))} className='flex items-center justify-center md:hidden'>
                        <div className={`aspect-square rounded-full h-4 w-4 ${getBookingstatusColor(booking.status)}`} />
                    </div>
                </div>


                {booking.status === BOOKING_STATUS.ON_GOING &&
                    <>
                        <div className="relative h-3 rounded-full border-light_black border overflow-hidden">
                            <div
                                className="absolute left-0 top-0 h-full bg-light_black rounded-full transition-transform"
                                style={{ width: `${progressPercentage}%`, transform: 'scaleX(1.2)' }}
                            />
                        </div>
                        <div className="flex justify-between">
                            <div>
                                <p className="text-sm">{i18n.t('days_of_stay')}</p>
                                <p className="text-xl font-medium">{totalDays}</p>
                            </div>
                            <div className="text-right">
                                <p className="text-sm">{i18n.t('end_of_stay')}</p>
                                <p className="text-xl font-medium">{moment(booking.toDate).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                    </>
                }

                {booking.status === BOOKING_STATUS.PENDING && (
                    <>
                        <div className="border-b border-light_black" />
                        <div className="flex justify-between">
                            <div>
                                <p className="text-sm">{i18n.t('booking_date')}</p>
                                <p className="text-xl font-medium">{moment(booking.created_at).format('YYYY-MM-DD')}</p>
                            </div>
                            <div className="text-right">
                                <p className="text-sm">{i18n.t('arrival_date')}</p>
                                <p className="text-xl font-medium">{moment(booking.fromDate).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                    </>
                )}

                {booking.status === BOOKING_STATUS.COMPLETED && (
                    <>
                        <div className="border-b border-light_black" />
                        <div>
                            <p className="text-sm">{i18n.t('length_of_stay')}</p>
                            <p className="text-xl font-medium">
                                {moment(booking.fromDate).format('YYYY-MM-DD')} - {moment(booking.toDate).format('YYYY-MM-DD')}
                            </p>
                        </div>
                    </>
                )}
            </div>
        </Link>
    );
};

export default BookingCard