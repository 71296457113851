import { Form, Navigate, useActionData, useLoaderData, useNavigate, useNavigation } from 'react-router-dom'
import { IStructureUser } from '../../utils/interfaces/db_models/structureUser.interface'
import useIsOpen from '../../hooks/useIsOpen';
import { getErrorMessage } from '../../utils/manageError';
import Modal from '../../components/Modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useAppSelector } from '../../redux/store';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import moment from 'moment';
import i18n from './../../i18n';

const AdministratorsManage = () => {
    const cookies = document.cookie.split(';').map((c) => c.trim());
    const cookie = cookies.find((c) => c.startsWith('structure='));

    const administrator = useLoaderData() as IStructureUser;
    const response = useActionData() as any
    const { user } = useAppSelector(state => state.auth) as { user: IUser };
    const { isOpen, setIsOpen, ref } = useIsOpen(false);
    const navigation = useNavigation();
    const navigate = useNavigate();

    const currentStructureUser = user?.structureUsers?.find(structureUser => structureUser.structure_id === cookie?.split('=')[1]);

    if (!currentStructureUser?.admin) {
        return <Navigate to={"/backoffice/" + moment().format('YYYY')} />
    }

    return (
        <Form method='PUT' encType='multipart/form-data' className='flex flex-col gap-20 p-12 select-none m-auto'>

            {/* header */}
            <div className='flex flex-col gap-6 items-center w-full'>
                <p className='text-4xl md:text-6xl font-bold text-center'>
                    {!!administrator ? i18n.t('manage_administrator') : i18n.t('add_administrator')}
                </p>
                <p className='text-base md:text-xl font-medium text-center'>
                    {!!administrator
                        ? i18n.t('remove_administrator', {administrator_name: administrator.user.name})
                        : i18n.t('fill_in_all_the_required_data_to_add_an_administrator')
                    }
                </p>
            </div>

            <input
                disabled={!!administrator?.user_id}
                defaultValue={administrator?.user.email}
                placeholder={i18n.t('enter_the_email_of_a_connivio_user')}
                name='userEmail'
                className={`border-b-2 w-full text-xl border-light_black p-1 overflow-hidden font-medium focus:outline-none disabled:opacity-50`}
            />
            {
                !!administrator && <>
                    <input value={administrator?.id} name='structureUserId' hidden readOnly />
                    <input value={administrator?.user_id} name='userId' hidden readOnly />
                </>
            }

            <div className='flex flex-col w-full md:col-span-3 gap-1'>
                <div className='md:col-span-3 flex w-full h-full items-center justify-between md:justify-end gap-4'>
                    <button
                        type={'button'}
                        onClick={() => navigate(-1)}
                        disabled={navigation.state !== 'idle'}
                        className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl disabled:opacity-50'
                    >
                        {i18n.t('cancel')}
                    </button>
                    <button
                        type={!!administrator ? 'button' : 'submit'}
                        onClick={() => !!administrator && setIsOpen(true)}
                        disabled={navigation.state !== 'idle'}
                        className={`px-4 py-1 rounded-full ${!!administrator ? "bg-red-600 text-white" : "bg-light_black text-white"} h-fit font-semibold text-sm md:text-xl whitespace-nowrap disabled:opacity-50`}
                    >
                        {!!administrator ? i18n.t('disconnect') : i18n.t('add')}
                    </button>
                </div>

                <Modal isOpen={isOpen} refs={ref} className='md:w-96 flex flex-col items-center justify-center gap-6 text-center'>
                    <p className='font-bold text-xl md:text-4xl'>{i18n.t('wait')}</p>
                    <p className='text-base whitespace-pre-line'>{i18n.t('remove_administrator_message_info', {administrator_name: administrator?.user?.name, structure_name: administrator?.structure?.name})}</p>
                    {navigation.state === 'idle'
                        ? <div className='flex gap-4 w-full justify-around'>
                            <button
                                type='button'
                                onClick={() => setIsOpen(false)}
                                className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
                            >
                                {i18n.t('cancel')}
                            </button>
                            <button
                                type='submit'
                                disabled={navigation.state !== 'idle'}
                                onClick={() => navigate(-1)}
                                className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                            >
                                {i18n.t('proceed')}
                            </button>
                        </div>
                        : <LoadingIndicator label={i18n.t('wait_a_moment')} />
                    }
                </Modal>

                {response?.errorCode &&
                    <div className='flex flex-col items-center'>
                        <p className="text-red-500 text-base font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
                        <p className="text-red-500 text-xs font-medium">{i18n.t('unexpected_error')}: {response.message}</p>
                    </div>
                }
            </div>
        </Form >
    )
}

export default AdministratorsManage