import { useState } from 'react';
import { HttpMethod, apiService } from '../api/apiService';
import { IUser } from '../utils/interfaces/db_models/user.interface';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { replaceUser } from '../redux/auth/authSlice';
import i18n from './../i18n';

const TutorialChatBot = () => {
    const { user } = useAppSelector(state => state.auth) as { user: IUser };
    const dispatch = useAppDispatch();
    const [step, setStep] = useState(0);

    const updateTutorialStatus = async () => {
        const response = await apiService<IUser>(HttpMethod.PUT, 'users/update/' + user.id, { chatbotTutorial: true });
        dispatch(replaceUser(response));
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center px-8 py-10 bg-light_black bg-opacity-20 select-none z-50">
            <div className="bg-white rounded shadow-md w-full md:w-2/3 xl:w-1/2 p-6 h-fit max-h-full overflow-auto flex flex-col gap-4">
                {/* Step 1 */}
                {step === 0 && <div className="flex flex-col gap-2">
                    <p className="text-3xl font-bold">{i18n.t('welcome_to_the_itineraries_section')}</p>
                    <p className='text-sm font-medium' dangerouslySetInnerHTML={{__html:i18n.t('welcome_to_the_itineraries_section_message', {escapeValue:false})}}></p>
                </div>}

                {/* Step 2 */}
                {step === 1 && <div className="flex flex-col gap-2">
                    <div className='flex flex-col lg:flex-row items-cener justify-between w-full gap-4'>
                        <p className="text-3xl font-bold">{i18n.t('i_would_like_to')}</p>
                        <p className='text-sm font-medium'>
                            {i18n.t('tutorial_chatbot_step2')}
                        </p>
                        <p className='text-sm font-medium'>
                            {i18n.t('tutorial_chatbot_step2_text2')}
                        </p>
                        <img alt='img-1' className='h-56 object-contain' src='/images/tutorial_chatbot_1.png'></img>
                    </div>
                </div>}

                {/* Step 3 */}
                {step === 2 && <div className="flex flex-col gap-2">
                    <p className="text-3xl font-bold">{i18n.t('your_itinerary')}</p>
                    <p className='text-md font-semibold'>
                        {i18n.t('tutorial_chatbot_step3_text1')}
                    </p>
                    <div className='flex flex-col lg:flex-row items-center w-full gap-4'>
                        <img alt='img-2' className='h-36 object-contain' src='/images/tutorial_chatbot_2.png'></img>
                        <div className='flex flex-col gap-2 justify-between'>
                            <div>
                                <p className='text-base font-bold'>{i18n.t('affinity')}</p>
                                <p className='text-md font-semibold'>{i18n.t('tutorial_chatbot_step3_text2')}</p>
                            </div>
                            <div>
                                <p className='text-base font-bold'>{i18n.t('maps')}</p>
                                <p className='text-md font-semibold'>{i18n.t('tutorial_chatbot_step3_text3')}</p>
                            </div>
                        </div>
                    </div>
                </div>}

                {/* Step 4 */}
                {step === 3 && <div className="flex flex-col gap-2">
                    <p className="text-3xl font-bold">{i18n.t('my_itineraries')}</p>
                    <div className='flex flex-col md:flex-row items-center justify-between w-full gap-4'>
                        <img alt='img-3' className='w-56 object-contain' src='/images/tutorial_chatbot_3.png'></img>
                        <div className='flex flex-col gap-1'>
                            <p className='text-md font-medium'>
                                {i18n.t('tutorial_chatbot_step4_text1')}
                            </p>
                        </div>
                    </div>
                </div>}

                {step === 4 && <div className="flex flex-col gap-2">
                    <p className="text-3xl font-bold">{i18n.t('start_now')}</p>
                    <p className='text-md font-semibold'>
                        {i18n.t('tutorial_chatbot_step4_text2')}
                    </p>
                </div>}

                {/* Close button */}
                <div className='flex justify-between w-full border-t border-gray-300 pt-2'>
                    <button
                        className="w-fit px-4 py-1 text-light_black font-medium"
                        onClick={updateTutorialStatus}
                    >
                        {i18n.t('skip_introduction')}
                    </button>
                    <button
                        className="w-fit px-4 py-1 bg-light_black text-white font-medium rounded-full"
                        onClick={() => {
                            (step === 4)
                                ? updateTutorialStatus()
                                : setStep(step + 1)
                        }}
                    >
                        {step === 4 ? i18n.t('start_now') : i18n.t('next')}
                    </button>
                </div>
            </div>
        </div >
    );
};

export default TutorialChatBot;
