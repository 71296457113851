import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { chatState } from "./chatState";
import { IMessage } from "../../utils/interfaces/db_models/message.interface";

export const ChatSlice: Slice<any> = createSlice({
    name: "chat",
    initialState: chatState,
    reducers: {
        addMessage: (state, action: PayloadAction<IMessage>) => {
            state.messages.push(action.payload);
        },
        setMessages: (state, action: PayloadAction<IMessage[]>) => {
            state.messages = action.payload.reverse();
        },
        setMoreMessages: (state, action: PayloadAction<IMessage[]>) => {
            action.payload.forEach((message) => {
                state.messages.unshift(message);
            });
        },
        replaceUnreadMessages(state, action) {
            state.unreadMessages = action.payload
        }
    }
});

export const { addMessage, setMessages, setMoreMessages, replaceUnreadMessages } = ChatSlice.actions;
export default ChatSlice.reducer;