import { Link, Navigate, Outlet, useLoaderData, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import useCookie from '../../hooks/useCookie';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { USER_ROLES } from '../../utils/enums/user_roles.enum';
import { useEffect, useState } from 'react';
import { replaceUser } from '../../redux/auth/authSlice';
import { HttpMethod, apiService } from '../../api/apiService';
import { HomeIcon, HomeIconActive } from '../../utils/icons/HomeIcon';
import { BookingIcon, BookingIconActive } from '../../utils/icons/BookingIcon';
import { MapPinIcon, MapPinIconActive } from '../../utils/icons/MapPinIcon';
import { AlertIcon, AlertIconActive } from '../../utils/icons/AlertIcon';
import useIsOpen from '../../hooks/useIsOpen';
import { AdminPanelIcon } from '../../utils/icons/AdminPanelIcon';
import { IStructure } from '../../utils/interfaces/db_models/structure.interface';
import { PrivacyIcon } from '../../utils/icons/PrivacyIcon';
import { SupportIcon } from '../../utils/icons/SupportIcon';
import { StructureIcon } from '../../utils/icons/StructureIcon';
import { StructureSetting } from '../../utils/icons/StructureSetting';
import moment from 'moment';
import { ConnivioLogo } from '../../utils/icons/ConnivioLogo';
import LoadingIndicator from '../../components/LoadingIndicator';
import useSocket from '../../hooks/useSocket';
import checkPageStatus from '../../utils/notification';
import { IMessage } from '../../utils/interfaces/db_models/message.interface';
import { BurgerMenuIcon } from '../../utils/icons/BurgerMenuIcon';
import useScreenSize from '../../hooks/useScreenSize';
import i18n from './../../i18n';
import LanguagesMenu from '../../components/LanguagesMenu';


export const BackofficeLayout = () => {
    const cookies = document.cookie.split(';').map((c) => c.trim());
    const cookie = cookies.find((c) => c.startsWith('structure='));

    const response = useLoaderData() as IUser;
    const { user } = useAppSelector(state => state.auth) as { user: IUser };
    const dispatch = useAppDispatch();
    const { cookieData, removeCookie, setCookie } = useCookie({ accessToken: 'accessToken', refreshToken: 'refreshToken', structure: 'structure', admin: 'admin' });
    const navigate = useNavigate();
    const navigation = useNavigation();
    const location = useLocation();
    const { isOpen: burgerOpen, setIsOpen: setBurgerOpen, ref: burgerRef } = useIsOpen(false);
    const { isOpen, setIsOpen, ref } = useIsOpen(false);
    const screenSize = useScreenSize();
    const socket = useSocket();

    const [currentStructure, setCurrentStructure] = useState<IStructure | null>();

    useEffect(() => {
        const element = document.querySelector('.iubenda-tp-btn.iubenda-cs-preferences-link');
        if (element)
            document.body.removeChild(element)
    }, []);

    useEffect(() => {
        if (response) {
            dispatch(replaceUser(response));
            setCurrentStructure(response.structureUsers.find(structureUser => structureUser.structure.id === cookie?.split('=')[1])?.structure);
        } else {
            removeCookie('refreshToken');
            removeCookie('accessToken');
            removeCookie('structure');
            dispatch(replaceUser(undefined));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, response]);

    useEffect(() => {
        if (socket && !!user) {
            socket.emit('join private room', user.id);
        }
    }, [socket, user]);

    useEffect(() => {
        if (!!socket) {
            socket.on('new message notification', (body: { chatRoomId: string, message: IMessage }) => {
                checkPageStatus(body.message, navigate, removeCookie);
            });

            return () => {
                socket.disconnect();
            };
        }
    }, [socket]);

    if (!cookieData.refreshToken) {
        return <Navigate to="/" />
    } else if (!cookie?.split('=')[1]) {
        return <Navigate to="/app" />
    }

    const logout = async () => {
        try {
            const response = await apiService(HttpMethod.POST, '/users/logout', undefined, undefined, { isRefreshToken: true });
            if (response.modifiedCount) {
                removeCookie('accessToken');
                removeCookie('refreshToken');
                removeCookie('structure');
                navigate('/', { replace: true });
            }
            return response;
        } catch (error) {
            throw error
        }
    };

    return (
        <div className="flex flex-col h-full w-full text-light_black">
            <div>
                <div className='w-full h-16 p-2 px-6 flex justify-between bg-white shadow items-center z-20 relative'>
                    {(screenSize === 'xs' || screenSize === 'sm') &&
                        <div onClick={() => setBurgerOpen(!burgerOpen)} className='cursor-pointer' >
                            <BurgerMenuIcon />
                        </div>
                    }

                    {burgerOpen && (
                        <div ref={burgerRef} className="absolute top-full right-0 p-4 px-6 bg-white rounded-b shadow flex flex-col gap-8 w-full font-semibold text-xl md:text-sm">

                            <Link to={"/backoffice/" + moment().format('YYYY')} onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/backoffice/2') && 'text-orange'}`}>
                                {location.pathname.startsWith('/backoffice/2') ? <HomeIconActive /> : <HomeIcon />}
                                <p>{i18n.t('home')}</p>
                            </Link>

                            <Link to="alert" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/backoffice/alert') && 'text-orange'}`}>
                                {location.pathname.startsWith('/backoffice/alert') ? <AlertIconActive /> : <AlertIcon />}
                                <p>{i18n.t('alerts')}</p>
                            </Link>

                            <Link to="bookings" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/backoffice/bookings') && 'text-orange'}`}>
                                {location.pathname.startsWith('/backoffice/bookings') ? <BookingIconActive /> : <BookingIcon />}
                                <p>{i18n.t('bookings')}</p>
                            </Link>

                            <Link to="activities" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/backoffice/activities') && 'text-orange'}`}>
                                {location.pathname.startsWith('/backoffice/activities') ? <MapPinIconActive /> : <MapPinIcon />}
                                <p>{i18n.t('activities')}</p>
                            </Link>

                        </div>
                    )}

                    <div onClick={() => removeCookie('structure')} className='cursor-pointer'>
                        <ConnivioLogo />
                    </div>

                    <div className='flex justify-end items-center gap-6 md:w-full'>
                        {(screenSize !== 'xs' && screenSize !== 'sm') &&
                            <>
                                <Link to={"/backoffice/" + moment().format('YYYY')}>
                                    {location.pathname.startsWith('/backoffice/2') ? <HomeIconActive /> : <HomeIcon />}
                                </Link>

                                <Link to="alert">
                                    {location.pathname.startsWith('/backoffice/alert') ? <AlertIconActive /> : <AlertIcon />}
                                </Link>

                                <Link to="bookings">
                                    {location.pathname.startsWith('/backoffice/bookings') ? <BookingIconActive /> : <BookingIcon />}
                                </Link>

                                <Link to="activities">
                                    {location.pathname.startsWith('/backoffice/activities') ? <MapPinIconActive /> : <MapPinIcon />}
                                </Link>
                            </>
                        }

                        {!!user &&
                            <div ref={ref} >
                                <div onClick={() => setIsOpen(!isOpen)} className='cursor-pointer' >
                                    <StructureIcon />
                                </div>

                                {isOpen && (
                                    <div className="absolute top-full right-0 p-4 px-6 bg-white sm:rounded-bl shadow flex flex-col gap-4 w-full md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6" style={{ boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1), -1px 0px 0px rgba(0, 0, 0, 0.1), 1px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px' }}>

                                        <div className='flex items-center justify-between gap-6'>
                                            <div className='flex items-center gap-4 cursor-pointer' onClick={() => removeCookie('structure')}>
                                                <img alt={'profile_' + user.name} src={user.profile_image} style={{ height: 30, width: 30 }} className='aspect-square rounded-full object-cover' />
                                                <p className='font-semibold text-sm line-clamp-1'>{i18n.t('user_profile')}</p>
                                            </div>
                                        </div>

                                        {user?.structureUsers?.map((structureUser, index) => {
                                            if (!structureUser.structure.isDisabled) {
                                                return <div key={index} className={`flex items-center justify-between gap-6 cursor-pointer ${currentStructure?.id === structureUser.structure.id && 'text-orange'}`}>
                                                    <div
                                                        className='flex items-center gap-4 w-full'
                                                        onClick={() => {
                                                            setCookie('structure', structureUser.structure.id.toString(), { isPayload: false })
                                                            navigate('./' + moment().format('YYYY'), { replace: true });
                                                        }}>
                                                        <img alt={'logo_' + structureUser.structure.name} src={structureUser.structure.logo_image} style={{ height: 30, width: 30 }} className='aspect-square rounded-full object-cover' />
                                                        <p className='font-semibold text-sm line-clamp-1'>{structureUser.structure.name}</p>
                                                    </div>
                                                    {currentStructure?.id === structureUser.structure.id &&
                                                        <Link to={'coliving/manage/' + cookie?.split('=')[1]} onClick={() => setIsOpen(false)} className='hover:scale-110'>
                                                            <StructureSetting />
                                                        </Link>}
                                                </div>
                                            } else {
                                                return null
                                            }
                                        })}

                                        {(user.role === USER_ROLES.BUSINESS || user.role === USER_ROLES.ADMIN) &&
                                            <Link to={'coliving/create'} onClick={() => setIsOpen(false)} title={!user.canCreateStructure ? i18n.t('can_create_structure_info') : undefined} className='flex items-center gap-4 cursor-pointer' >
                                                <div className={!user.canCreateStructure ? 'opacity-50 cursor-default' : undefined}><StructureIcon /></div>
                                                <div className='flex flex-col'>
                                                    <p className={`font-semibold text-sm line-clamp-1  ${!user.canCreateStructure && 'opacity-50 cursor-default'}`}>{i18n.t('add_accommodation')}</p>
                                                    {!user.canCreateStructure && <p className='font-semibold text-xs line-clamp-1 text-orange '>{i18n.t('not_enabled')}</p>}
                                                </div>
                                            </Link>
                                        }

                                        {user?.role === USER_ROLES.ADMIN &&
                                            <div className='flex items-center gap-4 cursor-pointer' onClick={() => {
                                                removeCookie('structure');
                                                setCookie('admin', user.id, { isPayload: false });
                                            }}>
                                                <AdminPanelIcon />
                                                <p className='font-semibold text-sm line-clamp-1'>{i18n.t('administration')}</p>
                                            </div>
                                        }

                                        <LanguagesMenu />

                                        <div className='border-b border-gray-300' />

                                        {/* <div className='flex items-center gap-4'>
                                    <PrivacyIcon />
                                    <p className='text-sm font-semibold'>Sicurezza e privacy</p>
                                </div>

                                <div className='flex items-center gap-4'>
                                    <SupportIcon />
                                    <p className='text-sm font-semibold'>Assistenza e supporto</p>
                                </div>

                                <div className='border-b border-gray-300' /> */}

                                        <button onClick={logout} className='bg-light_black text-white text-xl font-semibold rounded-full w-fit px-4 py-1 flex self-center'>
                                            {i18n.t('logout')}
                                        </button>
                                    </div>
                                )}

                            </div>
                        }
                    </div>
                </div>
                <div className='w-full bg-light_black'>
                    <p className='font-medium text-xs text-white_shady text-center py-1'>{i18n.t('management')} {currentStructure?.name}</p>
                </div>
            </div>

            <div className={`h-full w-full overflow-y-auto overflow-x-hidden ${(isOpen || burgerOpen) && "blur md:blur-none"}`}>
                <div className='flex flex-1 h-full container m-auto'>
                    {navigation.state === 'loading'
                        ? <LoadingIndicator icon additionalStyle='h-full' />
                        : <Outlet />
                    }
                </div>
            </div>
        </div >
    )
};