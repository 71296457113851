import { Link, Outlet, useNavigation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { CHAT_ROOM_TYPE } from '../../utils/enums/chat_room_type.enum';
import { ChatIcon } from '../../utils/icons/ChatIcon';
import useIsOpen from '../../hooks/useIsOpen';
import TutorialChat from '../../components/TutorialChat';
import useScreenSize from '../../hooks/useScreenSize';
import LoadingIndicator from '../../components/LoadingIndicator';
import { replaceUnreadMessages } from '../../redux/chat/chatSlice';
import i18n from './../../i18n';


const ChatRooms = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(store => store.auth) as { user: IUser };
    const { unreadMessages } = useAppSelector(state => state.chat) as { unreadMessages: { chatRoomId: string, unreadMessageCount: number }[] };
    const { id } = useParams();
    const screenSize = useScreenSize();
    const { isOpen, setIsOpen } = useIsOpen(screenSize === 'sm' || screenSize === 'xs' ? false : true);
    const navigation = useNavigation();

    const hasUnreadMessages = (id: string) => {
        for (const unreadMessage of unreadMessages) {
            if (unreadMessage.chatRoomId === id) {
                return unreadMessage.unreadMessageCount
            }
        }
    };

    const handleUpdateUnreadMessage = (chatRoomId: string) => {
        const updatedUnreadMessages = unreadMessages.map(unreadMessage => ({ ...unreadMessage }));
        const index = unreadMessages.findIndex(unreadMessage => unreadMessage.chatRoomId === chatRoomId);

        if (index !== -1) {
            updatedUnreadMessages[index].unreadMessageCount = 0;
            dispatch(replaceUnreadMessages(updatedUnreadMessages));
        } else {
            console.error(`Unable to find chatRoomId ${chatRoomId} in unreadMessages array.`);
        }
    };

    return (
        <div className='flex h-full w-full select-none bg-white_shady'>
            {isOpen
                ? <div className='flex flex-col py-2 pt-0 h-full w-full md:w-1/4 fixed md:relative z-40 md:z-0 bg-white_shady border-r border-gray_shady shadow'>

                    <div className='flex p-2 justify-between items-center'>
                        <p className='text-lg font-semibold truncate'>{i18n.t('conversations')}</p>
                        <div onClick={() => setIsOpen(false)} className='cursor-pointer'><ChatIcon /></div>
                    </div>

                    <div className='flex flex-col h-full w-full gap-2 overflow-x-hidden overflow-y-auto'>

                        <div className='flex self-center border-t border-gray_shady w-11/12' />

                        {!!user?.chatRoomMemberships.length && user?.chatRoomMemberships?.map((chatRoomMembership, index) => {
                            if (chatRoomMembership.chatRoom.type !== CHAT_ROOM_TYPE.PRIVATE)
                                return (
                                    <Link key={index} to={chatRoomMembership.chatRoom.id} onClick={() => handleUpdateUnreadMessage(chatRoomMembership.chatRoom.id)} className={`flex items-center gap-2 p-2 justify-between ${id === chatRoomMembership.chatRoom.id && 'bg-orange'}`}>
                                        <div className='flex items-center gap-2 truncate'>
                                            <img src={chatRoomMembership.chatRoom.structure.logo_image} alt='logo-structure' height={42} width={42} className='rounded-full object-cover' />
                                            <div className='flex flex-col truncate'>
                                                <p className='text-sm font-semibold capitalize truncate'>{chatRoomMembership.chatRoom.title}</p>
                                                <p className='text-xs capitalize truncate'>{chatRoomMembership.chatRoom.structure.name}</p>
                                            </div>
                                        </div>
                                        {!!hasUnreadMessages(chatRoomMembership.chatRoom.id) && <div className='h-6 w-6 flex items-center justify-center aspect-square rounded-full bg-orange'>
                                            <p className='text-sm font-medium'>{hasUnreadMessages(chatRoomMembership.chatRoom.id)}</p>
                                        </div>}
                                    </Link>
                                );
                            return null
                        })}

                        {!!user?.chatRoomMemberships?.some(chatRoomMembership => chatRoomMembership.chatRoom.type !== CHAT_ROOM_TYPE.PRIVATE) &&
                            <div className='flex self-center border-t border-gray_shady w-11/12' />
                        }

                        {!!user?.chatRoomMemberships.length && user?.chatRoomMemberships?.map((chatRoomMembership, index) => {
                            if (chatRoomMembership.chatRoom.type === CHAT_ROOM_TYPE.PRIVATE)
                                return (
                                    <Link key={index} to={chatRoomMembership.chatRoom.id} onClick={() => handleUpdateUnreadMessage(chatRoomMembership.chatRoom.id)} className={`flex items-center gap-2 p-2 justify-between ${id === chatRoomMembership.chatRoom.id && 'bg-orange'}`}>
                                        <div className='flex items-center gap-2 truncate'>
                                            <img src={chatRoomMembership.chatRoom.chatRoomMemberships[0].user.profile_image} alt='img-profile' height={42} width={42} className='rounded-full object-cover' />
                                            <div className='flex flex-col truncate'>
                                                <p className='text-sm font-semibold capitalize truncate'>{!!chatRoomMembership.chatRoom.title ? chatRoomMembership.chatRoom.title : chatRoomMembership?.chatRoom?.chatRoomMemberships?.map(chatMember => chatMember.user.name).join(', ')}</p>
                                                <p className='text-xs capitalize truncate'>{chatRoomMembership.chatRoom?.chatRoomMemberships[0]?.user.profession?.name}</p>
                                            </div>
                                        </div>
                                        {!!hasUnreadMessages(chatRoomMembership.chatRoom.id) && <div className='h-6 w-6 flex items-center justify-center aspect-square rounded-full bg-orange'>
                                            <p className='text-sm font-medium'>{hasUnreadMessages(chatRoomMembership.chatRoom.id)}</p>
                                        </div>}
                                    </Link>
                                );
                            return null
                        })}

                    </div>

                </div>
                : <div className='flex flex-col pt-0 h-full w-fit border-r border-gray_shady shadow'>

                    <div className='flex p-2 justify-center items-center'>
                        <div onClick={() => setIsOpen(true)} className='cursor-pointer'>
                            <ChatIcon />
                        </div>
                    </div>

                    <div className='flex flex-col h-full w-full gap-2 overflow-x-hidden overflow-y-auto'>

                        <div className='flex self-center border-t border-gray_shady w-5/6' />

                        {!!user?.chatRoomMemberships?.length && user?.chatRoomMemberships?.map((chatRoomMembership, index) => {
                            if (chatRoomMembership.chatRoom.type !== CHAT_ROOM_TYPE.PRIVATE)
                                return (
                                    <Link key={index} to={chatRoomMembership.chatRoom.id} onClick={() => handleUpdateUnreadMessage(chatRoomMembership.chatRoom.id)} className={`flex items-center relative gap-2 p-2 ${id === chatRoomMembership.chatRoom.id && 'bg-orange'}`}>
                                        <img src={chatRoomMembership.chatRoom.structure.logo_image} alt='logo-structure' height={42} width={42} className='aspect-square rounded-full object-cover' />
                                        {!!hasUnreadMessages(chatRoomMembership.chatRoom.id) && <div className='h-4 w-4 aspect-square rounded-full bg-orange top-1 right-1 absolute' />}
                                    </Link>
                                );
                            return null
                        })}

                        {!!user?.chatRoomMemberships?.some(chatRoomMembership => chatRoomMembership.chatRoom.type !== CHAT_ROOM_TYPE.PRIVATE) &&
                            <div className='flex self-center border-t border-gray_shady w-5/6' />
                        }

                        {!!user?.chatRoomMemberships.length && user?.chatRoomMemberships?.map((chatRoomMembership, index) => {
                            if (chatRoomMembership.chatRoom.type === CHAT_ROOM_TYPE.PRIVATE)
                                return (
                                    <Link key={index} to={chatRoomMembership.chatRoom.id} onClick={() => handleUpdateUnreadMessage(chatRoomMembership.chatRoom.id)} className={`flex items-center relative gap-2 p-2 ${id === chatRoomMembership.chatRoom.id && 'bg-orange'}`}>
                                        <img src={chatRoomMembership.chatRoom.chatRoomMemberships[0].user.profile_image} alt='img-profile' height={42} width={42} className='rounded-full object-cover' />
                                        {!!hasUnreadMessages(chatRoomMembership.chatRoom.id) && <div className='h-4 w-4 aspect-square rounded-full bg-orange top-1 right-1 absolute' />}
                                    </Link>
                                );
                            return null
                        })}

                    </div>
                </div>
            }

            {(!!user && !user.chatTutorial) && <TutorialChat />}

            <div className={`flex h-full w-full`}>
                {navigation.state === 'loading'
                    ? <LoadingIndicator icon additionalStyle='h-full' />
                    : <Outlet />}
            </div>
        </div>
    )
}

export default ChatRooms;