export enum BOOKING_STATUS {
    PENDING = "PENDING",
    ON_GOING = "ON_GOING",
    COMPLETED = "COMPLETED"
};

export const bookingStatusDbMapping = {
    [BOOKING_STATUS.PENDING]: 'pending',
    [BOOKING_STATUS.ON_GOING]: 'in_progress',
    [BOOKING_STATUS.COMPLETED]: 'completed',
};

export const mapBookingStatus = (value: string) => {
    if (Object.keys(bookingStatusDbMapping).includes(value)) {
        return bookingStatusDbMapping[value as keyof typeof bookingStatusDbMapping];
    }
    return value;
};

export const bookingStatusOptions: { label: string; value: string }[] = Object.keys(BOOKING_STATUS).map(key => ({
    label: mapBookingStatus(String(key)),
    value: key,
}));

export const getBookingstatusColor = (status: BOOKING_STATUS): string => {
    switch (status) {
        case BOOKING_STATUS.PENDING:
            return 'bg-gray_shady';
        case BOOKING_STATUS.ON_GOING:
            return 'bg-orange';
        case BOOKING_STATUS.COMPLETED:
            return 'bg-light_black';
        default:
            return 'bg-light_black';
    }
};