import { Outlet, useLoaderData } from "react-router-dom";
import { IBooking } from "../../utils/interfaces/db_models/bookings.interface";
import BookingCard from "../../components/BookingCard";
import { useEffect, useState } from "react";
import i18n from './../../i18n';

const Home = () => {
    const { response: { pastBookings, currentBookings, futureBookings } } = useLoaderData() as { response: { pastBookings: IBooking[], currentBookings: IBooking[], futureBookings: IBooking[] } };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="h-full w-full flex flex-col gap-10">
            {!!pastBookings.length || !!futureBookings.length || !!currentBookings.length
                ? <div className="w-full flex flex-col gap-10 p-8 pb-0">
                    {!!currentBookings.length && <>
                        <p className='font-bold text-3xl'>{i18n.t('your_bookings')}</p>
                        {currentBookings.map((booking, index) => (
                            <BookingCard key={index} booking={booking} />
                        ))}
                    </>
                    }

                    {!!futureBookings.length && <>
                        <p className='font-bold text-3xl'>{i18n.t('future')}</p>
                        {futureBookings.map((booking, index) => (
                            <BookingCard key={index} booking={booking} />
                        ))}
                    </>
                    }

                    {!!pastBookings.length && <>
                        <p className='font-bold text-3xl'>{i18n.t('past')}</p>
                        {pastBookings.map((booking, index) => (
                            <BookingCard key={index} booking={booking} />
                        ))}
                    </>
                    }

                </div>
                : <div className="relative">

                    <div className="relative">
                        <div className="absolute inset-0 bg-light_black opacity-25"></div>
                        <video
                            id="video-container"
                            className="max-h-screen object-cover w-full"
                            controls={false}
                            autoPlay={true}
                            loop
                            disablePictureInPicture={true}
                            muted={true}
                            playsInline
                        >
                            <source
                                id={isMobile ? "mobile-video" : "desktop-video"}
                                src={isMobile ? 'https://connivio-public.s3.eu-central-1.amazonaws.com/video/Connivio_home_mobile.mp4' : 'https://connivio-public.s3.eu-central-1.amazonaws.com/video/Connivio_home3.mp4'}
                                type="video/mp4"
                            />
                        </video>
                    </div>

                    <p className='absolute top-1/2 -translate-y-1/2 text-center w-full text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-white'>
                        {i18n.t('coexist')}, <br className="md:hidden" />
                        {i18n.t('communicate')}, <br className="md:hidden" />
                        {i18n.t('share')}
                    </p>

                </div>
            }

            <div className="p-8">
                <Outlet />
            </div>
        </div>
    )
}

export default Home