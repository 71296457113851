export const HomeIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 6.26L24 13.18V24H18V16H12V24H6V13.18L15 6.26ZM15 5L5 12.69V25H13V17H17V25H25V12.69L15 5Z" fill="#1A1C20" />
        </svg>
    )
}

export const HomeIconActive = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 5L5 12.69V25H13V17H17V25H25V12.69L15 5Z" fill="#D9981D" />
        </svg>
    )
}