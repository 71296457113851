import { ActionFunctionArgs, redirect } from "react-router-dom";
import { HttpMethod, apiService } from "../apiService";
import { IBooking } from "../../utils/interfaces/db_models/bookings.interface";

export async function acceptBookingViaLink({ params, request }: ActionFunctionArgs) {
    try {
        const formData = await request.formData();
        let data = Object.fromEntries(formData) as any;

        const response = await apiService<IBooking & { errorCode: string }>(HttpMethod.POST, '/bookings/create/via-link', data);

        if (!!response.errorCode) {
            return response;
        }

        localStorage.removeItem('bookingToken');

        return { success: true };
    } catch (error: any) {
        throw error
    };
};