import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { ChatSlice } from './chat/chatSlice';
import { AuthSlice } from './auth/authSlice';
import { LanguagesSlice } from './languages/languagesSlice';

export const store = configureStore({
    reducer: {
        auth: AuthSlice.reducer,
        chat: ChatSlice.reducer,
        language: LanguagesSlice.reducer
    }
});

// To use useAppDispatch & useAppSelector correctly with typescript you need this two line of code
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector;
