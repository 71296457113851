import { IStructure, fieldTypesStructure } from '../../utils/interfaces/db_models/structure.interface';
import { Form, useActionData, useLoaderData, useNavigate, useNavigation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REQUEST_STATUS, requestStatusOptions } from '../../utils/enums/request_status.enum';
import SelectForm from '../../components/SelectForm';
import { ArrowLeft2 } from '../../utils/icons/ArrowIcon';
import moment from 'moment';
import useIsOpen from '../../hooks/useIsOpen';
import { getErrorMessage } from '../../utils/manageError';
import Modal from '../../components/Modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ITALIAN_REGIONS, italianRegionOptions } from '../../utils/enums/italian_regions.enum';
import i18n from './../../i18n';

const StructureManage = () => {
    const structure: IStructure = useLoaderData() as IStructure;
    const response = useActionData() as any;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const { isOpen, setIsOpen } = useIsOpen(false);
    const { ref, isOpen: isOpenDelete, setIsOpen: setIsOpenDelete } = useIsOpen(false);

    const formik = useFormik({
        initialValues: {
            request_status: { label: i18n.t('state'), value: structure.request_status },
            name: { label: i18n.t('name'), value: structure.name },
            email: { label: i18n.t('email'), value: structure.email },
            phone_number: { label: i18n.t('phone_number'), value: structure.phone_number },
            city: { label: i18n.t('city'), value: structure.city },
            province: { label: i18n.t('province'), value: structure.province },
            address: { label: i18n.t('address'), value: structure.address },
            region: { label: i18n.t('region'), value: structure.region },
            latitude: { label: i18n.t('latitude'), value: structure.latitude ?? 0 },
            longitude: { label: i18n.t('longitude'), value: structure.longitude ?? 0 },
            description: { label: i18n.t('description'), value: structure?.description },
            plain_services: { label: i18n.t('plain_services'), value: structure?.plain_services },
        },
        validationSchema: Yup.object({
            request_status: Yup.object({ value: Yup.string().required(i18n.t('required_field')).oneOf(Object.values(REQUEST_STATUS), i18n.t('invalid_request_status')) }),
            name: Yup.object({ value: Yup.string().max(25, i18n.t('must_contain_a_maximum', {number: '25'})).required(i18n.t('required_field')) }),
            email: Yup.object({ value: Yup.string().email(i18n.t('invalid_email_address')).required(i18n.t('required_field')).matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, i18n.t('invalid_email_address')) }),
            phone_number: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            city: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            region: Yup.object({ value: Yup.string().required(i18n.t('select_a_region')).oneOf(Object.values(ITALIAN_REGIONS), i18n.t('invalid_value')) }),
            address: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            province: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            description: Yup.object({ value: Yup.string().max(1000, i18n.t('must_contain_a_maximum', {number: '1000'})).required(i18n.t('enter_a_description_of_the_accommodation')) }),
            plain_services: Yup.object({ value: Yup.string().max(1000, i18n.t('must_contain_a_maximum', {number: '1000'})).required(i18n.t('enter_the_services_offered_by_the_accommodation')) }),
            latitude: Yup.object({ value: Yup.number().required(i18n.t('required_field')) }),
            longitude: Yup.object({ value: Yup.number().required(i18n.t('required_field')) }),
        }),
        onSubmit: () => { }
    });

    return (
        <div className='flex flex-1 flex-col px-10 sm:px-14 select-none'>
            <div className='flex justify-between items-center py-6'>
                <div className='cursor-pointer w-1/5' onClick={() => navigate(-1)}>
                    <ArrowLeft2 />
                </div>

                <div className='flex col-span-4 flex-col items-center justify-center w-full'>
                    <p className='font-bold text-2xl capitalize'>{structure?.name}</p>
                </div>

                <div className='w-1/5 flex justify-end'>
                    <button type='button' onClick={() => setIsOpenDelete(true)} className='px-4 py-1 rounded-full text-light_black border border-light_black h-fit font-semibold text-sm md:text-xl whitespace-nowrap'>
                        {structure.isDisabled ? i18n.t('enable') : i18n.t('disable')}
                    </button>
                </div>
            </div>

            <div className="border-t shadow -mx-20" />

            <p className='text-center text-sm font-medium pt-6'>{i18n.t('accommodation_created_on', {date: moment(structure.created_at).format('YYYY-MM-DD HH:mm')})}</p>

            <Form method='POST' className='flex flex-col flex-1 gap-6 py-6 justify-between'>
                <div className='flex flex-1 flex-col gap-6'>

                    <div className='flex flex-col gap-6'>
                        {Object.entries(formik.values).map(([key, value]) => {
                            const errorValue = formik.errors[key as keyof typeof formik.values];
                            const fieldType = fieldTypesStructure[key as keyof typeof fieldTypesStructure];

                            const translatedRequestStatusOptions = requestStatusOptions.map(option => ({
                                ...option,
                                label: i18n.t(option.value.toLowerCase()), // Assumendo che le chiavi di traduzione siano "pending", "in_progress", "completed"
                            }));

                            return <div key={key} className='flex flex-col'>
                                <label className='text-sm font-medium'>{value.label}</label>
                                {key === 'description' || key === 'plain_services'
                                    ? <textarea
                                        name={key}
                                        maxLength={1000}
                                        rows={5}
                                        defaultValue={value.value}
                                        onChange={(e) => formik.setFieldValue(key, { ...value, value: e.target.value })}
                                        placeholder={key === 'description' ? i18n.t('write_a_description_of_your_accommodation') : i18n.t('describe_the_services_offered')}
                                        className='font-medium w-full h-full p-1 border-2 border-light_black focus:outline-none resize-none'
                                    />
                                    : (key === 'request_status' || key === 'region')
                                        ? <SelectForm
                                            name={key}
                                            options={key === 'request_status' ? translatedRequestStatusOptions : italianRegionOptions}
                                            value={value.value}
                                            setValue={(e) => formik.setFieldValue(key, { ...value, value: e })}
                                        />
                                        : <input
                                            name={key}
                                            onChange={(e) => formik.setFieldValue(key, { ...value, value: fieldType === 'number' ? Number(e.target.value) : e.target.value })}
                                            value={value.value}
                                            type={fieldType}
                                            className='border-b-2 border-gray-500 p-1 overflow-hidden font-medium focus:outline-none focus:border-gray-700'
                                        />
                                }

                                {!!errorValue && isOpen
                                    ? <div className='text-red-500 font-medium text-sm'>
                                        {errorValue.value}
                                    </div>
                                    : null}
                            </div>
                        })}

                        {!!response?.errorCode && <p className="text-red-500 text-sm mt-1 text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>}
                    </div>
                </div>

                <div className='flex flex-1 gap-4 justify-end'>
                    <button
                        type={Object.entries(formik.errors).length > 0 ? 'button' : 'submit'}
                        onClick={() => setIsOpen(true)}
                        disabled={navigation.state !== 'idle' || !formik.dirty}
                        className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        {i18n.t('save')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                    </button>
                </div>
            </Form>

            {(!!structure && isOpenDelete) &&
                <Modal isOpen={isOpenDelete} wFull={false} className='md:w-96' refs={ref}>
                    <Form method='put' action='disable' className='flex flex-col items-center justify-center gap-6 text-center'>
                        <p className='font-bold text-xl md:text-4xl'>{i18n.t('wait')}</p>
                        {structure.isDisabled
                            ? <p className='text-base whitespace-pre-line' dangerouslySetInnerHTML={{__html:i18n.t('reactivate_the_accommodation', {escapeValue:false})}}></p>
                            : <p className='text-base whitespace-pre-line' dangerouslySetInnerHTML={{__html:i18n.t('deactivate_the_accommodation', {escapeValue:false})}}></p>
                        }
                        {navigation.state === 'idle'
                            ? <div className='flex gap-4 w-full justify-around'>
                                <button
                                    type='button'
                                    onClick={() => setIsOpenDelete(false)}
                                    className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
                                >
                                    {i18n.t('cancel')}
                                </button>
                                <button
                                    type='submit'
                                    disabled={navigation.state !== 'idle'}
                                    className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                                >
                                    {i18n.t('proceed')}
                                </button>
                            </div>
                            : <LoadingIndicator label={i18n.t('wait_a_moment')} />
                        }
                    </Form>
                </Modal>
            }
        </div>
    )
}

export default StructureManage