export const BookingIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 6V4H20V6H10V4H9V6H5V25H25V6H21ZM24 24H6V12H24V24ZM24 11H6V7H24V11Z" fill="#1A1C20" />
        </svg>
    )
}

export const BookingIconActive = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 6V4H20V6H10V4H9V6H5V25H25V6H21ZM24 24H6V12H24V24Z" fill="#D9981D" />
        </svg>
    )
}