export const StructureSetting = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1165_108)">
                <path d="M21.0001 9C22.0022 9.00186 22.9955 9.1883 23.9301 9.55V7H12.3301V9.73L15.0001 11.62C15.7655 10.7932 16.6936 10.1336 17.7262 9.6827C18.7587 9.23182 19.8734 8.99938 21.0001 9Z" fill="#1A1C20" />
                <path d="M13.7399 13.2797L9.73988 10.4297L3.87988 14.6397V22.9997H7.87988V17.9997H11.5899V22.9997H15.1299C14.3654 22.2402 13.7596 21.3364 13.3476 20.3406C12.9355 19.3449 12.7256 18.2773 12.7299 17.1997C12.7349 15.8293 13.0821 14.4819 13.7399 13.2797Z" fill="#1A1C20" />
                <path d="M24.84 18.1502C25.0131 17.5457 25.0131 16.9048 24.84 16.3002L26.11 15.2202L25.23 13.6902L23.67 14.2802C23.4544 14.0627 23.2086 13.8775 22.94 13.7302C22.6726 13.588 22.3911 13.474 22.1 13.3902L21.83 11.7402H20.07L19.8 13.3902C19.5081 13.4671 19.2262 13.5779 18.96 13.7202C18.6889 13.86 18.4396 14.0386 18.22 14.2502L16.67 13.6902L15.78 15.1902L17.05 16.2602C16.8767 16.8783 16.8767 17.5322 17.05 18.1502L15.77 19.2002L16.65 20.6902L18.22 20.1502C18.4457 20.3545 18.694 20.5323 18.96 20.6802C19.2258 20.8259 19.5077 20.94 19.8 21.0202L20.07 22.6702H21.83L22.1 21.0002C22.3886 20.9184 22.667 20.8043 22.93 20.6602C23.1974 20.5144 23.4459 20.3364 23.67 20.1302L25.24 20.6702L26.12 19.1802L24.84 18.1502ZM22.61 18.8702C22.3927 19.092 22.1326 19.2675 21.8456 19.3861C21.5586 19.5047 21.2505 19.5639 20.94 19.5602C20.4778 19.5576 20.0266 19.4187 19.6428 19.161C19.2591 18.9032 18.96 18.538 18.7828 18.111C18.6056 17.6841 18.5583 17.2144 18.6467 16.7606C18.7352 16.3069 18.9555 15.8894 19.28 15.5602C19.4948 15.3378 19.7529 15.1618 20.0385 15.0431C20.324 14.9244 20.6308 14.8656 20.94 14.8702C21.4031 14.8709 21.8557 15.0084 22.2409 15.2654C22.6262 15.5224 22.9269 15.8875 23.1053 16.3148C23.2837 16.7422 23.3319 17.2127 23.2438 17.6674C23.1557 18.122 22.9352 18.5405 22.61 18.8702Z" fill="#1A1C20" />
            </g>
            <defs>
                <clipPath id="clip0_1165_108">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}