import i18n from './../i18n';

export enum HTTP_CODE {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
}

export enum EVENT {
    GENERIC = '000',
    UNAUTHORIZED = '001',
    WRONG_FIELD = '002',
    WRONG_DATA = '003',
    MISSING_DATA = '004',
    ALREADY_EXISTS = '005',
    EXPIRED_DATA = '006',
    NOT_FOUND = '007',
    NOT_CREATED = '008',
    NOT_UPDATED = '09',
    NOT_DELETED = '010',
    NOT_VERIFIED = '011',
    NOT_VALID = '012',
    ALREADY_VERIFIED = '013'
}

export enum ENTITY {
    USER = 'UR',
    STRUCTURE = 'ST',
    USERSTRUCTURE = 'US',
    BOOKING = 'BO',
    ITINERARY = 'IT',
    SUGGESTION = 'SG',
    CHAT_ROOM = 'CR',
    CHAT_DIRECT = 'CD',
    MESSAGE = 'MG',
    TAG = 'TG',
    PROFESSION = 'PS',
    CRASH = 'CH',
    GENERAL = 'GR',
    AUTHORIZATION = 'AU',
    GPT_MANAGER = 'GM',
    ACCESS_TOKEN = 'AT',
    REFRESH_TOKEN = 'RT',
    OTP = 'OT',
    MEDIA = 'MD',
    S3 = 'S3'
}

interface EventEntityType {
    [key: string]: string;
}

const entityMessages: EventEntityType = {
    [ENTITY.USER]: i18n.t('user'),
    [ENTITY.STRUCTURE]: i18n.t('accommodation'),
    [ENTITY.USERSTRUCTURE]: i18n.t('accommodation_user'),
    [ENTITY.BOOKING]: i18n.t('booking'),
    [ENTITY.ITINERARY]: i18n.t('itinerary'),
    [ENTITY.SUGGESTION]: i18n.t('suggestions'),
    [ENTITY.CHAT_ROOM]: i18n.t('collective_chat'),
    [ENTITY.CHAT_DIRECT]: i18n.t('private_chat'),
    [ENTITY.MESSAGE]: i18n.t('messages'),
    [ENTITY.TAG]: i18n.t('tag'),
    [ENTITY.PROFESSION]: i18n.t('profession'),
    [ENTITY.CRASH]: 'Crash',
    [ENTITY.GENERAL]: i18n.t('general'),
    [ENTITY.AUTHORIZATION]: i18n.t('authorization'),
    [ENTITY.GPT_MANAGER]: i18n.t('chat_bot'),
    [ENTITY.ACCESS_TOKEN]: i18n.t('access_token'),
    [ENTITY.REFRESH_TOKEN]: i18n.t('refresh_token'),
    [ENTITY.OTP]: i18n.t('otp_code'),
    [ENTITY.MEDIA]: i18n.t('file'),
    [ENTITY.S3]: i18n.t('archive')
};

const eventMessages: EventEntityType = {
    [EVENT.GENERIC]: ': '+i18n.t('generic_error'),
    [EVENT.UNAUTHORIZED]: ' '+i18n.t('unauthorized'),
    [EVENT.WRONG_FIELD]: ', '+i18n.t('incorrect_fields'),
    [EVENT.WRONG_DATA]: ', '+i18n.t('incorrect_data'),
    [EVENT.MISSING_DATA]: ', '+i18n.t('missing_data'),
    [EVENT.ALREADY_EXISTS]: ' '+i18n.t('already_exists'),
    [EVENT.EXPIRED_DATA]: ', '+i18n.t('data_disappeared'),
    [EVENT.NOT_FOUND]: ' '+i18n.t('not_found'),
    [EVENT.NOT_CREATED]: ' '+i18n.t('not_created')+': '+i18n.t('unknown_error'),
    [EVENT.NOT_UPDATED]: ' '+i18n.t('not_updated')+': '+i18n.t('unknown_error'),
    [EVENT.NOT_DELETED]: ' '+i18n.t('not_deleted')+': '+i18n.t('unknown_error'),
    [EVENT.NOT_VERIFIED]: ' '+i18n.t('not_verified_s'),
    [EVENT.NOT_VALID]: ' '+i18n.t('not_valid'),
    [EVENT.ALREADY_VERIFIED]: ' '+i18n.t('already_verified')
};

export const getErrorMessage = (errorCode: string) => {
    if (errorCode) {
        const entityCode = errorCode.substring(0, 2);
        const eventCode = errorCode.substring(2, 5);


        const entityMessage = entityMessages[entityCode] || i18n.t('unknown_error');
        const eventMessage = eventMessages[eventCode] || i18n.t('unknown_error');

        const errorMessage = `${entityMessage}${eventMessage}`;

        return errorMessage;
    }
    return i18n.t('unknown_error');
};
