import React, { useEffect, useState } from 'react'
import { Form, useLoaderData, useNavigate, useNavigation, useActionData, Link } from 'react-router-dom';
import CropImage from '../../components/CropImage';
import { StructureCoverImageIcon } from '../../utils/icons/StructureIcon';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Places } from '../../components/PlacesAutocomplete';
import { getErrorMessage } from '../../utils/manageError';
import useIsOpen from '../../hooks/useIsOpen';
import Modal from '../../components/Modal';
import { ITagCategory } from '../../utils/interfaces/db_models/tagCategory.interface';
import { HttpMethod, apiService } from '../../api/apiService';
import LoadingIndicator from '../../components/LoadingIndicator';
import { AutoGenerateIcon } from '../../utils/icons/AutoGenerateIcon';
import { ISuggestion } from '../../utils/interfaces/db_models/suggestion.interface';
import i18n from './../../i18n';

const ActivitiesManage: React.FC = () => {
    const navigation = useNavigation();
    const navigate = useNavigate();

    const { activity } = useLoaderData() as { activity: ISuggestion };
    const response = useActionData() as any;

    const { isOpen, setIsOpen } = useIsOpen(false);
    const { isOpen: backModal, setIsOpen: setBackModal, ref: backModalRef } = useIsOpen(false);
    const { isOpen: deleteActivityModal, setIsOpen: setDeleteActivityModal, ref: deleteActivityModalRef } = useIsOpen(false);

    const [searchString, setSearchString] = useState<string>('');
    const [isGenerating, setIsGenerating] = useState<boolean>(false);

    const [selectedCover, setSelectedCover] = useState<string | null>(null);

    const [autocompleteStatus, setAutocompleteStatus] = useState(false);

    // tag logic
    const { tagCategory } = useLoaderData() as { tagCategory: { data: ITagCategory[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number } };
    const [tagCategorySelected, setTagCategorySelected] = useState<string[]>([]);

    useEffect(() => {
        if (!!selectedCover) {
            formik.setFieldValue('image', { label: formik.values.image.label, value: selectedCover })
        }
    }, [selectedCover]);

    const handleChangeImage = (newImageSrc: string | null) => {
        setSelectedCover(newImageSrc);
    };

    useEffect(() => {
        if (!!activity) {
            setTagCategorySelected(activity?.tagCategories?.map((cat: any) => cat.id));
        }
    }, [activity])

    const formik = useFormik({
        initialValues: {
            title: { label: i18n.t('title'), value: activity?.title || '' },
            description: { label: i18n.t('description'), value: activity?.description || '' },
            city: { label: i18n.t('city'), value: activity?.city || '' },
            province: { label: i18n.t('province'), value: activity?.province || '' },
            address: { label: i18n.t('address'), value: activity?.address || '' },
            latitude: { label: i18n.t('latitude'), value: activity?.latitude || undefined },
            longitude: { label: i18n.t('longitude'), value: activity?.longitude || undefined },
            image: { label: i18n.t('cover'), value: activity?.image || '' }
        },
        validationSchema: Yup.object({
            title: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            description: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            city: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            province: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            address: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            latitude: Yup.object({ value: Yup.number().required(i18n.t('required_field')) }),
            longitude: Yup.object({ value: Yup.number().required(i18n.t('required_field')) }),
            image: Yup.object({ value: Yup.string().required(i18n.t('upload_a_cover_image_for_the_activity')) })
        }),
        onSubmit: () => { }
    });

    // this function gives the fields to the PlacesAutocomplete component to populate the fields
    const populateFields = (populatedFields: any) => {
        formik.setFieldValue('address', { ...formik.values.address, value: populatedFields.address });
        formik.setFieldValue('province', { ...formik.values.province, value: populatedFields.province });
        formik.setFieldValue('city', { ...formik.values.city, value: populatedFields.city });
        formik.setFieldValue('latitude', { ...formik.values.latitude, value: populatedFields.latitude });
        formik.setFieldValue('longitude', { ...formik.values.longitude, value: populatedFields.longitude });

        setAutocompleteStatus(true);
    }

    // function that generate description with gpt api
    const handleGenerateDescription = async () => {
        if (searchString === '') return;

        try {
            setIsGenerating(true)
            const response = await apiService(HttpMethod.POST, `/suggestions/autocomplete`, { searchString }, null, { multipart: false });

            // set description value to formik
            formik.setFieldValue('description', { ...formik.values.description, value: response });
            setIsGenerating(false)
        } catch (error: any) {
            setIsGenerating(false)
            throw error
        }
    };

    const handleDeleteSuggestion = async () => {
        try {
            const response = await apiService<any & { errorCode: string }>(HttpMethod.PUT, 'suggestions/delete-by-id/' + activity.id);

            if (!!response.errorCode) {
                throw response;
            }

            navigate('/backoffice/activities', { replace: true });
            return;

        } catch (error) {
            throw error;
        }
    };

    return (
        <Form method='PUT' encType='multipart/form-data' className='flex flex-col gap-10 p-12 select-none m-auto'>

            {/* header */}
            <div className='flex flex-col gap-6 items-center w-full mt-10'>
                <p className='text-4xl md:text-6xl font-bold'>
                    {!!activity ? i18n.t('edit_activity') : i18n.t('add_activity')}
                </p>
                <p className='text-xl font-medium text-center'>
                    {!!activity
                        ? i18n.t('edit_the_activity_data_to_update_them')
                        : i18n.t('fill_in_all_the_required_data_to_add_a_new_activity')
                    }
                </p>
            </div>

            {/* title form input */}
            <div className='w-full mt-4'>
                <p className='lg:text-2xl font-medium text-center'>{formik.values.title.label}</p>
                <input
                    name={'title'}
                    maxLength={200}
                    defaultValue={formik.values.title.value as string}
                    className={`border-b-2 border-light_black p-1 overflow-hidden font-medium focus:outline-none w-full`}
                    onChange={(e) => formik.setFieldValue('title', { ...formik.values.title, value: e.target.value })}
                />
            </div>

            {/* places form autocomplete input */}
            <Places populateFields={populateFields} setSearchString={setSearchString} status={autocompleteStatus} />
            <p className='font-regular text-sm md:text-md text-center mb-8' dangerouslySetInnerHTML={{__html:i18n.t('places_form_autocomplete_input_info', {escapeValue:false})}}></p>

            <div className='flex flex-col gap-6'>
                <CropImage onImageChange={handleChangeImage} name='image' rounded={false}>
                    <label htmlFor="image" className='cursor-pointer w-full'>
                        <div className="relative w-full">
                            {selectedCover || activity?.image
                                ? <img
                                    className='w-full aspect-video object-cover'
                                    src={selectedCover || activity?.image}
                                    alt='img-suggestion'
                                />
                                : <div className='w-full aspect-video flex items-center justify-center' style={{ backgroundColor: '#F1F1F1' }}>
                                    <StructureCoverImageIcon />
                                </div>
                            }
                            <p className='bg-light_black px-4 py-1 rounded-full text-white font-semibold text-sm md:text-xl absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 whitespace-nowrap'>
                                {i18n.t('change_cover')}
                            </p>
                        </div>
                    </label>
                </CropImage>

                <p className='font-regular text-sm md:text-md text-center mt-4' dangerouslySetInnerHTML={{__html:i18n.t('add_the_cover_image_which_will_be_displayed_on', {escapeValue:false})}}></p>

                {(!!formik.errors['image' as keyof typeof formik.values] && isOpen) &&
                    <div className='text-red-500 font-medium text-center text-sm'>
                        {/* {formik.errors['image' as keyof typeof formik.values]?.value} */}
                    </div>}
            </div>

            <div className='flex flex-col md:grid md:grid-cols-2 gap-x-8 gap-y-4 w-full mt-12'>
                {Object.entries(formik.values).map(([key, value]) => {
                    const errorValue = formik.errors[key as keyof typeof formik.values] as Record<string, string>;

                    if (key === 'image') { return null }

                    return <div key={key} className={`flex flex-col ${(key === 'description') && 'md:col-span-2 mt-8'}`}>
                        <div className='flex justify-between mb-4'>
                            {key === 'title' || key === 'latitude' || key === 'longitude' ? null : <p className='text-xl font-bold'>{value.label}</p>}
                            {key === 'latitude' ? <p className='text-xl font-bold'>{i18n.t('coordinates')}</p> : null}
                            {isGenerating && key === 'description' ? <LoadingIndicator label={i18n.t('we_are_generating_the_description')} /> : null}
                            {key === 'description' && searchString &&
                                <div className='flex flex-row bg-light_black rounded-full px-8 py-2 items-center'>
                                    <button type='button' className='text-base lg:text-lg font-medium text-white flex gap-2 items-center' onClick={handleGenerateDescription}>
                                        <AutoGenerateIcon /> {i18n.t('complete_with_ai')}
                                    </button>
                                </div>
                            }
                        </div>
                        {key === 'description'
                            ? <>
                                <textarea
                                    name={key}
                                    maxLength={3000}
                                    disabled={isGenerating}
                                    rows={15}
                                    defaultValue={value.value}
                                    onChange={(e) => formik.setFieldValue(key, { ...value, value: e.target.value })}
                                    placeholder={i18n.t('write_a_description_of_your_activity')}
                                    className='font-medium w-full h-full p-1 border-2 border-light_black focus:outline-none resize-none'
                                />
                                <div className='flex flex-col w-full items-end'>
                                    <p className='font-semibold text-lg'>{(typeof value.value === 'string') ? value.value.length : 0}/3000</p>
                                    <p className='font-semibold text-sm opacity-80'>{i18n.t('max_3000_characters')}</p>
                                </div>
                            </>
                            : key === 'title' || key === 'latitude' || key === 'longitude'
                                ? null
                                : <input
                                    name={key}
                                    maxLength={key === 'province' ? 2 : undefined}
                                    defaultValue={value.value as string}
                                    className={`border-b-2 border-light_black p-1 overflow-hidden font-medium focus:outline-none`}
                                    onChange={(e) => formik.setFieldValue(key, { ...value, value: e.target.value })}
                                />
                        }

                        {
                            key === 'latitude'
                                ? <div className='flex w-full gap-4'>
                                    <input
                                        name={'latitude'}
                                        placeholder={i18n.t('latitude')}
                                        defaultValue={value.value as number}
                                        className={`border-b-2 border-light_black p-1 overflow-hidden font-medium focus:outline-none w-full`}
                                        onChange={(e) => formik.setFieldValue('latitude', { ...formik.values.latitude, value: e.target.value })}
                                    />
                                    <input
                                        name={'longitude'}
                                        placeholder={i18n.t('longitude')}
                                        defaultValue={formik.values.longitude.value}
                                        className={`border-b-2 border-light_black p-1 overflow-hidden font-medium focus:outline-none w-full`}
                                        onChange={(e) => formik.setFieldValue('longitude', { ...formik.values.longitude, value: e.target.value })}
                                    />
                                </div>
                                : null
                        }

                        {(!!errorValue && isOpen) &&
                            <div className='text-red-500 font-medium text-sm'>
                                {errorValue.value}
                            </div>
                        }
                    </div>
                })}
            </div>

            <div className='flex flex-col items-center justify-between flex-1 w-full self-center gap-8 text-center mt-14'>
                <div className='flex flex-col w-full lg:w-2/3 gap-4'>
                    <p className="text-3xl lg:text-5xl font-bold text-center flex gap-4 items-end justify-center">
                        {i18n.t('select_tags')} <span className='text-2xl'>(Max 2)</span>
                    </p>
                    <p className='text-lg lg:text-2xl'>
                        {i18n.t('select_tags_info')}
                    </p>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 gap-4 w-full'>
                    {tagCategory?.data?.length > 0 && tagCategory?.data.map((category, index) => {
                        return <button
                            key={index}
                            type='button'
                            disabled={!tagCategorySelected.some(cat => cat === category.id) && tagCategorySelected.length === 2}
                            className={`flex flex-col w-full h-full text-left p-4 ${tagCategorySelected.find(cat => cat === category.id) ? 'bg-yellow-600' : 'border border-light_black'}`}
                            onClick={() => setTagCategorySelected(prev => {
                                const index = prev.indexOf(category.id);
                                return index === -1 ? [...prev, category.id] : prev.filter(c => c !== category.id);
                            })}
                        >
                            <p className='font-bold text-xl w-full truncate'>{category.name}</p>
                            <p className='font-medium text-sm w-full whitespace-pre-line line-clamp-6'>{category.description}</p>
                        </button>
                    })}
                </div>
            </div>


            {/* tag selector */}
            <select hidden multiple={true} name='tagCategories[]' value={tagCategorySelected} onChange={() => false}>
                {tagCategorySelected?.map(tagCategory => <option key={tagCategory} value={tagCategory} />)}
            </select>

            {/* confirm buttons */}
            <div className='flex flex-col w-full md:col-span-3 gap-1 py-10'>
                <div className='max-md:flex-col md:col-span-3 flex w-full h-full items-center justify-between md:justify-end gap-4'>
                    <div className='flex w-full justify-center md:justify-start'>
                        {!!activity &&
                            <button
                                type={'button'}
                                onClick={() => setDeleteActivityModal(true)}
                                disabled={navigation.state !== 'idle'}
                                className='px-4 py-1 max-md:w-1/2 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                            >
                                {i18n.t('delete')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                            </button>
                        }
                    </div>
                    <button
                        type={'button'}
                        onClick={() => setBackModal(true)}
                        disabled={navigation.state !== 'idle'}
                        className='px-4 py-1 max-md:w-1/2  rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl'
                    >
                        {i18n.t('cancel')}
                    </button>
                    <button
                        type={Object.entries(formik.errors).length > 0 ? 'button' : 'submit'}
                        onClick={() => setIsOpen(true)}
                        disabled={(!!activity) ? navigation.state !== 'idle' : navigation.state !== 'idle' || !formik.dirty}
                        className='px-4 py-1 max-md:w-1/2  rounded-full bg-orange text-light_black h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        {!!activity ? i18n.t('save') : i18n.t('create')}{navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                    </button>
                </div>
                {response?.errorCode &&
                    <div className='flex flex-col items-center'>
                        <p className="text-red-500 text-base font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
                        <p className="text-red-500 text-xs font-medium">{i18n.t('unexpected_error')}: {response.message}</p>
                    </div>
                }
            </div>


            <Modal isOpen={backModal} refs={backModalRef} wFull={false} className='w-fit md:w-96'>
                <div className='flex flex-col w-full h-full items-center justify-center gap-6 text-center'>
                    <p className='font-bold text-xl md:text-4xl'>{i18n.t('wait')}</p>
                    <p className='text-sm'>{i18n.t('changes_will_be_lost_do_you_still_want_to_proceed')}</p>
                    <div className='flex items-center justify-between w-full'>
                        <button
                            type={'button'}
                            onClick={() => setBackModal(false)}
                            className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
                        >
                            {i18n.t('cancel')}
                        </button>
                        <button
                            type={'button'}
                            onClick={() => navigate(-1)}
                            className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                        >
                            {i18n.t('proceed')}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={deleteActivityModal} refs={deleteActivityModalRef} wFull={false} className='w-fit md:w-96'>
                <div className='flex flex-col w-full h-full items-center justify-center gap-6 text-center'>
                    <p className='font-bold text-2xl md:text-4xl'>{i18n.t('warning')}</p>
                    <p className='text-sm'>
                        {i18n.t('warning_delete_activity')}
                    </p>

                    <div className='flex items-center justify-between w-full'>
                        <button
                            type={'button'}
                            onClick={() => setDeleteActivityModal(false)}
                            className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
                        >
                            {i18n.t('cancel')}
                        </button>
                        <button
                            type='button'
                            disabled={navigation.state !== 'idle'}
                            onClick={handleDeleteSuggestion}
                            className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap cursor-pointer'
                        >
                            {i18n.t('delete')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                        </button>

                    </div>
                </div>
            </Modal>

        </Form>
    )
}

export default ActivitiesManage