import { ActionFunctionArgs, redirect, useNavigate, useParams, Form } from 'react-router-dom';
import { HttpMethod, apiService } from '../../api/apiService';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import RegisterUser from './RegisterUser';
import RegisterBusiness from './RegisterBusiness';

export interface IRegisterVerify {
    email: string;
    otp: string;
}

export async function handleRegister({ request, params }: ActionFunctionArgs) {
    const formData = await request.formData();
    const data: any = Object.fromEntries(formData.entries());

    const user: Record<string, string> = data;

    if (user.surname) {
        user.name = user.name + " " + user.surname;
    }

    // add the type of user to the request object
    if (params.type)
        user.type = params.type === 'coliver' ? 'coliver' : 'business';
    else {
        user.type = 'not specified';
    }

    delete user.surname;
    delete user.passwordConfirm;

    let newUser: IUser | undefined;

    if (params.type === 'coliver') {
        newUser = await apiService<IUser>(HttpMethod.POST, '/users/register', user);
        if ('errorCode' in newUser) {
            return newUser;
        }
    }

    if (params.type === 'business') {
        newUser = await apiService<IUser>(HttpMethod.POST, '/users/register', user);
        if ('errorCode' in newUser) {
            return newUser;
        }
    }

    return redirect('/verify/' + newUser?.email);
};

const Register = () => {
    const params = useParams();

    return (
        <Form method='post'>
            {params.type === 'coliver' && <RegisterUser />}

            {params.type === 'business' && <RegisterBusiness />}
        </Form>
    )
}

export default Register
