import { IMessage } from "../../utils/interfaces/db_models/message.interface";

export const chatState: IChatState = {
    messages: [],
    unreadMessages: []
};

interface IChatState {
    messages: IMessage[];
    unreadMessages: { chatRoomId: string, unreadMessageCount: number }[]
}