import i18n from './../../i18n';

export enum CHAT_ROOM_TYPE {
    GLOBAL = "GLOBAL",
    ACTUAL = "ACTUAL",
    PRIVATE = "PRIVATE",
    GROUP = "GROUP"
};

export const chatRoomTypeDbMapping = {
    [CHAT_ROOM_TYPE.GLOBAL]: i18n.t('new_arrivals'),
    [CHAT_ROOM_TYPE.ACTUAL]: i18n.t('colivers'),
    [CHAT_ROOM_TYPE.PRIVATE]: i18n.t('private'),
    [CHAT_ROOM_TYPE.GROUP]: i18n.t('group')
};

export const mapChatRoomType = (value: string) => {
    if (Object.keys(chatRoomTypeDbMapping).includes(value)) {
        return chatRoomTypeDbMapping[value as keyof typeof chatRoomTypeDbMapping];
    }
    return value;
};

export const chatRoomTypeOptions: { label: string; value: string }[] = Object.keys(CHAT_ROOM_TYPE).map(key => ({
    label: mapChatRoomType(String(key)),
    value: key,
}));