import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

// Definire il tipo per lo stato
interface LanguagesState {
    language: string;
}

function getCookieValue(name: string) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : null;
}

// Stato iniziale
const initialState: LanguagesState = {
    language: getCookieValue('connivio_language') || 'en' // o 'en', se vuoi una lingua predefinita
};

export const LanguagesSlice: Slice<LanguagesState> = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        }
    }
});

// Azioni esportate
export const { setLanguage } = LanguagesSlice.actions;
export default LanguagesSlice.reducer;
