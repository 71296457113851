export const AutoGenerateIcon = ({ color = '#fff' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26}
            height={26}
            fill="none"
        >
            <path
                fill={color}
                d="m15.444 6.725 1.022.208a.399.399 0 0 1 0 .78l-.996.217a3.666 3.666 0 0 0-2.808 2.808l-.217.997a.4.4 0 0 1-.78 0l-.217-.997a3.666 3.666 0 0 0-2.834-2.8L7.61 7.722a.399.399 0 0 1 0-.78l1.005-.217a3.666 3.666 0 0 0 2.808-2.808l.217-.997a.398.398 0 0 1 .78 0l.217.997a3.666 3.666 0 0 0 2.808 2.808ZM20.895 16.328l1.24.268a.494.494 0 0 1 0 .97l-1.24.27a4.55 4.55 0 0 0-3.467 3.466l-.268 1.248a.494.494 0 0 1-.971 0l-.269-1.248a4.559 4.559 0 0 0-3.466-3.466l-1.248-.27a.494.494 0 0 1 0-.97l1.248-.268a4.55 4.55 0 0 0 3.466-3.467l.269-1.24a.495.495 0 0 1 .97 0l.27 1.24a4.541 4.541 0 0 0 3.466 3.467ZM9.074 14.127l.745.164a.294.294 0 0 1 0 .58l-.745.165a2.73 2.73 0 0 0-2.141 2.106l-.165.755a.303.303 0 0 1-.59 0l-.112-.755a2.73 2.73 0 0 0-2.106-2.105l-.754-.165a.294.294 0 0 1 0-.58l.754-.165a2.73 2.73 0 0 0 2.106-2.107l.148-.753a.303.303 0 0 1 .589 0l.165.754a2.73 2.73 0 0 0 2.106 2.105Z"
            />
        </svg>
    );
}