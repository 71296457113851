import { Form, redirect, useParams, ActionFunctionArgs, useActionData, useNavigation, useSearchParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HttpMethod, apiService } from '../../api/apiService';
import { getErrorMessage } from '../../utils/manageError';
import { OTP_TYPE } from '../../utils/enums/otpType';
import useScreenSize from '../../hooks/useScreenSize';
import i18n from './../../i18n';

export async function handleVerifyUser({ request, params }: ActionFunctionArgs) {
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    const { email } = params;

    if (!updates.otp || !email)
        return { errorCode: 'OT004' };

    const response = await apiService(HttpMethod.POST, '/users/verify-user', { ...updates, email });
    if (response?.isVerified)
        return redirect('?state=verified');

    return response;
};

const VerifyAccount = () => {
    const response = useActionData() as any;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const { email } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [error, setError] = useState<any>();
    const [countdown, setCountdown] = useState(0);

    const [isVerifiedPage, setIsVerifiedPage] = useState(false);

    useEffect(() => {
        if (searchParams.get('state') === 'verified') {
            setIsVerifiedPage(true);
        }
    }, [searchParams])

    useEffect(() => {
        if (!!response?.errorCode) {
            setError(response)
        }
    }, [response])

    useEffect(() => {
        if (!countdown) return;

        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [countdown]);

    const handleResendOtp = async () => {
        try {
            if (countdown > 0) return;
            setError(response);
            setCountdown(60);
            await apiService(HttpMethod.POST, '/users/request-otp', { email: email, type: OTP_TYPE.EMAIL_VERIFICATION });

        } catch (error: any) {
            setCountdown(0);
            throw error
        }
    };

    return (
        <div className='h-full flex flex-col items-center justify-center'>
            {isVerifiedPage
                ? <>
                    <p className='text-5xl md:text-6xl text-center font-bold mb-4'>{i18n.t('welcome')}</p>
                    <p className='text-xl md:text-2xl text-center mb-12'>{i18n.t('welcome_message_info')}</p>

                    <div className='flex gap-4 justify-center items-center flex-col mt-4'>
                        <button type="button" onClick={() => navigate('/login')} className='w-fit text-xl border border-light_black rounded-full font-medium px-8 py-1 bg-light_black text-white' disabled={navigation.state !== 'idle'}>{i18n.t('lets_get_started')}</button>
                    </div>
                </>

                : <>
                    <p className='text-6xl text-center font-bold mb-4'>{i18n.t('enter_the_code')}</p>
                    <p className='text-xl md:text-2xl text-center mb-12'>{i18n.t('enter_the_code_sent_to_the_address')} {email}</p>

                    <Form method='post' className='flex flex-col gap-4 px-8'>
                        <input
                            className='uppercase border-b-light_black border-b-2 focus:outline-none text-4xl text-center mb-8 pb-4 w-full'
                            minLength={6}
                            maxLength={6}
                            style={{ letterSpacing: 20 }}
                            type="text"
                            name="otp"
                        />
                        <p className='text-center mb-4'>{i18n.t('didnt_receive_a_code')} <span className='text-orange hover:cursor-pointer' onClick={handleResendOtp}>{i18n.t('resend_code')}</span></p>

                        {countdown > 0 && (
                            <p className="text-sm text-center font-medium whitespace-pre-line text-orange-500" dangerouslySetInnerHTML={{__html:i18n.t('send_code_to_email', {escapeValue:false, email: email, countdown: countdown})}}></p>
                        )}

                        <div className='flex gap-4 justify-center items-center flex-col mt-4'>
                            <button type="submit" onClick={() => setError(undefined)} className='w-fit border border-light_black rounded-full font-medium px-4 py-1 bg-light_black text-white' disabled={navigation.state !== 'idle'}>{i18n.t('confirm')}</button>
                        </div>

                        {!!error && <p className="text-red-500 text-sm mt-1 text-center font-medium">{!!error?.errorCode && getErrorMessage(error.errorCode)}</p>}
                    </Form></>
            }
        </div >
    )
}

export default VerifyAccount
