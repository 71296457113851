export const AlertIcon = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1179_10)">
            <path d="M19.2102 10.85V5L18.3902 5.68C18.3902 5.68 14.6902 8.72 12.0002 8.72H7.30022C6.12603 8.7566 5.0142 9.25704 4.20824 10.1117C3.40229 10.9664 2.9679 12.1057 3.00022 13.28C2.97437 14.3999 3.37186 15.4884 4.11333 16.3281C4.8548 17.1678 5.8857 17.697 7.00022 17.81L9.56022 24.54C9.66342 24.8221 9.82306 25.0803 10.0294 25.2986C10.2357 25.517 10.4844 25.691 10.7602 25.81C11.0166 25.9177 11.2922 25.9722 11.5702 25.97C11.8493 25.9726 12.1257 25.9146 12.3802 25.8C12.9147 25.5526 13.3351 25.1111 13.5561 24.5651C13.7771 24.0191 13.7821 23.4096 13.5702 22.86L12.1002 19L11.1602 19.36L12.6302 23.24C12.7448 23.543 12.7438 23.8776 12.6277 24.18C12.5115 24.4824 12.2882 24.7316 12.0002 24.88C11.8674 24.9367 11.7246 24.9659 11.5802 24.9659C11.4359 24.9659 11.293 24.9367 11.1602 24.88C11.0085 24.8116 10.8719 24.7135 10.7586 24.5916C10.6453 24.4697 10.5574 24.3263 10.5002 24.17L8.07022 17.84H12.0002C14.3402 17.84 18.4202 20.84 18.4602 20.91L19.2602 21.5V15.93C19.8131 15.7733 20.2998 15.4405 20.6464 14.9821C20.993 14.5237 21.1805 13.9647 21.1805 13.39C21.1805 12.8153 20.993 12.2563 20.6464 11.7979C20.2998 11.3395 19.8131 11.0067 19.2602 10.85H19.2102ZM18.2102 19.54C16.8302 18.61 13.9502 16.84 11.9502 16.84H7.30022C6.39121 16.8036 5.53365 16.4085 4.9152 15.7413C4.29674 15.0742 3.96775 14.1892 4.00022 13.28C3.96775 12.3708 4.29674 11.4858 4.9152 10.8187C5.53365 10.1515 6.39121 9.75644 7.30022 9.72H12.0002C14.2802 9.72 16.9402 8.02 18.2602 7.07L18.2102 19.54ZM19.2102 14.88V11.88C19.4902 12.0141 19.7258 12.2258 19.8889 12.49C20.052 12.7541 20.1358 13.0596 20.1302 13.37C20.1422 13.6842 20.0614 13.9951 19.8978 14.2636C19.7341 14.5322 19.495 14.7466 19.2102 14.88Z" fill="#1A1C20" />
            <path d="M21.9001 8.86914L21.3301 9.68914C21.8935 10.1236 22.3459 10.6855 22.6501 11.3287C22.9543 11.9718 23.1017 12.678 23.0801 13.3891C23.1017 14.1003 22.9543 14.8064 22.6501 15.4496C22.3459 16.0928 21.8935 16.6547 21.3301 17.0891L21.9001 17.9091C22.5941 17.381 23.1534 16.6962 23.5323 15.9107C23.9112 15.1251 24.0989 14.2611 24.0801 13.3891C24.0989 12.5172 23.9112 11.6532 23.5323 10.8676C23.1534 10.0821 22.5941 9.39727 21.9001 8.86914Z" fill="#1A1C20" />
            <path d="M24.9099 7.32031L24.2099 8.04031C25.4628 9.53248 26.1026 11.4446 25.9999 13.3903C26.098 15.3388 25.4509 17.2516 24.1899 18.7403L24.8899 19.4603C26.3503 17.7851 27.1063 15.6101 26.9999 13.3903C27.1118 11.1732 26.3631 8.99855 24.9099 7.32031Z" fill="#1A1C20" />
        </g>
        <defs>
            <clipPath id="clip0_1179_10">
                <rect width="30" height="30" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const AlertIconActive = () => (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1179_2)">
            <path d="M10.8402 18.3301H7.24023L9.52024 24.3301C9.58888 24.5429 9.70083 24.7391 9.84904 24.9065C9.99724 25.0739 10.1785 25.2089 10.3814 25.3028C10.5843 25.3967 10.8044 25.4476 11.028 25.4523C11.2515 25.457 11.4736 25.4154 11.6802 25.3301C12.1044 25.1353 12.437 24.7841 12.6084 24.3499C12.7799 23.9158 12.7769 23.4321 12.6002 23.0001L10.8402 18.3301Z" fill="#D9981D" />
            <path d="M18.2198 11.2905V6.06055C18.2198 6.06055 14.4098 9.22055 11.4598 9.22055H6.79978C5.75818 9.25708 4.7735 9.70482 4.06129 10.4658C3.34909 11.2267 2.9674 12.2388 2.99978 13.2805C2.98242 13.7967 3.06697 14.3112 3.2486 14.7947C3.43022 15.2782 3.70536 15.7211 4.05827 16.0982C4.41119 16.4752 4.83497 16.779 5.30538 16.9922C5.77578 17.2054 6.2836 17.3238 6.79978 17.3405H11.4598C13.9898 17.3405 18.2198 20.5105 18.2198 20.5105V15.5105C18.753 15.4738 19.2524 15.236 19.6171 14.8453C19.9818 14.4546 20.1846 13.94 20.1846 13.4055C20.1846 12.8711 19.9818 12.3565 19.6171 11.9658C19.2524 11.5751 18.753 11.3373 18.2198 11.3005V11.2905Z" fill="#D9981D" />
            <path d="M21.4098 8.86914L20.8398 9.68914C21.4033 10.1236 21.8556 10.6855 22.1599 11.3287C22.4641 11.9718 22.6114 12.678 22.5898 13.3891C22.6114 14.1003 22.4641 14.8064 22.1599 15.4496C21.8556 16.0928 21.4033 16.6547 20.8398 17.0891L21.4098 17.9091C22.1039 17.381 22.6632 16.6962 23.0421 15.9107C23.4209 15.1251 23.6086 14.2611 23.5898 13.3891C23.6086 12.5172 23.4209 11.6532 23.0421 10.8676C22.6632 10.0821 22.1039 9.39727 21.4098 8.86914Z" fill="#D9981D" />
            <path d="M24.4202 7.32031L23.7202 8.04031C24.9759 9.532 25.6223 11.4427 25.5302 13.3903C25.6243 15.3383 24.9776 17.2496 23.7202 18.7403L24.4202 19.4603C25.8806 17.7851 26.6366 15.6101 26.5302 13.3903C26.6366 11.1705 25.8806 8.99551 24.4202 7.32031Z" fill="#D9981D" />
        </g>
        <defs>
            <clipPath id="clip0_1179_2">
                <rect width="30" height="30" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
