export const SortIcon = ({ sortingState, columnId }) => {
  const sortingInfo = sortingState.find(item => item.id === columnId);
  const desc = sortingInfo ? sortingInfo.desc : false;
  const asc = sortingInfo ? !sortingInfo.desc : false;

  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {desc
        ? <path d="M1 13L6 18L11 13" stroke="black" strokeWidth="2" />
        : <path d="M1 13L6 18L11 13" stroke="#1A1C20" strokeOpacity="0.25" strokeWidth="2" />
      }
      {asc
        ? <path d="M11 7L6 2L1 7" stroke="black" strokeWidth="2" />
        : <path d="M11 7L6 2L1 7" stroke="#1A1C20" strokeOpacity="0.25" strokeWidth="2" />
      }
    </svg>
  );
};