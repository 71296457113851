import { useEffect, useState } from 'react'
import { Form, useActionData, useNavigation, useParams } from 'react-router-dom';
import { HttpMethod, apiService } from '../../api/apiService';
import { OTP_TYPE } from '../../utils/enums/otpType';
import { getErrorMessage } from '../../utils/manageError';
import i18n from './../../i18n';

const ChangeEmail = () => {
    const response = useActionData() as any;
    const params = useParams();

    const navigation = useNavigation();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [step, setStep] = useState(0);
    const [errors, setErrors] = useState<{ [key: string]: boolean | string }>({});

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        if (!countdown) return;

        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [countdown]);

    const handleRequestOtp = async () => {
        if (countdown > 0) return;

        setErrors({});

        try {
            const response = await apiService(HttpMethod.POST, '/users/request-otp', { oldEmail: params.email, email: email, type: OTP_TYPE.CHANGE_PASSWORD });
            setCountdown(60);

            if ('errorCode' in response) {
                setErrors(response)
                return
            }

            if (step === 0) {
                setStep(1);
            }

        } catch (error: any) {
            setCountdown(0);
            throw error
        }
    };

    const handleCheckToken = async () => {
        setErrors({});
        setIsLoading(true);

        if (otp.length <= 0) {
            setErrors({ otp: true });
            setIsLoading(false);
            return;
        }

        try {
            const response = await apiService(HttpMethod.POST, '/users/verify-otp', { email: params.email, otp });

            if ('errorCode' in response) {
                setErrors(response)
                setIsLoading(false);
                return
            }

            if (response.isValid) {
                setStep(2);
                setOtp('');
            }

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            throw error
        }
    };

    return (
        <div className='h-full flex flex-col p-8'>


            <div className='flex flex-col h-full gap-10 justify-center'>
                {step === 0 &&
                    <>
                        <div className='flex flex-col gap-4'>
                            <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('change_your_email')}.</p>
                            <p className='text-lg lg:text-2xl font-normal text-center'>{i18n.t('change_your_email_message')}</p>
                        </div>
                        <div className='flex flex-col gap-4 w-full items-center'>
                            <p className='font-medium text-lg'>{i18n.t('current_email')}</p>
                            <p className='text-xl'>{params.email}</p>

                            <p className='font-medium text-lg'>{i18n.t('new_email')}</p>
                            <input
                                className='border-b border-light_black focus:outline-none pb-1 text-sm w-full lg:w-1/3'
                                placeholder={i18n.t('enter_the_new_email')}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.errorCode && <p className="text-red-500 text-sm mt-1 text-center font-medium">{getErrorMessage(String(errors.errorCode))}</p>}
                        </div>

                        <div className='flex flex-col self-center gap-2 w-full lg:w-1/5 font-medium whitespace-nowrap'>
                            <button onClick={handleRequestOtp} type='button' disabled={isLoading} className='bg-light_black text-white py-1 w-full text-center rounded-full'>{i18n.t('send_code')}</button>
                        </div>
                    </>
                }

                {step === 1 &&
                    <>
                        <div className='flex flex-col gap-4'>
                            <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('enter_the_code')}.</p>
                            <p className='text-lg lg:text-2xl font-normal text-center'>{i18n.t('enter_the_code_message')}</p>
                        </div>

                        <div className='flex flex-col gap-4 w-full items-center'>
                            <input
                                minLength={6}
                                maxLength={6}
                                className='uppercase border-b-light_black border-b-2 focus:outline-none text-4xl text-center mb-8 pb-4 w-full md:w-1/2'
                                style={{ letterSpacing: 20 }}
                                onChange={(e) => setOtp(String(e.target.value).toUpperCase())}
                            />
                            {errors.otp && <p className="text-red-500 text-sm text-center font-medium">{i18n.t('enter_a_code')}.</p>}
                            {!!errors?.errorCode && <p className="text-red-500 text-sm text-center font-medium">{getErrorMessage(errors?.errorCode as string)}</p>}
                        </div>

                        <p className='text-center mb-4'>{i18n.t('didnt_receive_a_code')} <span className='text-orange hover:cursor-pointer' onClick={handleRequestOtp}>{i18n.t('resend_code')}</span></p>

                        {countdown > 0 && (
                            <p className="text-sm text-center font-medium whitespace-pre-line text-orange-500" dangerouslySetInnerHTML={{__html:i18n.t('send_code_to_email', {escapeValue:false, email: email, countdown: countdown})}}></p>
                        )}

                        <div className='flex flex-col self-center gap-2 w-full lg:w-1/5 font-medium whitespace-nowrap'>
                            <button onClick={handleCheckToken} type='button' disabled={isLoading} className='bg-light_black text-white py-1 w-full text-center rounded-full'>{i18n.t('verify')}</button>
                            <button onClick={() => setStep(0)} type='button' className='text-light_black bg-white py-1 w-full text-center rounded-full border border-light_black'>{i18n.t('previous_step')}</button>
                        </div>
                    </>
                }

                {
                    step === 2 &&
                    <Form method='post' className='flex flex-col gap-10'>


                        <div className='flex flex-col gap-4 w-full items-center'>
                            <p className='font-medium text-lg'>{i18n.t('new_email')}</p>
                            <p className='text-'>{email}</p>

                            <input
                                type='hidden'
                                name="email"
                                value={email}
                            />

                            {(!!response?.errorCode && response?.errorCode === 'UR09') && <p className="text-red-500 text-sm mt-1 text-center font-medium">{i18n.t('enter_different_email')}</p>}
                            {(!!response?.errorCode && response?.errorCode !== 'UR09') && <p className="text-red-500 text-sm mt-1 text-center font-medium">{getErrorMessage(response.errorCode)}</p>}
                        </div>

                        <div className='flex flex-col self-center gap-2 w-full lg:w-1/5 font-medium whitespace-nowrap'>
                            <button type='submit' disabled={navigation.state !== 'idle'} className='bg-light_black text-white py-1 w-full text-center rounded-full'>{i18n.t('confirm')}</button>
                        </div>
                    </Form>
                }
            </div>
        </div>
    )
}

export default ChangeEmail