import { Form, Link, useLoaderData, useNavigation, useSubmit } from "react-router-dom";
import { IBooking } from "../utils/interfaces/db_models/bookings.interface";
import { IStructure, ListStructureInput } from "../utils/interfaces/db_models/structure.interface";
import { useEffect, useState } from "react";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { listItems } from "../api/actions/genericActions";
import { ListInput } from "../utils/interfaces/listInput.interface";
import LoadingIndicator from "./LoadingIndicator";
import { BlackUserIcon } from "../utils/icons/UserIcon";
import { StructureCoverImageIconXS } from "../utils/icons/StructureIcon";
import { BOOKING_STATUS } from "../utils/enums/booking_status.enum";
import i18n from './../i18n';

interface IProps {
    isPublic: boolean;
}

const ColivingsList: React.FC<IProps> = ({ isPublic = false }) => {
    const { response, params } = useLoaderData() as { response: { data: IStructure[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number }, params: Record<string, string> };
    const navigation = useNavigation();
    const submit = useSubmit();
    const searching = navigation.location && new URLSearchParams(navigation.location.search).has("name");

    const [structures, setStructures] = useState<{ data: IStructure[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number }>(response);
    const [pagination, setPagination] = useState<PaginationState | undefined>(undefined);

    useEffect(() => {
        if (pagination)
            handleListStructure(pagination, undefined);
    }, [pagination]);

    useEffect(() => {
        if (response)
            setStructures(response);
    }, [response])

    useEffect(() => {
        const inputElement = document.getElementById("name") as HTMLInputElement | null;
        if (inputElement) {
            inputElement.value = params.name || '';
        }
    }, [params.name]);

    const handleListStructure = async (pagination: PaginationState | undefined, columnFilters: ColumnFiltersState | undefined) => {
        const { response } = await listItems<ListInput<ListStructureInput>, IStructure, ListStructureInput>('/structures/get-all', pagination, undefined, columnFilters, { bookings: { include: { user: true } } });
        setStructures(response);
    };

    return (
        <div className='flex flex-col h-full w-full gap-8'>
            <div className='flex items-center gap-4 flex-col md:flex-row'>

                <p className='font-bold text-3xl'>{i18n.t('colivings')}</p>

                <Form id="search-form" role="search">
                    <input
                        id='name'
                        className='p-1 border-b w-full text-lg lg:text-sm font-medium border-gray-500 focus:outline-none'
                        placeholder={i18n.t('search')}
                        defaultValue={params.name}
                        type="search"
                        name="name"
                        onChange={(event) => {
                            const isFirstSearch = params.name == null;
                            submit(event.currentTarget.form, {
                                replace: !isFirstSearch,
                            });
                        }}
                    />
                </Form>
            </div>
            {searching
                ? <LoadingIndicator icon={true} />
                : !!structures?.data?.length
                    ? <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-4'>
                        {
                            structures.data.map((structure, index) => {
                                const currentColivers = structure?.bookings?.filter(booking => booking.status === BOOKING_STATUS.ON_GOING);

                                if (isPublic) {
                                    return <div key={index} className='flex flex-col justify-between aspect-square rounded transition-transform transform hover:scale-105' style={{ boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)' }}>
                                        <ColivingBox structure={structure} currentColivers={currentColivers} isPublic={isPublic} />
                                    </div>
                                }

                                return (
                                    <Link to={'/app/coliving/' + structure.id} key={index} className='flex flex-col justify-between aspect-square rounded transition-transform transform hover:scale-105' style={{ boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)' }}>
                                        <ColivingBox structure={structure} currentColivers={currentColivers} isPublic={isPublic} />
                                    </Link>
                                );
                            })
                        }
                    </div>
                    : <p className='text-sm font-medium text-gray-400 text-center'>{i18n.t('no_coliving_found')}</p>
            }

            {(structures?.data?.length < structures.totalItems) &&
                <div className="flex  items-center justify-center">
                    <button
                        onClick={() => setPagination({ pageIndex: 0, pageSize: pagination?.pageSize || 10 + 10 })}
                        className='text-center mt-4 border-2 border-light_black font-bold text-xl rounded-full px-4 py-1 w-fit'>
                        {i18n.t('load_more')}
                    </button>
                </div>
            }
        </div>
    )
}

const ColivingBox = ({ structure, currentColivers, isPublic }: { structure: IStructure, currentColivers: IBooking[], isPublic: boolean }) => (
    <>
        {!!structure.cover_image
            ? <img
                className='w-full h-1/2 object-cover rounded-t'
                src={structure.cover_image}
                alt='img_coliving'
            />
            : <div className='w-full h-1/2 object-cover rounded-b flex justify-center items-center' style={{ backgroundColor: '#F1F1F1' }}>
                <StructureCoverImageIconXS />
            </div>
        }

        {!isPublic &&
            <button className="bg-light_black font-semibold text-xl text-white rounded-full px-4 py-1 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                {i18n.t('view')}
            </button>
        }

        <div className='flex flex-col justify-center md:gap-1 items-center text-center h-1/2 p-4'>
            <div className='flex flex-col'>
                <p className='font-semibold text-2xl line-clamp-1'>{structure.name}</p>
                <p className='text-xs line-clamp-1'>{structure.city}</p>
            </div>

            {!!currentColivers?.length &&
                <div className="flex items-center gap-2">
                    <p className='text-base font-bold'>{currentColivers.length}</p>
                    <BlackUserIcon />
                </div>
            }

        </div>
    </>
);

export default ColivingsList