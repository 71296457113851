import { ITagCategory } from "./interfaces/db_models/tagCategory.interface";
import { IUser } from "./interfaces/db_models/user.interface";

export const workAffinityPercentage = (user: IUser, otherUser: IUser): number => {
    const profession = user.profession;
    const affinities = user.affinities || [];
    const totalAffinities = affinities.length;

    const commonProfession = (profession && otherUser.profession)
        ? profession.id === otherUser.profession.id
        : false;

    const commonCategory = (!commonProfession && profession && otherUser.profession)
        ? profession.category_id === otherUser.profession.category_id
        : false;

    const commonAffinities = affinities.filter(p1 => otherUser.affinities?.some(p2 => p1.id === p2.id)).length;

    let affinityPercentage =
        (commonProfession ? 1 : 0) +
        (commonProfession ? 1 : commonCategory ? 0.5 : 0) +
        (commonAffinities / totalAffinities);

    return (affinityPercentage / 3) * 100;
};


export const tagCategoryAffinityPercentage = (userTags: ITagCategory[], otherUserTags: ITagCategory[]): number => {
    const commonCategories = userTags.filter(tag1 => otherUserTags.some(tag2 => tag1.id === tag2.id)).length;

    if (commonCategories === 0) {
        return 0;
    }

    const totalCategories = Math.max(userTags.length, otherUserTags.length);
    const affinityPercentage = (commonCategories / totalCategories) * 100;

    return affinityPercentage;
};
