import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IBooking, fieldTypesBooking } from '../../utils/interfaces/db_models/bookings.interface';
import { Form, useActionData, useLoaderData, useNavigate, useNavigation } from 'react-router-dom';
import moment from 'moment';
import useIsOpen from '../../hooks/useIsOpen';
import { getErrorMessage } from '../../utils/manageError';
import { ArrowLeft2 } from '../../utils/icons/ArrowIcon';
import Modal from '../../components/Modal';
import LoadingIndicator from '../../components/LoadingIndicator';
import { HttpMethod, apiService } from '../../api/apiService';
import { useState } from 'react';
import CopyToClipboard from '../../components/CopyToClipboard';
import { BOOKING_STATUS } from '../../utils/enums/booking_status.enum';
import { SupportIcon } from '../../utils/icons/SupportIcon';
import useScreenSize from '../../hooks/useScreenSize';
import Accordion from '../../components/Accordion';
import i18n from './../../i18n';

const BookingManage = () => {
    const response = useActionData() as any;
    const booking = useLoaderData() as IBooking;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const { isOpen, setIsOpen } = useIsOpen(false);
    const { isOpen: accordionOpen, setIsOpen: setAccordionOpen } = useIsOpen(false);
    const { isOpen: isOpenDelete, setIsOpen: setIsOpenDelete } = useIsOpen(false);

    const [loading, setLoading] = useState(false)
    const [link, setLink] = useState<string>('');
    const [linkError, setLinkError] = useState<any>();

    const formik = useFormik({
        initialValues: {
            email: { label: i18n.t('email'), value: booking?.user?.email ?? "" },
            fromDate: { label: i18n.t('start_date'), value: moment(booking?.fromDate).format('yyyy-MM-DD') ?? "" },
            toDate: { label: i18n.t('end_date'), value: moment(booking?.toDate).format('yyyy-MM-DD') ?? "" },
            room: { label: i18n.t('room'), value: booking?.room ?? "" }
        },
        validationSchema: Yup.object({
            email: Yup.object({ value: Yup.string().email(i18n.t('invalid_email_address')).required(i18n.t('required_field')) }),
            fromDate: Yup.object({
                value: Yup.date()
                    .test('is-pending', i18n.t('the_date_cannot_be_earlier_than_today'), function (value) {
                        if (booking?.status === 'PENDING') {
                            return moment(value).isSameOrAfter(moment().subtract(1, 'days'));
                        }
                        return true;
                    })
                    .required(i18n.t('required_field'))
            }),

            toDate: Yup.object({
                value: Yup.date().required(i18n.t('required_field'))
                    .min(moment().subtract(1, 'days').toDate(), i18n.t('the_date_cannot_be_earlier_than_today'))
                    .test('is-after-fromDate', i18n.t('the_date_cannot_be_later_than_the_start_date'), function (value) {
                        const date = formik.values.fromDate.value as string
                        return value && moment(value).isAfter(moment(date));
                    })
            }),
            room: Yup.object({ value: Yup.string().max(10, i18n.t('can_contain_a_maximum_of_number_characters', {number: '10'})) })
        }),
        onSubmit: () => { }
    });

    const generateLink = async () => {
        try {
            setLoading(true);
            setLink('');
            setLinkError('');

            const date = formik.values.fromDate.value as string;
            const toDate = formik.values.toDate.value as string;
            const today = moment();

            if (!date || !toDate) {
                setLinkError(i18n.t('both_dates_are_required'));
                return;
            }

            if (moment(date).isBefore(today, 'day')) {
                setLinkError(i18n.t('the_start_date_cannot_be_earlier_than_today'));
                return;
            }

            if (moment(date).isAfter(toDate)) {
                setLinkError(i18n.t('the_start_date_cannot_be_later_than_the_end_date'));
                return;
            }

            const data = {
                fromDate: date,
                toDate: toDate,
                room: formik.values.room.value
            }

            const link = await apiService<{ bookingLink?: string, errorCode?: string }>(HttpMethod.POST, 'bookings/generate-booking-link', data);
            if (!!link.bookingLink?.length) {
                setLink(link.bookingLink);
            }

            if (link.errorCode) {
                setLinkError(link);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error
        }
    }

    return (
        <div className='flex flex-1 flex-col px-10 sm:px-14 select-none w-full'>
            <div className='grid grid-cols-6 items-baseline py-6'>
                <div className='cursor-pointer w-fit' onClick={() => navigate(-1)}>
                    <ArrowLeft2 />
                </div>

                {!!booking
                    ? <div className='flex col-span-4 flex-col items-center justify-center'>
                        <p className='font-bold text-2xl text-center'>{booking?.user?.name}</p>
                        <p className='text-sm font-medium text-center'>{i18n.t('booking_created_on')} {moment(booking.created_at).format('YYYY-MM-DD HH:mm')}</p>
                    </div>
                    : <p className='text-center col-span-4 font-bold text-4xl'>{i18n.t('booking')}</p>
                }
            </div>

            <div className="border-t shadow -mx-20" />

            <Form method='POST' className='flex w-full h-full flex-col gap-6 py-6 justify-between'>

                <Accordion isOpen={accordionOpen} onToggle={() => setAccordionOpen(!accordionOpen)} title={i18n.t('how_to_book')}>
                    <div className='flex flex-col items-center text-center gap-4'>
                        <div>
                            <p className='text-sm font-bold'>{i18n.t('with_the_customers_email')}</p>
                            <p>{i18n.t('enter_the_customers_email_and_the_accommodation_date')}</p>
                            <p className='text-sm font-bold'>{i18n.t('generate_a_link')}</p>
                            <p>{i18n.t('generate_a_link_info')}</p>
                        </div>
                        <p className='font-medium text-center'>{i18n.t('generate_a_link_info2')}</p>
                    </div>
                </Accordion>

                <div className='flex w-full h-full flex-col gap-6'>

                    <div className='flex flex-col gap-6'>
                        {Object.entries(formik.values).map(([key, value]) => {
                            const errorValue = formik.errors[key as keyof typeof formik.values] as Record<string, string>;
                            const fieldType = fieldTypesBooking[key as keyof typeof fieldTypesBooking];

                            if (!!booking && key === 'email') {
                                return <div key={key} className='flex flex-col'>
                                    <p className='text-lg font-bold'>{value.label}</p>
                                    <input
                                        disabled={true}
                                        value={value.value as string}
                                        className='border-b-2 border-gray-500 p-1 overflow-hidden font-medium focus:outline-none focus:border-gray-700'
                                    />
                                </div>
                            }

                            return <div key={key} className='flex flex-col'>
                                <p className='text-lg font-bold'>{value.label}</p>
                                <input
                                    disabled={(!!booking && key === 'fromDate' && moment(value.value).isSameOrBefore(moment().startOf('days')))}
                                    name={key}
                                    lang="it-IT"
                                    onChange={(e) => {
                                        const selectedDate = e.target.value;
                                        if (!!booking && key === 'fromDate' && moment(selectedDate).isSameOrBefore(moment().startOf('day'))) {
                                            e.preventDefault();
                                        } else {
                                            formik.setFieldValue(key, {
                                                ...value,
                                                value: fieldType === 'number'
                                                    ? Number(selectedDate)
                                                    : (fieldType === 'date')
                                                        ? moment(selectedDate).format('YYYY-MM-DD')
                                                        : selectedDate
                                            });
                                        }
                                    }}
                                    defaultValue={value.value as string}
                                    type={fieldType}
                                    className='border-b-2 border-gray-500 p-1 overflow-hidden font-medium focus:outline-none focus:border-gray-700'
                                />

                                {!!errorValue && isOpen
                                    ? <div className='text-red-500 font-medium text-sm'>
                                        {errorValue.value}
                                    </div>
                                    : null}
                            </div>
                        })}

                        {response?.errorCode === "BO005"
                            ? <p className="text-red-500 text-sm mt-1 text-center font-medium">{i18n.t('this_user_already_has_a_booking_for_these_dates')}</p>
                            : <p className="text-red-500 text-sm mt-1 text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
                        }
                    </div>
                </div>

                {!!link && <div className="flex items-center justify-between bg-white_shady rounded-md w-full">
                    <div className="flex flex-col truncate p-4">
                        <p className="text-gray_shady font-medium truncate select-text">{link}</p>
                    </div>
                    <button type='button' className="bg-orange text-white rounded-r-md hover:bg-orange focus:outline-none focus:bg-orange relative">
                        <CopyToClipboard link={link} padding={4} />
                    </button>
                </div>}

                {!!linkError
                    ? !!linkError.errorCode
                        ? <p className="text-red-500 text-sm mt-1 text-center font-medium">{getErrorMessage(linkError.errorCode)}</p>
                        : <p className="text-red-500 text-sm mt-1 text-center font-medium">{linkError}</p>
                    : null}

                <div className='flex w-full gap-4 justify-end'>
                    {(!!booking && booking?.status !== BOOKING_STATUS.COMPLETED) && <button
                        type='button'
                        disabled={navigation.state !== 'idle' || loading}
                        onClick={() => setIsOpenDelete(true)}
                        className='px-4 py-1 rounded-full text-light_black border border-light_black h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        {i18n.t('chack_out')}
                    </button>}

                    {!booking && <button
                        type='button'
                        title={i18n.t('generate_a_link_to_share_with_the_interested_customer')}
                        onClick={generateLink}
                        disabled={navigation.state !== 'idle' || loading || !formik.dirty}
                        className='px-4 py-1 rounded-full text-light_black border border-light_black h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        {i18n.t('link')} {!!loading && <span className="animate-loading-dots"></span>}
                    </button>}

                    {booking?.status !== BOOKING_STATUS.COMPLETED && <button
                        type={Object.entries(formik.errors).length > 0 ? 'button' : 'submit'}
                        onClick={() => {
                            setIsOpen(true);
                            setLink('')
                        }}
                        disabled={navigation.state !== 'idle' || loading || !formik.dirty}
                        className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        {i18n.t('save')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                    </button>}
                </div>

            </Form>


            {
                !!booking &&
                <Form method="post" action="delete" className='flex w-full justify-end'>
                    {isOpenDelete &&
                        <Modal isOpen={isOpenDelete} wFull={false} className='md:w-96 flex flex-col items-center justify-center gap-6 text-center'>
                            <p className='font-bold text-xl md:text-4xl'>{i18n.t('wait')}</p>
                            <p className='text-base whitespace-pre-line'>{i18n.t('confirm_delete_booking')}</p>
                            {navigation.state === 'idle'
                                ? <div className='flex gap-4 w-full justify-around'>
                                    <button
                                        type='button'
                                        onClick={() => setIsOpenDelete(false)}
                                        className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
                                    >
                                        {i18n.t('cancel')}
                                    </button>
                                    <button
                                        type='submit'
                                        disabled={navigation.state !== 'idle'}
                                        onClick={() => navigate(-1)}
                                        className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                                    >
                                        {i18n.t('proceed')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                                    </button>
                                </div>
                                : <LoadingIndicator label={i18n.t('wait_a_moment')} />
                            }
                        </Modal>
                    }
                </Form>
            }
        </div >
    )
}

export default BookingManage