import { Link, Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/store';
import useCookie from '../../hooks/useCookie';
import { ConnivioLogo } from '../../utils/icons/ConnivioLogo';
import Footer from '../../components/Footer';
import i18n from './../../i18n';
import LanguagesMenu from '../../components/LanguagesMenu';

export const PublicLayout = () => {
    const { user } = useAppSelector(state => state.auth);
    const { cookieData } = useCookie({ accessToken: 'accessToken', refreshToken: 'refreshToken' });

    const location = useLocation();

    if (user || !!cookieData?.refreshToken) {
        return <Navigate to={`/app`} />
    } else {
        return (<div className="flex flex-col h-full w-full text-light_black">
            <div className='w-full h-16 p-2 px-6 flex justify-between bg-white shadow items-center z-20'>

                <Link to={'/'}>
                    <ConnivioLogo />
                </Link>

                <div className='flex items-center gap-6'>
                    <Link to={'login'} className='font-medium text-sm px-4 py-1'>{i18n.t('login')}</Link>
                    <Link to={'register/select'} className='font-medium text-sm bg-orange text-light_black rounded-full px-4 py-1'>{i18n.t('register')}</Link>
                    <LanguagesMenu />
                </div>
            </div>

            <div className='flex-1 overflow-auto'>
                <Outlet />
                {
                    location.pathname.startsWith('/register')
                        || location.pathname.startsWith('/login')
                        || location.pathname.startsWith('/forgot-password')
                        || location.pathname.startsWith('/verify')
                        ? null
                        : <Footer />
                }
            </div>

        </div>
        )
    }

};