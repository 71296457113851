import React, { ReactNode } from 'react';

interface AccordionProps {
    isOpen: boolean;
    onToggle: () => void;
    title: ReactNode;
    children: ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ isOpen, onToggle, title, children }) => {

    return (
        <div className={`select-none flex flex-col transition-all duration-500 ease-in-out h-fit border-b border-darkGray`}>
            <button type='button' className={`flex justify-between items-center cursor-pointer gap-4 overflow-hidden transition-all duration-500 ease-in-out ${isOpen && 'bg-background rounded py-2 '}`} onClick={onToggle}>
                <p className='text-xl font-semibold'>{title}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-6 w-6 ${isOpen ? 'transform rotate-180' : ''}`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </button>

            <div className={`overflow-hidden transition-all duration-500 ease-in-out ${isOpen ? 'h-full pb-2' : 'h-0 opacity-0'}`}>
                {children}
            </div>
        </div>
    );
};

export default Accordion;
