import React from 'react';
import { CrossIcon } from '../utils/icons/CrossIcon';
import i18n from './../i18n';

interface ItineraryBox {
    itinerary: any;
    deleteItinerary: (itineraryId: any) => void;
    viewItinerary: (itineraryId: any) => void;
    isLoading: boolean
}

const ItineraryBox: React.FC<ItineraryBox> = ({ itinerary, deleteItinerary, viewItinerary, isLoading }) => {
    return (
        <>
            <div className='flex w-full relative'>
                {
                    itinerary.suggestions.map((suggestion: any, index: number) => {
                        return (
                            <div key={index} className='w-full' style={{ minHeight: 250 }}>
                                <img
                                    src={suggestion.image}
                                    alt={suggestion.title}
                                    className='h-full w-full'
                                    style={{ objectFit: 'cover' }}
                                />
                            </div>
                        )
                    })
                }
                <div className='absolute top-0 right-1/2 translate-x-1/2 -translate-y-1/2'>
                    <div
                        className='cursor-pointer'
                        onClick={() => {
                            deleteItinerary(itinerary?.id)
                        }}>
                        <CrossIcon />
                    </div>
                </div>
                <div className='absolute bottom-0 right-1/2 translate-x-1/2 translate-y-1/2'>
                    <p onClick={() => viewItinerary(itinerary?.id)} className='cursor-pointer text-lg text-center text-white p-2 bg-light_black px-10 py-2 w-fit rounded-full'>{i18n.t('select')}</p>
                </div>
            </div>

            <div className='flex flex-col justify-center gap-2 items-center text-center h-1/2'>
                <div className='flex flex-col mb-2'>
                    <p className='font-semibold text-2xl mb-2 mt-10'>{itinerary.title}</p>
                </div>
            </div>
        </>
    )
}

export default ItineraryBox