import React from 'react'

export const UserNoBGIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_843_368" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                <circle cx="15" cy="15" r="15" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_843_368)">
                <rect width="30" height="30" fill="#F1F1F1" />
                <path fillRule="evenodd" clipRule="evenodd" d="M24.0055 30C24.0139 29.8307 24.0181 29.6602 24.0181 29.4886C24.0181 24.1647 19.9602 19.8488 14.9546 19.8488C9.94902 19.8488 5.89118 24.1647 5.89118 29.4886C5.89118 29.6602 5.89539 29.8307 5.90371 30L24.0055 30Z" fill="#1A1C20" />
                <circle cx="15.0001" cy="12.3717" r="5.66465" fill="#1A1C20" />
            </g>
        </svg>
    )
}