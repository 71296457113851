import { useEffect, useState } from 'react';
import moment from 'moment';
import { jwtDecode } from "jwt-decode";


const useCookie = (cookieNames: Record<string, string>) => {

    const getCookie = (name: string): string | null => {
        const cookies = document.cookie.split(';').map((c) => c.trim());
        const cookie = cookies.find((c) => c.startsWith(name + '='));
        return cookie ? cookie.split('=')[1] : null;
    };

    const optionsToString = (options?: any): string => {
        if (!options) return '';
        return Object.entries(options)
            .map(([key, value]) => `${key}=${value}`)
            .join('; ');
    };


    const [cookieData, setCookieData] = useState<Record<string, string | null>>(() => {
        const initialData: Record<string, string | null> = {};
        Object.keys(cookieNames).forEach((name) => {
            const cookieValue = getCookie(cookieNames[name]);
            initialData[name] = (cookieValue) ? cookieValue : null;
        });
        return initialData;
    });

    const setCookie = (name: string, data: any, options: any) => {
        let expiresDate: undefined | string;
        if (options.isPayload) {
            const payload = jwtDecode(data);
            const cookieExpirationTime = (payload.exp! - Math.floor(Date.now() / 1000))
                ? payload.exp!
                : payload.exp! - 3600 // 3600 seconds = 1 hour

            expiresDate = moment(cookieExpirationTime * 1000).toDate().toUTCString();
        }

        setCookieData((prevData) => ({
            ...prevData,
            [name]: data,
        }));

        if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
            document.cookie = `${cookieNames[name]}=${data}; path=/; ${!!expiresDate && "expires=" + expiresDate + ';'}`
        } else {
            document.cookie = `${cookieNames[name]}=${data}; path=/; domain=.connivio.com; ${!!expiresDate && "expires=" + expiresDate + ';'}`
        }

    };

    const removeCookie = (name: string, options?: any) => {
        setCookieData((prevData) => ({
            ...prevData,
            [name]: null,
        }));

        if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
            document.cookie = `${cookieNames[name]}=; path = /; ${optionsToString(options)} expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        } else {
            document.cookie = `${cookieNames[name]}=; path = /; domain=.connivio.com; ${optionsToString(options)} expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        }

    };

    return { cookieData, setCookie, removeCookie };
};

export default useCookie;
