import { useNavigate } from 'react-router-dom'
import i18n from './../../i18n';

const PageNotFound = () => {
    const navigate = useNavigate()

    const handleRedirect = () => {
        navigate('/')
    }

    return (
        <div className="flex flex-col items-center justify-center h-full w-full bg-white_shady p-4">
            <h1 className='font-bold text-orange text-6xl mb-4'>404</h1>
            <h2 className='font-medium text-2xl mb-2'>{i18n.t('page_not_found')}</h2>
            <p className='text-center mb-4'>{i18n.t('page_not_found_info')}</p>
            <button
                type='button'
                onClick={handleRedirect}
                className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
            >
                {i18n.t('go_back_to_home')}
            </button>
        </div>
    )
}

export default PageNotFound
