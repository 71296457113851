import { useEffect, useState } from 'react';
import { ActionFunctionArgs, Form, redirect, useActionData, useNavigate, useNavigation } from 'react-router-dom';
import { HttpMethod, apiService } from '../../api/apiService';
import { getErrorMessage } from '../../utils/manageError';
import { OTP_TYPE } from '../../utils/enums/otp_type.enum';
import i18n from './../../i18n';

export async function handleResetPassword({ request, params }: ActionFunctionArgs) {
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);
    const { email, password, confirmPassword } = updates;

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email as string)) {
        return { email: true };
    }
    if (!/^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password as string)) {
        return { password: true };
    }
    if (password !== confirmPassword) {
        return { confirmPassword: true };
    }

    const response = await apiService(HttpMethod.POST, '/users/reset-password', { email, password });

    if ('errorCode' in response) {
        return response
    }

    return redirect('/login')
};

const ResetPassword = () => {
    const response = useActionData() as any;
    const navigation = useNavigation();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [errors, setErrors] = useState<{ [key: string]: boolean | string }>({});
    const [step, setStep] = useState(0);

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    useEffect(() => {
        if (!countdown) return;

        const intervalId = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [countdown]);

    const handleRequestOtp = async () => {
        if (countdown > 0) return;

        setErrors({});
        if (!emailRegex.test(email)) {
            setErrors({ email: true })
            return;
        }

        try {

            setCountdown(60);
            const response = await apiService(HttpMethod.POST, '/users/request-otp', { email, type: OTP_TYPE.RESET_PASSWORD });

            if ('errorCode' in response) {
                setErrors(response)
                return
            }

            if (step === 0) {
                setStep(1);
            }
        } catch (error: any) {
            setCountdown(0);
            throw error
        }
    };

    const handleCheckToken = async () => {
        setErrors({});
        setIsLoading(true);

        if (otp.length <= 0) {
            setErrors({ otp: true });
            setIsLoading(false);
            return;
        }

        try {
            const response = await apiService(HttpMethod.POST, '/users/verify-otp', { email, otp });

            if ('errorCode' in response) {
                setErrors(response)
                setIsLoading(false);
                return
            }

            if (response.isValid) {
                setStep(2);
                setOtp('');
            }

            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            throw error
        }
    };

    return (
        <div className='flex-1 flex flex-col container m-auto p-8 gap-10'>
            <div className='flex flex-col gap-2'>
                <p className='font-medium text-sm text-center'>{i18n.t('step')} {step + 1}/3</p>
                <div className='border border-light_black w-full rounded-full'>
                    <div className={`bg-light_black h-4 rounded-full transition-width ease-in-out duration-300 ${step === 0 ? 'w-1/3' : step === 1 ? 'w-2/3' : step === 2 ? 'w-full' : ''}`} />
                </div>
            </div>

            <div className='flex flex-col h-full gap-10 justify-center'>
                {step === 0 &&
                    <>
                        <div className='flex flex-col gap-4'>
                            <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('enter_the_email')}</p>
                            <p className='text-lg lg:text-2xl font-normal text-center'>{i18n.t('enter_the_email_of_your_account')}</p>
                        </div>

                        <div className='flex flex-col gap-4 w-full items-center'>
                            <p className='font-medium text-lg'>{i18n.t('email')}</p>
                            <input
                                className='border-b border-light_black focus:outline-none pb-1 text-sm w-full lg:w-1/3'
                                placeholder={i18n.t('enter_your_email')}
                                type="text"
                                name='email'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {errors.email && <p className="text-red-500 text-sm mt-1 text-center font-medium">{i18n.t('enter_a_valid_email')}</p>}
                        </div>

                        <div className='flex flex-col self-center gap-2 w-full lg:w-1/5 font-medium whitespace-nowrap'>
                            <button onClick={handleRequestOtp} type='button' disabled={isLoading} className='bg-light_black text-white py-1 w-full text-center rounded-full'>{i18n.t('send_code')}</button>
                            {(countdown > 0 && step === 0) && (
                                <p className="text-sm text-center font-medium whitespace-pre-line text-orange-500">
                                    {i18n.t('try_again_in_x_seconds', {countdown: countdown})}
                                </p>
                            )}
                        </div>
                    </>
                }

                {step === 1 &&
                    <>
                        <div className='flex flex-col gap-4'>
                            <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('enter_the_code')}</p>
                            <p className='text-lg lg:text-2xl font-normal text-center'>{i18n.t('enter_the_code_message')}</p>
                        </div>

                        <div className='flex flex-col gap-4 w-full items-center'>
                            <input
                                minLength={6}
                                maxLength={6}
                                className='uppercase border-b-light_black border-b-2 focus:outline-none text-4xl text-center mb-8 pb-4 w-full'
                                style={{ letterSpacing: 20 }}
                                onChange={(e) => setOtp(String(e.target.value).toUpperCase())}
                            />
                            {errors.otp && <p className="text-red-500 text-sm text-center font-medium">{i18n.t('enter_a_code')}</p>}
                            {!!errors?.errorCode && <p className="text-red-500 text-sm text-center font-medium">{getErrorMessage(errors?.errorCode as string)}</p>}
                        </div>

                        <div className='flex flex-col gap-2 mb-4'>
                            {countdown > 0 && (
                                <p className="text-sm text-center font-medium whitespace-pre-line text-orange-500" dangerouslySetInnerHTML={{__html:i18n.t('send_code_to_email', {escapeValue:false, email: email, countdown: countdown})}}></p>
                            )}
                            <p className='text-center'>{i18n.t('didnt_receive_a_code')} <span className='text-orange hover:cursor-pointer' onClick={handleRequestOtp}>{i18n.t('resend_code')}</span></p>
                        </div>
                        <div className='flex flex-col self-center gap-2 w-full lg:w-1/5 font-medium whitespace-nowrap'>
                            <button onClick={handleCheckToken} type='button' disabled={isLoading} className='bg-light_black text-white py-1 w-full text-center rounded-full'>{i18n.t('verify')}</button>
                            <button onClick={() => setStep(0)} type='button' className='text-light_black bg-white py-1 w-full text-center rounded-full border border-light_black'>{i18n.t('previous_step')}</button>
                        </div>
                    </>
                }

                {
                    step === 2 &&
                    <Form method='post' className='flex flex-col gap-10'>
                        <div className='flex flex-col gap-4'>
                            <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('reset_the_password')}</p>
                            <p className='text-lg lg:text-2xl font-normal text-center'>{i18n.t('enter_your_new_password')}</p>
                        </div>

                        <div className='flex flex-col gap-4 w-full items-center'>
                            <p className='font-medium text-lg'>{i18n.t('new_password')}</p>
                            <input
                                className='border-b border-light_black focus:outline-none pb-1 text-sm w-full lg:w-1/3'
                                placeholder={i18n.t('enter_your_new_password')}
                                type="password"
                                name='password'
                            />
                            {!!response?.password && <p className="text-red-500 text-sm text-center font-medium">{i18n.t('policy_password_info')}</p>}
                            <p className='font-medium text-lg'>{i18n.t('confirm_new_password')}</p>
                            <input
                                className='border-b border-light_black focus:outline-none pb-1 text-sm w-full lg:w-1/3'
                                placeholder={i18n.t('confirm_new_password')}
                                type="password"
                                name='confirmPassword'
                            />
                            {!!response?.confirmPassword && <p className="text-red-500 text-sm text-center font-medium">{i18n.t('the_password_does_not_match_the_one_entered_above')}</p>}
                            <input
                                type='hidden'
                                name="email"
                                value={email}
                            />
                            {response?.errorCode === 'UR09' && <p className="text-red-500 text-sm mt-1 text-center font-medium">{i18n.t('enter_a_password_different_from_the_previous_one')}</p>}
                            {!!response?.errorCode && response?.errorCode !== 'UR09' && <p className="text-red-500 text-sm mt-1 text-center font-medium">{getErrorMessage(response.errorCode)}</p>}
                        </div>

                        <div className='flex flex-col self-center gap-2 w-full lg:w-1/5 font-medium whitespace-nowrap'>
                            <button type='submit' disabled={navigation.state !== 'idle'} className='bg-light_black text-white py-1 w-full text-center rounded-full'>{i18n.t('confirm')}</button>
                        </div>
                    </Form>
                }
            </div>
        </div>
    )
}

export default ResetPassword