import { useState } from 'react';
import { useLoaderData, Form, useNavigation } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { IUser } from "../../utils/interfaces/db_models/user.interface";
import { IProfessionCategory } from '../../utils/interfaces/db_models/professionCategory.interface';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ArrowLeft, ArrowRight } from '../../utils/icons/ArrowIcon';
import { IProfession } from '../../utils/interfaces/db_models/profession.interface';
import { ITagCategory } from '../../utils/interfaces/db_models/tagCategory.interface';
import CropImage from '../../components/CropImage';
import i18n from './../../i18n';

const CompleteProfile = () => {
    const user = useAppSelector(store => store.auth.user) as IUser;
    const { professionsCategory } = useLoaderData() as { professionsCategory: { data: IProfessionCategory[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number } };
    const { tagCategory } = useLoaderData() as { tagCategory: { data: ITagCategory[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number } };
    const navigation = useNavigation();

    const [step, setStep] = useState<number>(0);
    const [selectedImageURL, setSelectedImageURL] = useState<string | null>();
    const [bio, setBio] = useState<string>('');
    const [categoryIndex, setCategoryIndex] = useState(0);
    const [professionIndex, setProfessionIndex] = useState(0);
    const [affinitiesIndex, setAffinitiesIndex] = useState(0);
    const [selectedProfession, setSelectedProfession] = useState<IProfession>(professionsCategory?.data[0]?.professions[0]);
    const [affinities, setAffinities] = useState<string[]>([]);
    const [tagCategorySelected, setTagCategorySelected] = useState<string[]>([]);

    const handleImageChange = (newImageSrc: string | null) => {
        setSelectedImageURL(newImageSrc);
    };

    return (!!user
        ? <Form method='PUT' encType="multipart/form-data" className='flex flex-col h-full w-full p-8 gap-14 select-none justify-between'>
            {(step > 0 && step < 6) && <div className='flex flex-col items-center justify-center gap-2 w-full'>
                <p className='font-bold text-lg'>{i18n.t('step')} {step}/{5}</p>
                <div className='w-full h-4 bg-white_shady rounded-full border border-light_black'>
                    <div className='h-full bg-light_black rounded-md' style={{ width: `${(step) / 5 * 100}%` }}></div>
                </div>
            </div>}

            {step === 0 && <div className='flex flex-col items-center justify-center h-full gap-16 text-center'>
                <div className='flex flex-col gap-4 lg:gap-8 w-full'>
                    <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('complete_your_profile')}</p>
                    <p className='text-lg lg:text-2xl'>{i18n.t('complete_your_profile_info')}</p>
                </div>
                <button type='button' onClick={() => setStep(step + 1)} className='bg-light_black font-semibold text-xl text-white rounded-full px-4 py-0.5'>{i18n.t('ok_i_got_it')}</button>
            </div>}

            {step === 1 && <div className='flex flex-col items-center justify-center h-fit w-full gap-8 text-center'>
                <div className='flex flex-col w-full gap-4'>
                    <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('profile_picture')}</p>
                    <p className='text-lg lg:text-2xl'>{i18n.t('profile_picture_info')}</p>
                </div>
                <div className='h-56 2xl:h-72 aspect-square rounded-full overflow-hidden flex' >
                    <label htmlFor="image" className='cursor-pointer'>
                        <img
                            className='w-full h-full object-cover'
                            src={selectedImageURL || user.profile_image}
                            alt='profile-img'
                        />
                    </label>
                </div>
                <div className='flex flex-col gap-2'>
                    <button type='button' onClick={() => setStep(step + 1)} className='border border-light_black font-semibold text-xl rounded-full px-4 py-0.5'>{i18n.t('next_step')}</button>
                </div>
            </div>}

            {step === 2 && <div className='flex flex-col items-center justify-center h-full w-full gap-8 text-center'>
                <div className='flex flex-col w-full lg:w-2/3 gap-4'>
                    <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('tell_us_about_yourself')}</p>
                    <p className='text-lg lg:text-2xl'>{i18n.t('tell_us_about_yourself_info')}</p>
                </div>
                <div className='w-full h-full flex flex-col gap-2 2xl:pt-20'>
                    <textarea name='biography' maxLength={1000} rows={6} value={bio} onChange={(e) => setBio(e.target.value)} placeholder={i18n.t('write_a_brief_description_of_yourself')} className='font-medium text-base w-full p-2 border border-light_black focus:outline-none resize-none' />
                    <div className='flex flex-col w-full items-end'>
                        <p className='font-semibold text-xl'>{bio.length}/1000</p>
                        <p className='font-semibold text-sm opacity-60'>{i18n.t('max_1000_characters')}</p>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row w-full justify-end gap-3 pt-4'>
                    <button type='button' onClick={() => setStep(step - 1)} className='border border-light_black font-semibold text-xl rounded-full px-4 py-1'>{i18n.t('previous_step')}</button>
                    <button type='button' onClick={() => setStep(step + 1)} className='bg-light_black font-semibold text-xl text-white rounded-full px-4 py-1'>{i18n.t('next_step')}</button>
                </div>
            </div>}

            {step === 3 && <div className='flex flex-col items-center justify-center h-full w-full gap-8 text-center'>
                <div className='flex flex-col w-full lg:w-2/3 gap-4'>
                    <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('your_profession')}</p>
                    <p className='text-lg lg:text-2xl'>{i18n.t('your_profession_info')}</p>
                </div>
                <div className='flex flex-col w-full lg:w-2/3 h-full justify-center gap-4'>
                    <p className="text-2xl lg:text-3xl font-semibold text-center">{i18n.t('category')} {(categoryIndex + 1).toString().padStart(2, '0')}/{professionsCategory?.data.length}</p>
                    <div className='flex items-center justify-between w-full gap-12'>
                        <button type='button' disabled={categoryIndex === 0} className='disabled:opacity-50 scale-150' onClick={() => {
                            setCategoryIndex(categoryIndex - 1);
                            setProfessionIndex(0);
                            setSelectedProfession(professionsCategory.data[categoryIndex - 1]?.professions[0]);
                        }}>
                            <ArrowLeft />
                        </button>
                        <p className='font-semibold text-base lg:text-xl text-center'>{professionsCategory.data[categoryIndex].name}</p>
                        <button type='button' disabled={categoryIndex === professionsCategory.data.length - 1} className='disabled:opacity-50 scale-150' onClick={() => {
                            setCategoryIndex(categoryIndex + 1);
                            setProfessionIndex(0);
                            setSelectedProfession(professionsCategory.data[categoryIndex + 1]?.professions[0]);
                        }}>
                            <ArrowRight />
                        </button>
                    </div>

                    <p className="text-2xl lg:text-3xl font-semibold text-center">{i18n.t('profession')}</p>
                    <div className='flex items-center justify-between w-full gap-12'>
                        <button type='button' disabled={professionIndex === 0} className='disabled:opacity-50 scale-150' onClick={() => {
                            setProfessionIndex(professionIndex - 1);
                            setSelectedProfession(professionsCategory.data[categoryIndex]?.professions[professionIndex - 1]);
                        }}>
                            <ArrowLeft />
                        </button>
                        <p className='font-semibold text-base lg:text-xl text-center'>{professionsCategory.data[categoryIndex].professions[professionIndex].name}</p>
                        <button type='button' disabled={professionIndex === professionsCategory.data[categoryIndex].professions.length - 1} className='disabled:opacity-50 scale-150' onClick={() => {
                            setProfessionIndex(professionIndex + 1);
                            setSelectedProfession(professionsCategory.data[categoryIndex]?.professions[professionIndex + 1]);
                        }}>
                            <ArrowRight />
                        </button>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row w-full justify-end gap-3'>
                    <button type='button' onClick={() => setStep(step - 1)} className='border border-light_black font-semibold text-xl rounded-full px-4 py-1'>{i18n.t('previous_step')}</button>
                    <button type='button' onClick={() => setStep(step + 1)} className='bg-light_black font-semibold text-xl text-white rounded-full px-4 py-1'>{i18n.t('next_step')}</button>
                </div>
            </div>}

            {step === 4 && <div className='flex flex-col items-center justify-between h-full w-full gap-8 text-center'>
                <div className='flex flex-col w-full lg:w-2/3 gap-4'>
                    <p className="text-4xl lg:text-6xl font-bold text-center mt-8">{i18n.t('your_work_affinities')}</p>
                    <p className='text-lg lg:text-2xl'>{i18n.t('your_work_affinities_info')}</p>
                </div>

                <div className='flex flex-col items-center justify-start w-full gap-6'>
                    <div className='flex flex-col w-full lg:w-2/3 gap-4'>
                        <p className="text-2xl lg:text-3xl font-semibold text-center">{i18n.t('category')} {(affinitiesIndex + 1).toString().padStart(2, '0')}/{professionsCategory.data.length}</p>
                        <div className='flex items-center justify-between w-full gap-12'>
                            <button type='button' disabled={affinitiesIndex === 0} className='disabled:opacity-50 scale-150' onClick={() => setAffinitiesIndex(affinitiesIndex - 1)}>
                                <ArrowLeft />
                            </button>
                            <p className='font-semibold text-base lg:text-xl text-center'>{professionsCategory.data[affinitiesIndex].name}</p>
                            <button type='button' disabled={affinitiesIndex === professionsCategory.data.length - 1} className='disabled:opacity-50 scale-150' onClick={() => setAffinitiesIndex(affinitiesIndex + 1)}>
                                <ArrowRight />
                            </button>
                        </div>
                    </div>

                    <div className='flex flex-col gap-4'>
                        <p className="text-2xl lg:text-3xl font-semibold text-center">{i18n.t('professions')} <span className='whitespace-nowrap'>({i18n.t('select2')} {affinities.length})</span></p>
                        <div className='flex gap-4 flex-wrap sm:justify-center w-full border-t border-light_black py-6'>
                            {professionsCategory.data[affinitiesIndex].professions.map((profession, index) => {
                                return <button
                                    key={index}
                                    type='button'
                                    className={`text-xl font-semibold rounded-full px-4 py-1 ${affinities.find(aff => aff === profession.id) ? 'bg-yellow-600' : 'border border-light_black'}`}
                                    onClick={() => setAffinities(prevAffinities => {
                                        const index = prevAffinities.indexOf(profession.id);
                                        return index === -1 ? [...prevAffinities, profession.id] : prevAffinities.filter(aff => aff !== profession.id);
                                    })}
                                >
                                    {profession.name}
                                </button>
                            })}
                        </div>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row w-full justify-end gap-3 '>
                    <button type='button' onClick={() => setStep(step - 1)} className='border border-light_black font-semibold text-xl rounded-full px-4 py-1'>{i18n.t('previous_step')}</button>
                    <button type='button' onClick={() => setStep(step + 1)} className='bg-light_black font-semibold text-xl text-white rounded-full px-4 py-1'>{i18n.t('next_step')}</button>
                </div>
            </div>}

            {step === 5 && <div className='flex flex-col items-center justify-between h-full md:h-full w-full gap-8 text-center'>
                <div className='flex flex-col w-full lg:w-2/3 gap-4'>
                    <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('your_interests')}</p>
                    <p className='text-lg lg:text-2xl'>{i18n.t('your_interests_info')}</p>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 w-full'>
                    {tagCategory.data.map((category, index) => {
                        return <button
                            key={index}
                            type='button'
                            className={`aspect-video flex flex-col w-full h-full text-left p-4 ${tagCategorySelected.find(cat => cat === category.id) ? 'bg-yellow-600' : 'border border-light_black'}`}
                            onClick={() => setTagCategorySelected(prev => {
                                const index = prev.indexOf(category.id);
                                return index === -1 ? [...prev, category.id] : prev.filter(c => c !== category.id);
                            })}
                        >
                            <p className='font-bold text-xl w-full truncate'>{category.name}</p>
                            <p className='font-medium text-sm w-full whitespace-pre-line line-clamp-6'>{category.description}</p>
                        </button>
                    })}
                </div>
                <div className='flex flex-col sm:flex-row w-full justify-end gap-3'>
                    <button type='button' onClick={() => setStep(step - 1)} className='border border-light_black font-semibold text-xl rounded-full px-4 py-1'>{i18n.t('previous_step')}</button>
                    <button type='button' onClick={() => setStep(step + 1)} className='bg-light_black font-semibold text-xl text-white rounded-full px-4 py-1'>{i18n.t('next_step')}</button>
                </div>
            </div>}

            {/* STEP 1 */}
            <div className={`${step === 1 ? '' : 'hidden'}`}><CropImage onImageChange={handleImageChange} name='image' rounded={true} maxWidth={512} maxHeight={512}><></></CropImage></div>

            {/* STEP 2 */}
            <input type='hidden' name='biography' value={bio} />

            {/* STEP 3 */}
            <input type='hidden' name='profession_id' value={selectedProfession?.id} />

            {/* STEP 4 */}
            <select hidden multiple={true} name='affinities[]' value={affinities} onChange={() => false}>
                {affinities.map(affinity => <option key={affinity} value={affinity} />)}
            </select>

            {/* STEP 5 */}
            <select hidden multiple={true} name='tagCategories[]' value={tagCategorySelected} onChange={() => false}>
                {tagCategorySelected.map(tagCategory => <option key={tagCategory} value={tagCategory} />)}
            </select>

            {step === 6 && <div className='flex flex-col items-center justify-center w-full h-full gap-16 text-center'>
                <div className='flex flex-col gap-4 lg:gap-8 w-full'>
                    <p className="text-4xl lg:text-6xl font-bold text-center">{i18n.t('excellent')}</p>
                    <p className='text-lg lg:text-2xl'>{i18n.t('excellent_info')}</p>
                </div>

                {navigation.state !== 'idle' && <div>
                    <LoadingIndicator icon />
                </div>}

                <button type='submit' disabled={navigation.state !== 'idle'} className='bg-light_black font-semibold text-xl text-white rounded-full px-4 py-1'>{i18n.t('lets_get_started')}</button>
            </div>}

        </Form>

        : <LoadingIndicator icon additionalStyle='h-full' />
    )
}

export default CompleteProfile