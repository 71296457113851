import moment from "moment";
import { createBrowserRouter } from "react-router-dom";
import { genericPost, getItem, getUserByRefreshToken, listItems, genericPut, genericDelete } from "../api/actions/genericActions";

// Utils
import ErrorPage from "../views/utilities/ErrorPage";
import PageNotFound from "../views/utilities/PageNotfound";
import CompleteProfile from "../views/utilities/CompleteProfile";
import ColivingsList from "../components/ColivingsList";

// Layout
import { PublicLayout } from "./layouts/PublicLayout";
import { AppLayout } from "./layouts/AppLayout";
import { BackofficeLayout } from "./layouts/BackofficeLayout";
import { AdminLayout } from "./layouts/AdminLayout";
import { UtilitiesLayout } from "./layouts/UtilitiesLayout";

// Public
import Landing from "../views/public/Landing";
import Login, { handleLogin } from "../views/auth/Login";
import Register, { handleRegister } from "../views/auth/RegisterNew";
import VerifyAccount, { handleVerifyUser } from "../views/auth/VerifyAccount";
import ResetPassword, { handleResetPassword } from "../views/auth/ResetPassword";
import SelectRegister from "../views/auth/SelectRegister";

// App
import Home from "../views/app/Home";
import Coliving from "../views/app/Coliving";
import ChatRooms from "../views/app/ChatRooms";
import Chat from "../views/app/Chat";
import UserProfile from "../views/app/UserProfile";
import UserProfileManage from "../views/app/UserProfileManage";
import BookingsList from "../views/app/BookingsList";
import Booking from "../views/app/Booking";

// Backoffice
import ColivingManage from "../views/backoffice/ColivingManage";
import BackofficeHome from "../views/backoffice/BackofficeHome";
import Bookings from "../views/backoffice/Bookings";
import BookingManage from "../views/backoffice/BookingManage";

// Admin
import AdminHome from "../views/admin/AdminHome";
import Structures from "../views/admin/Structures";
import StructureManage from "../views/admin/StructureManage";
import Users from "../views/admin/Users";
import UserManage from "../views/admin/UserManage";

// Interface 
import { IUser, ListUserInput } from "../utils/interfaces/db_models/user.interface";
import { IStructure, ListStructureInput } from "../utils/interfaces/db_models/structure.interface";
import { IBooking, ListBookingInput } from "../utils/interfaces/db_models/bookings.interface";
import { ListInput } from "../utils/interfaces/listInput.interface";
import { IProfessionCategory, ListProfessionCategoryInput } from "../utils/interfaces/db_models/professionCategory.interface";
import { IChatRoom } from "../utils/interfaces/db_models/chatRoom.interface";
import { ITagCategory, ListTagCategoryInput } from "../utils/interfaces/db_models/tagCategory.interface";
import Activities from "../views/backoffice/Activities";
import ActivitiesManage from "../views/backoffice/ActivitiesManage";
import { ISuggestion, ListSuggestionInput } from "../utils/interfaces/db_models/suggestion.interface";
import ItineraryChatbot from "../views/app/ItineraryChatbot";
import ChangeEmail from "../views/app/ChangeEmail";
import Administrators from "../views/backoffice/Administrators";
import AdministratorsManage from "../views/backoffice/AdministratorsManage";
import { IStructureUser } from "../utils/interfaces/db_models/structureUser.interface";
import BookingInvitation from "../views/app/BookingInvitation";
import { acceptBookingViaLink } from "../api/actions/bookingApi";
import Alert from "../views/backoffice/Alert";
import { IMessage } from "../utils/interfaces/db_models/message.interface";

export const routing = createBrowserRouter([
    {
        path: "/",
        element: <PublicLayout />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        element: <Landing />,
                        children: [
                            {
                                errorElement: <ErrorPage />,
                                children: [
                                    {
                                        index: true,
                                        element: <ColivingsList isPublic={true} />,
                                        loader: (args) => listItems<ListInput<ListStructureInput>, IStructure, ListStructureInput>('/structures/get-all', { pageIndex: 0, pageSize: 10 }, undefined, [{ id: 'isDisabled', value: false }], { bookings: { include: { user: true } } }, args),
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "login",
                        element: <Login />,
                        action: handleLogin
                    },
                    {
                        path: "register/select",
                        element: <SelectRegister />
                    },
                    {
                        path: "register/:type",
                        element: <Register />,
                        action: handleRegister
                    },
                    {
                        path: "verify/:email",
                        element: <VerifyAccount />,
                        action: handleVerifyUser
                    },
                    {
                        path: "forgot-password",
                        element: <ResetPassword />,
                        action: handleResetPassword
                    }
                ]
            }
        ]
    },
    {
        path: "/app",
        element: <AppLayout />,
        loader: async () => await getUserByRefreshToken<IUser>(),
        errorElement: <ErrorPage />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        element: <Home />,
                        loader: async () => await listItems<ListInput<ListBookingInput>, IBooking, ListBookingInput>('/bookings/list-user-bookings'),
                        children: [
                            {
                                errorElement: <ErrorPage />,
                                children: [
                                    {
                                        index: true,
                                        element: <ColivingsList isPublic={false} />,
                                        loader: async (args) => await listItems<ListInput<ListStructureInput>, IStructure, ListStructureInput>('/structures/get-all', { pageIndex: 0, pageSize: 10 }, undefined, [{ id: 'isDisabled', value: false }], { bookings: { include: { user: true } } }, args)
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: "coliving/:id",
                        element: <Coliving />,
                        loader: async (args) => await getItem('/structures/get-by-id/', args),
                        children: [
                            {
                                errorElement: <ErrorPage />,
                                children: [
                                    {
                                        index: true,
                                        element: <ColivingsList isPublic={false} />,
                                        loader: async (args) => await listItems<ListInput<ListStructureInput>, IStructure, ListStructureInput>('/structures/get-all', { pageIndex: 0, pageSize: 10 }, undefined, [{ id: 'isDisabled', value: false }], { bookings: { include: { user: true } } }, args),
                                    }
                                ]
                            }
                        ]
                    },

                    // **** BOOKING ROUTES ****
                    {
                        path: "bookings",
                        element: <BookingsList />,
                        loader: async () => await listItems<ListInput<ListBookingInput>, IBooking, ListBookingInput>('/bookings/list-user-bookings')
                    },
                    {
                        path: "bookings/:id",
                        element: <Booking />,
                        loader: (args) => getItem('/bookings/get-by-id/', args)
                    },
                    {
                        path: "booking/invite/:invite",
                        element: <BookingInvitation />,
                        loader: (args) => getItem('/bookings/invitation/get-info/', args, "invite"),
                        action: async (args) => await acceptBookingViaLink(args)
                    },
                    // **** BOOKING ROUTES ****

                    // **** CHAT ROUTES ****
                    {
                        path: "chatrooms",
                        element: <ChatRooms />,
                        errorElement: <ErrorPage />,
                        children: [
                            {
                                path: ":id",
                                element: <Chat />,
                                loader: (args) => getItem('/chat-rooms/get-by-id/', args),
                                action: (args) => genericPost<IChatRoom>('chat-rooms/create/private', '/app/chatrooms/', args, true)
                            }
                        ]
                    },
                    // **** CHAT ROUTES ****

                    // **** USER PROFILE ****
                    {
                        path: "user/:id",
                        element: <UserProfile />,
                        loader: async (args) => await getItem('/users/get-by-id/', args),
                        action: (args) => genericPost<IChatRoom>('chat-rooms/create/private', '/app/chatrooms/', args, true)
                    },
                    {
                        path: "user/:id/manage",
                        element: <UserProfileManage />,
                        action: (args) => genericPut<IChatRoom>('users/update/', '/app/user/', args, true, true)
                    },
                    {
                        path: "user/:id/manage/change/:email",
                        element: <ChangeEmail />,
                        action: (args) => genericPut<IChatRoom>('users/update/', '/app/user/', args, true, true)
                    },
                    // **** USER PROFILE ****

                    {
                        path: "coliving/create",
                        element: <ColivingManage />,
                        action: async (args) => await genericPost<IStructure>('structures/create', '/backoffice/' + moment().format('YYYY'), args, false, true)
                    },

                    // **** ITINERARY ROUTES ****
                    {
                        path: "itinerary/chatbot",
                        element: <ItineraryChatbot />,
                        loader: async (args) => {
                            const suggestions = await listItems<ListInput<ListSuggestionInput>, ISuggestion, ListSuggestionInput>('suggestions/list-current-booking-suggestions', { pageIndex: 0, pageSize: 1000 });
                            const itineraries = await listItems<ListInput<ListSuggestionInput>, ISuggestion, ListSuggestionInput>('itineraries/list', { pageIndex: 0, pageSize: 1000 });
                            const bookings: any = await getItem('/bookings/is-current-booking', args);
                            return { suggestions: suggestions.response, itineraries: itineraries.response, bookings }
                        }
                    }
                ]
            }
        ]
    },
    {
        path: "/utilities",
        element: <UtilitiesLayout />,
        loader: async () => await getUserByRefreshToken<IUser>(),
        errorElement: <ErrorPage />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: "profile/complete/:id",
                        element: <CompleteProfile />,
                        errorElement: <ErrorPage />,
                        loader: async () => {
                            const professionsCategory = await listItems<ListInput<ListProfessionCategoryInput>, IProfessionCategory, ListProfessionCategoryInput>('/profession-categories/get-all', { pageIndex: 0, pageSize: 100 }, undefined, undefined, { professions: true });
                            const tagCategory = await listItems<ListInput<ListTagCategoryInput>, ITagCategory, ListTagCategoryInput>('/tag-categories/get-all', { pageIndex: 0, pageSize: 100 });
                            return { professionsCategory: professionsCategory.response, tagCategory: tagCategory.response }
                        },
                        action: async (args) => await genericPut<IChatRoom>('users/update/', '/app', args, false, true)
                    }
                ]
            }
        ]
    },
    {
        path: "/backoffice",
        element: <BackofficeLayout />,
        errorElement: <ErrorPage />,
        loader: async () => await getUserByRefreshToken<IUser>(),
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: ":targetYear",
                        element: <BackofficeHome />,
                        loader: async (args) => await getItem<IStructure>('/structures/get/bookings/info/', args, 'targetYear'),
                    },

                    // **** ADMINISTRATORS ROUTES ****
                    {
                        path: "administrators",
                        element: <Administrators />,
                        loader: async (args) => await getItem<IStructureUser[]>('/structure-users/list-structure-administrators/', args),
                    },
                    {
                        path: "administrators/manage/:id",
                        element: <AdministratorsManage />,
                        loader: async (args) => await getItem<IStructureUser>('/structure-users/get-by-id/', args),
                        action: async (args) => await genericPost<IBooking>('structure-users/remove-administrator', '/backoffice/administrators', args, false, false)
                    },
                    {
                        path: "administrators/create",
                        element: <AdministratorsManage />,
                        action: async (args) => await genericPost<IStructureUser>('structure-users/add-administrator', '/backoffice/administrators', args, false, false)
                    },
                    // **** ADMINISTRATORS ROUTES ****

                    // **** COLIVING ROUTES ****
                    {
                        path: "coliving/manage/:id",
                        element: <ColivingManage />,
                        loader: async (args) => await getItem<IStructure>('/structures/get-by-id/', args),
                        action: async (args) => await genericPut<IBooking>('structures/update/', '/backoffice/' + moment().format('YYYY'), args, false, true)
                    },
                    {
                        path: "coliving/create",
                        element: <ColivingManage />,
                        action: async (args) => await genericPost<IStructure>('structures/create', '/backoffice/' + moment().format('YYYY'), args, false, true)
                    },
                    // **** COLIVING ROUTES ****

                    // **** ALERT ROUTES ****
                    {
                        path: "alert",
                        element: <Alert />,
                        loader: async (args) => await getItem<IStructure>('structures/client-info', args),
                        action: async (args) => await genericPost<IMessage[]>('messages/send-alert', '/backoffice/' + moment().format('YYYY'), args, false, false)
                    },
                    // **** ALERT ROUTES ****

                    // **** BOOKINGS ROUTES ****
                    {
                        path: "bookings",
                        element: <Bookings />,
                        loader: async (args) => {
                            const cookies = document.cookie.split(';').map((c) => c.trim());
                            const cookie = cookies.find((c) => c.startsWith('structure='));
                            const structureId = cookie ? cookie.split('=')[1] : null;

                            return await listItems<ListInput<ListBookingInput>, IBooking, ListBookingInput>('/bookings/get-all', { pageIndex: 0, pageSize: 10 }, [{ id: 'created_at', desc: true }], [{ id: 'structure_id', value: structureId }], { user: true }, args)
                        }
                    },
                    {
                        path: "bookings/create",
                        element: <BookingManage />,
                        action: async (args) => await genericPost('bookings/create', '/backoffice/bookings', args)
                    },
                    {
                        path: "bookings/manage/:id",
                        element: <BookingManage />,
                        loader: async (args) => await getItem<IBooking>('/bookings/get-by-id/', args),
                        action: async (args) => await genericPut<IBooking>('bookings/update/', '/backoffice/bookings', args)
                    },
                    {
                        path: "bookings/manage/:id/delete",
                        action: async (args) => await genericDelete<IBooking>('bookings/delete/', '/backoffice/bookings', args)
                    },
                    // **** BOOKINGS ROUTES ****

                    // **** ACTIVITIES ROUTES ****
                    {
                        path: "activities",
                        element: <Activities />,
                        loader: async (args) => await listItems<ListInput<ListSuggestionInput>, ISuggestion, ListSuggestionInput>('/suggestions/get-all', { pageIndex: 0, pageSize: 20 }, undefined, undefined, {}, args)
                    },
                    {
                        path: "activities/create",
                        element: <ActivitiesManage />,
                        loader: async () => {
                            const tagCategories = await listItems<ListInput<ListTagCategoryInput>, ITagCategory, ListTagCategoryInput>('/tag-categories/get-all', { pageIndex: 0, pageSize: 100 });
                            return { tagCategory: tagCategories.response }
                        },
                        action: async (args) => await genericPost('/suggestions/create', '/backoffice/activities', args, false, true)
                    },
                    {
                        path: "activities/manage/:id",
                        element: <ActivitiesManage />,
                        loader: async (args) => {
                            const activity = await getItem('suggestions/get-by-id/', args)
                            const tagCategories = await listItems<ListInput<ListTagCategoryInput>, ITagCategory, ListTagCategoryInput>('/tag-categories/get-all', { pageIndex: 0, pageSize: 100 });
                            return { activity, tagCategory: tagCategories.response }
                        },
                        action: async (args) => await genericPut('suggestions/update/', '/backoffice/activities', args, false, true)
                    }
                    // **** END ACTIVITIES ROUTES ****

                ]
            }
        ]
    },
    {
        path: 'admin',
        element: <AdminLayout />,
        errorElement: <ErrorPage />,
        loader: async () => await getUserByRefreshToken<IUser>(),
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <AdminHome />,
                        loader: async (args) => await getItem('/users/admin/app/info', args),
                    },
                    {
                        path: "structures",
                        element: <Structures />,
                        loader: async () => await listItems<ListInput<ListStructureInput>, IStructure, ListStructureInput>('/structures/get-all', { pageIndex: 0, pageSize: 10 })
                    },
                    {
                        path: "structures/manage/:id",
                        element: <StructureManage />,
                        loader: async (args) => await getItem('/structures/get-by-id/', args),
                        action: async (args) => await genericPut('/structures/update/', '/admin/structures', args),
                    },
                    {
                        path: "structures/manage/:id/disable",
                        action: async (args) => await genericPut('/structures/disable/', '/admin/structures/manage/', args, true),
                    },
                    {
                        path: "users",
                        element: <Users />,
                        loader: async () => await listItems<ListInput<ListUserInput>, IUser, ListUserInput>('/users/get-all', { pageIndex: 0, pageSize: 10 })
                    },
                    {
                        path: "users/manage/:id",
                        element: <UserManage />,
                        loader: async (args) => await getItem('/users/get-by-id/', args),
                        action: async (args) => await genericPut('/users/update/', '/admin/users', args),
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element: <PageNotFound />
    }
]);
