import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./landing.css";
import iubendaLoader from '../../utils/iubenda';
import i18n from './../../i18n';

const Landing = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        iubendaLoader();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="flex-1 flex flex-col">
            <section id="hero" className="relative">

                <div className="relative">
                    <div className="absolute inset-0 bg-light_black opacity-75"></div>
                    <video
                        id="video-container"
                        className="max-h-screen object-cover w-full"
                        controls={false}
                        autoPlay={true}
                        loop
                        disablePictureInPicture={true}
                        muted={true}
                        playsInline
                    >
                        <source
                            id={isMobile ? "mobile-video" : "desktop-video"}
                            src={isMobile ? 'https://connivio-public.s3.eu-central-1.amazonaws.com/video/Connivio_home_mobile.mp4' : 'https://connivio-public.s3.eu-central-1.amazonaws.com/video/Connivio_home3.mp4'}
                            type="video/mp4"
                        />
                    </video>
                </div>

                <div className='absolute w-full flex flex-col items-center top-1/2 -translate-y-1/2 text-center'>
                    <p className='text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-bold text-white'>
                        {i18n.t('coexist')}, <br className="md:hidden" />
                        {i18n.t('communicate')}, <br className="md:hidden" />
                        {i18n.t('share')}
                    </p>
                </div>
                <a className="absolute bottom-5 m-auto left-0 right-0 w-1/2" href="#hero-description">
                    <p className='lg:text-md text-white px-4 py-1 text-center' >{i18n.t('learn_more')}</p>
                    <div className="scroll m-auto mb-4"></div>
                </a>

            </section>

            <div className="container m-auto">

                <section id="hero-description" className="py-24 m-auto px-4">
                    <p className="text-3xl lg:text-4xl font-bold lg:text-center text-light_black">{i18n.t('landing_info_connivio')} <br className="hidden lg:block" />
                        {i18n.t('landing_info_connivio_2')}
                    </p>
                    <p className="mt-6 text-xl lg:text-center" dangerouslySetInnerHTML={{__html:i18n.t('landing_info_connivio_3', {escapeValue:false})}}></p>
                </section>

                <div className="bg-light_black w-full" style={{ height: 1 }}></div>

                <section id="features" className="py-24 m-auto px-4">
                    <p className="text-3xl lg:text-4xl font-bold text-center text-light_black">{i18n.t('the_main_benefits')}</p>
                    <p className="text-center mt-4 text-xl">
                        {i18n.t('the_main_benefits_info')}
                    </p>

                    <div className="flex flex-col lg:flex-row gap-4 justify-around mt-20 items-center">
                        <div className="flex flex-col items-center justify-center mb-10 w-2/3 lg:w-1/6 lg:mb-0 ">
                            <img src="/images/f_networking.svg" alt="networking" />
                            <p className="text-2xl font-medium mt-4 text-light_black">{i18n.t('networking')}</p>
                            <p className="text-center">{i18n.t('networking_info')}</p>
                        </div>

                        <div className="flex flex-col items-center justify-center mb-10 w-2/3 lg:w-1/6 lg:mb-0">
                            <img src="/images/f_aidriven.svg" alt="ai driven" />
                            <p className="text-2xl font-medium mt-4 text-light_black">{i18n.t('ai_driven')}</p>
                            <p className="text-center">{i18n.t('ai_driven_info')}</p>
                        </div>

                        <div className="flex flex-col items-center justify-center w-2/3 lg:w-1/6 lg:mb-0 ">
                            <img src="/images/f_colivings.svg" alt="ricerca coliving" />
                            <p className="text-2xl font-medium mt-4 text-light_black">{i18n.t('search_colivings')}</p>
                            <p className="text-center">{i18n.t('search_colivings_info')}</p>
                        </div>

                    </div>
                </section>

                <div className="bg-light_black w-full" style={{ height: 1 }}></div>

                <section id="mission" className="py-24 m-auto px-4">
                    <p className="text-3xl lg:text-4xl font-bold lg:text-center text-light_black">{i18n.t('our_mission')}</p>
                    <p className="lg:text-center mt-6 text-xl" dangerouslySetInnerHTML={{__html:i18n.t('our_mission_info', {escapeValue:false})}}></p>
                </section>
            </div>

            <section id="register-cta" className="px-4 mb-24 m-auto bg-black w-full py-32 flex flex-col items-center gap-10">
                <p className="text-5xl font-bold text-center text-white" dangerouslySetInnerHTML={{__html:i18n.t('join_connivio', {escapeValue:false})}}></p>
                <Link to={'register/select'} className='font-medium lg:text-xl bg-orange text-light_black rounded-full px-8 py-2 w-fit'>{i18n.t('sign_up_now')}</Link>
            </section>

            <section id="info" className="px-4 py-24 m-auto container flex flex-col items-center">
                <p className="text-3xl lg:text-4xl font-bold text-center text-light_black">{i18n.t('do_you_still_have_questions')}</p>
                <p className="text-center text-xl mb-8 mt-4">
                    {i18n.t('do_you_still_have_questions_info')}
                </p>
                <a href="mailto:info@navitascoworking.it?subject=Richiesta%20Informazioni" className='font-medium lg:text-xl bg-orange text-light_black rounded-full px-8 py-2 w-fit'>{i18n.t('contact_us')}</a>
            </section>

            <div className="h-full w-full p-8 px-4 mb-10">
                <Outlet />
            </div>
        </div>
    )
}

export default Landing