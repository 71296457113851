import i18n from './../../i18n';

export enum USER_ROLES {
    ADMIN = "ADMIN",
    BUSINESS = "BUSINESS",
    USER = "USER"
}

export const userRolesDbMapping = {
    [USER_ROLES.ADMIN]: 'Admin',
    [USER_ROLES.BUSINESS]: 'Business',
    [USER_ROLES.USER]: i18n.t('user')
};

export const mapUserRoles = (value: string) => {
    if (Object.keys(userRolesDbMapping).includes(value)) {
        return userRolesDbMapping[value as keyof typeof userRolesDbMapping];
    }
    return value;
};

export const userRolesOptions: { label: string; value: string }[] = Object.keys(USER_ROLES).map(key => ({
    label: mapUserRoles(String(key)),
    value: key,
}));