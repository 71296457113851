import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { HttpMethod, apiService } from "../apiService";
import { ActionFunctionArgs, LoaderFunctionArgs, redirect } from "react-router-dom";
import { JwtPayload, jwtDecode } from "jwt-decode";

export async function listItems<T, K, V>(url: string, pagination?: PaginationState, sorting?: SortingState, columnFilters?: ColumnFiltersState, includeRelations?: V, args?: LoaderFunctionArgs) {
    try {
        const filters: Record<string, any> = {};
        const sort: Record<string, 'ASC' | 'DESC'> = {};
        const params: Record<string, string> = {};

        if (!!args) {
            const url = new URL(args.request.url);

            url.searchParams.forEach((value, key) => {
                params[key] = value;
                filters[key] = value;
            });
        }

        if (columnFilters && !!columnFilters.length) {
            columnFilters.forEach(({ id, value }) => {
                filters[id] = value;
            });
        }

        if (sorting && !!sorting.length) {
            sorting.forEach(({ id, desc }) => {
                sort[id] = desc ? 'DESC' : 'ASC';
            });
        }
        const response = await apiService<{ data: K[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number }>(
            HttpMethod.POST,
            url,
            { ...pagination, filters, sort, includeRelations } as T
        );

        return { response, params };
    } catch (error: any) {
        throw error
    }
};

export async function getItem<T>(url: string, { params }: LoaderFunctionArgs, paramKey: string = 'id') {
    try {
        const response = await apiService<T & { errorCode: string }>(HttpMethod.GET, url + (!!params[paramKey] ? params[paramKey] : ''));

        if (!!response.errorCode) {
            return response;
        }

        return response;
    } catch (error: any) {
        throw error
    };
};

// Impostare isMultipart a true solo se si vuole mandare i file direttamente come formData (ad esempio se si ha uno o piu file tra i valori da mandare al server), altrimenti lasciare a false
export async function genericPut<T>(url: string, redirectUrl: string, { params, request }: ActionFunctionArgs, redirectById: boolean = false, isMultipart: boolean = false) {
    try {
        const formData = await request.formData();
        let data = Object.fromEntries(formData) as any;

        const response = await apiService<T & { id: string, errorCode: string }>(HttpMethod.PUT, url + params.id, isMultipart ? formData : data, null, { multipart: isMultipart });

        if (!!response.errorCode) {
            return response;
        }

        if (redirectById) {
            return redirect(redirectUrl + response.id);
        } else {
            return redirect(redirectUrl);
        }
    } catch (error: any) {
        throw error
    };

};

export async function genericPost<T>(url: string, redirectUrl: string, { request }: ActionFunctionArgs, redirectById: boolean = false, isMultipart: boolean = false) {
    try {
        const formData = await request.formData();
        let data = Object.fromEntries(formData) as any;

        const response = await apiService<T & { id: string, errorCode: string }>(HttpMethod.POST, url, isMultipart ? formData : data, null, { multipart: isMultipart })

        if (!!response.errorCode) {
            return response;
        }

        if (redirectById) {
            return redirect(redirectUrl + response.id);
        } else {
            return redirect(redirectUrl);
        }
    } catch (error: any) {
        throw error
    };
};

interface CustomJwtPayload extends JwtPayload {
    userId: string;
}

export async function getUserByRefreshToken<T>() {
    const cookies = document.cookie.split(';').map((c) => c.trim());
    const cookie = cookies.find((c) => c.startsWith('refreshToken='));
    const data = cookie ? cookie.split('=')[1] : null;

    if (!data) {
        return null;
    }

    try {
        const payload = jwtDecode(data) as CustomJwtPayload;

        const response = await apiService(HttpMethod.GET, '/users/get-by-id/' + payload.userId);

        return response as T;
    } catch (error: any) {
        throw error;
    };
};

export async function genericDelete<T>(url: string, redirectUrl: string, { params }: ActionFunctionArgs) {
    try {
        const response = await apiService<T & { id: string, errorCode: string }>(HttpMethod.DELETE, url + params.id);

        if (!!response.errorCode) {
            return response;
        }

        return redirect(redirectUrl);
    } catch (error: any) {
        throw error
    };

};
