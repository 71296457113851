import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, useActionData, useLoaderData, useNavigate, useNavigation } from 'react-router-dom';
import { IUser, fieldTypesUser } from '../../utils/interfaces/db_models/user.interface';
import SelectForm from '../../components/SelectForm';
import moment from 'moment';
import { USER_ROLES, userRolesOptions } from '../../utils/enums/user_roles.enum';
import { ArrowLeft2 } from '../../utils/icons/ArrowIcon';
import useIsOpen from '../../hooks/useIsOpen';
import { getErrorMessage } from '../../utils/manageError';
import i18n from './../../i18n';

const UserManage = () => {
    const user: IUser = useLoaderData() as IUser;
    const response = useActionData() as any;
    const navigation = useNavigation();
    const navigate = useNavigate();
    const { isOpen, setIsOpen } = useIsOpen(false);

    const formik = useFormik({
        initialValues: {
            role: { label: i18n.t('role'), value: user.role },
            isVerified: { label: i18n.t('verified'), value: user.isVerified },
            canCreateStructure: { label: i18n.t('enable_accommodation_creation'), value: user.canCreateStructure },
            name: { label: i18n.t('name'), value: user.name },
            email: { label: i18n.t('email'), value: user.email },
            city: { label: i18n.t('city'), value: user.city },
            province: { label: i18n.t('province'), value: user.province },
            biography: { label: i18n.t('biography'), value: user.biography },
            city_business: { label: i18n.t('business_city'), value: user.city_business || "" },
            cap_business: { label: i18n.t('business_zip_code'), value: user.cap_business || "" },
            province_business: { label: i18n.t('business_province'), value: user.province_business || "" },
            address_business: { label: i18n.t('business_address'), value: user.address_business || "" },
            vat_business: { label: i18n.t('vat_number'), value: user.vat_business || "" },
            company_name_business: { label: i18n.t('business_name'), value: user.company_name_business || "" },
            unique_code_business: { label: i18n.t('unique_code'), value: user.unique_code_business || "" }
        },
        validationSchema: Yup.object({
            role: Yup.object({ value: Yup.string().required(i18n.t('required_field')).oneOf(Object.values(USER_ROLES), i18n.t('invalid_request_status')) }),
            isVerified: Yup.object({ value: Yup.boolean().required(i18n.t('required_field')) }),
            name: Yup.object({ value: Yup.string().max(25, i18n.t('must_contain_a_maximum', {number: '25'})).required(i18n.t('required_field')) }),
            email: Yup.object({ value: Yup.string().email(i18n.t('invalid_email_address')).required(i18n.t('required_field')).matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, i18n.t('invalid_email_address')) }),
            city: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            province: Yup.object({ value: Yup.string().required(i18n.t('required_field')) })
        }),
        onSubmit: () => { }
    });

    return (
        <div className='flex flex-1 flex-col px-10 sm:px-14 select-none'>
            <div className='grid grid-cols-6 items-center py-6'>
                <div className='cursor-pointer w-fit' onClick={() => navigate(-1)}>
                    <ArrowLeft2 />
                </div>

                {<div className='flex col-span-4 flex-col items-center justify-center'>
                    <div className='font-medium text-2xl'>{user.name}</div>
                    <p>{i18n.t('created_on')} {moment(user.created_at).format('YYYY-MM-DD hh:mm')}</p>
                    <p>{i18n.t('last_modified')} {moment(user.updated_at).format('YYYY-MM-DD hh:mm')}</p>
                </div>}
            </div>

            <div className="border-t shadow -mx-20" />

            <Form method='POST' className='flex flex-col flex-1 gap-6 py-6 justify-between'>
                <div className='flex flex-1 flex-col gap-6'>
                    {!!response?.errorCode && <p className="text-red-500 text-sm mt-1 text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>}

                    {Object.entries(formik.values).map(([key, value]) => {
                        const errorValue = formik.errors[key as keyof typeof formik.values];
                        const fieldType = fieldTypesUser[key as keyof typeof fieldTypesUser];

                        return <div key={key} className='flex flex-col'>
                            <label className='text-sm font-medium'>{value.label}</label>
                            {(key === 'role')
                                ? <SelectForm name={key} options={userRolesOptions} value={value.value} setValue={(e) => formik.setFieldValue(key, { ...value, value: e })} />
                                : (key === 'isVerified')
                                    ? <SelectForm name={key} options={[{ label: i18n.t('verified'), value: true }, { label: i18n.t('not_verified'), value: false }]} value={value.value} setValue={(e) => formik.setFieldValue(key, { ...value, value: e === 'true' ? true : false })} />
                                    : (key === 'canCreateStructure')
                                        ? <SelectForm name={key} options={[{ label: i18n.t('enabled'), value: true }, { label: i18n.t('not_enabled'), value: false }]} value={value.value} setValue={(e) => formik.setFieldValue(key, { ...value, value: e === 'true' ? true : false })} />
                                        : <input
                                            name={key}
                                            onChange={(e) => formik.setFieldValue(key, { ...value, value: fieldType === 'number' ? Number(e.target.value) : e.target.value })}
                                            value={value.value as string}
                                            type={fieldType}
                                            className='border-2 border-gray-500 p-2 rounded-md focus:border-teal-500 focus:ring-teal-500'
                                        />
                            }

                            {!!errorValue && isOpen
                                ? <div className='text-red-500 font-medium text-sm'>
                                    {errorValue.value}
                                </div>
                                : null}
                        </div>
                    })}

                    {!!response?.errorCode && <p className="text-red-500 text-sm mt-1 text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>}
                </div>

                <div className='flex flex-1 gap-4 justify-end'>
                    {/* {!!structure && <button
                        type='button'
                        disabled={navigation.state !== 'idle'}
                        onClick={() => setIsOpenDelete(true)}
                        className='px-4 py-1 rounded-full text-light_black border border-light_black h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        Elimina
                    </button>} */}
                    <button
                        type={Object.entries(formik.errors).length > 0 ? 'button' : 'submit'}
                        onClick={() => setIsOpen(true)}
                        disabled={navigation.state !== 'idle' || !formik.dirty}
                        className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        {i18n.t('save')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default UserManage