export const ConnivioLogo = () => {
    return (
        <svg width="107" height="26" viewBox="0 0 107 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1357 12.2914C20.1357 8.50406 22.9663 5.69434 26.7918 5.69434C29.4251 5.69434 31.6843 6.96427 32.7032 9.43314L30.5667 10.5338C30.1204 9.16447 28.7045 7.94306 26.7918 7.94306C24.4075 7.94306 22.6692 9.75151 22.6692 12.2926C22.6692 14.9319 24.4821 16.6421 26.7918 16.6421C28.5301 16.6421 30.1204 15.7379 30.7616 13.9057L32.8728 14.8833C31.8792 17.6197 29.4949 18.8908 26.787 18.8908C22.917 18.8896 20.1357 16.0799 20.1357 12.2914Z" fill="#1A1C20" />
            <path d="M40.0588 5.69434C36.1599 5.69434 33.3281 8.45553 33.3281 12.2914C33.3281 16.1521 36.1599 18.8896 40.0588 18.8896C43.8842 18.8896 46.7954 16.1521 46.7954 12.2914C46.7858 8.45553 43.8794 5.69434 40.0588 5.69434ZM40.0588 16.6657C37.6528 16.6657 35.8616 14.9058 35.8616 12.2867C35.8616 9.77045 37.6504 7.90755 40.0588 7.90755C42.4671 7.90755 44.2571 9.76571 44.2571 12.2867C44.2523 14.9058 42.4647 16.6657 40.0588 16.6657Z" fill="#1A1C20" />
            <path d="M48.0439 6.19497H50.5245V10.019C50.5245 7.74659 52.1389 5.69434 54.7216 5.69434C57.6773 5.69434 59.1931 7.8685 59.1931 10.264V18.5712H56.7126V10.9717C56.7126 9.23667 55.3965 8.01289 53.7316 8.01289C51.8189 8.01526 50.5245 9.38343 50.5245 11.244V18.5748H48.0439V6.19497Z" fill="#1A1C20" />
            <path d="M60.4551 6.19497H62.9392V10.019C62.9392 7.74659 64.5536 5.69434 67.1364 5.69434C70.0921 5.69434 71.6066 7.8685 71.6066 10.264V18.5712H69.1237V10.9717C69.1237 9.23667 67.8004 8.01289 66.1427 8.01289C64.23 8.01289 62.9392 9.38106 62.9392 11.238V18.5689H60.4551V6.19497Z" fill="#1A1C20" />
            <path d="M75.3953 6.19434H72.9111V18.5706H75.3953V6.19434Z" fill="#1A1C20" />
            <path d="M76.0732 6.19434H78.7066L82.5633 18.2049L86.2805 6.19434H88.9631L84.8477 18.5706H80.179L76.0732 6.19434Z" fill="#1A1C20" />
            <path d="M92.1257 6.19434H89.6416V18.5706H92.1257V6.19434Z" fill="#1A1C20" />
            <path d="M100.158 5.69434C96.2587 5.69434 93.4209 8.45553 93.4209 12.2914C93.4209 16.1521 96.2527 18.8896 100.158 18.8896C103.983 18.8896 106.894 16.1521 106.894 12.2914C106.889 8.45553 103.983 5.69434 100.158 5.69434ZM100.158 16.6657C97.7516 16.6657 95.9604 14.9058 95.9604 12.2867C95.9604 9.77045 97.7492 7.90755 100.158 7.90755C102.566 7.90755 104.356 9.76571 104.356 12.2867C104.356 14.9058 102.567 16.6657 100.158 16.6657Z" fill="#1A1C20" />
            <path d="M7.55106 0C5.54839 0 3.62775 0.782704 2.21165 2.17593C0.795555 3.56915 0 5.45876 0 7.42908L0 26L2.51662 24.762L12.5843 19.8101L15.1009 18.5721V7.42908C15.1011 6.45353 14.9059 5.48751 14.5266 4.58618C14.1472 3.68485 13.5911 2.86587 12.89 2.176C12.1889 1.48613 11.3566 0.938889 10.4405 0.565528C9.52447 0.192167 8.54262 -1.23837e-08 7.55106 0ZM7.55106 14.857L2.51662 12.381V7.42908C2.5063 6.77226 2.62888 6.11999 2.87722 5.51022C3.12556 4.90045 3.4947 4.34538 3.96316 3.87731C4.43162 3.40924 4.99003 3.03752 5.6059 2.78379C6.22176 2.53007 6.88277 2.3994 7.55045 2.3994C8.21813 2.3994 8.87915 2.53007 9.49501 2.78379C10.1109 3.03752 10.6693 3.40924 11.1377 3.87731C11.6062 4.34538 11.9754 4.90045 12.2237 5.51022C12.472 6.11999 12.5946 6.77226 12.5843 7.42908V17.3329L7.55106 14.857Z" fill="#1A1C20" />
        </svg>
    )
}