export const ItineraryIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            fill="none"
        >
            <g fill="#000" clipPath="url(#a)">
                <path d="M20 6a4 4 0 1 1 0 8H10a4 4 0 1 1 0-8h10Zm0-1H10a5 5 0 1 0 0 10h10a5 5 0 1 0 0-10ZM15 18a7 7 0 0 1 6.93 6H8.07A7 7 0 0 1 15 18Zm0-1a8 8 0 0 0-8 8h16a8 8 0 0 0-8-8Z" />
                <path d="M11 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM19 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h30v30H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const ItineraryIconActive = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            fill="none"
        >
            <g clipPath="url(#a)">
                <path
                    fill="#D9981D"
                    d="M20 5H10a5 5 0 0 0 0 10h10a5 5 0 0 0 0-10ZM15 17a8 8 0 0 0-8 8h16a8 8 0 0 0-8-8Z"
                />
                <path
                    fill="#fff"
                    d="M11 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM19 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h30v30H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}