export const CrossIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            fill="none"
        >
            <circle cx={16} cy={16} r={16} fill="#000" />
            <mask
                id="a"
                width={24}
                height={24}
                x={4}
                y={4}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: "alpha",
                }}
            >
                <path fill="#D9D9D9" d="M4 4h24v24H4z" />
            </mask>
            <g mask="url(#a)">
                <path
                    fill="#fff"
                    d="M10.4 23 9 21.6l5.6-5.6L9 10.4 10.4 9l5.6 5.6L21.6 9l1.4 1.4-5.6 5.6 5.6 5.6-1.4 1.4-5.6-5.6-5.6 5.6Z"
                />
            </g>
        </svg>
    )
};