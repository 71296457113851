export const retrieveConfig = () => {
	console.log(process.env.REACT_APP_ENV);
    switch (process.env.REACT_APP_ENV) {
        case 'DEVELOPMENT':
            return configDevelopment;

        case 'STAGING':
            return configStaging;

        case 'PRODUCTION':
            return configProduction;

        default:
            return configDevelopment;
    }
};

export const configDevelopment = {
    apiBaseUrl: process.env.REACT_APP_API_LOCAL,
    socketEndpoint: process.env.REACT_APP_SOCKET_LOCAL,
    debugMode: true,
};

export const configStaging = {
    apiBaseUrl: process.env.REACT_APP_API_STAGING,
    socketEndpoint: process.env.REACT_APP_SOCKET_STAGING,
    debugMode: true,
};

export const configProduction = {
    apiBaseUrl: process.env.REACT_APP_API_PRODUCTION,
    socketEndpoint: process.env.REACT_APP_SOCKET_PRODUCTION,
    debugMode: false,
};
