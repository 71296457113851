import BookingCard from '../../components/BookingCard'
import { useLoaderData } from 'react-router-dom';
import { IBooking } from '../../utils/interfaces/db_models/bookings.interface';
import i18n from './../../i18n';

const BookingsList = () => {
    const { response: { pastBookings, currentBookings, futureBookings } } = useLoaderData() as { response: { pastBookings: IBooking[], currentBookings: IBooking[], futureBookings: IBooking[] } };

    return (
        <div className="h-full w-full flex flex-col gap-10">
            {!!pastBookings.length || !!futureBookings.length || !!currentBookings.length
                ? <div className="w-full flex flex-col gap-10 p-8 pb-0">
                    {!!currentBookings.length && <>
                        <p className='font-bold text-3xl'>{i18n.t('your_bookings')}</p>
                        {currentBookings.map((booking, index) => (
                            <BookingCard key={index} booking={booking} redirectUrl='' />
                        ))}
                    </>
                    }

                    {!!futureBookings.length && <>
                        <p className='font-bold text-3xl'>{i18n.t('future')}</p>
                        {futureBookings.map((booking, index) => (
                            <BookingCard key={index} booking={booking} redirectUrl='' />
                        ))}
                    </>
                    }

                    {!!pastBookings.length && <>
                        <p className='font-bold text-3xl'>{i18n.t('past')}</p>
                        {pastBookings.map((booking, index) => (
                            <BookingCard key={index} booking={booking} redirectUrl='' />
                        ))}
                    </>
                    }

                </div>
                : <div className='h-64 w-full bg-gray-200 flex flex-col justify-center items-center text-center gap-6'>
                    <p className='text-5xl font-bold'>{i18n.t('no_bookings')}.</p>
                    <p className='font-medium text-sm'>{i18n.t('you_have_no_active_bookings')}.</p>
                </div>
            }
        </div>
    )
}

export default BookingsList