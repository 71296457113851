import i18n from './../../i18n';

export enum ITALIAN_REGIONS {
    ABRUZZO = "ABRUZZO",
    BASILICATA = "BASILICATA",
    CALABRIA = "CALABRIA",
    CAMPANIA = "CAMPANIA",
    EMILIAROMAGNA = "EMILIAROMAGNA",
    FRIULIVENEZIAGIULIA = "FRIULIVENEZIAGIULIA",
    LAZIO = "LAZIO",
    LIGURIA = "LIGURIA",
    LOMBARDIA = "LOMBARDIA",
    MARCHE = "MARCHE",
    MOLISE = "MOLISE",
    PIEMONTE = "PIEMONTE",
    PUGLIA = "PUGLIA",
    SARDEGNA = "SARDEGNA",
    SICILIA = "SICILIA",
    TOSCANA = "TOSCANA",
    TRENTINOALTOADIGE = "TRENTINOALTOADIGE",
    UMBRIA = "UMBRIA",
    VALLEAOSTA = "VALLEAOSTA",
    VENETO = "VENETO"
};

export const italianRegionDbMapping = {
    [ITALIAN_REGIONS.ABRUZZO]: 'Abruzzo',
    [ITALIAN_REGIONS.BASILICATA]: 'Basilicata',
    [ITALIAN_REGIONS.CALABRIA]: 'Calabria',
    [ITALIAN_REGIONS.CAMPANIA]: 'Campania',
    [ITALIAN_REGIONS.EMILIAROMAGNA]: 'Emilia-Romagna',
    [ITALIAN_REGIONS.FRIULIVENEZIAGIULIA]: 'Friuli Venezia Giulia',
    [ITALIAN_REGIONS.LAZIO]: 'Lazio',
    [ITALIAN_REGIONS.LIGURIA]: 'Liguria',
    [ITALIAN_REGIONS.LOMBARDIA]: 'Lombardia',
    [ITALIAN_REGIONS.MARCHE]: 'Marche',
    [ITALIAN_REGIONS.MOLISE]: 'Molise',
    [ITALIAN_REGIONS.PIEMONTE]: 'Piemonte',
    [ITALIAN_REGIONS.PUGLIA]: 'Puglia',
    [ITALIAN_REGIONS.SARDEGNA]: 'Sardegna',
    [ITALIAN_REGIONS.SICILIA]: 'Sicilia',
    [ITALIAN_REGIONS.TOSCANA]: 'Toscana',
    [ITALIAN_REGIONS.TRENTINOALTOADIGE]: 'Trentino-Alto Adige',
    [ITALIAN_REGIONS.UMBRIA]: 'Umbria',
    [ITALIAN_REGIONS.VALLEAOSTA]: 'Valle d\'Aosta',
    [ITALIAN_REGIONS.VENETO]: 'Veneto'
};

export const mapItalianRegion = (value: string) => {
    if (Object.keys(italianRegionDbMapping).includes(value)) {
        return italianRegionDbMapping[value as keyof typeof italianRegionDbMapping];
    }
    return value;
};

export const italianRegionOptions: { label: string; value: string }[] = Object.keys(ITALIAN_REGIONS).map(key => ({
    label: mapItalianRegion(String(key)),
    value: key,
}));
