import { createSlice, Slice } from "@reduxjs/toolkit";
import { authState } from "./authState";

export const AuthSlice: Slice<any> = createSlice({
    name: "auth",
    initialState: authState,
    reducers: {
        replaceUser(state, action) {
            state.user = action.payload
        }
    }
});

export const { replaceUser } = AuthSlice.actions;
export default AuthSlice.reducer;