import { IMessage } from "./interfaces/db_models/message.interface"
import { NavigateFunction, useNavigate } from 'react-router-dom';
import i18n from './../i18n';

export default function checkPageStatus(message: IMessage, navigate: NavigateFunction, removeCookie?: (name: string, options?: any) => void) {
    if (!("Notification" in window)) {
        alert(i18n.t('this_browser_does_not_support_the_connivio_notification_system'))
    }
    else if (Notification.permission === "granted") {
        sendNotification(message, navigate, removeCookie)
    }
    else if (Notification.permission !== "denied") {
        Notification.requestPermission((permission) => {
            if (permission === "granted") {
                sendNotification(message, navigate, removeCookie)
            }
        })
    }
};


function sendNotification(message: IMessage, navigate: NavigateFunction, removeCookie?: (name: string, options?: any) => void) {
    if (document.hidden) {

        const notification = new Notification(i18n.t('new_message_from_connivio'), {
            icon: "./logo512.png",
            body: `@${message.author.name}: ${message.content}`
        });

        notification.onclick = function () {
            navigate(`/app/chatrooms/${message.chat_room_id}`);

            if (removeCookie) {
                removeCookie('structure');
                navigate(`/app/chatrooms/${message.chat_room_id}`);
            }

            window.focus()

            notification.close();
        };

        setTimeout(() => {
            notification.close();
        }, 5000);

    }
};