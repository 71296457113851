import { useLoaderData, useParams, useNavigate, useNavigation, Link } from "react-router-dom";
import { IStructure } from "../../utils/interfaces/db_models/structure.interface";
import { IBooking } from "../../utils/interfaces/db_models/bookings.interface";
import useIsOpen from "../../hooks/useIsOpen";
import { ArrowLeft, ArrowRight } from "../../utils/icons/ArrowIcon";
import { useAppSelector } from "../../redux/store";
import { IUser } from "../../utils/interfaces/db_models/user.interface";
import i18n from './../../i18n';

const BackofficeHome = () => {
    const response = useLoaderData() as {
        structure: IStructure,
        onGoingBookings: IBooking[],
        acceptedBookings: IBooking[],
        completedBookings: IBooking[],
        quarterStats: { name: string; value: number; percentage: number; }[],
        onGoingProfessionStats: { [profession: string]: { count: number; percentage: number; } },
        totalProfessionStats: { [profession: string]: { count: number; percentage: number; } }
    };
    const params = useParams();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const { isOpen, setIsOpen } = useIsOpen(false);
    const { user } = useAppSelector(store => store.auth) as { user: IUser };

    const updateTargetYear = (targetYear: number) => {
        const newParams = { ...params, targetYear };
        navigate('/backoffice/' + newParams.targetYear);
    };

    const currentStructureUser = user?.structureUsers?.find(structureUser => structureUser.structure_id === response.structure.id);

    return (
        <div className='flex flex-col gap-10 px-10 sm:px-14 py-6 w-full select-none'>

            <div className='flex justify-between items-center -mb-4'>
                <p className="font-bold text-5xl">{response.structure.name}</p>
                {!!currentStructureUser?.admin &&
                    <Link to={'/backoffice/administrators'} className='w-fit px-4 py-2 mt-3 text-white bg-light_black font-medium rounded-full'>
                        {i18n.t('administrators')}
                    </Link>}
            </div>

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-6">
                <p className="font-semibold text-2xl">{i18n.t('bookings')}</p>

                <div className="flex justify-between">
                    <div className="flex flex-col items-center">
                        <p className="font-medium text-lg">{i18n.t('colivers')}</p>
                        <p className="text-5xl text-orange">{response.onGoingBookings.length.toString().padStart(2, '0')}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="font-medium text-lg">{i18n.t('incoming')}</p>
                        <p className="text-5xl">{response.acceptedBookings.length.toString().padStart(2, '0')}</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="font-medium text-lg">{i18n.t('past')}</p>
                        <p className="text-5xl">{response.completedBookings.length.toString().padStart(2, '0')}</p>
                    </div>
                </div>
            </div>

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <p className="font-semibold text-2xl">{i18n.t('colivers')}</p>
                    <div className="flex gap-2">
                        <button onClick={() => setIsOpen(false)} className={`rounded-full px-4 py-1 font-medium ${!isOpen ? 'border-orange border bg-orange' : 'border-light_black border'}`}>{i18n.t('current')}</button>
                        <button onClick={() => setIsOpen(true)} className={`rounded-full px-4 py-1 font-medium ${isOpen ? 'border-orange border bg-orange' : 'border-light_black border'}`}>{i18n.t('totals')}</button>
                    </div>
                </div>

                {Object.entries(isOpen ? response.onGoingProfessionStats : response.totalProfessionStats).length > 0
                    ? <div className="flex flex-col gap-4">
                        {Object.entries(isOpen ? response.onGoingProfessionStats : response.totalProfessionStats)
                            .sort(([_, value], [__, value2]) => value2.percentage - value.percentage)
                            .map(([key, value], index) => (
                                <div key={index} className="flex flex-col gap-4 first:text-orange">
                                    <div className="flex justify-between items-center">
                                        <p className="font-medium">{key}</p>
                                        <p className="font-medium">{value?.percentage?.toFixed(2) || 0} %</p>
                                    </div>
                                    <div className="border-t border-gray-300" />
                                </div>
                            ))}
                    </div>
                    : <p className="font-medium text-gray-500 text-center">{i18n.t('no_colivers')}</p>
                }

            </div>

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <p className="font-semibold text-2xl">{i18n.t('quarterly_trend')}</p>
                    <div className="flex gap-6 items-center">
                        <button disabled={navigation.state === 'loading' || params.targetYear === '2024'} className="disabled:opacity-50" onClick={() => updateTargetYear(Number(params.targetYear) - 1)}>
                            <ArrowLeft />
                        </button>
                        <p className="font-semibold text-2xl">{params.targetYear}</p>
                        <button disabled={navigation.state === 'loading'} className="disabled:opacity-50" onClick={() => updateTargetYear(Number(params.targetYear) + 1)}>
                            <ArrowRight />
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-3">
                        <p className="text-sm font-semibold">{i18n.t('quarter')}</p>
                        <p className="text-sm font-semibold text-center">{i18n.t('bookings')}</p>
                        <p className="text-sm font-semibold text-right">{i18n.t('saturation')}</p>
                    </div>

                    <div className="border-t border-light_black" />

                    {response?.quarterStats?.map((value, index, array) => {
                        const maxPercentage = Math.max(...array.map(item => item.percentage));
                        const isMaxPercentage = value.percentage === maxPercentage;

                        return (
                            <div key={index} className="flex flex-col gap-4">
                                <div className="grid grid-cols-3 font-medium">
                                    <p className={isMaxPercentage ? "text-orange" : ""}>{value.name}</p>
                                    <p className={isMaxPercentage ? "text-orange text-center" : "text-center"}>{value.value}</p>
                                    <p className={isMaxPercentage ? "text-orange text-right" : "text-right"}>{value?.percentage?.toFixed(2) || 0} %</p>
                                </div>
                                <div className="border-t border-gray-300" />
                            </div>
                        );
                    })}
                </div>
            </div>

        </div>
    )
}

export default BackofficeHome