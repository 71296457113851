import React, { ReactNode, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { setLanguage } from './../redux/languages/languagesSlice';
import Select, { SingleValue } from 'react-select';
import i18n from './../i18n';

const LanguagesMenu: React.FC = () => {

    const currentLanguage = useAppSelector((state) => state.language.language);
    const dispatch = useAppDispatch();
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

    useEffect(() => {
        if(currentLanguage){
            setSelectedLanguage(currentLanguage);
        }
    }, []);

    const handleLanguageChange = (newValue: SingleValue<{ value: string; label: JSX.Element }>) => {
        if (newValue) {
            const language = newValue.value; // Ottieni il valore selezionato
            dispatch(setLanguage(language)); // Aggiorna il Redux store

            const date = new Date();
            date.setTime(date.getTime() + (3 * 60 * 60 * 1000));
            document.cookie = `connivio_language=${language}; path=/; expires=${date.toUTCString()}; SameSite=Strict`;
        }
    };

    const languageOptions = [
        {
          value: 'en',
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/gb.png`}
                alt={i18n.t('english')}
                style={{ marginRight: '10px' }}
              />
              {i18n.t('english')}
            </div>
          ),
        },
        {
          value: 'it',
          label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={`${process.env.PUBLIC_URL}/images/it.png`}
                alt={i18n.t('italian')}
                style={{ marginRight: '10px' }}
              />
              {i18n.t('italian')}
            </div>
          ),
        },
      ];

    return (
        <Select
            value={languageOptions.find(option => option.value === selectedLanguage)}
            options={languageOptions}
            onChange={handleLanguageChange}
            id="language-select"
        />
    );
};

export default LanguagesMenu;
