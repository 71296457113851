import { useEffect, useState } from 'react';

function useScreenSize() {
    const [screenSize, setScreenSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'>(getScreenSize());

    let resizeTimer: NodeJS.Timeout;

    function getScreenSize() {
        const screenWidth = window.innerWidth;

        if (screenWidth < 640) {
            return 'xs';
        } else if (screenWidth < 768) {
            return 'sm';
        } else if (screenWidth < 1024) {
            return 'md';
        } else if (screenWidth < 1280) {
            return 'lg';
        } else if (screenWidth < 1536) {
            return 'xl';
        } else {
            return '2xl';
        }
    }

    function handleResize() {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
            const newSize = getScreenSize();
            if (newSize !== screenSize) {
                setScreenSize(newSize);
            }
        }, 200);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenSize]);

    return `${screenSize}`;
}

export default useScreenSize;
