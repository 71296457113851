import { Outlet, useLoaderData } from "react-router-dom"
import { ArrowDown, ArrowUp } from "../../utils/icons/ArrowIcon";
import { ColiverAvatar } from "../../utils/icons/ColiverAvatar";
import { IStructure } from "../../utils/interfaces/db_models/structure.interface";
import useScreenSize from "../../hooks/useScreenSize";
import useIsOpen from "../../hooks/useIsOpen";
import { tagCategoryAffinityPercentage, workAffinityPercentage } from "../../utils/affinityPercentuage";
import { useAppSelector } from "../../redux/store";
import { useEffect, useState } from "react";
import { StructureCoverImageIconXS } from "../../utils/icons/StructureIcon";
import { HttpMethod, apiService } from "../../api/apiService";
import { ISuggestion } from "../../utils/interfaces/db_models/suggestion.interface";
import { IUser } from "../../utils/interfaces/db_models/user.interface";
import { TagCategoryAffinityIcon, TagCategoryAffinityIconXL, WorkAffinityIcon, WorkAffinityIconXL } from "../../utils/icons/AffinityIcon";
import Modal from "../../components/Modal";
import i18n from './../../i18n';

const Coliving = () => {
    const { user } = useAppSelector(store => store.auth) as { user: IUser };
    const structure = useLoaderData() as IStructure;
    const screenSize = useScreenSize();
    const { isOpen, setIsOpen } = useIsOpen();
    const { isOpen: accordionOpen, setIsOpen: setAccordionOpen, ref: accordionRef } = useIsOpen();

    const [affinityCount, setAffinityCount] = useState<number>(0);
    const [isUserColiver, setIsUserColiver] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            const isBooked = structure.bookings.findIndex(booking => user?.id === booking.user_id)
            setIsUserColiver(isBooked !== -1)
        }
    }, [user]);

    useEffect(() => {
        if (user && structure) {
            getColiversAffinityCount();
        }
    }, [structure, user]);

    const getColiversAffinityCount = () => {
        let count = 0;
        const colivers = structure.bookings.filter(booking => booking.user_id !== user?.id).map(booking => booking.user);

        colivers.forEach(coliver => {
            const workAffinity = workAffinityPercentage(user, coliver);
            const tagCategoryPercent = tagCategoryAffinityPercentage(user.tagCategories, coliver.tagCategories);

            if (workAffinity >= 33 || tagCategoryPercent >= 33) count++
        })

        setAffinityCount(count);
    };

    const getColiversCount = (index: number) => {
        if (screenSize === 'md') {
            return index < 4 ? 4 : null;
        } else if (screenSize === 'lg') {
            return index < 5 ? 5 : null;
        } else if (screenSize === 'xl') {
            return index < 6 ? 6 : null;
        } else if (screenSize === '2xl') {
            return index < 10 ? 10 : null;
        } else {
            return index < 4 ? 4 : null;
        }
    };

    const maxColiversToShow = Math.max(...[3, 4, 6, 10].map((count, index) => getColiversCount(index) || count));

    return (
        <div className="flex flex-col h-full w-full overflow-auto">

            <div className="flex flex-col gap-2 w-full h-fit p-12">
                <p className="text-4xl md:text-6xl font-bold">{structure.name}</p>
                <p className="text-xl md:text-2xl font-medium">{structure.city}</p>
            </div>
            <img
                loading="eager"
                className="w-full object-cover h-64 md:h-80 lg:h-96 2xl:h-1/2"
                src={structure.cover_image}
                alt='img_coliving'
            />

            <div className="flex flex-col gap-12 p-12">
                <p className="text-sm md:text-xl whitespace-pre-line">{structure.description}</p>
                <div className="flex flex-col gap-4">
                    <p className="text-3xl font-bold">{i18n.t('plain_services')}</p>
                    <p className="text-sm md:text-xl whitespace-pre-line">{structure.plain_services}</p>
                </div>
            </div>

            {!!structure.bookings.length &&
                <div className="flex flex-col gap-8 p-12 border-t border-gray-200">
                    <div className="flex flex-col gap-4 justify-between md:items-center">
                        <div className="flex justify-between w-full justify-items-center">
                            <p className="text-3xl font-bold">{i18n.t('colivers')}</p>
                            <button type='button' onClick={() => setAccordionOpen(true)} className="px-4 py-1 rounded-full bg-orange w-fit text-light_black font-medium">{i18n.t('affinity')}</button>
                        </div>
                        {!!affinityCount && <p className="w-full font-medium text-md md:text-base text-orange text-center md:text-right">{i18n.t('affinity_people', {affinityCount: affinityCount})}</p>}
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
                        {structure.bookings.map((booking, index) => {

                            let workPercent: number = 0;
                            let tagCategoryPercent: number = 0;

                            if (getColiversCount(index) === null && !isOpen) {
                                return null;
                            }

                            if (user && (user.id !== booking.user_id)) {
                                workPercent = workAffinityPercentage(user, booking.user);
                                tagCategoryPercent = tagCategoryAffinityPercentage(user.tagCategories, booking.user.tagCategories);
                            }

                            if (isUserColiver) {
                                return <div key={index} className="flex flex-col items-center gap-2">
                                    <div className={`relative h-32 w-32 md:h-48 md:w-48 aspect-square rounded-full ${workPercent >= 33 || tagCategoryPercent >= 33 && "border-orange"}`} style={{ borderWidth: (workPercent >= 33 || tagCategoryPercent >= 33) ? '6px' : '' }} >
                                        <img
                                            className='w-full h-full object-cover overflow-hidden rounded-full'
                                            src={booking.user.profile_image}
                                            alt='profile-img'
                                        />
                                        <div className="flex gap-2 absolute bottom-0 right-1/2 translate-y-1/2 translate-x-1/2">
                                            {workPercent >= 33 && <div className=""><WorkAffinityIcon /></div>}
                                            {tagCategoryPercent >= 33 && <div className=""><TagCategoryAffinityIcon /></div>}
                                        </div>
                                    </div>
                                    <p className="font-bold text-lg text-center truncate w-full">{booking?.user?.name}</p>
                                    <p className="font-medium text-sm text-center truncate w-full">{booking?.user?.profession?.name}</p>
                                </div>
                            } else {
                                return <div key={index} className="flex flex-col items-center gap-2">
                                    <div className={`relative w-fit rounded-full ${workPercent >= 33 || tagCategoryPercent >= 33 && "border-orange"}`} style={{ borderWidth: (workPercent >= 33 || tagCategoryPercent >= 33) ? '6px' : '' }} >
                                        <div className="overflow-hidden rounded-full">
                                            <ColiverAvatar />
                                        </div>
                                        <div className="flex gap-2 absolute bottom-0 right-1/2 translate-y-1/2 translate-x-1/2">
                                            {workPercent >= 33 && <div className=""><WorkAffinityIcon /></div>}
                                            {tagCategoryPercent >= 33 && <div className=""><TagCategoryAffinityIcon /></div>}
                                        </div>
                                    </div>
                                    <p className="font-bold text-lg text-center truncate w-full">{i18n.t('coliver')} {(index + 1).toString().padStart(2, '0')}</p>
                                    <p className="font-medium text-sm text-center truncate w-full">{booking?.user?.profession?.name}</p>
                                </div>
                            }
                        })}
                    </div>

                    {structure.bookings.length > maxColiversToShow && <div className="flex justify-center">
                        <button type="button" onClick={() => setIsOpen(!isOpen)} className="flex flex-col items-center hover:animate-bounce duration-500">
                            {isOpen && <ArrowUp />}
                            {isOpen ? i18n.t('show_less') : i18n.t('view_all')}
                            {!isOpen && <ArrowDown />}
                        </button>
                    </div>}
                </div>
            }

            <div className="flex justify-between flex-col-reverse md:flex-row gap-10 md:items-center p-12 border-y border-gray-200">
                <div className="flex flex-col gap-8 ">
                    <p className="text-3xl font-bold">{i18n.t('contacts')}</p>
                    <div className="flex flex-col gap-2">
                        <div >
                            <p className="text-xl font-medium">{i18n.t('email')}</p>
                            <p className="text-sm">{structure.email}</p>
                        </div>
                        <div>
                            <p className="text-xl font-medium">{i18n.t('phone_number')}</p>
                            <p className="text-sm">{structure.phone_number}</p>
                        </div>
                        <div>
                            <p className="text-xl font-medium">{i18n.t('address')}</p>
                            <p className="text-sm">{structure.city} <span className="uppercase">({structure.province})</span>, {structure.address}</p>
                        </div>
                    </div>
                </div>
                <img
                    className="rounded-full aspect-square object-cover flex self-center w-52 md:w-60"
                    src={structure.logo_image}
                    alt='img_coliving'
                />
            </div>

            <div className="flex flex-col gap-4 p-12 border-y border-gray-200">
                <p className="text-3xl font-bold">{i18n.t('recommended_activities')}</p>
                {
                    structure.suggestions.length > 0
                        ? <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            {structure.suggestions.map((activity: any, index: number) => (
                                <div key={index} className='flex flex-col gap-4 h-full'>
                                    <SuggestedActivityBox activity={activity} />
                                </div>
                            ))
                            }
                        </div>
                        : <p className="text-sm font-medium text-gray-400 text-center">{i18n.t('no_activities_recommended_this_coliving')}</p>
                }
            </div>

            <Modal isOpen={accordionOpen} refs={accordionRef} className="flex flex-col gap-6 w-fit md:w-96" heightFit>
                <p className="text-4xl font-bold">{i18n.t('how_do_affinities_work')}</p>
                <div className="flex gap-4 items-start">
                    <div className="w-fit">
                        <WorkAffinityIconXL />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl font-medium">{i18n.t('work_affinity')}</p>
                        <p className="text-sm">{i18n.t('work_affinity_message')}</p>
                    </div>
                </div>
                <div className="flex gap-4 items-start">
                    <div className="w-fit">
                        <TagCategoryAffinityIconXL />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl font-medium">{i18n.t('interest_affinity')}</p>
                        <p className="text-sm">{i18n.t('interest_affinity_message')}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-xl font-medium">{i18n.t('how_to_use_affinities')}</p>
                    <p className="text-sm" dangerouslySetInnerHTML={{__html:i18n.t('how_to_use_affinities_message', {escapeValue:false})}}></p>
                </div>
                <button onClick={() => setAccordionOpen(false)} className="flex self-center px-4 py-1 rounded-full bg-light_black w-fit text-white font-medium">{i18n.t('ok_i_got_it')}</button>
            </Modal>

            <div className="p-12">
                <Outlet />
            </div>

        </div >
    )
}

interface IProps {
    activity: ISuggestion;
    hasCommonTag?: boolean;
}

export const SuggestedActivityBox: React.FC<IProps> = ({ activity, hasCommonTag = false }) => {

    const [isLoading, setIsLoading] = useState(false);

    const getPlaceDetails = async (latitude: number, longitude: number) => {
        try {
            setIsLoading(true);
            const encodedQuery = encodeURIComponent(`${activity.title} ${activity.city} ${activity.address}`);
            const response = await apiService(HttpMethod.POST, "/itineraries/get/info/goople-place", { latitude, longitude, query: encodedQuery });

            window.open(`https://www.google.com/maps/place/?q=place_id:${response.place_id}`, "_blank");

            setIsLoading(false);
            return;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    return (
        <div className={`flex flex-col justify-between border border-black h-full aspect-square cursor-pointer ${isLoading && 'cursor-progress'} `} onClick={() => getPlaceDetails(activity.latitude, activity.longitude)}>
            {!!activity.image
                ? <div className='relative h-3/5'>
                    <img
                        className='w-full max-w-lg object-cover h-full'
                        src={activity.image}
                        alt='img_activity'
                    />
                    {hasCommonTag &&
                        <div className='absolute top-0 left-0 w-full h-full bg-light_black bg-opacity-60 flex items-center justify-center'>
                            <p className='text-orange text-xl font-bold'>{i18n.t('recommended_for_you')}</p>
                        </div>
                    }
                </div>
                : <div className='w-full h-3/5 object-cover rounded-b flex justify-center items-center' style={{ backgroundColor: '#F1F1F1' }}>
                    <StructureCoverImageIconXS />
                </div>
            }

            <div className='flex flex-col justify-center gap-1 items-center text-center flex-1 px-1'>
                <p className='font-bold text-xl line-clamp-2'>{activity.title}</p>
                <p className='text-sm font-medium line-clamp-1'>{activity.city}, {activity.address}</p>
            </div>
        </div>
    )
}

export default Coliving