import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { addMessage, setMessages, setMoreMessages } from '../../redux/chat/chatSlice';
import { useLoaderData, useNavigation, useParams } from 'react-router-dom';
import useSocket from '../../hooks/useSocket';
import { IMessage } from '../../utils/interfaces/db_models/message.interface';
import moment from 'moment';
import { IChatRoom } from '../../utils/interfaces/db_models/chatRoom.interface';
import useIsOpen from '../../hooks/useIsOpen';
import { CHAT_ROOM_TYPE } from '../../utils/enums/chat_room_type.enum';
import { UserNoBGIcon } from '../../utils/icons/UserNoBGIcon'
import { ArrowRight2 } from '../../utils/icons/ArrowIcon';
import ChatRoomInfo from '../../components/ChatRoomInfo';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import i18n from './../../i18n';


const Chat = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const chatRoom = useLoaderData() as IChatRoom;
    const { messages } = useAppSelector(state => state.chat) as { messages: IMessage[] };
    const { user } = useAppSelector(state => state.auth) as { user: IUser };
    const socket = useSocket();
    const { isOpen, setIsOpen } = useIsOpen(false);
    const navigation = useNavigation();

    const [input, setInput] = useState<string>('');
    const [pagination, setPagination] = useState<{ totalItems: number, page: number }>({ totalItems: 0, page: 0 });

    const messagesContainerRef = useRef<HTMLDivElement | null>(null);
    const [autoScroll, setAutoScroll] = useState(true);

    useEffect(() => {
        if (messagesContainerRef.current && autoScroll) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (!!socket) {
            socket.on('new message', (message: string) => {
                socket.emit('update_last_access', { user: user, chatRoomId: params.id });

                setPagination(prevPagination => {
                    const updatedPagination = { ...prevPagination, totalItems: prevPagination.totalItems + 1 };
                    return updatedPagination;
                });

                dispatch(addMessage(message));
            });

            socket.on('initial messages', (response: { messages: IMessage[] | null, totalItems: number, page: number }) => {
                setPagination(prevPagination => {
                    const updatedPagination = { ...prevPagination, totalItems: response.totalItems, page: response.page };
                    return updatedPagination;
                });

                dispatch(setMessages(response.messages || []));
            });

            socket.on('get more messages', (response: { messages: IMessage[] | null, totalItems: number, page: number }) => {
                setPagination(prevPagination => {
                    const updatedPagination = { ...prevPagination, totalItems: response.totalItems, page: response.page };
                    return updatedPagination;
                });

                dispatch(setMoreMessages(response.messages));
            });

            socket.on('error', (errorMessage: string | Object) => {
                console.error(errorMessage);
                // TODO : fare una gestione dell'errore dei messaggi chat tramite il modal delle azioni/errori
            });

            return () => {
                socket.disconnect();
            };
        }
    }, [dispatch, socket]);

    useEffect(() => {
        if (!!params.id && !!socket && !!user) {
            socket.emit('initialize chatroom', { chatRoomId: params.id, user });
            socket.emit('update_last_access', { user: user, chatRoomId: params.id });
        }
    }, [params, socket, user]);

    const loadMoreMessages = () => {
        if (!!socket) {
            setAutoScroll(false);
            socket.emit('load more messages', { page: pagination.page, chatRoomId: params.id, user });
        }
    };

    const sendMessage = () => {
        if (input.trim() !== '' && !!socket) {
            setAutoScroll(true);
            socket.emit('message', { from: user, content: input, chatRoomId: params.id });
            setInput('');
        }
    };

    const renderMessages = () => {
        const renderedMessages: JSX.Element[] = [];
        let currentDay: string | null = null;

        messages.forEach((message: IMessage, index: number) => {
            const messageDate = new Date(message.created_at);
            const messageDay = messageDate.toISOString().split('T')[0];

            if (currentDay !== messageDay) {
                renderedMessages.push(
                    <div key={index + messageDay} className='flex flex-col self-center my-2 gap-2 w-11/12 font-regular text-sm text-center text-light_black capitalize border-b border-light_black'>
                        {moment(messageDate).format('dddd DD MMMM YYYY')}
                    </div>
                );
                currentDay = messageDay;
            }

            renderedMessages.push(
                <div key={message.id} className={`flex flex-col px-4 ${message.isAlert ? "w-fit bg-orange py-2 mx-2 rounded-md" : "w-full"}`} >
                    <div className={`flex gap items-baseline gap-2`}>
                        <button className="text-sm font-bold cursor-pointer" onClick={() => setIsOpen(true)}>
                            {message.isAlert ? i18n.t('notice_from_the_host') : message.author.name}
                        </button>
                        <p className={`text-xs font-medium  ${message.isAlert ? "text-light_black" : "text-gray-500"}`}>{moment(message.created_at).format('HH:mm')}</p>
                    </div>

                    <p className={`text-xs break-words whitespace-pre-line select-text`}>{message.content}</p>
                </div>
            );
        });

        return renderedMessages;
    };

    return (
        <>
            <div className={`flex flex-col justify-end h-full relative ${isOpen ? 'hidden md:block md:w-8/12' : 'w-full'}`}>

                <div className='flex h-12 gap-4 px-4 items-center border-b border-gray-300 shadow w-full absolute top-0'>
                    {chatRoom.type !== CHAT_ROOM_TYPE.GROUP &&
                        <img
                            alt='img-profile'
                            height={30}
                            width={30}
                            className='aspect-square rounded-full object-cover'
                            src={chatRoom.type === CHAT_ROOM_TYPE.PRIVATE
                                ? chatRoom?.chatRoomMemberships?.find(chatRoomMembership => chatRoomMembership.user.id !== user?.id)?.user.profile_image
                                : chatRoom.structure.logo_image}
                        />
                    }

                    <div className='w-full flex justify-between items-center'>
                        <div className='flex flex-col'>
                            <p className='text-sm font-bold line-clamp-1'>
                                {!!chatRoom.title
                                    ? chatRoom.title
                                    : chatRoom.type === CHAT_ROOM_TYPE.PRIVATE
                                        ? chatRoom?.chatRoomMemberships?.find(chatRoomMembership => chatRoomMembership.user.id !== user?.id)?.user.name
                                        : chatRoom.type === CHAT_ROOM_TYPE.GROUP &&
                                        chatRoom.chatRoomMemberships.map(chatRoomMembership => chatRoomMembership.user.name).join(', ')
                                }
                            </p>

                            <p className='text-xs line-clamp-1'>
                                {
                                    !!chatRoom.description
                                        ? chatRoom.description
                                        : chatRoom.type === CHAT_ROOM_TYPE.PRIVATE
                                            ? chatRoom?.chatRoomMemberships?.find(chatRoomMembership => chatRoomMembership.user.id !== user?.id)?.user.profession?.name
                                            : chatRoom.type === CHAT_ROOM_TYPE.GROUP &&
                                            i18n.t('group')
                                }
                            </p>
                        </div>

                        {!isOpen && <div className='cursor-pointer' onClick={() => setIsOpen(true)}>
                            <UserNoBGIcon />
                        </div>}
                    </div>
                </div>

                <div className='flex flex-col w-full h-full pt-12'>
                    <div ref={messagesContainerRef} className='flex flex-col pb-2 gap-2 h-full overflow-auto'>
                        {(messages.length < pagination.totalItems) &&
                            <button
                                onClick={loadMoreMessages}
                                className='text-center mt-4 px-2 py-0.5 border w-fit flex self-center border-gray-400 rounded-xl cursor-pointer'>
                                {i18n.t('load_more')}
                            </button>
                        }

                        <div className='flex flex-col gap-4 mt-2'>
                            {renderMessages()}
                        </div>

                    </div>
                    <div className='flex w-full justify-between gap-4 p-4 border-t border-t-gray-300 shadow'>
                        <textarea
                            className="w-11/12 border-b-2 border-gray-500 p-1 overflow-hidden font-medium focus:outline-none focus:border-gray-700 resize-none bg-transparent"
                            placeholder='Aa'
                            rows={1}
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            disabled={navigation.state !== 'idle'}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && navigation.state === 'idle') {
                                    e.preventDefault();
                                    sendMessage();
                                }
                            }}
                        />
                        <button disabled={navigation.state !== 'idle'} className='w-8 h-8 bg-orange rounded-full disabled:opacity-50' onClick={sendMessage}></button>
                    </div>
                </div>
            </div>

            {isOpen &&
                <div className={`flex flex-col border-l-gray-300 border shadow h-full w-full md:w-4/12 bg-white_shady p-4 pt-2 overflow-auto`}>
                    <div className='flex justify-between items-center w-full'>
                        <p className='text-lg font-bold'>{i18n.t('information')}</p>
                        <div className='cursor-pointer' onClick={() => setIsOpen(false)}><ArrowRight2 /></div>
                    </div>

                    <ChatRoomInfo chatRoom={chatRoom} />
                </div>
            }

        </>
    );
}

export default Chat