import { useNavigate } from "react-router-dom";
import i18n from './../../i18n';

const SelectRegister: React.FC = () => {

    const navigate = useNavigate();

    const handleColiverPress = () => {
        navigate("/register/coliver");
    }

    const handlePressHomeowner = () => {
        navigate("/register/business");
    }

    return (
        <div className="w-full flex-1 flex flex-col gap-16 justify-center md:justify-evenly p-8 text-light_black lg:container m-auto">
            <div className="flex flex-col gap-4">
                <p className="text-center font-bold text-4xl md:text-6xl">{i18n.t('coliver_or_coliving_manager')}</p>
                <p className="text-center font-medium text-sm">{i18n.t('coliver_or_coliving_manager_message')}</p>
            </div>
            <div className="gap-10 grid grid-cols-1 lg:grid-cols-2 w-full">
                <div className="flex justify-center">
                    <button className="max-w-xs md:max-w-sm flex flex-col gap-8 p-8 border border-light_black aspect-square  hover:scale-105 hover:bg-orange transition-all duration-200 ease-out" onClick={handleColiverPress}>
                        <p className="text-left font-bold text-4xl">{i18n.t('coliver')}</p>
                        <div className="border-b border-light_black w-full" />
                        <p className="text-left font-medium text-sm" dangerouslySetInnerHTML={{__html:i18n.t('message_info_coliver', {escapeValue:false})}}></p>
                    </button>
                </div>
                <div className="flex justify-center">
                    <button className="max-w-xs md:max-w-sm flex flex-col gap-8 p-8 border border-light_black aspect-square  hover:scale-105 hover:bg-orange transition-all duration-200 ease-out" onClick={handlePressHomeowner}>
                        <p className="text-left font-bold text-4xl w-full">{i18n.t('coliving_manager')}</p>
                        <div className="border-b border-light_black w-full" />
                        <p className="text-left font-medium text-sm" dangerouslySetInnerHTML={{__html:i18n.t('coliving_manager_info_message', {escapeValue:false})}}></p>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SelectRegister;