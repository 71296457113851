import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
    Column,
    ColumnFiltersState,
    FilterMeta,
    PaginationState,
    SortingState,
    Table,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { SortIcon } from '../utils/icons/SortIcon'
import useIsOpen from '../hooks/useIsOpen'
import { FilterIcon } from '../utils/icons/FilterIcon';
import { ArrowLeft, ArrowRight } from '../utils/icons/ArrowIcon';
import LoadingIndicator from './LoadingIndicator';
import i18n from './../i18n';

interface IProps<T> {
    data: T[],
    columns: any,
    pageIndexs: number,
    pageSizes: number,
    pageCount: number,
    totalItems: number,
    handleNext: (pagination: PaginationState, sorting: SortingState, columnFilters: ColumnFiltersState) => void,
    isLoading?: boolean,
    noDataMessage: string,
    onRowClick: (rowData: T) => void;
}

interface CustomFilterMeta extends FilterMeta {
    filterComponent: (info: { column: Column<any, unknown>; table: Table<any> }) => JSX.Element;
}

const DataTable = <T,>({ data, columns, pageIndexs, pageSizes, pageCount, totalItems, handleNext, isLoading = false, noDataMessage, onRowClick }: IProps<T>) => {
    const isFirstRender = useRef(true);
    const { isOpen, setIsOpen, ref } = useIsOpen(false);

    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: Number(pageIndexs),
        pageSize: Number(pageSizes),
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    );

    useEffect(() => {
        if (!isFirstRender.current) {
            handleNext(pagination, sorting, columnFilters);
        } else {
            isFirstRender.current = false;
        }
    }, [pageIndex, pageSize, sorting, columnFilters]);

    const table: Table<T> = useReactTable({
        data,
        columns,
        pageCount,
        state: {
            pagination,
            sorting,
            columnFilters
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
        enableColumnFilters: true,
    });

    const handleColumnFilterChange = (headerId: string, columnFilters: ColumnFiltersState, targetValue: string): void => {
        const filterIndex = columnFilters.findIndex((filter) => filter.id === headerId);
        let newFilters: ColumnFiltersState;

        if (filterIndex !== -1) {
            const updatedFilters = [...columnFilters];
            updatedFilters[filterIndex] = { id: headerId, value: targetValue };
            newFilters = updatedFilters;
        } else {
            newFilters = [...columnFilters, { id: headerId, value: targetValue }];
        }

        table.setColumnFilters(newFilters);
    };

    return (
        <div className='w-full h-full select-none justify-between flex flex-col text-light_black'>
            <div className='flex flex-col gap-2'>

                <div className={`w-full flex ${!!columnFilters?.length ? "justify-between" : "justify-end"} rounded-t-md`}>

                    {!!columnFilters?.length && <button
                        className='font-bold border border-light_black px-2 py-1 rounded-full focus:outline-none'
                        onClick={() => table.resetColumnFilters()}>
                        {i18n.t('reset_filters')}
                    </button>}

                    <div ref={ref} className="relative flex">
                        <div className="flex items-center gap-2 border border-light_black px-2 py-1 rounded-full cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                            <FilterIcon />
                            <p className='font-bold text-light_black'>{i18n.t('filters')}</p>
                            {!!columnFilters?.length && <p className='font-bold text-light_black'>{columnFilters.length}</p>}
                        </div>

                        {isOpen && (
                            <div className="absolute top-full p-4 right-0 mt-2 gap-8 flex flex-col bg-white rounded-md w-fit" style={{ boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.3)' }}>
                                {table.getHeaderGroups().map(headerGroup => (
                                    headerGroup.headers.map(header => {
                                        if (!header.column.getCanFilter()) return null;

                                        const { id, columnDef } = header.column;
                                        const meta = columnDef?.meta as CustomFilterMeta;
                                        const filterComponent = meta?.filterComponent;

                                        return (
                                            <div key={id}>
                                                {filterComponent ? (
                                                    filterComponent({ column: header.column, table })
                                                ) : (
                                                    <input
                                                        value={columnFilters.find((columnFilters) => columnFilters.id === header.id)?.value as string || ''}
                                                        onChange={(e) => handleColumnFilterChange(header.id, columnFilters, e.target.value)}
                                                        placeholder={i18n.t('search') + flexRender(header.column.columnDef.header, header.getContext())}
                                                        className='border-b-2 border-gray_shady p-1 overflow-hidden font-medium focus:outline-none focus:border-gray_shady'
                                                    />
                                                )}
                                            </div>
                                        );
                                    })
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <table className='w-full h-full' >
                    <thead className='text-left'>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header, index) => {
                                    const isLastHeader = index === headerGroup?.headers?.length - 1;

                                    return (
                                        <th key={index} onClick={header.column.getToggleSortingHandler()} className='border-b border-light_black cursor-pointer'>
                                            <p className={`flex flex-row w-full items-center gap-2 p-2 ${isLastHeader ? 'justify-center' : ''}`}>
                                                <span className='font-semibold text-xl'>{flexRender(header.column.columnDef.header, header.getContext())}</span>
                                                {header.column.getCanSort() && <SortIcon sortingState={sorting} columnId={header.id} />}
                                            </p>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>


                    <tbody className='w-full h-full'>
                        {isLoading
                            ? <tr>
                                <td colSpan={7} className='h-40'>
                                    <LoadingIndicator label={i18n.t('loading_reservations')} />
                                </td>
                            </tr>
                            : !!table.getRowModel().rows.length ?
                                table.getRowModel().rows.map(row => (
                                    <tr key={row.id} onClick={() => onRowClick(row.original)} className='cursor-pointer'>
                                        {row.getVisibleCells().map((cell, index) => {
                                            return (
                                                <td key={index} className={`h-full py-4 px-2 border-b border-gray_shady font-semibold`}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))
                                : <tr>
                                    <td colSpan={7} className='h-40'>
                                        <p className='text-2xl font-medium text-gray_shady text-center w-full'>{noDataMessage}</p>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className={`w-full p-2 flex justify-between`}>
                <p className='font-semibold text-xl md:w-1/5 text-center md:text-left whitespace-nowrap'>{totalItems} {i18n.t('elements')}</p>

                <div className="flex items-start justify-end md:justify-center gap-4 w-full md:w-3/5">
                    <button
                        className='md:flex disabled:opacity-50 hidden'
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <ArrowLeft />
                        <ArrowLeft />
                    </button>
                    <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className='disabled:opacity-50'
                    >
                        <ArrowLeft />
                    </button>
                    <p className='font-semibold text-xl'>{table.getState().pagination.pageIndex + 1}/{table.getPageCount()}</p>
                    <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className='disabled:opacity-50'
                    >
                        <ArrowRight />
                    </button>
                    <button
                        className='md:flex disabled:opacity-50 hidden'
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        <ArrowRight />
                        <ArrowRight />
                    </button>
                </div>

                <div className='md:flex hidden items-start gap-4 justify-end w-1/5'>
                    <p className='font-semibold text-xl whitespace-nowrap'>{i18n.t('per_page')}</p>
                    <button
                        onClick={() => table.setPageSize(table.getState().pagination.pageSize - 10)}
                        disabled={table.getState().pagination.pageSize === 10}
                        className='disabled:opacity-50'
                    >
                        <ArrowLeft />
                    </button>
                    <p className='font-semibold text-xl'>{table.getState().pagination.pageSize}</p>
                    <button
                        onClick={() => table.setPageSize(table.getState().pagination.pageSize + 10)}
                        disabled={table.getState().pagination.pageSize === 50 || table.getState().pagination.pageSize >= totalItems}
                        className='disabled:opacity-50'
                    >
                        <ArrowRight />
                    </button>
                </div>
            </div>

        </div>
    )
}

export default DataTable