import i18n from './../i18n';

const Footer = () => {
    return (
        <div className='w-full p-10 md:p-20 bg-light_black'>
            <div className='flex text-white w-full justify-between flex-col lg:flex-row gap-y-10'>
                <img src='/images/logo_footer.svg' alt="logo_footer_connivio" className='max-w-sm' />
                <div className='flex flex-col gap-x-4 items-start'>
                    <p>WAS S.R.L.</p>
                    <p>Via Enzo Ferrari, 9-A</p>
                    <p>62012 Civitanova Marche (MC)</p>
                </div>
                <div className='flex flex-col gap-x-4 items-start'>
                    <p>{i18n.t('vat_number')} 01923240434</p>
                    <p>{i18n.t('tax_id_code')} 01923240434</p>
                </div>
                <div className='lg:flex flex-col items-end gap-x-4 hidden lg:visible xl:w-1/4'>
                </div>
                <div className='flex flex-col lg:items-end text-orange font-medium whitespace-nowrap'>
                    <a href="https://www.iubenda.com/privacy-policy/66665785" title="Privacy Policy">Privacy Policy</a>
                    <a href="https://www.iubenda.com/privacy-policy/66665785/cookie-policy" title="Cookie Policy">Cookie Policy</a>
                    {/* <a href="https://www.iubenda.com/termini-e-condizioni/66665785" title="Termini e Condizioni">Termini e Condizioni</a> */}
                </div>
            </div>
        </div>
    )
}


export default Footer