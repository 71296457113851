import { USER_ROLES } from "../../enums/user_roles.enum";
import { IBooking, ListBookingInput } from "./bookings.interface";
import { IChatRoom, IChatRoomMembership, ListChatRoomInput, ListChatRoomMembershipInput } from "./chatRoom.interface";
import { IModel } from "./index.interface";
import { IMessage, ListMessageInput } from "./message.interface";
import { IProfession, ListProfessionInput } from "./profession.interface";
import { IStructureUser, ListStructureUserInput } from "./structureUser.interface";
import { ITag, ListTagInput } from "./tag.interface";
import { ITagCategory, ListTagCategoryInput } from "./tagCategory.interface";

export interface IUser extends IModel {
  id: string;
  email: string;
  phone?: string;
  password: string;
  name: string;
  city: string;
  province: string;
  role: USER_ROLES;
  biography?: string;
  profile_image: string;

  chatTutorial: boolean;
  chatbotTutorial: boolean;
  isVerified: boolean;
  isProfileCompleted: boolean;
  isBusiness: boolean;
  canCreateStructure: boolean;

  city_business?: string;
  cap_business?: string;
  province_business?: string;
  address_business?: string;
  vat_business?: string;
  company_name_business?: string;
  unique_code_business?: string;

  tags: ITag[];
  tagCategories: ITagCategory[];
  structureUsers: IStructureUser[];
  bookings: IBooking[];
  messages: IMessage[];
  profession: IProfession;
  profession_id: string;
  // chatRooms: IChatRoom[];
  chatRoomMemberships: IChatRoomMembership[];
  affinities: IProfession[];
};

export interface ListUserInput {
  tags?: boolean | { include: ListTagInput };
  structureUser?: boolean | { include: ListStructureUserInput };
  bookings?: boolean | { include: ListBookingInput };
  messages?: boolean | { include: ListMessageInput };
  tagCategories?: boolean | { include: ListTagCategoryInput };
  affinities?: boolean | { include: ListProfessionInput };
  profession?: boolean | { include: ListProfessionInput };
  chatRooms?: boolean | { include: ListChatRoomInput };
  chatRoom_membership?: boolean | { include: ListChatRoomMembershipInput };
};

export const fieldTypesUser: Record<string, string> = {
  id: 'text',
  email: 'email',
  name: 'text',
  city: 'text',
  province: 'text',
  role: 'select',
  created_at: 'text',
  updated_at: 'text',
  deleted: 'checkbox',
  version: 'number',
};