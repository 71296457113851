import { Link, useLoaderData, useNavigate } from 'react-router-dom'
import { IStructureUser } from '../../utils/interfaces/db_models/structureUser.interface';
import { AddIcon } from '../../utils/icons/AddIcon';
import i18n from './../../i18n';

const Administrators = () => {
    const administrators = useLoaderData() as IStructureUser[];
    const navigate = useNavigate();

    return (
        <div className='flex flex-col gap-6 px-10 sm:px-14 py-6 w-full select-none'>
            <div className='flex justify-between items-center'>
                <p className="font-bold text-3xl">{i18n.t('administrators')}</p>
                <Link to={'create'}>
                    <div className='rounded-full'> <AddIcon /></div>
                </Link>
            </div>

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                    <div className="flex w-full justify-between items-center text-xl font-semibold gap-4">
                        <div className="w-8 flex items-center justify-center"><p>#</p></div>
                        <div className="grid grid-cols-3 w-10/12">
                            <p className="text-center">{i18n.t('name')}</p>
                            <p className="text-center col-span-2">{i18n.t('email')}</p>
                        </div>
                        <p className="text-center flex-1">Admin</p>
                    </div>
                    <div className="border-t border-light_black" />

                    {administrators?.map((administrator, index) => {
                        return (
                            <div key={index} onClick={() => !administrator.admin && navigate('manage/' + administrator.id)} className={`flex flex-col gap-4 ${!administrator.admin && 'cursor-pointer'}`}>
                                <div className="flex w-full justify-between items-center gap-4">
                                    <img src={administrator.user.profile_image} alt='img-profile' className='h-8 w-8 rounded-full object-cover' />
                                    <div className="grid grid-cols-3 items-center font-medium w-10/12">
                                        <p className={`text-center truncate ${!!administrator.admin && "text-orange"}`}>{administrator.user.name}</p>
                                        <p className={`text-center col-span-2 truncate ${!!administrator.admin && "text-orange"}`}>{administrator.user.email}</p>
                                    </div>
                                    <div className='flex-1'>
                                        <div className={`h-4 w-4 rounded-full shadow flex m-auto ${administrator.admin ? "bg-orange" : "bg-gray_shady"}`} />
                                    </div>
                                </div>

                                <div className="border-t border-gray-300" />
                            </div>
                        );
                    })}
                </div>
            </div>

        </div >
    )
}

export default Administrators