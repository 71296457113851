import { useEffect } from 'react';
import { Outlet, useNavigate, useLoaderData, Navigate } from 'react-router-dom';
import useCookie from '../../hooks/useCookie';
import { useAppDispatch } from '../../redux/store';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { replaceUser } from '../../redux/auth/authSlice';
import i18n from './../../i18n';

export const UtilitiesLayout = () => {
    const response = useLoaderData() as IUser;
    const dispatch = useAppDispatch();
    const { cookieData, removeCookie } = useCookie({ accessToken: 'accessToken', refreshToken: 'refreshToken' });
    const navigate = useNavigate();

    useEffect(() => {
        if (response) {
            dispatch(replaceUser(response));
        } else {
            removeCookie('refreshToken');
            removeCookie('accessToken');
            dispatch(replaceUser(undefined));
        }
    }, [dispatch, navigate]);

    if (!cookieData.refreshToken) {
        return <Navigate to="/" />
    } else if (response?.isProfileCompleted) {
        return <Navigate to={'/app'} />
    }

    return <div className='h-full w-full overflow-y-auto overflow-x-hidden'>
        <div className='flex h-full container m-auto'>
            <Outlet />
        </div>
    </div>

};