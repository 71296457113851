import React, { useEffect, useState } from 'react'
import { Form, useActionData, useLoaderData, useNavigate, useNavigation } from 'react-router-dom'
import { IStructure } from '../../utils/interfaces/db_models/structure.interface'
import Accordion from '../../components/Accordion';
import useIsOpen from '../../hooks/useIsOpen';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { IChatRoom } from '../../utils/interfaces/db_models/chatRoom.interface';
import { getErrorMessage } from '../../utils/manageError';
import { useAppSelector } from '../../redux/store';
import i18n from './../../i18n';

const Alert = () => {
    const structure = useLoaderData() as IStructure;
    const response = useActionData() as any;
    const { user } = useAppSelector(store => store.auth) as { user: IUser };
    const { isOpen, setIsOpen } = useIsOpen();
    const navigate = useNavigate();
    const navigation = useNavigation();

    const [chatRoomIds, setChatRoomIds] = useState<string[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [message, setMessage] = useState('');

    const structureChatRooms = structure.chatRooms.filter(chatRoom => !!chatRoom.structure_id?.length);
    const seenIds = new Set();
    const users = structure.bookings
        .map(booking => booking.user)
        .filter(u => !seenIds.has(u.id) && seenIds.add(u?.id));

    const handleManageUserIds = (user: IUser) => {
        setUserIds(prevIds => {
            if (prevIds.includes(user.id)) {
                return prevIds.filter(id => id !== user.id);
            } else {
                return [...prevIds, user.id];
            }
        })
    };

    const handleManageChatRoomIds = (chatRoom: IChatRoom) => {
        setChatRoomIds(prevIds => {
            if (prevIds.includes(chatRoom.id)) {
                return prevIds.filter(id => id !== chatRoom.id);
            } else {
                return [...prevIds, chatRoom.id];
            }
        })
    };

    return (
        <Form method='POST' className='flex flex-col gap-6 px-10 sm:px-14 py-6 w-full h-full select-none'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-3xl'>{i18n.t('information')}</p>
            </div>

            <div className="border-t shadow -mx-20" />

            <Accordion isOpen={isOpen} onToggle={() => setIsOpen(!isOpen)} title={i18n.t('how_does_it_work')}>
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col text-left'>
                        <p>1. <span className='font-semibold'>{i18n.t('select_the_recipients')}:</span> {i18n.t('select_the_recipients_info')}</p>
                        <p>2. <span className='font-semibold'>{i18n.t('chat_message')}:</span> {i18n.t('chat_message_info')}</p>
                        <p>3. <span className='font-semibold'>{i18n.t('email_notification')}:</span> {i18n.t('email_notification_info')}</p>
                        <p className='mt-4'><span className='font-semibold'>{i18n.t('advice')}:</span> {i18n.t('advice_info')}</p>
                    </div>
                    <p className='font-medium text-center'>{i18n.t('advice_info2')}</p>
                </div>
            </Accordion>

            <div className='flex flex-col gap-2'>
                <p className='text-xl font-semibold'>{i18n.t('message')}</p>
                <textarea
                    name="message"
                    maxLength={500}
                    rows={6}
                    placeholder={i18n.t('write_a_warning_to_send_to_your_colivers')}
                    className='font-medium w-full h-full p-1 border-2 border-light_black focus:outline-none resize-none'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <div className='flex flex-col w-full items-end'>
                    <p className='font-semibold text-lg'>{message.length}/500</p>
                    <p className='font-semibold text-sm opacity-80'>{i18n.t('max_500_characters')}</p>
                </div>
            </div>

            <div className='flex flex-col gap-2'>
                <p className='text-xl font-semibold'>{i18n.t('select_the_recipients')}</p>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-0 justify-center'>
                    <div className='md:border-r border-light_black border-opacity-70'>
                        <p className='text-lg font-medium text-orange text-center'>{i18n.t('chat')}</p>
                        {structureChatRooms.map((chatRoom, index) => (
                            <button type='button' onClick={() => handleManageChatRoomIds(chatRoom)} key={index} className={`flex w-full items-center gap-2 p-2 justify-between ${chatRoomIds.some(id => chatRoom.id === id) && 'bg-orange'}`}>
                                <div className='flex items-center gap-4 truncate w-full'>
                                    <img src={structure.logo_image} alt='img-profile' height={42} width={42} className='rounded-full object-cover' />
                                    <div className='flex flex-col truncate text-left'>
                                        <p className='text-sm font-semibold capitalize truncate'>{chatRoom.title}</p>
                                        <p className='text-xs capitalize truncate'>{i18n.t('users')}: {chatRoom?.chatRoomMemberships?.filter(member => member.userId !== user?.id).length}</p>
                                    </div>
                                </div>
                            </button>
                        ))}
                    </div>
                    <div className='max-md:border-t md:border-l border-light_black border-opacity-70'>
                        <p className='text-lg font-medium text-orange text-center'>{i18n.t('colivers')}</p>
                        {users.map((u, index) => (
                            <button type='button' onClick={() => handleManageUserIds(u)} key={index} className={`flex w-full items-center gap-2 p-2 justify-between ${userIds.some(id => u.id === id) && 'bg-orange'}`}>
                                <div className='flex items-center gap-4 truncate w-full'>
                                    <img src={u.profile_image} alt='img-profile' height={42} width={42} className='rounded-full object-cover' />
                                    <div className='flex flex-col truncate text-left'>
                                        <p className='text-sm font-semibold capitalize truncate'>{u.name}</p>
                                        <p className='text-xs capitalize truncate'>{u.profession?.name}</p>
                                    </div>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            {/* INPUT FOR THE FORM   */}

            {!!chatRoomIds.length && <input type="hidden" name="chatRooms" value={JSON.stringify(chatRoomIds)} />}
            {!!userIds.length && <input type="hidden" name="users" value={JSON.stringify(userIds)} />}

            {/* INPUT FOR THE FORM   */}

            <div className='flex flex-col w-full md:col-span-3 gap-1'>
                <div className='md:col-span-3 flex w-full h-full items-center justify-between md:justify-end gap-4'>
                    <button
                        type={'button'}
                        onClick={() => navigate(-1)}
                        disabled={navigation.state !== 'idle'}
                        className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl disabled:opacity-50'
                    >
                        {i18n.t('cancel')}
                    </button>
                    <button
                        title={!message.length ? i18n.t('write_a_message_before_sending_it') : (!chatRoomIds.length && !userIds.length) ? i18n.t('select_at_least_one_recipient') : i18n.t('send')}
                        disabled={navigation.state !== 'idle' || !message.length || (!chatRoomIds.length && !userIds.length)}
                        className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap disabled:opacity-50'
                    >
                        {i18n.t('send')}
                    </button>
                </div>
                {response?.errorCode &&
                    <div className='flex flex-col items-center'>
                        <p className="text-red-500 text-base font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
                        <p className="text-red-500 text-xs font-medium">{i18n.t('unexpected_error')}: {response.message}</p>
                    </div>
                }
            </div>

            <div className='pb-0.5' >
            </div>

        </Form>
    )
}

export default Alert