export const UserIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 14.2742C13.8918 14.2742 12.9431 13.8691 12.1539 13.0589C11.3647 12.2487 10.9701 11.2748 10.9701 10.1371C10.9701 8.9994 11.3647 8.02545 12.1539 7.21527C12.9431 6.40509 13.8918 6 15 6C16.1082 6 17.0569 6.40509 17.8461 7.21527C18.6353 8.02545 19.0299 8.9994 19.0299 10.1371C19.0299 11.2748 18.6353 12.2487 17.8461 13.0589C17.0569 13.8691 16.1082 14.2742 15 14.2742ZM5 25V23.0081C5 22.4462 5.16791 21.9163 5.50373 21.4183C5.83955 20.9204 6.29353 20.5309 6.86567 20.25C8.23383 19.586 9.59577 19.088 10.9515 18.756C12.3072 18.4241 13.6567 18.2581 15 18.2581C16.3433 18.2581 17.6928 18.4241 19.0485 18.756C20.4042 19.088 21.7662 19.586 23.1343 20.25C23.7065 20.5309 24.1604 20.9204 24.4963 21.4183C24.8321 21.9163 25 22.4462 25 23.0081V25H5ZM6.04478 23.9274H23.9552V23.0081C23.9552 22.6505 23.8371 22.3122 23.6007 21.9929C23.3644 21.6737 23.0348 21.3992 22.6119 21.1694C21.4179 20.582 20.178 20.1287 18.8923 19.8095C17.6066 19.4903 16.3091 19.3306 15 19.3306C13.6909 19.3306 12.3935 19.4903 11.1077 19.8095C9.82198 20.1287 8.58209 20.582 7.38806 21.1694C6.96517 21.3992 6.63557 21.6737 6.39925 21.9929C6.16294 22.3122 6.04478 22.6505 6.04478 23.0081V23.9274ZM15 13.2016C15.8209 13.2016 16.5236 12.9015 17.1082 12.3014C17.6928 11.7013 17.9851 10.9798 17.9851 10.1371C17.9851 9.29435 17.6928 8.57292 17.1082 7.97278C16.5236 7.37265 15.8209 7.07258 15 7.07258C14.1791 7.07258 13.4764 7.37265 12.8918 7.97278C12.3072 8.57292 12.0149 9.29435 12.0149 10.1371C12.0149 10.9798 12.3072 11.7013 12.8918 12.3014C13.4764 12.9015 14.1791 13.2016 15 13.2016Z" fill="black" />
        </svg>
    )
};

export const UserIconActive = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 13.2742C13.8918 13.2742 12.9431 12.8691 12.1539 12.0589C11.3647 11.2487 10.9701 10.2748 10.9701 9.1371C10.9701 7.9994 11.3647 7.02545 12.1539 6.21527C12.9431 5.40509 13.8918 5 15 5C16.1082 5 17.0569 5.40509 17.8461 6.21527C18.6353 7.02545 19.0299 7.9994 19.0299 9.1371C19.0299 10.2748 18.6353 11.2487 17.8461 12.0589C17.0569 12.8691 16.1082 13.2742 15 13.2742ZM5 24V22.0081C5 21.4462 5.16791 20.9163 5.50373 20.4183C5.83955 19.9204 6.29353 19.5309 6.86567 19.25C8.23383 18.586 9.59577 18.088 10.9515 17.756C12.3072 17.4241 13.6567 17.2581 15 17.2581C16.3433 17.2581 17.6928 17.4241 19.0485 17.756C20.4042 18.088 21.7662 18.586 23.1343 19.25C23.7065 19.5309 24.1604 19.9204 24.4963 20.4183C24.8321 20.9163 25 21.4462 25 22.0081V24H5Z" fill="#D9981D" />
        </svg>
    )
};

export const BlackUserIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 13.2742C13.8918 13.2742 12.9431 12.8691 12.1539 12.0589C11.3647 11.2487 10.9701 10.2748 10.9701 9.1371C10.9701 7.9994 11.3647 7.02545 12.1539 6.21527C12.9431 5.40509 13.8918 5 15 5C16.1082 5 17.0569 5.40509 17.8461 6.21527C18.6353 7.02545 19.0299 7.9994 19.0299 9.1371C19.0299 10.2748 18.6353 11.2487 17.8461 12.0589C17.0569 12.8691 16.1082 13.2742 15 13.2742ZM5 24V22.0081C5 21.4462 5.16791 20.9163 5.50373 20.4183C5.83955 19.9204 6.29353 19.5309 6.86567 19.25C8.23383 18.586 9.59577 18.088 10.9515 17.756C12.3072 17.4241 13.6567 17.2581 15 17.2581C16.3433 17.2581 17.6928 17.4241 19.0485 17.756C20.4042 18.088 21.7662 18.586 23.1343 19.25C23.7065 19.5309 24.1604 19.9204 24.4963 20.4183C24.8321 20.9163 25 21.4462 25 22.0081V24H5Z" fill="#1A1C20" />
        </svg>
    )
};