import { useLoaderData, useNavigate } from 'react-router-dom';
import { IStructure, ListStructureInput } from '../../utils/interfaces/db_models/structure.interface';
import DataTable from '../../components/DataTable';
import { Column, ColumnFiltersState, PaginationState, SortingState, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import SelectForm from '../../components/SelectForm';
import { listItems } from '../../api/actions/genericActions';
import { REQUEST_STATUS, getRequestStatusColor, mapRequestStatus, requestStatusOptions } from '../../utils/enums/request_status.enum';
import { ListInput } from '../../utils/interfaces/listInput.interface';
import moment from 'moment';
import useScreenSize from '../../hooks/useScreenSize';
import i18n from './../../i18n';

const columnHelper = createColumnHelper<IStructure>();

const columns = [
    columnHelper.accessor('name', {
        id: 'name',
        header: i18n.t('name'),
        cell: info => info.getValue(),
    }),
    columnHelper.accessor('email', {
        header: i18n.t('email'),
        cell: info => info.renderValue(),
    }),
    columnHelper.accessor('phone_number', {
        header: i18n.t('phone_number'),
        cell: info => info.renderValue(),
        enableSorting: false
    }),
    columnHelper.accessor('region', {
        header: i18n.t('region'),
        cell: info => info.renderValue(),
        enableColumnFilter: false,
        enableSorting: false
    }),
    columnHelper.accessor('created_at', {
        header: i18n.t('registration'),
        cell: info => moment(info.renderValue()).format('YYYY-MM-DD'),
        enableColumnFilter: false,
        enableSorting: false
    }),
    columnHelper.accessor('isDisabled', {
        header: i18n.t('state'),
        cell: info => <div title={info.renderValue() ? i18n.t('deactivated') : i18n.t('active')} className='flex w-full h-full items-center justify-center'>
            <div className={`aspect-square rounded-full h-4 w-4 ${info.renderValue() ? 'bg-orange' : 'bg-green-700'}`} />
        </div>,
        enableColumnFilter: false
    })
]

const Structures = () => {
    const { response } = useLoaderData() as { response: { data: IStructure[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number } };
    const navigate = useNavigate()
    const screenSize = useScreenSize();

    const [structures, setStructures] = useState<{ data: IStructure[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number }>(response);
    const [isLoading, setIsLoading] = useState(false);

    const handleLoadStructure = async (pagination: PaginationState, sorting: SortingState, columnFilters: ColumnFiltersState) => {
        setIsLoading(true);
        const { response } = await listItems<ListInput<ListStructureInput>, IStructure, ListStructureInput>('/structures/get-all', pagination, sorting, columnFilters);
        setStructures(response);
        setIsLoading(false);
    };

    const handleRowClick = (data: IStructure) => {
        navigate('manage/' + data.id);
    }

    return (
        <div className='flex flex-col h-full w-full gap-6 px-10 sm:px-14 py-6 select-none text-light_black'>
            <div className='flex justify-between'>
                <p className='font-bold text-3xl'>{i18n.t('colivings')}</p>
                <p className='font-bold text-3xl'>{i18n.t('totals')} {structures?.totalItems}</p>
            </div>

            <div className="border-t shadow -mx-20" />

            <DataTable<IStructure>
                columns={
                    (screenSize === 'xs' || screenSize === 'sm')
                        ? [columns[0], columns[5]]
                        : (screenSize === 'md')
                            ? [columns[0], columns[1], columns[2], columns[5]]
                            : columns
                }
                data={structures?.data}
                pageIndexs={structures.pageIndex}
                pageSizes={structures.pageSize}
                pageCount={structures.totalPages}
                totalItems={structures.totalItems}
                handleNext={handleLoadStructure}
                isLoading={isLoading}
                noDataMessage={i18n.t('no_accommodation_found')}
                onRowClick={handleRowClick}
            />
        </div>
    )
}

export default Structures