import { ActionFunctionArgs, Form, Link, useActionData, useNavigate, useNavigation } from 'react-router-dom';
import { HttpMethod, apiService } from '../../api/apiService';
import { getErrorMessage } from '../../utils/manageError';
import { useEffect, useState } from 'react';
import useCookie from '../../hooks/useCookie';
import { getUserByRefreshToken } from '../../api/actions/genericActions';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { replaceUser } from '../../redux/auth/authSlice';
import { useAppDispatch } from '../../redux/store';
import { PasswordHidden, PasswordVisible } from '../../utils/icons/PasswordVisibility';
import i18n from './../../i18n';

export async function handleLogin({ request }: ActionFunctionArgs) {
    const formData = await request.formData();
    const updates = Object.fromEntries(formData);

    if (!updates.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(updates.email as string))
        return { emailError: true }

    const response = await apiService(HttpMethod.POST, '/users/login', updates);

    return { ...response, ...updates }
};

const Login = () => {
    const navigate = useNavigate();
    const navigation = useNavigation();
    const response = useActionData() as any;
    const dispatch = useAppDispatch();
    const { removeCookie } = useCookie({ accessToken: 'accessToken', refreshToken: 'refreshToken' });

    const [passwordVisibility, setPasswordVisibility] = useState(false);

    useEffect(() => {
        if (response?.tokens) {
            setUser();
        }
    }, [response]);

    const setUser = async () => {
        const user = await getUserByRefreshToken<IUser>();
        if (!!user) {
            dispatch(replaceUser(user));
        } else {
            removeCookie('refreshToken');
            removeCookie('accessToken');
            dispatch(replaceUser(undefined));
        }
    };

    return (
        <div className='h-full flex flex-row items-center'>
            {/* left box 33% */}
            <div className='flex w-full md:w-1/2 flex-col px-16 md:px-24 xl:px-32'>
                {/* logo */}
                <img src='/images/connivio_login_xl.svg' alt='logo' className='h-24 2xl:h-32 mb-20 self-center' />

                {/* form input username and pass */}
                <p className='font-semibold text-2xl mb-8'>{i18n.t('login')}</p>
                <Form method='post' className='flex flex-col'>
                    <div className="flex flex-col gap-4">
                        <div>
                            <input
                                placeholder={i18n.t('email')}
                                type="text"
                                name="email"
                                className="border-b border-light_black py-2 w-full focus:outline-none"
                            />
                            {!!response?.emailError && <p className="text-red-500 text-sm mt-1 font-medium">{i18n.t('enter_a_valid_email')}</p>}
                        </div>
                        <div className='flex justify-between items-center border-b border-light_black'>
                            <input
                                placeholder={i18n.t('password')}
                                type={passwordVisibility ? 'text' : 'password'}
                                name="password"
                                className=" py-2 w-full focus:outline-none"
                            />
                            <div onClick={() => setPasswordVisibility(!passwordVisibility)}>
                                {passwordVisibility ? <PasswordVisible /> : <PasswordHidden />}
                            </div>
                        </div>
                    </div>

                    <p className="text-red-500 text-sm my-1 text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>

                    <div className='flex justify-end font-semibold text-sm underline mb-8'>
                        <Link to={`/forgot-password`}>{i18n.t('forgotten_password')}</Link>
                    </div>

                    <div className='flex flex-col gap-2 whitespace-nowrap w-full items-center'>
                        {response?.errorCode === 'UR011' &&
                            <button type='button' onClick={() => navigate("/verify/" + response.email)} className='bg-orange rounded-3xl text-light_black py-1 font-medium text-lg w-fit px-8' >
                                {i18n.t('validate_account')}
                            </button>
                        }

                        <button className='w-fit bg-light_black rounded-3xl text-white px-12 py-1 font-medium text-lg' type="submit" disabled={navigation.state !== 'idle' || response?.errorCode === 'UR004'}>
                            {i18n.t('login_button')}
                        </button>

                        <p className='mt-8'>
                            {i18n.t('dont_have_an_account_yet')} <Link to='/register/select' className='text-orange'>{i18n.t('register')}</Link>
                        </p>
                    </div>
                </Form>
            </div>

            {/* right box 66% */}
            <div className='w-1/2 h-full hidden md:flex'>
                <img src='/images/login_bg.png' alt='background' className='w-full h-full object-cover' />
            </div>
        </div >
    )
}

export default Login
