export const MapPinIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0055 14.5625C15.5227 14.5625 15.9635 14.3784 16.3281 14.0101C16.6927 13.6418 16.875 13.1991 16.875 12.682C16.875 12.1648 16.6909 11.724 16.3226 11.3594C15.9543 10.9948 15.5116 10.8125 14.9945 10.8125C14.4773 10.8125 14.0365 10.9966 13.6719 11.3649C13.3073 11.7332 13.125 12.1759 13.125 12.693C13.125 13.2102 13.3091 13.651 13.6774 14.0156C14.0457 14.3802 14.4884 14.5625 15.0055 14.5625ZM15 24.7188C17.2917 22.7604 19.1406 20.6927 20.5469 18.5156C21.9531 16.3385 22.6562 14.4688 22.6562 12.9063C22.6562 10.6146 21.9323 8.71875 20.4844 7.21875C19.0365 5.71875 17.2083 4.96875 15 4.96875C12.7917 4.96875 10.9635 5.71875 9.51562 7.21875C8.06771 8.71875 7.34375 10.6146 7.34375 12.9063C7.34375 14.4688 8.04688 16.3385 9.45312 18.5156C10.8594 20.6927 12.7083 22.7604 15 24.7188ZM15 25.9063C12.1875 23.3854 10.0625 21.0313 8.625 18.8438C7.1875 16.6563 6.46875 14.6771 6.46875 12.9063C6.46875 10.4063 7.28646 8.31771 8.92188 6.64063C10.5573 4.96354 12.5833 4.125 15 4.125C17.4167 4.125 19.4427 4.96354 21.0781 6.64063C22.7135 8.31771 23.5312 10.4063 23.5312 12.9063C23.5312 14.6771 22.8125 16.6563 21.375 18.8438C19.9375 21.0313 17.8125 23.3854 15 25.9063Z" fill="black" />
        </svg>
    )
}

export const MapPinIconActive = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0055 14.5625C15.5227 14.5625 15.9635 14.3784 16.3281 14.0101C16.6927 13.6418 16.875 13.1991 16.875 12.682C16.875 12.1648 16.6909 11.724 16.3226 11.3594C15.9543 10.9948 15.5116 10.8125 14.9945 10.8125C14.4773 10.8125 14.0365 10.9966 13.6719 11.3649C13.3073 11.7332 13.125 12.1759 13.125 12.693C13.125 13.2102 13.3091 13.651 13.6774 14.0156C14.0457 14.3802 14.4884 14.5625 15.0055 14.5625ZM15 25.9063C12.1875 23.3854 10.0625 21.0313 8.625 18.8438C7.1875 16.6563 6.46875 14.6771 6.46875 12.9063C6.46875 10.4063 7.28646 8.31771 8.92188 6.64063C10.5573 4.96354 12.5833 4.125 15 4.125C17.4167 4.125 19.4427 4.96354 21.0781 6.64063C22.7135 8.31771 23.5312 10.4063 23.5312 12.9063C23.5312 14.6771 22.8125 16.6563 21.375 18.8438C19.9375 21.0313 17.8125 23.3854 15 25.9063Z" fill="#D9981D" />
        </svg>
    )
}