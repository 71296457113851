export const WorkAffinityIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1191_131)">
                <circle cx="15" cy="15" r="15" fill="#1A1C20" />
                <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM12.33 7.88C12.33 7.76359 12.3531 7.64834 12.3979 7.54091C12.4428 7.43349 12.5085 7.33603 12.5913 7.25418C12.6741 7.17234 12.7723 7.10773 12.8802 7.06409C12.9881 7.02046 13.1036 6.99868 13.22 7H16.78C16.8964 6.99868 17.0119 7.02046 17.1198 7.06409C17.2278 7.10773 17.326 7.17234 17.4087 7.25418C17.4915 7.33603 17.5572 7.43349 17.6021 7.54091C17.6469 7.64834 17.67 7.76359 17.67 7.88V8.76H16.78V7.88H13.22V8.76H12.33V7.88ZM23 21.11C23 21.346 22.9062 21.5724 22.7393 21.7393C22.5724 21.9062 22.3461 22 22.11 22H7.89001C7.65396 22 7.42759 21.9062 7.26068 21.7393C7.09377 21.5724 7.00001 21.346 7.00001 21.11V17.59H13.22V18.47H16.78V17.59H23V21.11ZM23 16.71H16.78V15.82H13.22V16.71H7.00001V10.53C7 10.4136 7.02309 10.2983 7.06794 10.1909C7.11278 10.0835 7.1785 9.98603 7.26128 9.90418C7.34406 9.82234 7.44225 9.75773 7.55017 9.71409C7.6581 9.67046 7.7736 9.64868 7.89001 9.65H22.11C22.2264 9.64868 22.3419 9.67046 22.4498 9.71409C22.5578 9.75773 22.656 9.82234 22.7387 9.90418C22.8215 9.98603 22.8872 10.0835 22.9321 10.1909C22.9769 10.2983 23 10.4136 23 10.53V16.71Z" fill="#D9981D" />
            </g>
            <defs>
                <clipPath id="clip0_1191_131">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
};

export const TagCategoryAffinityIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1191_185)">
                <circle cx="15" cy="15" r="15" fill="#1A1C20" />
                <path d="M17.6501 17.1204C17.4837 17.0279 17.2885 17.0012 17.1034 17.0456C16.9182 17.0901 16.7564 17.2024 16.6501 17.3604C16.4805 17.6343 16.2433 17.86 15.9614 18.0158C15.6795 18.1717 15.3622 18.2525 15.0401 18.2504C14.7168 18.2513 14.3986 18.1701 14.1153 18.0145C13.8319 17.8588 13.5928 17.6338 13.4201 17.3604C13.373 17.2668 13.3069 17.1841 13.2259 17.1176C13.1449 17.0511 13.0508 17.0023 12.9498 16.9744C12.8488 16.9464 12.743 16.94 12.6394 16.9555C12.5357 16.9709 12.4365 17.0079 12.348 17.0641C12.2595 17.1203 12.1838 17.1944 12.1257 17.2816C12.0676 17.3688 12.0285 17.4673 12.0108 17.5706C11.9931 17.6739 11.9972 17.7797 12.0229 17.8813C12.0487 17.9829 12.0954 18.078 12.1601 18.1604C12.4661 18.648 12.8908 19.05 13.3945 19.3286C13.8983 19.6072 14.4645 19.7533 15.0401 19.7533C15.6158 19.7533 16.182 19.6072 16.6857 19.3286C17.1894 19.05 17.6142 18.648 17.9201 18.1604C18.0222 17.9867 18.051 17.7796 18.0004 17.5845C17.9498 17.3895 17.8238 17.2226 17.6501 17.1204Z" fill="#D9981D" />
                <path d="M12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z" fill="#D9981D" />
                <path d="M18 16C18.5523 16 19 15.5523 19 15C19 14.4477 18.5523 14 18 14C17.4477 14 17 14.4477 17 15C17 15.5523 17.4477 16 18 16Z" fill="#D9981D" />
                <path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649929 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM15 23C13.4178 23 11.871 22.5308 10.5554 21.6518C9.23985 20.7727 8.21447 19.5233 7.60897 18.0615C7.00347 16.5997 6.84504 14.9911 7.15372 13.4393C7.46241 11.8874 8.22433 10.462 9.34315 9.34314C10.462 8.22432 11.8874 7.4624 13.4393 7.15372C14.9911 6.84504 16.5997 7.00346 18.0615 7.60896C19.5233 8.21446 20.7727 9.23984 21.6518 10.5554C22.5308 11.871 23 13.4177 23 15C23 17.1217 22.1572 19.1566 20.6569 20.6569C19.1566 22.1571 17.1217 23 15 23Z" fill="#D9981D" />
            </g>
            <defs>
                <clipPath id="clip0_1191_185">
                    <rect width="30" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export const WorkAffinityIconXL = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1191_891)">
                <circle cx="30" cy="30" r="30" fill="#1A1C20" />
                <path d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35235 4.55426 13.0377 2.28363 18.5195C0.0129986 24.0013 -0.581102 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4236C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4457 51.6477 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.1607 37.9565 0 30 0ZM24.66 15.76C24.66 15.5272 24.7062 15.2967 24.7959 15.0818C24.8856 14.867 25.017 14.6721 25.1826 14.5084C25.3481 14.3447 25.5445 14.2154 25.7603 14.1282C25.9762 14.0409 26.2072 13.9974 26.44 14H33.56C33.7928 13.9974 34.0238 14.0409 34.2397 14.1282C34.4555 14.2154 34.6519 14.3447 34.8175 14.5084C34.983 14.6721 35.1145 14.867 35.2042 15.0818C35.2938 15.2967 35.34 15.5272 35.34 15.76V17.52H33.56V15.76H26.44V17.52H24.66V15.76ZM46 42.22C46 42.6921 45.8125 43.1448 45.4787 43.4786C45.1448 43.8125 44.6921 44 44.22 44H15.78C15.3079 44 14.8552 43.8125 14.5214 43.4786C14.1875 43.1448 14 42.6921 14 42.22V35.18H26.44V36.94H33.56V35.18H46V42.22ZM46 33.42H33.56V31.64H26.44V33.42H14V21.06C14 20.8272 14.0462 20.5967 14.1359 20.3818C14.2256 20.167 14.357 19.9721 14.5226 19.8084C14.6881 19.6447 14.8845 19.5154 15.1003 19.4282C15.3162 19.3409 15.5472 19.2974 15.78 19.3H44.22C44.4528 19.2974 44.6838 19.3409 44.8997 19.4282C45.1155 19.5154 45.3119 19.6447 45.4775 19.8084C45.643 19.9721 45.7745 20.167 45.8642 20.3818C45.9538 20.5967 46 20.8272 46 21.06V33.42Z" fill="#D9981D" />
            </g>
            <defs>
                <clipPath id="clip0_1191_891">
                    <rect width="60" height="60" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
};

export const TagCategoryAffinityIconXL = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1191_894)">
                <circle cx="30" cy="30" r="30" fill="#1A1C20" />
                <path d="M35.3002 34.2409C34.9673 34.0557 34.5771 34.0023 34.2067 34.0912C33.8363 34.1801 33.5128 34.4048 33.3002 34.7209C32.961 35.2686 32.4867 35.7199 31.9228 36.0316C31.359 36.3433 30.7245 36.5049 30.0802 36.5009C29.4337 36.5026 28.7972 36.3402 28.2306 36.0289C27.6639 35.7176 27.1855 35.2675 26.8402 34.7209C26.7461 34.5336 26.6138 34.3681 26.4517 34.2351C26.2897 34.1021 26.1016 34.0046 25.8996 33.9487C25.6976 33.8929 25.4861 33.88 25.2788 33.9109C25.0715 33.9418 24.8729 34.0159 24.696 34.1282C24.519 34.2406 24.3676 34.3887 24.2514 34.5632C24.1353 34.7377 24.057 34.9346 24.0216 35.1412C23.9861 35.3478 23.9944 35.5595 24.0459 35.7627C24.0973 35.9659 24.1908 36.156 24.3202 36.3209C24.9321 37.2961 25.7816 38.1 26.7891 38.6572C27.7965 39.2144 28.9289 39.5067 30.0802 39.5067C31.2315 39.5067 32.3639 39.2144 33.3714 38.6572C34.3788 38.1 35.2283 37.2961 35.8402 36.3209C36.0443 35.9734 36.1021 35.5591 36.0008 35.1691C35.8996 34.7791 35.6476 34.4452 35.3002 34.2409Z" fill="#D9981D" />
                <path d="M24 32C25.1046 32 26 31.1046 26 30C26 28.8954 25.1046 28 24 28C22.8954 28 22 28.8954 22 30C22 31.1046 22.8954 32 24 32Z" fill="#D9981D" />
                <path d="M36 32C37.1046 32 38 31.1046 38 30C38 28.8954 37.1046 28 36 28C34.8954 28 34 28.8954 34 30C34 31.1046 34.8954 32 36 32Z" fill="#D9981D" />
                <path d="M30 0C24.0666 0 18.2664 1.75947 13.3329 5.05591C8.39943 8.35235 4.55426 13.0377 2.28363 18.5195C0.0129986 24.0013 -0.581102 30.0333 0.576455 35.8527C1.73401 41.6721 4.59123 47.0176 8.78681 51.2132C12.9824 55.4088 18.3279 58.266 24.1473 59.4236C29.9667 60.5811 35.9987 59.987 41.4805 57.7164C46.9623 55.4458 51.6477 51.6006 54.9441 46.6671C58.2405 41.7336 60 35.9334 60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.16071 37.9565 0 30 0ZM30 46C26.8355 46 23.7421 45.0616 21.1109 43.3035C18.4797 41.5454 16.4289 39.0466 15.2179 36.1229C14.0069 33.1993 13.6901 29.9823 14.3074 26.8786C14.9248 23.7749 16.4487 20.9239 18.6863 18.6863C20.9239 16.4487 23.7749 14.9248 26.8786 14.3074C29.9823 13.6901 33.1993 14.0069 36.123 15.2179C39.0466 16.4289 41.5454 18.4797 43.3035 21.1109C45.0616 23.7421 46 26.8355 46 30C46 34.2435 44.3143 38.3131 41.3137 41.3137C38.3131 44.3143 34.2435 46 30 46Z" fill="#D9981D" />
            </g>
            <defs>
                <clipPath id="clip0_1191_894">
                    <rect width="60" height="60" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
