import { Link, Navigate, Outlet, useLoaderData, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { IUser } from "../../utils/interfaces/db_models/user.interface";
import useCookie from "../../hooks/useCookie";
import { USER_ROLES } from "../../utils/enums/user_roles.enum";
import { HomeIcon, HomeIconActive } from "../../utils/icons/HomeIcon";
import { ColivingIcon, ColivingIconActive } from "../../utils/icons/ColivingIcon";
import { UserIcon, UserIconActive } from "../../utils/icons/UserIcon";
import useIsOpen from "../../hooks/useIsOpen";
import { AdminPanelIcon, AdminPanelIconActive } from "../../utils/icons/AdminPanelIcon";
import { HttpMethod, apiService } from "../../api/apiService";
import { useEffect } from "react";
import { replaceUser } from "../../redux/auth/authSlice";
import { PrivacyIcon } from "../../utils/icons/PrivacyIcon";
import { SupportIcon } from "../../utils/icons/SupportIcon";
import LoadingIndicator from "../../components/LoadingIndicator";
import { ConnivioLogo } from "../../utils/icons/ConnivioLogo";
import useScreenSize from "../../hooks/useScreenSize";
import { BurgerMenuIcon } from "../../utils/icons/BurgerMenuIcon";
import i18n from './../../i18n';
import LanguagesMenu from "../../components/LanguagesMenu";

export const AdminLayout = () => {
    const response = useLoaderData() as IUser;
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.auth) as { user: IUser };
    const { cookieData, removeCookie, setCookie } = useCookie({ accessToken: 'accessToken', refreshToken: 'refreshToken', structure: 'structure', admin: 'admin' });
    const { isOpen, setIsOpen, ref } = useIsOpen(false);
    const { isOpen: burgerOpen, setIsOpen: setBurgerOpen, ref: burgerRef } = useIsOpen(false);
    const screenSize = useScreenSize();
    const location = useLocation();
    const navigate = useNavigate();
    const navigation = useNavigation();

    useEffect(() => {
        const element = document.querySelector('.iubenda-tp-btn.iubenda-cs-preferences-link');
        if (element)
            document.body.removeChild(element)
    }, []);

    useEffect(() => {
        if (response) {
            dispatch(replaceUser(response));
        } else {
            removeCookie('refreshToken');
            removeCookie('accessToken');
            removeCookie('admin');
            dispatch(replaceUser(undefined));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, response]);

    if (!cookieData.refreshToken) {
        return <Navigate to="/" />
    } else if (!cookieData.admin || response.role !== USER_ROLES.ADMIN) {
        return <Navigate to="/app" />
    }

    const logout = async () => {
        try {
            const response = await apiService(HttpMethod.POST, '/users/logout', undefined, undefined, { isRefreshToken: true });
            if (response.modifiedCount) {
                removeCookie('accessToken');
                removeCookie('refreshToken');
                removeCookie('structure');
                navigate('/', { replace: true });
            }
            return response;
        } catch (error) {
            throw error
        }
    };

    return (
        <div className="flex flex-col h-full w-full text-light_black">
            <div>
                <div className='w-full h-16 p-2 px-6 flex justify-between bg-white shadow items-center z-20 relative'>

                    {(screenSize === 'xs' || screenSize === 'sm') &&
                        <div onClick={() => setBurgerOpen(!burgerOpen)} className='cursor-pointer' >
                            <BurgerMenuIcon />
                        </div>
                    }

                    {burgerOpen && (
                        <div ref={burgerRef} className="absolute top-full right-0 p-4 px-6 bg-white rounded-b shadow flex flex-col gap-8 w-full font-semibold text-xl md:text-sm">

                            <Link to="/admin" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname === '/admin' && 'text-orange'}`}>
                                {location.pathname === '/admin' ? <HomeIconActive /> : <HomeIcon />}
                                <p>{i18n.t('home')}</p>
                            </Link>

                            <Link to="structures" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/admin/structures') && 'text-orange'}`}>
                                {location.pathname.startsWith('/admin/structures') ? <ColivingIconActive /> : <ColivingIcon />}
                                <p>{i18n.t('accommodations')}</p>
                            </Link>

                            <Link to="users" onClick={() => setBurgerOpen(false)} className={`flex gap-4 items-center ${location.pathname.startsWith('/admin/users') && 'text-orange'}`}>
                                {location.pathname.startsWith('/admin/users') ? <UserIconActive /> : <UserIcon />}
                                <p>{i18n.t('users')}</p>
                            </Link>


                        </div>
                    )}

                    <div onClick={() => removeCookie('admin')} className='cursor-pointer'>
                        <ConnivioLogo />
                    </div>

                    <div className='flex justify-end items-center gap-6 md:w-full'>
                        {(screenSize !== 'xs' && screenSize !== 'sm') &&
                            <>  <Link to="/admin">
                                {location.pathname === '/admin' ? <HomeIconActive /> : <HomeIcon />}
                            </Link>

                                <Link to="structures">
                                    {location.pathname.startsWith('/admin/structures') ? <ColivingIconActive /> : <ColivingIcon />}
                                </Link>

                                <Link to="users">
                                    {location.pathname.startsWith('/admin/users') ? <UserIconActive /> : <UserIcon />}
                                </Link>
                            </>
                        }

                        {!!user && <div ref={ref} className="flex">
                            <div onClick={() => setIsOpen(!isOpen)} className='cursor-pointer'>
                                <AdminPanelIcon />
                            </div>

                            {isOpen && (
                                <div className="absolute top-full right-0 p-4 px-6 bg-white rounded-bl shadow flex flex-col gap-4 w-full md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6" style={{ boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1), -1px 0px 0px rgba(0, 0, 0, 0.1), 1px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px' }}>

                                    <div className='flex items-center justify-between gap-6'>
                                        <div className='flex items-center gap-4 cursor-pointer' onClick={() => removeCookie('admin')}>
                                            <img alt={'profile_' + user.name} src={user.profile_image} style={{ height: 30, width: 30 }} className='aspect-square rounded-full object-cover' />
                                            <p className='font-semibold text-sm line-clamp-1'>{i18n.t('user_profile')}</p>
                                        </div>
                                    </div>

                                    {user?.structureUsers?.map((structureUser, index) => {
                                        if (!structureUser.structure.isDisabled) {
                                            return <div
                                                key={index}
                                                className={`flex items-center justify-between gap-6 cursor-pointer`}
                                                onClick={() => {
                                                    removeCookie('admin');
                                                    setCookie('structure', structureUser.structure.id.toString(), { isPayload: false });
                                                }}
                                            >
                                                <div className='flex items-center gap-4'>
                                                    <img alt={'logo_' + structureUser.structure.name} src={structureUser.structure.logo_image} style={{ height: 30, width: 30 }} className='aspect-square rounded-full object-cover' />
                                                    <p className='font-semibold text-sm line-clamp-1'>{structureUser.structure.name}</p>
                                                </div>
                                            </div>
                                        } else {
                                            return null
                                        }
                                    })}

                                    <div className='flex items-center gap-4 cursor-pointer text-orange'>
                                        <AdminPanelIconActive />
                                        <p className='font-semibold text-sm line-clamp-1'>{i18n.t('administration')}</p>
                                    </div>

                                    <LanguagesMenu />

                                    <div className='border-b border-gray-300' />

                                    {/* <div className='flex items-center gap-4'>
                                    <PrivacyIcon />
                                    <p className='text-sm font-semibold'>Sicurezza e privacy</p>
                                </div>

                                <div className='flex items-center gap-4'>
                                    <SupportIcon />
                                    <p className='text-sm font-semibold'>Assistenza e supporto</p>
                                </div>

                                <div className='border-b border-gray-300' /> */}

                                    <button onClick={logout} className='bg-light_black text-white text-xl font-semibold rounded-full w-fit px-4 py-1 flex self-center'>
                                        {i18n.t('logout')}
                                    </button>
                                </div>
                            )}

                        </div>
                        }
                    </div>
                </div>
                <div className='w-full bg-light_black'>
                    <p className='font-medium text-xs text-white_shady text-center py-1'>{i18n.t('administration')}</p>
                </div>
            </div>


            <div className={`h-full w-full overflow-y-auto overflow-x-hidden ${(isOpen || burgerOpen) && "blur md:blur-none"}`}>
                <div className='flex w-full h-full container mx-auto'>
                    {navigation.state === 'loading'
                        ? <LoadingIndicator icon additionalStyle='h-full' />
                        : <Outlet />
                    }
                </div>
            </div>
        </div>
    )
}