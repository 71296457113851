import i18n from './../i18n';

interface IProps {
    value: any;
    setValue: (arg0: any) => void;
    options: { label: string; value: any; }[];
    name?: string;
    className?: string;
    disabled?: boolean | undefined
}

const SelectForm: React.FC<IProps> = ({ value, setValue, options, name, className, disabled }) => {
    return (
        <div className={`flex relative ${className}`}>
            <select
                name={name}
                disabled={disabled}
                value={value ?? ''}
                onChange={(e) => setValue(e.target.value)}
                className="border-b-2 border-light_black py-1 overflow-hidden font-medium focus:outline-none bg-transparent w-full"
            >
                <option value="">{i18n.t('select')}</option>
                {options.map(({ label, value }, i) => (
                    <option key={i} value={value}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default SelectForm