import React, { ReactNode, useRef, useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/cropUtils';
import i18n from './../i18n';

interface CropImageProps {
    onImageChange: (newImageSrc: string | null) => void;
    children: ReactNode;
    name: string;
    rounded?: boolean;
    maxWidth?: number
    maxHeight?: number;
}

const CropImage: React.FC<CropImageProps> = ({ onImageChange, children, name, rounded = false, maxHeight = 1080, maxWidth = 1920 }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
    const [fileName, setfileName] = useState('');

    const inputRef = useRef<HTMLInputElement | null>(null);

    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        console.log("🚀 ~ onCropComplete ~ croppedAreaPixels:", croppedAreaPixels)
        console.log("🚀 ~ onCropComplete ~ croppedArea:", croppedArea)
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const getImageBlobFromUrl = async (imageUrl: string): Promise<Blob> => {
        const response = await fetch(imageUrl);

        if (!response.ok) {
            throw new Error('Failed to load image');
        }

        return await response.blob();
    };

    const showCroppedImage = async () => {
        if (imageSrc)
            try {
                const cropUrl = await getCroppedImg(imageSrc, croppedAreaPixels, maxWidth, maxHeight);

                if (cropUrl) {
                    setImageSrc(null)
                    onImageChange(cropUrl);

                    const blob = await getImageBlobFromUrl(cropUrl);

                    if (inputRef.current) {
                        const file = new File([blob], fileName, { type: blob.type });
                        const fileList = new DataTransfer();
                        fileList.items.add(file);
                        inputRef.current.files = fileList.files;
                    }
                }
            } catch (e) {
                throw e;
            }
    };

    const readFile = (file: File): Promise<string> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result as string), false);
            reader.readAsDataURL(file);
        });
    }

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);

            const image = new Image();
            image.onload = () => {
                setfileName(file.name);
                setImageSrc(imageDataUrl);
            };
            image.src = imageDataUrl;
        }
    };


    const handleButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
        }

        setImageSrc(null);
    };

    return (
        <div>
            {imageSrc ? (
                <div className="absolute inset-0 flex items-center justify-center px-8 py-10 z-50">
                    <div className="fixed inset-0 bg-light_black opacity-30" />
                    <div className="bg-white p-4 rounded-lg z-50 h-fit flex flex-col gap-6 overflow-auto container w-full select-none">
                        <div className="relative w-full h-52 sm:h-96">
                            {rounded
                                ? <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    cropShape="round"
                                    showGrid={false}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    cropSize={{ width: 200, height: 200 }}
                                />
                                : <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    aspect={16 / 9}
                                    cropShape={undefined}
                                    showGrid={true}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                />
                            }
                        </div>

                        <div className='flex w-full h-full items-center justify-end gap-4 text-center'>
                            <button
                                type={'button'}
                                onClick={handleButtonClick}
                                className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
                            >
                                {i18n.t('cancel')}
                            </button>
                            <button
                                type={'button'}
                                onClick={showCroppedImage}
                                className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                            >
                                {i18n.t('done')}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                null
            )}

            <input type="file" ref={inputRef} name={name} id={name} className="hidden" accept="image/jpeg, image/png, image/webp" onChange={onFileChange} />
            <div className='flex flex-col justify-center items-center gap-1 w-full'>
                <p className='text-center font-medium text-xs text-gray-400'>{i18n.t("recommended_min_dimensions")}: <strong>{maxWidth}x{maxHeight}</strong></p>
                {children}
            </div>
        </div>
    );
};

export default CropImage;
