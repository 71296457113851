import { useNavigate, useRouteError } from "react-router-dom";
import Modal from "../../components/Modal";
import { getErrorMessage } from "../../utils/manageError";
import i18n from './../../i18n';

const ErrorPage = () => {
    const error: any = useRouteError();
    const navigate = useNavigate();

    return (
        <Modal isOpen={true}>
            <div className="flex flex-col gap-4">
                <p className="text-3xl font-semibold">{i18n.t('unexpected_error')}</p>
                {!!error?.response?.data?.errorCode
                    ? <p>{getErrorMessage(error.response.data.errorCode)}</p>
                    : <p className="w-full text-center">{i18n.t('unexpected_error')}</p>
                }
                <button className="text-lg font-medium" onClick={() => navigate(-1)}>{i18n.t('go_back')}</button>
            </div>
        </Modal>
    );
}

export default ErrorPage