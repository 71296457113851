import { Form, useNavigation } from 'react-router-dom';
import { IChatRoom } from '../utils/interfaces/db_models/chatRoom.interface';
import { useAppSelector } from '../redux/store';
import { IUser } from '../utils/interfaces/db_models/user.interface';
import { CHAT_ROOM_TYPE } from '../utils/enums/chat_room_type.enum';
import { ChatIcon } from '../utils/icons/ChatIcon';
import i18n from './../i18n';

interface IProps {
    chatRoom: IChatRoom;
};

const ChatRoomInfo: React.FC<IProps> = ({ chatRoom }) => {
    const { user } = useAppSelector(store => store.auth) as { user: IUser };
    const navigation = useNavigation();

    return (
        <div className={`flex flex-col items-center gap-4 w-full py-4 -mb-4 custom-scrollbar ${navigation.state === 'submitting' && 'cursor-progress'}`}>

            <img
                alt='img-profile'
                className='h-24 w-24 rounded-full object-cover'
                src={chatRoom.type === CHAT_ROOM_TYPE.PRIVATE
                    ? chatRoom?.chatRoomMemberships?.find(chatRoomMembership => chatRoomMembership.user.id !== user?.id)?.user.profile_image
                    : chatRoom.structure.logo_image}
            />

            <div className='flex flex-col items-center w-full'>
                <p className='font-bold line-clamp-1 w-full'>
                    {!!chatRoom.title
                        ? chatRoom.title
                        : chatRoom.type === CHAT_ROOM_TYPE.PRIVATE
                            ? chatRoom?.chatRoomMemberships?.find(chatRoomMembership => chatRoomMembership.user.id !== user?.id)?.user?.name
                            : chatRoom.type === CHAT_ROOM_TYPE.GROUP &&
                            chatRoom.chatRoomMemberships.map(chatRoomMembership => chatRoomMembership.user?.name).join(', ')
                    }
                </p>

                <p className='text-sm w-full'>
                    {
                        !!chatRoom.description
                            ? chatRoom.description
                            : chatRoom.type === CHAT_ROOM_TYPE.PRIVATE
                                ? chatRoom?.chatRoomMemberships?.find(chatRoomMembership => chatRoomMembership.user.id !== user?.id)?.user.profession?.name
                                : chatRoom.type === CHAT_ROOM_TYPE.GROUP &&
                                'Gruppo'
                    }
                </p>
            </div>

            {chatRoom.type === CHAT_ROOM_TYPE.PRIVATE
                ? <div className='flex flex-col w-full px-4 gap-4'>
                    <div className='flex self-center border-t border-light_black w-full' />

                    {chatRoom.chatRoomMemberships[0].user.biography &&
                        <div className="flex flex-col w-full gap-1">
                            <p className='text-base font-bold'>{i18n.t('about_me')}</p>
                            <p className='text-xs font-medium break-words whitespace-pre-line'>{chatRoom?.chatRoomMemberships?.find(chatRoomMembership => chatRoomMembership.user.id !== user?.id)?.user.biography}</p>
                        </div>
                    }
                </div>
                : <div className='flex flex-col w-full gap-2'>
                    <p className='font-bold'>{i18n.t('participants')}</p>

                    {chatRoom.chatRoomMemberships.map((chatRoomMembership, index) => {
                        return <div key={index} className='flex gap-2 items-center'>
                            <img src={chatRoomMembership.user.profile_image} alt='img-profile' className='h-8 w-8 rounded-full object-cover' />
                            <div className='flex w-full justify-between items-center'>
                                <div className='flex flex-col'>
                                    <p className='text-sm font-bold line-clamp-1'>{chatRoomMembership?.user?.name}</p>
                                    <p className='text-xs line-clamp-1'>{chatRoomMembership?.user?.profession?.name}</p>
                                </div>
                                {chatRoomMembership.user.id !== user.id &&
                                    <Form method='post'>
                                        <input name="memberId" value={chatRoomMembership?.userId} readOnly hidden />
                                        <button disabled={navigation.state === 'submitting'} type='submit'>
                                            <ChatIcon />
                                        </button>
                                    </Form>
                                }
                            </div>
                        </div>
                    })}
                </div>
            }
        </div>
    )
}

export default ChatRoomInfo