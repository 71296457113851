export const ChatIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5 25V5H25V21H9L5 25ZM8.59 20H24V6H6V22.59L8.29 20.29L8.59 20ZM9 9H21V10H9V9ZM21 12H9V13H21V12ZM9 15H15V16H9V15Z" fill="#1A1C20" />
        </svg>
    )
}

export const ChatIconActive = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 5V25L9 21H25V5H5ZM15 16H9V15H15V16ZM21 13H9V12H21V13ZM21 10H9V9H21V10Z" fill="#D9981D" />
        </svg>
    )
}