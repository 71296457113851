import { useEffect, useState } from 'react';
import { Form, useLoaderData, useNavigate, useNavigation, useActionData, Navigate } from 'react-router-dom';
import { IStructure } from '../../utils/interfaces/db_models/structure.interface';
import CropImage from '../../components/CropImage';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getErrorMessage } from '../../utils/manageError';
import useIsOpen from '../../hooks/useIsOpen';
import { ITALIAN_REGIONS, italianRegionOptions } from '../../utils/enums/italian_regions.enum';
import SelectForm from '../../components/SelectForm';
import { StructureCoverImageIcon, StructureIconXl } from '../../utils/icons/StructureIcon';
import Modal from '../../components/Modal';
import { useAppSelector } from '../../redux/store';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { Places } from '../../components/PlacesAutocomplete';
import i18n from './../../i18n';

const ColivingManage = () => {
    const coliving = useLoaderData() as IStructure;
    const response = useActionData() as any;
    const user = useAppSelector(store => store.auth.user) as IUser;
    const navigate = useNavigate();
    const navigation = useNavigation();
    const { isOpen, setIsOpen } = useIsOpen(false);
    const { isOpen: backModal, setIsOpen: setBackModal, ref: backModalRef } = useIsOpen(false);

    const [selectedLogo, setSelectedLogo] = useState<string | null>(null);
    const [selectedCover, setSelectedCover] = useState<string | null>(null);
    const [autocompleteStatus, setAutocompleteStatus] = useState(false);

    useEffect(() => {
        if (!!selectedLogo)
            formik.setFieldValue('logo_image', { label: formik.values.logo_image.label, value: selectedLogo })
    }, [selectedLogo]);

    useEffect(() => {
        if (!!selectedCover)
            formik.setFieldValue('cover_image', { label: formik.values.cover_image.label, value: selectedCover })
    }, [selectedCover]);

    const handleCoverChange = (newImageSrc: string | null) => {
        setSelectedCover(newImageSrc);
    };

    const handleLogoChange = (newImageSrc: string | null) => {
        setSelectedLogo(newImageSrc);
    };

    const formik = useFormik({
        initialValues: {
            name: { label: i18n.t('name'), value: coliving?.name || '' },
            email: { label: i18n.t('email'), value: coliving?.email || '' },
            phone_number: { label: i18n.t('phone_number'), value: coliving?.phone_number || '' },
            city: { label: i18n.t('city'), value: coliving?.city || '' },
            province: { label: i18n.t('province'), value: coliving?.province || '' },
            address: { label: i18n.t('address'), value: coliving?.address || '' },
            latitude: { label: i18n.t('latitude'), value: coliving?.latitude || undefined },
            longitude: { label: i18n.t('longitude'), value: coliving?.longitude || undefined },
            region: { label: i18n.t('region'), value: coliving?.region || '' },
            description: { label: i18n.t('description'), value: coliving?.description || '' },
            plain_services: { label: i18n.t('plain_services'), value: coliving?.plain_services || '' },
            logo_image: { label: i18n.t('logo'), value: coliving?.logo_image || '' },
            cover_image: { label: i18n.t('cover'), value: coliving?.cover_image || '' },
        },
        validationSchema: Yup.object({
            name: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            email: Yup.object({ value: Yup.string().email(i18n.t('invalid_email_address')).required(i18n.t('required_field')) }),
            phone_number: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            city: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            province: Yup.object({ value: Yup.string().min(2, i18n.t('minimum_characters', {number:'2'})).required(i18n.t('required_field')) }),
            address: Yup.object({ value: Yup.string().required(i18n.t('required_field')) }),
            latitude: Yup.object({ value: Yup.string() }),
            longitude: Yup.object({ value: Yup.string() }),
            region: Yup.object({ value: Yup.string().required(i18n.t('select_a_region')).oneOf(Object.values(ITALIAN_REGIONS), i18n.t('invalid_value')) }),
            description: Yup.object({ value: Yup.string().max(1000, i18n.t('must_contain_a_maximum', {number:'1000'})).required(i18n.t('enter_a_description_of_the_accommodation')) }),
            plain_services: Yup.object({ value: Yup.string().max(1000, i18n.t('must_contain_a_maximum', {number:'1000'})).required(i18n.t('enter_the_services_offered_by_the_accommodation')) }),
            logo_image: Yup.object({ value: Yup.string().required(i18n.t('upload_a_logo')) }),
            cover_image: Yup.object({ value: Yup.string().required(i18n.t('upload_a_cover_image_for_the_accommodation')) })
        }),
        onSubmit: () => { }
    });

    // this function gives the fields to the PlacesAutocomplete component to populate the fields
    const populateFields = (populatedFields: any) => {
        formik.setFieldValue('address', { ...formik.values.address, value: populatedFields.address });
        formik.setFieldValue('province', { ...formik.values.province, value: populatedFields.province });
        formik.setFieldValue('city', { ...formik.values.city, value: populatedFields.city });
        formik.setFieldValue('latitude', { ...formik.values.latitude, value: populatedFields.latitude });
        formik.setFieldValue('longitude', { ...formik.values.longitude, value: populatedFields.longitude });

        setAutocompleteStatus(true);
    }

    if (!!user && !user?.canCreateStructure) {
        return <Navigate to="/" />
    }

    return (
        <Form method='PUT' encType='multipart/form-data' className='flex flex-col gap-20 p-12 select-none m-auto'>

            {/* header */}
            <div className='flex flex-col gap-6 items-center w-full'>
                <p className='text-4xl md:text-6xl font-bold text-center'>
                    {!!coliving ? i18n.t('edit_accommodation') : i18n.t('add_accommodation')}
                </p>
                <p className='text-base md:text-xl font-medium text-center'>
                    {!!coliving
                        ? i18n.t('edit_the_accommodation_details')
                        : i18n.t('fill_in_all_the_required_details_to_add_a_new_accommodation')
                    }
                </p>
            </div>

            {/* logo form */}
            <div className='flex flex-col gap-6'>
                <CropImage onImageChange={handleLogoChange} name='logo_image' rounded={true} maxWidth={512} maxHeight={512}>
                    <label htmlFor="logo_image" className='cursor-pointer' >
                        <div className="relative w-fit m-auto">
                            {selectedLogo || coliving?.logo_image
                                ? <img
                                    className='h-36 md:h-56 object-cover aspect-square rounded-full'
                                    src={selectedLogo || coliving?.logo_image}
                                    alt='img_coliving'
                                />
                                : <StructureIconXl />
                            }

                            <p className='bg-light_black px-4 py-1 rounded-full text-white font-semibold text-sm md:text-xl absolute bottom-0 right-1/2 transform translate-x-1/2 translate-y-1/2 whitespace-nowrap'>
                                {i18n.t('change_logo')}
                            </p>
                        </div>
                    </label>
                </CropImage>

                <p className='font-regular text-sm md:text-md text-center mt-4' dangerouslySetInnerHTML={{__html:i18n.t('change_logo_info', {escapeValue:false})}}></p>

                {(!!formik.errors['logo_image' as keyof typeof formik.values] && isOpen) &&
                    <div className='text-red-500 font-medium text-center text-sm'>
                        {formik.errors['logo_image' as keyof typeof formik.values]?.value}
                    </div>}
            </div>

            <div className='w-full flex flex-col gap-12'>
                <div className='flex flex-col gap-6'>
                    <CropImage onImageChange={handleCoverChange} name='cover_image' rounded={false}>
                        <label htmlFor="cover_image" className='cursor-pointer w-full'>
                            <div className="relative w-full">
                                {selectedCover || coliving?.cover_image
                                    ? <img
                                        className='w-full aspect-video object-cover'
                                        src={selectedCover || coliving?.cover_image}
                                        alt='img_coliving'
                                    />
                                    : <div className='w-full aspect-video flex items-center justify-center' style={{ backgroundColor: '#F1F1F1' }}>
                                        <StructureCoverImageIcon />
                                    </div>
                                }
                                <p className='bg-light_black px-4 py-1 rounded-full text-white font-semibold text-sm md:text-xl absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 whitespace-nowrap'>
                                    {i18n.t('change_cover')}
                                </p>
                            </div>
                        </label>
                    </CropImage>


                    <p className='font-regular text-sm md:text-md text-center mt-4' dangerouslySetInnerHTML={{__html:i18n.t('add_the_cover_image_which_will_be_displayed_on', {escapeValue:false})}}></p>

                    {(!!formik.errors['cover_image' as keyof typeof formik.values] && isOpen) &&
                        <div className='text-red-500 font-medium text-center text-sm'>
                            {formik.errors['cover_image' as keyof typeof formik.values]?.value}
                        </div>}
                </div>


                {/* places form autocomplete input */}
                <Places populateFields={populateFields} status={autocompleteStatus} />
                <p className='font-regular text-sm md:text-md text-center mb-8' dangerouslySetInnerHTML={{__html:i18n.t('places_form_autocomplete_input_info_accommodation', {escapeValue:false})}}></p>

                <div className='flex flex-col md:grid md:grid-cols-2 gap-x-8 gap-y-4 w-full'>
                    {Object.entries(formik.values).map(([key, value]) => {
                        const errorValue = formik.errors[key as keyof typeof formik.values] as Record<string, string>;

                        if (key === 'logo_image' || key === 'cover_image') { return null }

                        return <div key={key} className={`flex flex-col ${(key === 'description' || key === 'region' || key === 'plain_services') && 'md:col-span-2 mt-8'}`}>
                            {key !== 'longitude' && key !== 'latitude' && <p className='text-base lg:text-lg font-medium'>{value.label}</p>}
                            {key === 'description' || key === 'plain_services'
                                ? <>
                                    <textarea
                                        name={key}
                                        maxLength={1000}
                                        rows={10}
                                        defaultValue={value.value}
                                        onChange={(e) => formik.setFieldValue(key, { ...value, value: e.target.value })}
                                        placeholder={key === 'description' ? i18n.t('write_a_description_of_your_accommodation') : i18n.t('describe_the_services_offered')}
                                        className='font-medium w-full h-full p-1 border-2 border-light_black focus:outline-none resize-none mt-4'
                                    />
                                    <div className='flex flex-col w-full items-end'>
                                        <p className='font-semibold text-lg'>{(typeof value.value === 'string') ? value.value.length : 0}/1000</p>
                                        <p className='font-semibold text-sm opacity-80'>{i18n.t('max_1000_characters')}</p>
                                    </div>
                                </>
                                : key === 'region'
                                    ? <SelectForm
                                        name='region'
                                        options={italianRegionOptions}
                                        value={value.value}
                                        setValue={(e) => formik.setFieldValue(key, { ...value, value: e })}
                                    />
                                    : (key === 'latitude' || key === 'longitude')
                                        ? <input
                                            hidden
                                            name={key}
                                            maxLength={30}
                                            defaultValue={value.value as string}
                                            onChange={(e) => {
                                                formik.setFieldValue(key, { ...value, value: e.target.value });
                                            }}
                                        />
                                        : <input
                                            disabled={!!coliving && key === 'email'}
                                            name={key}
                                            maxLength={key === 'province' ? 2 : undefined}
                                            defaultValue={value.value as string}
                                            className={`border-b-2 border-light_black p-1 overflow-hidden font-medium focus:outline-none disabled:opacity-50 ${key === 'province' && 'uppercase'}`}
                                            onChange={(e) => {
                                                const uppercaseValue = key === 'province' ? e.target.value.toUpperCase() : e.target.value;
                                                formik.setFieldValue(key, { ...value, value: uppercaseValue });
                                            }}
                                        />
                            }

                            {(!!errorValue && isOpen) &&
                                <div className='text-red-500 font-medium text-sm'>
                                    {errorValue.value}
                                </div>
                            }
                        </div>
                    })}

                    {!!coliving && <input hidden name='email' defaultValue={coliving.email} />}
                </div>
            </div>

            <div className='flex flex-col w-full md:col-span-3 gap-1'>
                <div className='md:col-span-3 flex w-full h-full items-center justify-between md:justify-end gap-4'>
                    <button
                        type={'button'}
                        onClick={() => setBackModal(true)}
                        disabled={navigation.state !== 'idle'}
                        className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl disabled:opacity-50'
                    >
                        {i18n.t('cancel')}
                    </button>
                    <button
                        type={Object.entries(formik.errors).length > 0 ? 'button' : 'submit'}
                        onClick={() => setIsOpen(true)}
                        disabled={navigation.state !== 'idle' || !formik.dirty || (!autocompleteStatus && !coliving)}
                        className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap disabled:opacity-50'
                    >
                        {i18n.t('save')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                    </button>
                </div>
                {response?.errorCode &&
                    <div className='flex flex-col items-center'>
                        <p className="text-red-500 text-base font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
                        <p className="text-red-500 text-xs font-medium">{i18n.t('unexpected_error')}: {response.message}</p>
                    </div>
                }
            </div>


            {(!!formik.errors['plain_services' as keyof typeof formik.values] && isOpen) &&
                <div className='flex justify-end w-full text-red-500 font-medium text-sm'>
                    <p>{formik.errors['plain_services' as keyof typeof formik.values]?.value}</p>
                </div>}

            <Modal isOpen={backModal} refs={backModalRef} wFull={false} className='w-fit md:w-72'>
                <div className='flex flex-col w-full h-full items-center justify-center gap-4 text-center'>
                    <p className='font-bold text-xl md:text-4xl'>{i18n.t('wait')}</p>
                    <p className='text-sm'>{i18n.t('changes_to_the_accommodation_will_be_lost')}</p>
                    <button
                        type={'button'}
                        onClick={() => setBackModal(false)}
                        className='px-4 py-1 rounded-full border border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
                    >
                        {i18n.t('cancel')}
                    </button>
                    <button
                        type={'button'}
                        onClick={() => navigate(-1)}
                        className='px-4 py-1 rounded-full bg-light_black text-white h-fit font-semibold text-sm md:text-xl whitespace-nowrap'
                    >
                        {i18n.t('proceed')}
                    </button>
                </div>
            </Modal>

        </Form>
    );
};

export default ColivingManage;
