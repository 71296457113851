import { useEffect, useState } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { IBooking, ListBookingInput } from '../../utils/interfaces/db_models/bookings.interface';
import { listItems } from '../../api/actions/genericActions';
import { Column, ColumnFiltersState, PaginationState, SortingState, createColumnHelper } from '@tanstack/react-table';
import DataTable from '../../components/DataTable';
import { BOOKING_STATUS, getBookingstatusColor, mapBookingStatus } from '../../utils/enums/booking_status.enum';
import { ListInput } from '../../utils/interfaces/listInput.interface';
import moment from 'moment';
import { AddIcon } from '../../utils/icons/AddIcon';
import useScreenSize from '../../hooks/useScreenSize';
import useCookie from '../../hooks/useCookie';
import i18n from './../../i18n';

const columnHelper = createColumnHelper<IBooking>()

const columns = [
    columnHelper.accessor('user.name', {
        id: 'user.name',
        header: i18n.t('name'),
        cell: info => info.renderValue(),
        meta: {
            filterComponent: (props: { column: Column<IBooking> }) =>
                <div className='flex items-center gap-4 w-full'>
                    <input
                        className='px-1 border-b text-lg font-medium border-gray-500 focus:outline-none w-full'
                        placeholder={i18n.t('search_by_name')}
                        type="search"
                        name="user.name"
                        onChange={(event) => props.column.setFilterValue(event.target.value)}
                    />
                </div>
        },
    }),
    columnHelper.accessor('user.email', {
        id: 'user.email',
        header: i18n.t('email'),
        cell: info => info.renderValue(),
        enableColumnFilter: false,
    }),
    columnHelper.accessor('user.phone', {
        id: 'user.phone',
        header: i18n.t('phone_number'),
        cell: info => info.renderValue(),
        enableColumnFilter: false,
    }),
    columnHelper.accessor('fromDate', {
        header: i18n.t('arrival_date'),
        cell: info => moment(info.renderValue()).format('YYYY-MM-DD'),
        enableColumnFilter: false,
        sortDescFirst: true
    }),
    columnHelper.accessor('toDate', {
        header: i18n.t('end_of_stay'),
        cell: info => moment(info.renderValue()).format('YYYY-MM-DD'),
        enableColumnFilter: false
    }),
    columnHelper.accessor('created_at', {
        header: i18n.t('booking'),
        cell: info => moment(info.renderValue()).format('YYYY-MM-DD'),
        enableColumnFilter: false
    }),
    columnHelper.accessor('status', {
        header: i18n.t('state'),
        cell: info => <div title={i18n.t(mapBookingStatus(String(info.renderValue())))} className='flex w-full h-full items-center justify-center'>
            <div className={`aspect-square rounded-full h-4 w-4 ${getBookingstatusColor(info.renderValue() || BOOKING_STATUS.PENDING)}`} />
        </div>,
        meta: {
            filterComponent: (props: { column: Column<IBooking> }) =>
                <div className='flex items-center gap-2'>
                    <button
                        className={`rounded-full px-4 py-1 font-semibold  ${props.column.getFilterValue() === BOOKING_STATUS.COMPLETED ? 'border border-orange bg-orange' : 'border border-light_black'}`}
                        onClick={() => {
                            props.column.setFilterValue(BOOKING_STATUS.COMPLETED)
                        }}
                    >
                        {i18n.t('past')}
                    </button>
                    <button
                        className={`rounded-full px-4 py-1  font-semibold ${props.column.getFilterValue() === BOOKING_STATUS.ON_GOING ? 'border border-orange bg-orange' : 'border border-light_black'}`}
                        onClick={() => {
                            props.column.setFilterValue(BOOKING_STATUS.ON_GOING)
                        }}
                    >
                        {i18n.t('current')}
                    </button>
                    <button
                        className={`rounded-full px-4 py-1 font-semibold  ${props.column.getFilterValue() === BOOKING_STATUS.PENDING ? 'border border-orange bg-orange' : 'border border-light_black'}`}
                        onClick={() => {
                            props.column.setFilterValue(BOOKING_STATUS.PENDING)
                        }}
                    >
                        {i18n.t('future')}
                    </button>
                    <button
                        className={`rounded-full px-4 py-1 font-semibold ${props.column.getFilterValue() === undefined ? 'border border-orange bg-orange' : 'border border-light_black'}`}
                        onClick={() => {
                            props.column.setFilterValue('')
                        }}
                    >
                        {i18n.t('all')}
                    </button>
                </div>
        },
        enableSorting: false
    })
]

const Bookings = () => {
    const { response } = useLoaderData() as { response: { data: IBooking[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number } };
    const navigate = useNavigate()
    const screenSize = useScreenSize();
    const { cookieData } = useCookie({ structure: 'structure' });

    const [bookings, setBookings] = useState<{ data: IBooking[], pageIndex: number, pageSize: number, totalItems: number, totalPages: number }>(response);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (response)
            setBookings(response);
    }, [response])

    const handleLoadBookings = async (pagination: PaginationState, sorting: SortingState, columnFilters: ColumnFiltersState) => {
        setIsLoading(true);
        const { response } = await listItems<ListInput<ListBookingInput>, IBooking, ListBookingInput>('/bookings/get-all', pagination, sorting, [...columnFilters, { id: 'structure_id', value: cookieData.structure }], { user: true });
        setBookings(response);
        setIsLoading(false);
    };

    const handleRowClick = (data: IBooking) => {
        navigate('manage/' + data.id);
    }

    return (
        <div className='flex flex-col gap-6 px-10 sm:px-14 py-6 w-full h-full select-none'>
            <div className='flex justify-between items-center'>
                <p className='font-bold text-3xl'>{i18n.t('bookings')}</p>
                <Link to={'create'} >
                    <div className='rounded-full'> <AddIcon /></div>
                </Link>
            </div>

            <div className="border-t shadow -mx-20" />

            <DataTable<IBooking>
                columns={
                    (screenSize === 'xs' || screenSize === 'sm')
                        ? [columns[0], columns[6]]
                        : (screenSize === 'md')
                            ? [columns[0], columns[2], columns[3], columns[4], columns[6]]
                            : columns
                }
                data={bookings?.data}
                pageIndexs={bookings.pageIndex}
                pageSizes={bookings.pageSize}
                pageCount={bookings.totalPages}
                totalItems={bookings.totalItems}
                handleNext={handleLoadBookings}
                isLoading={isLoading}
                noDataMessage={i18n.t('no_bookings')}
                onRowClick={handleRowClick}
            />
        </div >
    )
}

export default Bookings