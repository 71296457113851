import ReactDOM from 'react-dom/client';
import './index.css';
import '../src/utils/styles/scrollbar.css'
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(  
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>
);

serviceWorkerRegistration.register();

// Send a message to the service worker to skip waiting and activate the new version
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.controller?.postMessage({ type: 'SKIP_WAITING' });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
