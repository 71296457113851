import { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useActionData, useNavigate, useNavigation } from 'react-router-dom';
import { getErrorMessage } from '../../utils/manageError';
import { PasswordVisible, PasswordHidden } from '../../utils/icons/PasswordVisibility';
import i18n from './../../i18n';

const RegisterBusiness = () => {
    const navigation = useNavigation();
    const navigate = useNavigate();
    const response = useActionData() as any;

    const [showErrors, setShowErrors] = useState(false);

    const [passwordVisibility, setPasswordVisibility] = useState({
        password: false,
        passwordConfirm: false
    });

    const businessForm = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            city: '',
            province: '',
            cap_business: '',
            address_business: '',
            vat_business: '',
            company_name_business: '',
            unique_code_business: '',
            password: '',
            passwordConfirm: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(i18n.t('required_field')),
            phone: Yup.string().required(i18n.t('required_field')),
            email: Yup.string().email(i18n.t('invalid_email_address')).required(i18n.t('required_field')),
            city: Yup.string().required(i18n.t('required_field')),
            province: Yup.string().required(i18n.t('required_field')),
            cap_business: Yup.string().required(i18n.t('required_field')),
            address_business: Yup.string().required(i18n.t('required_field')),
            vat_business: Yup.string().required(i18n.t('required_field')),
            company_name_business: Yup.string().required(i18n.t('required_field')),
            unique_code_business: Yup.string().required(i18n.t('required_field')),
            password: Yup.string().required(i18n.t('required_field')).matches(/^(?=.*[A-Z])(?=.*\d).{8,}$/, i18n.t('policy_password_info')),
            passwordConfirm: Yup.string().oneOf([Yup.ref('password')], i18n.t('the_passwords_must_match')).required(i18n.t('required_field')),
        }),
        onSubmit: values => { },
    })

    const handleConfirm = () => {
        if (Object.entries(businessForm.errors).length > 0) {
            setShowErrors(true);
            return;
        }
    };


    return (
        <div className='h-full 2xl:h-screen flex flex-col items-center justify-center p-8 md:p-20 gap-12'>

            {/* form title */}
            <div className='flex flex-col items-center'>
                <p className='text-6xl font-bold mb-4'>{i18n.t('your_organization')}</p>
                <p className='text-xl'>{i18n.t('your_organization_info')}</p>
            </div>

            <div className='grid grid-cols-1 md:grid-cols-3 w-full gap-x-12 gap-y-6'>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('name')}</p>
                    <input
                        placeholder={i18n.t('enter_the_name_of_your_organization')}
                        type="text"
                        name="name"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.name}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.name}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('city')}</p>
                    <input
                        placeholder={i18n.t('enter_the_city_of_your_organization')}
                        type="text"
                        name="city"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.city}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.city}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('province')}</p>
                    <input
                        placeholder={i18n.t('enter_the_province_of_your_organization')}
                        type="text"
                        name="province"
                        maxLength={2}
                        onChange={businessForm.handleChange}
                        value={businessForm.values.province}
                        className="uppercase placeholder:normal-case border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.province}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('zip_code')}</p>
                    <input
                        placeholder={i18n.t('enter_the_zip_code_of_your_organization')}
                        type="text"
                        name="cap_business"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.cap_business}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.cap_business}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('address')}</p>
                    <input
                        placeholder={i18n.t('enter_the_address_of_your_organization')}
                        type="text"
                        name="address_business"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.address_business}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.address_business}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('phone_number')}</p>
                    <input
                        placeholder={i18n.t('enter_the_phone_number_of_your_organization')}
                        type="text"
                        name="phone"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.phone}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.phone}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('vat_number')}</p>
                    <input
                        placeholder={i18n.t('enter_the_vat_number_of_your_organization')}
                        type="text"
                        name="vat_business"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.vat_business}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.vat_business}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('business_name')}</p>
                    <input
                        placeholder={i18n.t('enter_the_business_name_of_your_organization')}
                        type="text"
                        name="company_name_business"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.company_name_business}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.company_name_business}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('sdi')}</p>
                    <input
                        placeholder={i18n.t('enter_the_unique_code_of_your_organization')}
                        type="text"
                        name="unique_code_business"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.unique_code_business}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.unique_code_business}</p>
                </div>
                <div>
                    <p className='font-semibold text-lg'>{i18n.t('email')}</p>
                    <input
                        placeholder={i18n.t('enter_the_email_of_your_organization')}
                        type="text"
                        name="email"
                        onChange={businessForm.handleChange}
                        value={businessForm.values.email}
                        className="border-b border-light_black py-2 w-full focus:outline-none"
                    />
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.email}</p>
                </div>
                <div>
                    <p className="font-semibold text-lg">{i18n.t('password')}</p>
                    <div className='flex justify-between items-center border-b border-light_black'>
                        <input
                            placeholder={i18n.t('enter_a_password_for_your_organization')}
                            type={passwordVisibility.password ? 'text' : 'password'}
                            name="password"
                            onChange={businessForm.handleChange}
                            value={businessForm.values.password}
                            className="py-2 w-full focus:outline-none"
                        />
                        <div onClick={() => setPasswordVisibility(
                            { ...passwordVisibility, password: !passwordVisibility.password }
                        )}>
                            {passwordVisibility.password ? <PasswordVisible /> : <PasswordHidden />}
                        </div>
                    </div>
                    <p className="text-red-500 text-sm mt-1">{(showErrors && !businessForm.errors.password?.startsWith(i18n.t('the_password_must'))) && businessForm.errors.password}</p>
                </div>
                <div>
                    <p className="font-semibold text-lg">{i18n.t('confirm_password')}</p>
                    <div className='flex justify-between items-center border-b border-light_black'>
                        <input
                            placeholder={i18n.t('confirm_password')}
                            type={passwordVisibility.passwordConfirm ? 'text' : 'password'}
                            name="passwordConfirm"
                            onChange={businessForm.handleChange}
                            value={businessForm.values.passwordConfirm}
                            className="py-2 w-full focus:outline-none"
                        />
                        <div onClick={() => setPasswordVisibility(
                            { ...passwordVisibility, passwordConfirm: !passwordVisibility.passwordConfirm }
                        )}>
                            {passwordVisibility.passwordConfirm ? <PasswordVisible /> : <PasswordHidden />}
                        </div>
                    </div>
                    <p className="text-red-500 text-sm mt-1">{showErrors && businessForm.errors.passwordConfirm}</p>
                </div>
            </div>

            <div className='flex flex-col gap-2 w-full -my-8'>
                <p className={`text-gray_shady text-left w-full text-xs ${(showErrors && businessForm.errors.password?.startsWith(i18n.t('the_password_must'))) && 'text-red-500'}`}>{i18n.t('policy_password_info')}</p>
                <p className="text-red-500 text-sm text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
            </div>

            {/* navigation buttons */}
            <div className='flex gap-4 justify-end w-full'>
                <button type="button"
                    className='border border-light_black rounded-full px-4 py-1 font-medium'
                    disabled={navigation.state !== 'idle'}
                    onClick={() => navigate(-1)}
                >
                    {i18n.t('cancel')}
                </button>
                <button
                    className="border border-light_black text-white bg-light_black rounded-full px-4 py-1 font-medium"
                    type={Object.entries(businessForm.errors).length > 0 ? 'button' : 'submit'}
                    disabled={navigation.state !== "idle" || !businessForm.dirty}
                    onClick={handleConfirm}
                >
                    {i18n.t('confirm')} {navigation.state !== 'idle' && <span className="animate-loading-dots"></span>}
                </button>
            </div>
        </div>
    )
}

export default RegisterBusiness
