import React, { SetStateAction } from 'react'
import { useLoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { SuccessIcon } from '../utils/icons/SuccessIcon';
import i18n from './../i18n';

// This component is a wrapper for the Google Maps Places API.
// It requires the REACT_APP_GOOGLE_MAPS_API_KEY environment variable to be set.
// After loading the script, it renders the PlacesAutocomplete component.
interface IPropsPlaces {
    populateFields: (populatedFields: IPopulatedFields) => void;
    setSearchString?: React.Dispatch<SetStateAction<string>>;
    status?: boolean;
}

export const Places: React.FC<IPropsPlaces> = ({ populateFields, setSearchString, status }) => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
        throw new Error('REACT_APP_GOOGLE_MAPS_API_KEY is not set');
    }

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places']
    });

    if (!isLoaded) return <div>'Loading...'</div>;
    return <PlacesAutocomplete populateFields={populateFields} setSearchString={setSearchString} statusAutoComplete={status} />
}

interface IPopulatedFields {
    address: string;
    province: string;
    city: string;
    latitude: number;
    longitude: number;
}

interface IProps {
    populateFields: (populatedFields: IPopulatedFields) => void;
    setSearchString?: React.Dispatch<SetStateAction<string>>;
    statusAutoComplete?: boolean;
}

// This component is a form input field that uses the Google Places API to autocomplete addresses.
const PlacesAutocomplete: React.FC<IProps> = ({ populateFields, setSearchString, statusAutoComplete }) => {

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions
    } = usePlacesAutocomplete();

    const handleSelect = async (v: string) => {
        setValue(v, false);
        clearSuggestions();

        const results = await getGeocode({ address: v });
        const { lat, lng } = getLatLng(results[0]);

        // format lat and lng to float
        const formattedLat = parseFloat(lat.toFixed(6));
        const formattedLng = parseFloat(lng.toFixed(6));

        const { address, city, province } = formatAddressData(v);

        const populatedFields: IPopulatedFields = {
            address: address,
            province: province,
            city: city,
            latitude: formattedLat,
            longitude: formattedLng
        }

        populateFields(populatedFields);

        if (setSearchString) {
            setSearchString(v);
        }
    }

    // this function formats the address data 
    // to retrieve the province, city, postal code, and region
    const formatAddressData = (value: string) => {
        // Navitas Coliving, Contrada Asola, Civitanova Marche, MC, Italia
        const addressArray = value.split(', ');
        const address = addressArray[1];
        const city = addressArray[2];
        const province = addressArray[3];

        return { address, city, province };
    }

    return (
        <div>
            {/* search bar */}
            <div className='flex flex-row items-center justify-center gap-3'>
                <p className='lg:text-2xl font-medium text-center'>
                    {i18n.t('position_search')}
                </p>
                {statusAutoComplete && <SuccessIcon />}
            </div>
            <input
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                disabled={!ready}
                placeholder={i18n.t('enter_the_location')}
                className='border-b-2 border-light_black p-2 w-full'
            />

            {/* suggestions container */}
            {status === 'OK' && data.map((suggestion) => (
                <div
                    className='border-b border-light_black p-2'
                    key={suggestion.place_id}
                    onClick={() => handleSelect(suggestion.description)}>
                    <p>{suggestion.description}</p>
                </div>
            ))
            }
        </div>
    )
}

export default PlacesAutocomplete