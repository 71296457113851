import { Form, Navigate, useActionData, useLoaderData, useNavigate, useNavigation } from 'react-router-dom';
import { IStructure } from '../../utils/interfaces/db_models/structure.interface';
import moment from 'moment';
import { useEffect } from 'react';
import { getErrorMessage } from '../../utils/manageError';
import { ConnivioError } from '../../utils/interfaces/connivioError.interface';
import LoadingIndicator from '../../components/LoadingIndicator';
import i18n from './../../i18n';

const BookingInvitation = () => {
    const navigate = useNavigate();
    const response = useActionData() as any;
    const navigation = useNavigation();
    const bookingInfo = useLoaderData() as {
        invitationInfo: {
            exp: number,
            fromDate: string,
            iat: number,
            room: string,
            structureId: string,
            toDate: string
        },
        structure: IStructure
    } | ConnivioError;

    const bookingToken = localStorage.getItem('bookingToken');

    const expirationDate = "invitationInfo" in bookingInfo ? new Date(bookingInfo.invitationInfo.exp * 1000) : new Date();

    useEffect(() => {
        if ("invitationInfo" in bookingInfo) {
            const expirationDate = moment.unix(bookingInfo.invitationInfo.exp);
            const currentDate = moment();

            if (expirationDate.isBefore(currentDate, 'day')) {
                alert(i18n.t('invitation_expired_message'));
                localStorage.removeItem('bookingToken');
            }
        }
    }, [bookingInfo]);

    useEffect(() => {
        if (response?.success) {
            navigate('/app/bookings', { replace: true });
        }
    }, [response, navigate]);

    const handleDeclineInvite = () => {
        localStorage.removeItem('bookingToken');
        navigate('/app', { replace: true });
    };

    if ("errorCode" in bookingInfo) {
        return <div className='flex flex-col gap-10 p-8 select-none h-full justify-center'>
            <div className='flex flex-col items-center gap-2'>
                <p className='text-4xl md:text-6xl font-bold text-center'>{i18n.t('unexpected_error')}</p>
                <p className='text-2xl font-medium text-center'>{i18n.t('link_not_valid_booking_invitation')}</p>
            </div>

            <div className='flex flex-col items-center text-red-500'>
                <p className="text-lg mt-1 text-center font-medium">{!!bookingInfo?.errorCode && getErrorMessage(bookingInfo.errorCode)}</p>
                <p className='text-sm font-medium text-center'>{bookingInfo.message}</p>
            </div>

            <button
                type='button'
                onClick={handleDeclineInvite}
                className='px-4 py-1 rounded-full border w-fit self-center border-light_black text-light_black h-fit font-semibold text-sm md:text-xl '
            >
                {i18n.t('go_back_to_home')}
            </button>
        </div>
    }

    if ("invitationInfo" in bookingInfo && !moment.unix(bookingInfo.invitationInfo.exp).isBefore(moment(), 'day')) {
        return (
            <Form method='POST' className="flex flex-col gap-10 p-8 select-none h-full justify-between">

                <div className="flex flex-col gap-8">
                    <div className='flex flex-col gap-6 items-center w-full'>
                        <p className='text-4xl md:text-6xl font-bold text-center'>
                            {i18n.t('booking_invitation')}
                        </p>
                        <p className='text-sm md:text-lg font-medium text-center' dangerouslySetInnerHTML={{__html:i18n.t('booking_invitation_message', {date: moment(expirationDate).format('YYYY-MM-DD'), escapeValue:false})}}></p>
                    </div>
                </div>

                <div className='flex flex-col gap-8 container m-auto'>
                    <div className="flex flex-col gap-4">
                        <p className="text-4xl font-bold">{i18n.t('information')}</p>
                        <div className="border-t border-light_black w-full" />
                        <div className="flex justify-between flex-col md:flex-row">
                            <div>
                                <p className="text-xl">{i18n.t('arrival_date')}</p>
                                <p className="text-3xl font-semibold">{moment(bookingInfo.invitationInfo.fromDate).format('YYYY-MM-DD')}</p>
                            </div>
                            <div className="md:text-right mt-4 md:mt-0">
                                <p className="text-xl">{i18n.t('end_of_stay')}</p>
                                <p className="text-3xl font-semibold">{moment(bookingInfo.invitationInfo.toDate).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between flex-col-reverse md:flex-row gap-10 md:items-center">
                        <div className="flex flex-col gap-4">
                            <p className="text-4xl font-bold">{i18n.t('contacts')}</p>
                            <div className="flex flex-col">
                                <p className="text-2xl font-medium">{i18n.t('email')}: <span className="text-lg">{bookingInfo.structure.email}</span></p>
                                <p className="text-2xl font-medium">{i18n.t('phone_number')}: <span className="text-lg">{bookingInfo.structure.phone_number}</span></p>
                                <p className="text-2xl font-medium">{i18n.t('address')}: <span className="text-lg">{bookingInfo.structure.city} ({bookingInfo.structure.province.toUpperCase()}), {bookingInfo.structure.address}</span></p>
                            </div>
                        </div>
                        <img
                            className="rounded-full aspect-square object-cover flex self-center w-32 md:w-40"
                            src={bookingInfo.structure.logo_image}
                            alt='img_coliving'
                        />
                    </div>
                </div>

                {/* FormData Input */}
                <>
                    <input hidden name='structureId' defaultValue={bookingInfo.structure.id} />
                    <input hidden name='fromDate' defaultValue={bookingInfo.invitationInfo.fromDate} />
                    <input hidden name='toDate' defaultValue={bookingInfo.invitationInfo.toDate} />
                    <input hidden name='room' defaultValue={bookingInfo.invitationInfo.room} />
                    <input hidden name='bookingToken' defaultValue={bookingToken ?? undefined} />
                </>

                {!!response?.errorCode
                    ? response?.errorCode === "BO005"
                        ? <p className="text-red-500 text-sm mt-1 text-center font-medium">{response?.message}</p>
                        : <p className="text-red-500 text-sm mt-1 text-center font-medium">{!!response?.errorCode && getErrorMessage(response.errorCode)}</p>
                    : null
                }

                <div className="flex justify-end gap-4 container m-auto">
                    {navigation.state === 'idle'
                        ? response?.errorCode === "BO005"
                            ? <button type='button' disabled={navigation.state !== 'idle'} className="border-light_black border text-xl font-semibold rounded-full w-fit px-4 py-1 flex self-center" onClick={handleDeclineInvite}>
                                {i18n.t('close')}
                            </button>
                            : <>
                                <button type='button' disabled={navigation.state !== 'idle'} className="border-light_black border text-xl font-semibold rounded-full w-fit px-4 py-1 flex self-center" onClick={handleDeclineInvite}>
                                    {i18n.t('decline')}
                                </button>
                                <button type='submit' disabled={navigation.state !== 'idle'} className="bg-orange text-light_black text-xl font-semibold rounded-full w-fit px-4 py-1 flex self-center">
                                    {i18n.t('accept')}
                                </button>
                            </>
                        : <LoadingIndicator label={i18n.t('wait_a_moment')} />}
                </div>


            </Form>
        )
    } else {
        localStorage.removeItem('bookingToken');
        return <Navigate to={'/app'} />
    }
}

export default BookingInvitation