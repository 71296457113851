import moment from "moment";
import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { IBooking } from "../../utils/interfaces/db_models/bookings.interface";
import { ArrowDown, ArrowUp } from "../../utils/icons/ArrowIcon";
import { WorkAffinityIcon, TagCategoryAffinityIcon, WorkAffinityIconXL, TagCategoryAffinityIconXL } from "../../utils/icons/AffinityIcon";
import useIsOpen from "../../hooks/useIsOpen";
import { useAppSelector } from "../../redux/store";
import { IUser } from "../../utils/interfaces/db_models/user.interface";
import useScreenSize from "../../hooks/useScreenSize";
import { tagCategoryAffinityPercentage, workAffinityPercentage } from "../../utils/affinityPercentuage";
import { useEffect, useState } from "react";
import { SuggestedActivityBox } from "./Coliving";
import Accordion from "../../components/Accordion";
import Modal from "../../components/Modal";
import i18n from './../../i18n';

const Booking = () => {
    const navigate = useNavigate();
    const user = useAppSelector(store => store.auth.user) as IUser;
    const booking = useLoaderData() as IBooking;
    const screenSize = useScreenSize();
    const { isOpen, setIsOpen } = useIsOpen();
    const { isOpen: accordionOpen, setIsOpen: setAccordionOpen, ref: accordionRef } = useIsOpen();


    const [activityAffinityCount, setActivityAffinityCount] = useState<number>(0);
    const [coliversAffinityCount, setColiversAffinityCount] = useState<number>(0);

    const totalDays = moment(booking.toDate).diff(moment(booking.fromDate), 'days');
    const daysPassed = moment().diff(moment(booking.fromDate), 'days');
    const progressPercentage = (daysPassed / totalDays) * 100;

    useEffect(() => {
        if (user && booking) {
            getActivityAffinityCount();
            getColiversAffinityCount();
        }
    }, [booking, user]);

    const getActivityAffinityCount = () => {
        let count = 0;
        const userTags = user?.tagCategories.map(tag => tag.id);
        const structureSuggestions = booking.structure.suggestions;

        structureSuggestions.forEach(suggestion => {
            const suggestionTags = suggestion.tagCategories.map(tag => tag.id);
            const commonTags = userTags.filter(tag => suggestionTags.includes(tag));

            if (commonTags.length > 0) {
                count++;
                suggestion.hasCommonTag = true;
            }
        });

        setActivityAffinityCount(count);
    };

    const getColiversAffinityCount = () => {
        let count = 0;
        const colivers = booking.structure.bookings.filter(booking => booking.user_id !== user?.id).map(booking => booking.user);

        colivers.forEach(coliver => {
            const workAffinity = workAffinityPercentage(user, coliver);
            const tagCategoryPercent = tagCategoryAffinityPercentage(user.tagCategories, coliver.tagCategories);

            if (workAffinity >= 33 || tagCategoryPercent >= 33) count++
        })

        setColiversAffinityCount(count);
    };

    const getBookingCount = (index: number) => {
        if (screenSize === 'md') {
            return index < 4 ? 4 : null;
        } else if (screenSize === 'lg') {
            return index < 5 ? 5 : null;
        } else if (screenSize === 'xl') {
            return index < 6 ? 6 : null;
        } else if (screenSize === '2xl') {
            return index < 10 ? 10 : null;
        } else {
            return index < 4 ? 4 : null;
        }
    };

    const maxBookingsToShow = Math.max(...[3, 4, 6, 10].map((count, index) => getBookingCount(index) || count));

    return (
        <div className="p-8 flex flex-col gap-8 z-10 select-none">

            <div className="flex items-center gap-4 flex-col-reverse md:flex-row">
                <div className="flex flex-col w-full gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="text-5xl font-bold">{booking.structure.name}</p>
                        <p className="text-lg font-medium">{booking.structure.city}</p>
                    </div>
                    <div className="flex flex-col">
                        {!!activityAffinityCount
                            ? <>
                                <p className="text-xl font-bold">{booking.structure.suggestions.length} {i18n.t('recommended_activities')}</p>
                                <p className="text-base font-medium text-orange">{i18n.t('affinity_activities', {activityAffinityCount: activityAffinityCount})}</p>
                            </>
                            : <p className="text-xl font-bold">{i18n.t('accommodation_no_activities_recommend')}</p>
                        }
                    </div>
                    {
                        booking.structure.suggestions.length > 0 &&
                        <button type="button" className="px-4 py-1 flex bg-light_black text-white w-fit rounded-full text-xl font-semibold" onClick={() => navigate("/app/itinerary/chatbot")}>{i18n.t('generate_itinerary')}</button>
                    }
                </div>
                <img
                    className="object-cover aspect-square rounded-full h-32 w-32 md:h-60 md:w-60"
                    src={booking.structure.logo_image}
                    alt='img_coliving'
                />
            </div>


            {(moment().isSameOrAfter(moment(booking.fromDate)) && moment().isBefore(moment(booking.toDate)))
                ? <div className="flex flex-col gap-2">
                    <div className="relative mt-4 h-4 rounded-full border-light_black border overflow-hidden">
                        <div
                            className="absolute left-0 top-0 h-full bg-light_black rounded-full transition-transform"
                            style={{ width: `${progressPercentage}%`, transform: 'scaleX(1.2)' }}
                        />
                    </div>

                    <div className="flex justify-between flex-col md:flex-row">
                        <div>
                            <p className="text-xl">{i18n.t('days_of_stay')}</p>
                            <p className="text-3xl font-semibold">{totalDays}</p>
                        </div>
                        <div className="md:text-right  mt-4 md:mt-0">
                            <p className="text-xl">{i18n.t('end_of_stay')}</p>
                            <p className="text-3xl font-semibold">{moment(booking.toDate).format('YYYY-MM-DD')}</p>
                        </div>
                    </div>
                </div>
                : <div className="flex flex-col gap-2">
                    <div className="border-t border-light_black h-0.5" />
                    <div className="flex justify-between flex-col md:flex-row">
                        <div>
                            <p className="text-xl">{i18n.t('booking_date')}</p>
                            <p className="text-3xl font-semibold">{moment(booking.created_at).format('YYYY-MM-DD')}</p>
                        </div>
                        <div className="md:text-right mt-4 md:mt-0">
                            <p className="text-xl">{i18n.t('arrival_date')}</p>
                            <p className="text-3xl font-semibold">{moment(booking.fromDate).format('YYYY-MM-DD')}</p>
                        </div>
                    </div>
                </div>
            }

            {!!booking.structure.bookings.filter(booking => booking.user_id !== user?.id).length && <div className="border-t shadow -mx-20" />}

            {!!booking.structure.bookings.filter(booking => booking.user_id !== user?.id).length &&
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-4 justify-between md:items-center">
                        <div className="flex justify-between w-full justify-items-center">
                            <p className="text-3xl font-bold">{i18n.t('colivers')}</p>
                            <button type='button' onClick={() => setAccordionOpen(true)} className="px-4 py-1 rounded-full bg-orange w-fit text-light_black font-medium">{i18n.t('affinity')}</button>
                        </div>
                        {!!coliversAffinityCount && <p className="font-medium text-orange truncate text-center md:text-right">{i18n.t('affinity_colivers', {coliversAffinityCount: coliversAffinityCount})}</p>}
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
                        {booking.structure.bookings.map((structureBooking, index) => {
                            let workPercent: number = 0;
                            let tagCategoryPercent: number = 0;

                            if (getBookingCount(index) === null && !isOpen) {
                                return null;
                            }

                            if (user && (user.id !== structureBooking.user_id)) {
                                workPercent = workAffinityPercentage(user, structureBooking.user);
                                tagCategoryPercent = tagCategoryAffinityPercentage(user.tagCategories, structureBooking.user.tagCategories);
                            }

                            return <Link key={index} to={'/app/user/' + structureBooking?.user?.id} className="flex flex-col items-center gap-2">
                                <div className={`relative h-32 w-32 md:h-48 md:w-48 rounded-full aspect-square border-half-orange shadow-md ${workPercent >= 33 || tagCategoryPercent >= 33 && "border-orange"}`} style={{ borderWidth: (workPercent >= 33 || tagCategoryPercent >= 33) ? '6px' : '' }} >
                                    <img
                                        className='w-full h-full object-cover overflow-hidden rounded-full'
                                        src={structureBooking.user.profile_image}
                                        alt='profile-img'
                                    />
                                    <div className="flex gap-2 absolute bottom-0 right-1/2 translate-y-1/2 translate-x-1/2">
                                        {workPercent >= 33 && <div className=""><WorkAffinityIcon /></div>}
                                        {tagCategoryPercent >= 33 && <div className=""><TagCategoryAffinityIcon /></div>}
                                    </div>
                                </div>
                                <p className="font-medium text-lg text-center truncate w-full">{structureBooking.user.name}</p>
                                <p className="text-sm truncate w-full text-center">{structureBooking?.user?.profession?.name}</p>
                            </Link>
                        })}
                    </div>

                    {booking.structure.bookings.length > maxBookingsToShow && <div className="flex justify-center">
                        <button type="button" onClick={() => setIsOpen(!isOpen)} className="flex flex-col items-center hover:animate-bounce duration-500">
                            {isOpen && <ArrowUp />}
                            {isOpen ? i18n.t('show_less') : i18n.t('view_all')}
                            {!isOpen && <ArrowDown />}
                        </button>
                    </div>}
                </div>
            }

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-4  border-gray-200">
                <p className="text-3xl font-bold">{i18n.t('recommended_activities')}</p>
                {
                    booking.structure.suggestions.length > 0
                        ? <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            {booking.structure.suggestions.map((activity: any, index: number) => (
                                <div key={index} className='flex flex-col gap-4 h-full'>
                                    <SuggestedActivityBox activity={activity} hasCommonTag={activity.hasCommonTag} />
                                </div>
                            ))
                            }
                        </div>
                        : <p className="text-sm font-medium text-gray-400 text-center">{i18n.t('no_activities_recommended_this_coliving')}</p>
                }
            </div>

            <Modal isOpen={accordionOpen} refs={accordionRef} className="flex flex-col gap-6 w-fit md:w-96" heightFit>
                <p className="text-4xl font-bold">{i18n.t('how_do_affinities_work')}</p>
                <div className="flex gap-4 items-start">
                    <div className="w-fit">
                        <WorkAffinityIconXL />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl font-medium">{i18n.t('work_affinity')}</p>
                        <p className="text-sm">{i18n.t('work_affinity_message')}</p>
                    </div>
                </div>
                <div className="flex gap-4 items-start">
                    <div className="w-fit">
                        <TagCategoryAffinityIconXL />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl font-medium">{i18n.t('interest_affinity')}</p>
                        <p className="text-sm">{i18n.t('interest_affinity_message')}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-xl font-medium">{i18n.t('how_to_use_affinities')}</p>
                    <p className="text-sm" dangerouslySetInnerHTML={{__html:i18n.t('how_to_use_affinities_message', {escapeValue:false})}}></p>
                </div>
                <button onClick={() => setAccordionOpen(false)} className="flex self-center px-4 py-1 rounded-full bg-light_black w-fit text-white font-medium">{i18n.t('ok_i_got_it')}</button>
            </Modal>
        </div>
    )
}

export default Booking