import { BOOKING_STATUS } from "../../enums/booking_status.enum";
import { IModel } from "./index.interface";
import { IStructure, ListStructureInput } from "./structure.interface";
import { IUser, ListUserInput } from "./user.interface";

export interface IBooking extends IModel {
    id: string;
    fromDate: Date;
    toDate: Date;
    acceptedAt?: Date;
    room: string;
    status: BOOKING_STATUS;

    user: IUser;
    user_id: string;
    structure: IStructure;
    structure_id: string;
};

export interface ListBookingInput {
    user?: boolean | { include: ListUserInput };
    structure?: boolean | { imclude: ListStructureInput };
};

export const fieldTypesBooking: Record<string, string> = {
    id: 'text',
    fromDate: 'date',
    toDate: 'date',
    acceptedAt: 'date',
    room: 'text',
    status: 'enum',
    created_at: 'text',
    updated_at: 'text',
    deleted: 'checkbox',
    version: 'number',
};