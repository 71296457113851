export const SuccessIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            fill="none"
        >
            <circle cx={16} cy={16} r={16} fill="#000" />
            <mask
                id="a"
                width={24}
                height={24}
                x={4}
                y={4}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: "alpha",
                }}
            >
                <path fill="#D9D9D9" d="M4 4h24v24H4z" />
            </mask>
            <g mask="url(#a)">
                <path fill="#fff" d="m14 20.4-4-4 1.4-1.4 2.6 2.6 6.6-6.6 1.4 1.4-8 8Z" />
            </g>
        </svg>
    )
};