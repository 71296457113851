import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { HttpMethod, apiService } from '../../api/apiService';
import { StructureCoverImageIconXS } from '../../utils/icons/StructureIcon';
import TutorialChatBot from '../../components/TutorialChatBot';
import { useAppSelector } from '../../redux/store';
import { IUser } from '../../utils/interfaces/db_models/user.interface';
import { CrossIcon } from '../../utils/icons/CrossIcon';
import ItineraryBox from '../../components/ItineraryBox';
import { IBooking } from '../../utils/interfaces/db_models/bookings.interface';
import { IStructure } from '../../utils/interfaces/db_models/structure.interface';
import { AutoGenerateIcon } from '../../utils/icons/AutoGenerateIcon';
import LoadingIndicator from '../../components/LoadingIndicator';
import { LocationIcon } from '../../utils/icons/LocationIcon';
import { WarningIcon } from '../../utils/icons/WarningIcon';
import { ISuggestion } from '../../utils/interfaces/db_models/suggestion.interface';
import { ITagCategory } from '../../utils/interfaces/db_models/tagCategory.interface';
import i18n from './../../i18n';


const ItineraryChatbot: React.FC = () => {
    const navigate = useNavigate()

    const { user } = useAppSelector(store => store.auth) as { user: IUser };
    const { bookings, itineraries, suggestions } = useLoaderData() as { bookings: IBooking[], suggestions: any[], itineraries: any[] }

    const [generated, setGenerated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bestSuggestions, setBestSuggestions] = useState([]);
    const [selectedStructure, setSelectedStructure] = useState<IStructure | undefined>(undefined);

    const [isSaving, setIsSaving] = useState(false);
    const [viewItinerary, setViewItinerary] = useState(false);
    const [isImproving, setIsImproving] = useState<boolean>(false);
    const [isSuggestionView, setIsSuggestionView] = useState({
        view: false,
        currentSuggestionId: null
    })

    useEffect(() => {
        if (generated === true) {
            setIsLoading(false);
        }
    }, [generated]);

    useEffect(() => {
        const storedStructureId = localStorage.getItem('selectedStructureId');
        if (storedStructureId) {
            const selectedStructure = bookings.find(booking => booking.structure_id === storedStructureId)?.structure

            if (selectedStructure) {
                setSelectedStructure(selectedStructure);
            } else {
                localStorage.removeItem('selectedStructureId');
            }
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            userInput: '',
        },
        validationSchema: Yup.object({
            userInput: Yup.string().required('Required'),
        }),
        onSubmit: () => {
            callGenerateItinerary()
        }
    });


    const callGenerateItinerary = async () => {
        try {
            setViewItinerary(false);
            setIsLoading(true);
            const data = { userInput: formik.values.userInput, structureId: selectedStructure?.id }

            const response = await apiService(HttpMethod.POST, `/itineraries/generate`, data, null, { multipart: false });

            if (response?.disabled) {
                setIsLoading(false)
                setGenerated(true)
                setBestSuggestions([])
                return
            }

            setGenerated(true)
            setBestSuggestions(response)
        } catch (error: any) {
            setIsLoading(false)
            throw error
        }
    }

    const handleGoToMaps = () => {
        const destinations = bestSuggestions.map((suggestion: any) => {
            return {
                lat: suggestion.latitude,
                lng: suggestion.longitude
            }
        })

        const url = `https://www.google.com/maps/dir/${selectedStructure?.latitude},${selectedStructure?.longitude}/${destinations.map((dest: any) => `${dest.lat},${dest.lng}`).join('/')}`

        if (bestSuggestions.length < 1) return console.error('No destinations found')

        // redirect to google maps in a new tab
        window.open(url, '_blank');
    };

    // buttons logic
    const deleteSuggestion = (suggestion: any) => {
        if (suggestion.id === undefined) return console.error('No suggestion id found')
        const newSuggestions = bestSuggestions.filter((s: any) => s.id !== suggestion.id)
        setBestSuggestions(newSuggestions)
    }

    const changeSuggestion = (suggestion: any) => {

        if (suggestion.id === undefined) return console.error('No suggestion id found')

        // set the view mode to select a new suggestion for the current one
        setIsSuggestionView({
            view: true,
            currentSuggestionId: suggestion.id
        })
    }

    const overwriteSuggestion = (newSuggestion: any) => {
        if (isSuggestionView.currentSuggestionId === null) return console.error('No suggestion id found')

        const newSuggestions: any = bestSuggestions.map((s: any) => {
            if (s.id === isSuggestionView.currentSuggestionId) {
                return newSuggestion
            }
            return s
        })

        setBestSuggestions(newSuggestions)
        setIsSuggestionView({
            view: false,
            currentSuggestionId: null
        })
    }

    const closeSuggestionView = () => {
        setIsSuggestionView({
            view: false,
            currentSuggestionId: null
        })
    }

    const saveItinerary = async () => {
        const bestSuggestionsIds = bestSuggestions.map((suggestion: any) => suggestion.id)
        const data = {
            suggestionsIds: bestSuggestionsIds
        }

        try {
            setIsSaving(true);

            await apiService(HttpMethod.POST, `/itineraries/save`, data, null, { multipart: false });

            setIsSaving(false);
            setGenerated(false);
            navigate('/app/itinerary/chatbot')
        } catch (error: any) {
            setIsSaving(false)
            throw error
        }
    }

    const deleteItinerary = async (itineraryId: any) => {
        if (itineraryId === undefined) return console.error('No itinerary id found')

        const data = {
            itineraryId: itineraryId
        }

        try {
            setIsSaving(true);

            await apiService(HttpMethod.POST, `/itineraries/delete`, data, null, { multipart: false });

            setIsSaving(false);
            navigate('/app/itinerary/chatbot')
        } catch (error: any) {
            setIsSaving(false);
            throw error
        }
    }

    const onPressViewItinerary = (itineraryId: any) => {
        setViewItinerary(true)
        setBestSuggestions(itineraries.find((itinerary: any) => itinerary.id === itineraryId).suggestions)
        setGenerated(true)
    };

    const handleImprovePrompt = async () => {
        try {
            setIsImproving(true)
            const response = await apiService(HttpMethod.POST, `/itineraries/improve-prompt`, { input: formik.values.userInput }, null, { multipart: false });

            formik.setFieldValue('userInput', response);
            setIsImproving(false)
        } catch (error: any) {
            setIsImproving(false)
            throw error
        }
    }

    if (bookings.length <= 0) {
        return <div className='container m-auto px-4'>
            <div className='flex flex-col gap-6 py-6 h-screen justify-center'>
                <p className='text-6xl font-medium text-center'>{i18n.t('you_have_no_active_bookings')}</p>
                <p className='text-2xl font-medium text-center' dangerouslySetInnerHTML={{__html:i18n.t('to_use_itinerary_generator', {escapeValue:false})}}></p>
            </div>
        </div>
    } else if (!selectedStructure) {
        return <div className='container m-auto px-4 py-8 flex flex-col gap-8'>

            <div className='flex flex-col gap-1'>
                <p className='font-bold text-4xl text-light_black'>{i18n.t('your_accommodation')}</p>
                <p className='font-medium text-xl text-light_black opacity-80'>{i18n.t('choose_the_accommodation_where_you_are')}</p>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-4'>
                {Array.from(new Set(bookings.map(booking => booking.structure_id)))
                    .map((structureId) => {
                        const booking = bookings.find(b => b.structure_id === structureId);
                        if (booking) {
                            const isAvailable = booking.structure.suggestions.length >= 10;
                            return (
                                <button
                                    type='button'
                                    key={structureId}
                                    onClick={() => {
                                        setSelectedStructure(booking.structure);
                                        localStorage.setItem('selectedStructureId', booking.structure_id);
                                    }}
                                    disabled={!isAvailable}
                                    className={`flex flex-col justify-between aspect-square rounded transition-transform transform ${isAvailable ? 'hover:scale-105 cursor-pointer' : 'grayscale'}`}
                                    style={{ boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.2)' }}
                                >
                                    <img className='w-full h-1/2 object-cover rounded-t' src={booking.structure.cover_image} alt='img_coliving' />

                                    {isAvailable && (
                                        <div className="bg-light_black font-semibold text-lg text-white rounded-full px-4 py-0.5 absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                            {i18n.t('select')}
                                        </div>
                                    )}

                                    <div className='flex flex-col justify-center gap-1 items-center text-center w-full h-1/2 p-4'>
                                        <p className='font-semibold text-2xl line-clamp-1 capitalize'>{booking.structure.name}</p>
                                        <p className='text-xs line-clamp-1'>{booking.structure.city}</p>
                                        {!isAvailable && (
                                            <p className='text-red-500 text-xs font-medium'>
                                                {i18n.t('not_offer_activity_recommendation_service')}
                                            </p>
                                        )}
                                    </div>
                                </button>
                            )
                        } else {
                            return null
                        }
                    })}
            </div>
        </div>
    }

    return (
        <div className='container m-auto p-4'>

            <div className='flex items-center justify-between pb-4 border-b border-light_black' >
                <div className='flex items-center gap-4 text-2xl font-semibold text-light_black bg-orange capitalize p-1 pr-4 rounded-full w-fit select-none'>
                    <img
                        alt='img-profile'
                        height={30}
                        width={30}
                        className='aspect-square rounded-full object-cover bg-white p-0.5'
                        src={selectedStructure.logo_image}
                    />
                    {selectedStructure.name}
                </div>
                <button
                    type='button'
                    onClick={() => {
                        setSelectedStructure(undefined);
                        localStorage.removeItem('selectedStructureId');
                        setGenerated(false);
                        setBestSuggestions([]);
                    }}
                    className='text-orange font-medium text-xl'>{i18n.t('change_accommodation')}</button>
            </div>

            {isSuggestionView.view && isLoading === false && <SuggestionView closeSuggestionView={closeSuggestionView} loadedSuggestions={suggestions} overwriteSuggestion={overwriteSuggestion} bestSuggestions={bestSuggestions} selectedSuggestion={isSuggestionView.currentSuggestionId} />}

            {isSuggestionView.view === false &&
                <div className='flex flex-col flex-1 gap-6 py-6 justify-between'>

                    {isLoading === true &&
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            <LoadingIndicator icon />
                            <p className='text-2xl lg:text-4xl font-semibold text-center mb-4 mt-10'>{i18n.t('we_are_generating_your_itinerary')}</p>
                        </div>
                    }

                    {generated !== true && isLoading === false &&
                        <>
                            <p className='text-3xl md:text-6xl font-semibold text-center mb-4 mt-20'>{i18n.t('whats_on_your_mind')}</p>

                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-col items-end w-3/5 m-auto'>
                                    <button type='button' disabled={isImproving || formik.values.userInput === ''} className='font-bold disabled:opacity-50 w-fit rounded-full flex items-center gap-2' onClick={handleImprovePrompt}>
                                        <AutoGenerateIcon color='#1A1C20' /> {i18n.t('enhance_with_ai')} {isImproving && <span className="animate-loading-dots"></span>}
                                    </button>
                                </div>
                                <textarea
                                    rows={4}
                                    maxLength={300}
                                    name='userInput'
                                    disabled={isImproving}
                                    placeholder={i18n.t('write_a_message')}
                                    className='border border-light_black rounded-md p-2 w-3/5 m-auto mb-4'
                                    value={formik.values.userInput}
                                    onChange={formik.handleChange}
                                />
                                <div className='flex flex-col items-end w-3/5 m-auto'>
                                    <p className='font-semibold text-lg'>{formik.values.userInput.length}/300</p>
                                    <p className='font-semibold text-sm opacity-80'>{i18n.t('max_300_characters')}</p>
                                </div>
                            </div>

                            <button
                                type='button'
                                disabled={isImproving}
                                onClick={() => formik.handleSubmit()}
                                className='bg-light_black text-white font-bold py-2 px-4 w-fit m-auto rounded-full'>
                                {i18n.t('generate_itinerary')} {isLoading && <span className="animate-loading-dots"></span>}
                            </button>
                        </>
                    }

                    {generated === true && bestSuggestions.length > 0 &&
                        <>
                            <div className='m-auto w-full flex flex-col mt-20'>
                                <p className='text-4xl md:text-6xl font-semibold text-center mb-4'>{i18n.t('your_itinerary')}</p>
                                <p className='text-xl text-center mb-20' dangerouslySetInnerHTML={{__html:i18n.t('we_have_created_an_itinerary_for_you', {escapeValue:false})}}></p>

                                {
                                    bestSuggestions.length > 0 && bestSuggestions.map((res: any, index: number) => (
                                        <div key={index} className='flex flex-col gap-4 md:w-1/2 m-auto'>
                                            <SuggestionBox suggestion={res} changeSuggestion={changeSuggestion} deleteSuggestion={deleteSuggestion} />
                                        </div>
                                    ))
                                }
                            </div>

                            <p className='text-4xl font-semibold text-center mt-20'>{i18n.t('ready_to_go')}</p>
                            <p className='text-xl text-center mb-4' dangerouslySetInnerHTML={{__html:i18n.t('ready_to_go_maps', {escapeValue:false})}}></p>

                            <button
                                type='button'
                                className='bg-light_black text-white font-bold py-2 px-4 w-fit rounded-full m-auto'
                                onClick={() => {
                                    handleGoToMaps()
                                }}
                            >
                                {i18n.t('access_the_map')}
                            </button>


                            <p className='text-4xl font-semibold text-center mt-20'>{i18n.t('not_convinced')}</p>
                            <p className='text-xl text-center mb-4' dangerouslySetInnerHTML={{__html:i18n.t('not_convinced_message', {escapeValue:false})}}></p>

                            <button
                                type='button'
                                className='bg-light_black text-white font-bold py-2 px-4 w-fit rounded-full m-auto'
                                onClick={() => setGenerated(false)}
                            >
                                {i18n.t('regenerate_itinerary')}
                            </button>

                            {!viewItinerary &&
                                <button
                                    type='button'
                                    className='bg-white border mb-10 border-light_black text-light_black font-medium py-2 px-4 w-fit rounded-full m-auto'
                                    onClick={() => saveItinerary()}
                                    disabled={isSaving}
                                >
                                    {i18n.t('save_for_later')} {isSaving && <span className="animate-loading-dots"></span>}
                                </button>
                            }

                        </>
                    }

                    {generated === true && bestSuggestions.length <= 0 &&
                        <>
                            <div className='flex flex-col gap-6 h-screen justify-center items-center'>
                                <p className='text-6xl font-medium text-center'>{i18n.t('no_recommended_activities_found')}</p>
                                <p className='text-2xl font-medium text-center' dangerouslySetInnerHTML={{__html:i18n.t('no_recommended_activities_found_message', {escapeValue:false})}}></p>
                                <button
                                    type='button'
                                    className='bg-white border mb-10 border-light_black text-light_black font-medium py-2 px-4 w-fit rounded-full mx-auto'
                                    onClick={() => setGenerated(false)}
                                >
                                    {i18n.t('regenerate')}
                                </button>
                            </div>
                        </>}

                    {(itineraries.length > 0 && !generated && !isLoading)
                        ? <>
                            <div className='w-full mt-20 mb-20' style={{ height: 1, backgroundColor: '#000' }}></div>
                            <p className='text-4xl md:text-6xl font-semibold text-center'>{i18n.t('my_itineraries')}</p>
                            <p className='text-xl font-semibold text-center mb-4'>{i18n.t('my_itineraries_message')}</p>

                            <div className='gap-20  px-4 grid grid-cols-1 md:grid-cols-3'>
                                {itineraries.length > 0 && itineraries.map((itinerary: any, index: number) => (
                                    <div key={index} className='flex flex-col gap-4 w-full m-auto'>
                                        <ItineraryBox viewItinerary={onPressViewItinerary} itinerary={itinerary} deleteItinerary={deleteItinerary} isLoading={isSaving} />
                                    </div>
                                ))}
                            </div>
                        </>
                        : null
                    }

                </div>
            }

            {(!!user && !user.chatbotTutorial) && <TutorialChatBot />}

        </div>
    )
}

interface IProps {
    suggestion: any;
    deleteSuggestion: any;
    changeSuggestion: any;
}

export const SuggestionBox: React.FC<IProps> = ({ suggestion, deleteSuggestion, changeSuggestion }) => {
    return (
        <div className='flex flex-col border-y border-gray' >
            {!!suggestion.image
                ? <div className='relative'>
                    <img
                        className={`w-full object-cover aspect-video ${suggestion.isDeleted && 'grayscale-0'} `}
                        src={suggestion.image}
                        alt='img_activity'
                    />
                    <div className='absolute top-0 right-1/2 translate-x-1/2 -translate-y-1/2'>
                        <div className='cursor-pointer' onClick={() => {
                            deleteSuggestion(suggestion)
                        }}>
                            <CrossIcon />
                        </div>
                    </div>
                    <div className='absolute bottom-0 right-1/2 translate-x-1/2 translate-y-1/2'>
                        <p onClick={() => changeSuggestion(suggestion)} className='cursor-pointer text-lg text-center text-white p-2 bg-light_black px-10 py-2 w-fit rounded-full'>{i18n.t('change')}</p>
                    </div>
                </div>
                : <div className='w-full h-1/2 object-cover rounded-b flex justify-center items-center' style={{ backgroundColor: '#F1F1F1' }}>
                    <StructureCoverImageIconXS />
                </div>
            }

            <div className='flex flex-col justify-center gap-2 items-center text-center h-1/2 mb-8 pt-10 px-2'>
                {suggestion.isDeleted && <div className='flex flex-col items-center gap-2 text-sm text-orange font-medium'><WarningIcon /> {i18n.t('this_activity_is_no_longer_recommended_by_your_coliving')}</div>}
                <p className='font-bold text-2xl mb-2'>{suggestion.title}</p>
                <p className='font-medium'>{suggestion.city}</p>
                {!!suggestion.distance && <p className='text-xs font-medium flex items-center gap-1 justify-center'><LocationIcon /> {Number(suggestion.distance).toFixed(2)} {i18n.t('km_from_your_coliving')}</p>}
                <p className='text-left my-4'>{suggestion.description}</p>
            </div>
        </div>
    )
}

interface ISuggestionView {
    loadedSuggestions: any;
    overwriteSuggestion: any;
    bestSuggestions: any;
    closeSuggestionView: any;
    selectedSuggestion: any;
}

const SuggestionView: React.FC<ISuggestionView> = ({ loadedSuggestions, overwriteSuggestion, bestSuggestions, closeSuggestionView, selectedSuggestion }) => {

    const [selectableSuggestions, setSelectableSuggestions] = useState([]);

    const getActivitySelectable = (currentSuggestions: any, allSuggestions: any) => {
        let tags: ITagCategory[] = [];
        for (const suggestion of allSuggestions) {
            if (suggestion.id === selectedSuggestion) {
                tags = suggestion.tagCategories
            }
        }

        let selectableSuggestion = [];

        // Prendo le attivita differenti dalle 4 dell'itinerario.
        selectableSuggestion = allSuggestions.filter((suggestion: any) => {
            return !currentSuggestions.some((bestSuggestion: any) => bestSuggestion.id === suggestion.id)
        });

        // Filtro le attivita per i tag selezionati
        selectableSuggestion = selectableSuggestion.filter((suggestion: any) => {
            return suggestion.tagCategories.some((tag: ITagCategory) => tags?.some((tagSelected: ITagCategory) => tagSelected.id === tag.id))
        });

        if (selectableSuggestion.length <= 0) {
            return allSuggestions.filter((suggestion: any) => {
                return !currentSuggestions.some((bestSuggestion: any) => bestSuggestion.id === suggestion.id)
            });
        } else {
            return selectableSuggestion as any;
        }
    };

    useEffect(() => {
        setSelectableSuggestions(getActivitySelectable(bestSuggestions, loadedSuggestions))
    }, [bestSuggestions])

    return (
        <>
            {/* header */}
            <div className='w-full mt-20 mb-20'>
                <p className='text-6xl font-medium text-center'>{i18n.t('choose_a_stop')}</p>
                {selectableSuggestions.length <= 0 && <p className='text-2xl font-medium text-center mt-6'>
                    {i18n.t('there_are_no_other_stops_available')}
                </p>}
            </div>

            {/* suggestions */}
            <div className='flex flex-col gap-20 w-1/2 m-auto'>
                {selectableSuggestions.map((suggestion: any, index: number) => {
                    return <SuggestionBoxSelect key={index} suggestion={suggestion} changeSuggestion={() => { overwriteSuggestion(suggestion) }} />
                })}
            </div>

            {/* back button */}
            <div onClick={closeSuggestionView} className='mb-20 mt-8 m-auto cursor-pointer text-lg text-center text-white p-2 bg-light_black px-10 py-2 w-fit rounded-full'>{i18n.t('back')}</div>
        </>
    )
}

interface ISuggestionBoxSelect {
    suggestion: any;
    changeSuggestion: any;
}

export const SuggestionBoxSelect: React.FC<ISuggestionBoxSelect> = ({ suggestion, changeSuggestion }) => {
    return (
        <div className='flex flex-col border-y border-light_black' >
            {!!suggestion.image
                ? <div className='relative'>
                    <img
                        className='w-full object-cover aspect-video'
                        src={suggestion.image}
                        alt='img_activity'
                    />
                    <div className='absolute top-0 right-1/2 translate-x-1/2 -translate-y-1/2'>
                        <div className='absolute top-0 right-1/2 translate-x-1/2 -translate-y-1/2'>
                            <p className='text-lg font-medium whitespace-nowrap px-8 py-2 rounded-full' style={{ backgroundColor: '#D9981D' }}>{i18n.t('recommended_by_your_coliving')}</p>
                        </div>
                    </div>
                    <div className='absolute bottom-0 right-1/2 translate-x-1/2 translate-y-1/2'>
                        <p onClick={() => changeSuggestion(suggestion)} className='cursor-pointer text-lg text-center text-white p-2 bg-light_black px-10 py-2 w-fit rounded-full'>{i18n.t('select')}</p>
                    </div>
                </div>
                : <div className='w-full h-1/2 object-cover rounded-b flex justify-center items-center' style={{ backgroundColor: '#F1F1F1' }}>
                    <StructureCoverImageIconXS />
                </div>
            }

            <div className='flex flex-col justify-center gap-2 items-center text-center h-1/2'>
                <div className='flex flex-col mb-2'>
                    <p className='font-semibold text-2xl mb-2 mt-10'>{suggestion.title}</p>
                    <p className='text-md'>{suggestion.city}, {suggestion.address}</p>
                    <p className='text-md text-left my-4'>{suggestion.description}</p>
                </div>
            </div>

        </div>
    )
}

export default ItineraryChatbot