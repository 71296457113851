export const AdminPanelIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_735_372" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                <circle cx="15" cy="15" r="15" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_735_372)">
                <rect width="30" height="30" fill="#1A1C20" />
                <path d="M15 15.3768C15.9552 15.3768 16.7612 15.058 17.4179 14.4203C18.0746 13.7826 18.403 13 18.403 12.0725C18.403 11.1449 18.0746 10.3623 17.4179 9.72464C16.7612 9.08696 15.9552 8.76812 15 8.76812C14.0448 8.76812 13.2388 9.08696 12.5821 9.72464C11.9254 10.3623 11.597 11.1449 11.597 12.0725C11.597 13 11.9254 13.7826 12.5821 14.4203C13.2388 15.058 14.0448 15.3768 15 15.3768ZM15 25C12.5323 24.2464 10.5821 22.8454 9.14925 20.7971C7.71642 18.7488 7 16.4589 7 13.9275V7.89855L15 5L23 7.89855V13.9275C23 16.4589 22.2836 18.7488 20.8507 20.7971C19.4179 22.8454 17.4677 24.2464 15 25ZM15 24.1304C16.0348 23.8019 16.9801 23.3188 17.8358 22.6812C18.6915 22.0435 19.4279 21.2899 20.0448 20.4203C19.2687 20.0338 18.4577 19.7343 17.6119 19.5217C16.7662 19.3092 15.8955 19.2029 15 19.2029C14.1045 19.2029 13.2338 19.3092 12.3881 19.5217C11.5423 19.7343 10.7313 20.0338 9.95522 20.4203C10.5721 21.2899 11.3085 22.0435 12.1642 22.6812C13.0199 23.3188 13.9652 23.8019 15 24.1304Z" fill="white" />
            </g>
        </svg>
    )
};

export const AdminPanelIconActive = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_735_372" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                <circle cx="15" cy="15" r="15" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_735_372)">
                <rect width="30" height="30" fill="#D9981D" />
                <path d="M15 15.3768C15.9552 15.3768 16.7612 15.058 17.4179 14.4203C18.0746 13.7826 18.403 13 18.403 12.0725C18.403 11.1449 18.0746 10.3623 17.4179 9.72464C16.7612 9.08696 15.9552 8.76812 15 8.76812C14.0448 8.76812 13.2388 9.08696 12.5821 9.72464C11.9254 10.3623 11.597 11.1449 11.597 12.0725C11.597 13 11.9254 13.7826 12.5821 14.4203C13.2388 15.058 14.0448 15.3768 15 15.3768ZM15 25C12.5323 24.2464 10.5821 22.8454 9.14925 20.7971C7.71642 18.7488 7 16.4589 7 13.9275V7.89855L15 5L23 7.89855V13.9275C23 16.4589 22.2836 18.7488 20.8507 20.7971C19.4179 22.8454 17.4677 24.2464 15 25ZM15 24.1304C16.0348 23.8019 16.9801 23.3188 17.8358 22.6812C18.6915 22.0435 19.4279 21.2899 20.0448 20.4203C19.2687 20.0338 18.4577 19.7343 17.6119 19.5217C16.7662 19.3092 15.8955 19.2029 15 19.2029C14.1045 19.2029 13.2338 19.3092 12.3881 19.5217C11.5423 19.7343 10.7313 20.0338 9.95522 20.4203C10.5721 21.2899 11.3085 22.0435 12.1642 22.6812C13.0199 23.3188 13.9652 23.8019 15 24.1304Z" fill="white" />
            </g>
        </svg>
    )
}