import { RouterProvider } from 'react-router-dom';
import { routing } from './routes/Routing';
import moment from 'moment';
import 'moment/locale/it';
import { useEffect, useState } from 'react';
import { useAppSelector } from './redux/store';
import i18n from './i18n';
import axios from 'axios';

moment.locale('it');

function App() {

  const currentLanguage = useAppSelector((state) => state.language.language);
  const [appLanguage, setAppLanguage] = useState<string>(currentLanguage);

  useEffect(() => {
      if (currentLanguage) {
          i18n.changeLanguage(currentLanguage); // Aggiorna la lingua di i18n
          moment.locale(currentLanguage);
          setAppLanguage(currentLanguage);
          axios.defaults.headers.common['Accept-Language'] = currentLanguage;
      }
  }, [currentLanguage]);

  return (
    <RouterProvider key={appLanguage} router={routing} />
  );
}

export default App;
