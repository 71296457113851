import { useLoaderData } from "react-router-dom"
import i18n from './../../i18n';

const AdminHome = () => {

    const response = useLoaderData() as {
        totalUsers: number,
        userCountByCategory: { [category: string]: { count: number; percentage: number; } },
        totalStructures: number,
        structureCountByRegion: { [region: string]: number },
        totalSuggestion: number,
        suggestionsTagCategory: { id: string, name: string, _count: { suggestions: number } }[]
    };

    return (
        <div className='flex flex-col gap-10 px-10 sm:px-14 py-6 w-full h-full select-none'>

            <p className="font-bold text-5xl -mb-4">{i18n.t('overview')}</p>

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <p className="font-semibold text-2xl">{i18n.t('colivings')}</p>
                    <p className="font-semibold text-2xl">{i18n.t('totals')} {response.totalStructures}</p>
                </div>

                {Object.entries(response.userCountByCategory).length > 0
                    ? <div className="flex flex-col gap-4">
                        {Object.entries(response.structureCountByRegion)
                            .sort(([_, value], [__, value2]) => value2 - value)
                            .map(([key, value], index) => (
                                <div key={index} className="flex flex-col gap-4 first:text-orange">
                                    <div className="flex justify-between items-center">
                                        <p className="font-medium">{key}</p>
                                        <p className="font-medium">{value}</p>
                                    </div>
                                    <div className="border-t border-gray-300" />
                                </div>
                            ))}
                    </div>
                    : <p className="font-medium text-gray-500 text-center">{i18n.t('no_colivings')}</p>
                }
            </div>

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-6">
                <div className="flex justify-between items-center">
                    <p className="font-semibold text-2xl">{i18n.t('users')}</p>
                    <p className="font-semibold text-2xl">{i18n.t('totals')} {response.totalUsers}</p>
                </div>

                {Object.entries(response.userCountByCategory).length > 0
                    ? <div className="flex flex-col gap-4">
                        {Object.entries(response.userCountByCategory)
                            .sort(([_, value], [__, value2]) => value2.count - value.count)
                            .map(([key, value], index) => (
                                <div key={index} className="flex flex-col gap-4 first:text-orange">
                                    <div className="flex justify-between items-center">
                                        <p className="font-medium">{key}</p>
                                        <p className="font-medium">{value?.count}</p>
                                    </div>
                                    <div className="border-t border-gray-300" />
                                </div>
                            ))}
                    </div>
                    : <p className="font-medium text-gray-500 text-center">{i18n.t('no_users')}</p>
                }
            </div>

            <div className="border-t shadow -mx-20" />

            <div className="flex flex-col gap-6 pb-10">
                <div className="flex justify-between items-center">
                    <p className="font-semibold text-2xl">{i18n.t('activities')}</p>
                    <p className="font-semibold text-2xl">{i18n.t('totals')} {response.totalSuggestion}</p>
                </div>

                {response.suggestionsTagCategory.length > 0
                    ? <div className="flex flex-col gap-4">
                        {response.suggestionsTagCategory
                            .sort((value, value2) => value2._count.suggestions - value._count.suggestions)
                            .map((value, index) => (
                                <div key={index} className="flex flex-col gap-4 first:text-orange">
                                    <div className="flex justify-between items-center">
                                        <p className="font-medium">{value.name}</p>
                                        <p className="font-medium">{value._count.suggestions}</p>
                                    </div>
                                    <div className="border-t border-gray-300" />
                                </div>
                            ))}
                    </div>
                    : <p className="font-medium text-gray-500 text-center">{i18n.t('no_activities')}</p>
                }
            </div>
        </div>
    )
}

export default AdminHome